import { useCallback, useEffect, useState } from 'react'
import URLParse from 'url-parse'

import { qs } from '../../../#/shared/qs'
import { setLoadingForLogin } from '../context/actions/auth/setLoadingForLogin'

export interface ILinkedInLoginHook {
  redirectUri: string
  clientId: string
  loginHandler: (code: string) => Promise<void>
  stateCode: string
  scope: string
  closePopupMessage?: string
}

const LINKEDIN_URL = 'https://www.linkedin.com'
const LINKED_RESPONSE_TYPE = 'code'

export const useLinkedInLogin = ({
  redirectUri,
  clientId,
  loginHandler,
  scope,
  stateCode,
}: ILinkedInLoginHook) => {
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    const q = new URLParse(location.href, true).query
    const code = q.code
    const state = q.state
    if (state?.includes('_linkedin') && code) {
      localStorage.setItem('linkedin', code)
      window.close()
    }
  }, [])

  const onChangeLocalStorage = useCallback(() => {
    window.removeEventListener('storage', onChangeLocalStorage, false)
    setLoadingForLogin(true)
    const code = localStorage.getItem('linkedin')
    if (code) {
      handleLoginLinkedIn(code)
      localStorage.removeItem('linkedin')
    } else {
      setIsProcessing(false)
      setLoadingForLogin(false)
    }
  }, [])

  const handleLoginLinkedIn = async (code: string) => {
    setIsProcessing(true)
    await loginHandler(code)
    setIsProcessing(false)
  }

  const getUrl = () => {
    const query = qs.stringify({
      response_type: LINKED_RESPONSE_TYPE,
      client_id: clientId,
      redirect_uri: redirectUri,
      state: stateCode + '_linkedin',
      scope,
    })
    const linkedInAuthLink = `${LINKEDIN_URL}/oauth/v2/authorization?${query}`
    return linkedInAuthLink
  }

  const linkedInLogin = useCallback(() => {
    if (!isProcessing) {
      window.addEventListener('storage', onChangeLocalStorage, false)
      const oauthUrl = getUrl()
      const width = 450
      const height = 730
      const left = window.screen.width / 2 - width / 2
      const top = window.screen.height / 2 - height / 2
      window.open(
        oauthUrl,
        'Linkedin',
        'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
          width +
          ', height=' +
          height +
          ', top=' +
          top +
          ', left=' +
          left,
      )
    }
  }, [isProcessing])

  return linkedInLogin
}
