import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

// eslint-disable-next-line css-modules/no-unused-class
import css from './LiveItemCommon.module.scss'

import { S } from '../../../context/store'
import { ItemAccordion } from './ItemAccordion'
import { PlayLists } from './MediaPlaylists'

export const LiveMedia = observer(() => {
  const fullPlaylist = S.webrtc.getFullMediaPlaylists()
  const listFilterOnAir = fullPlaylist
    .filter(
      item =>
        !S.webrtc.isOnAirItemOfLayout(S.webrtc.layoutMedias, item.mediaId) &&
        item.value !== S.webrtc.backgroundUrl &&
        item.value !== S.webrtc.backgroundVideoUrl,
    )
    .slice(0, 2)

  useEffect(() => {
    S.webrtc.updateDataOfStore({
      mediaSelectionId: [],
    })
  }, [])

  return (
    <div className={css.LiveItemContainer}>
      <ItemAccordion label='Media'>
        <div className={css.LiveItemAccordionContent}>
          {listFilterOnAir.length > 0 ? (
            <div className={css.ListItemLive}>
              <PlayLists playlists={listFilterOnAir} />
            </div>
          ) : (
            <span className={css.NotYetText}>There are no title yet</span>
          )}
        </div>
      </ItemAccordion>
    </div>
  )
})
