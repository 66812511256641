/* eslint-disable */
import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
const gqlOperationsFactory = {} as GqlOperationsFactory
export const gqlOperations = (client: Urql.Client): GqlOperations =>
  Object.entries(gqlOperationsFactory).reduce<any>((m, [k, v]) => {
    m[k] = v(client)
    return m
  }, {})
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: Date; output: Date }
  Email: { input: string; output: string }
  Json: { input: any; output: any }
  NFloat: { input: number; output: number }
  NInt: { input: number; output: number }
  NeString: { input: string; output: string }
  NpFloat: { input: number; output: number }
  NpInt: { input: number; output: number }
  Null: { input: null; output: null }
  OrgPermissions: {
    input: Record<OrgPermission, boolean>
    output: Record<OrgPermission, boolean>
  }
  PFloat: { input: number; output: number }
  PInt: { input: number; output: number }
  Password: { input: any; output: any }
  SDate: { input: string; output: string }
  STime: { input: string; output: string }
  SessionPermissions: {
    input: Record<SessionPermission, boolean>
    output: Record<SessionPermission, boolean>
  }
  SystemPermissions: {
    input: Record<SystemPermission, boolean>
    output: Record<SystemPermission, boolean>
  }
  UFloat: { input: number; output: number }
  UInt: { input: number; output: number }
}

export type Ams = {
  __typename?: 'Ams'
  active: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  createdById: Scalars['ID']['output']
  id: Scalars['ID']['output']
  repeat: Scalars['Boolean']['output']
  session?: Maybe<Session>
  sessionId: Scalars['ID']['output']
  sync: Scalars['Boolean']['output']
}

export type AmsFilter = {
  AND?: InputMaybe<Array<AmsFilter>>
  NOT?: InputMaybe<AmsFilter>
  OR?: InputMaybe<Array<AmsFilter>>
  active?: InputMaybe<Scalars['Boolean']['input']>
  active_ne?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdById?: InputMaybe<Scalars['ID']['input']>
  createdById_in?: InputMaybe<Array<Scalars['ID']['input']>>
  createdById_ne?: InputMaybe<Scalars['ID']['input']>
  createdById_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  createdBy_none?: InputMaybe<UserFilter>
  createdBy_some?: InputMaybe<UserFilter>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  repeat?: InputMaybe<Scalars['Boolean']['input']>
  repeat_ne?: InputMaybe<Scalars['Boolean']['input']>
  sessionId?: InputMaybe<Scalars['ID']['input']>
  sessionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId_ne?: InputMaybe<Scalars['ID']['input']>
  sessionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  session_none?: InputMaybe<SessionFilter>
  session_some?: InputMaybe<SessionFilter>
  sync?: InputMaybe<Scalars['Boolean']['input']>
  sync_ne?: InputMaybe<Scalars['Boolean']['input']>
}

export type AmsOrderBy =
  | 'active_asc'
  | 'active_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'createdById_asc'
  | 'createdById_desc'
  | 'createdBy_avatarUrl_asc'
  | 'createdBy_avatarUrl_desc'
  | 'createdBy_blockedBySystemAdmin_asc'
  | 'createdBy_blockedBySystemAdmin_desc'
  | 'createdBy_createdAt_asc'
  | 'createdBy_createdAt_desc'
  | 'createdBy_currentOrgId_asc'
  | 'createdBy_currentOrgId_desc'
  | 'createdBy_email_asc'
  | 'createdBy_email_desc'
  | 'createdBy_hashedPassword_asc'
  | 'createdBy_hashedPassword_desc'
  | 'createdBy_id_asc'
  | 'createdBy_id_desc'
  | 'createdBy_name_asc'
  | 'createdBy_name_desc'
  | 'createdBy_referralById_asc'
  | 'createdBy_referralById_desc'
  | 'createdBy_rootOrgId_asc'
  | 'createdBy_rootOrgId_desc'
  | 'createdBy_socialLogin_asc'
  | 'createdBy_socialLogin_desc'
  | 'createdBy_stripeCustomerId_asc'
  | 'createdBy_stripeCustomerId_desc'
  | 'createdBy_studioSettings_asc'
  | 'createdBy_studioSettings_desc'
  | 'createdBy_themeWarmness_asc'
  | 'createdBy_themeWarmness_desc'
  | 'createdBy_theme_asc'
  | 'createdBy_theme_desc'
  | 'id_asc'
  | 'id_desc'
  | 'repeat_asc'
  | 'repeat_desc'
  | 'sessionId_asc'
  | 'sessionId_desc'
  | 'session_createdAt_asc'
  | 'session_createdAt_desc'
  | 'session_createdById_asc'
  | 'session_createdById_desc'
  | 'session_description_asc'
  | 'session_description_desc'
  | 'session_endTime_asc'
  | 'session_endTime_desc'
  | 'session_id_asc'
  | 'session_id_desc'
  | 'session_insightEngineId_asc'
  | 'session_insightEngineId_desc'
  | 'session_layout_asc'
  | 'session_layout_desc'
  | 'session_lbAmsId_asc'
  | 'session_lbAmsId_desc'
  | 'session_lbMediasoupId_asc'
  | 'session_lbMediasoupId_desc'
  | 'session_lbMixerId_asc'
  | 'session_lbMixerId_desc'
  | 'session_orgId_asc'
  | 'session_orgId_desc'
  | 'session_settings_asc'
  | 'session_settings_desc'
  | 'session_startTime_asc'
  | 'session_startTime_desc'
  | 'session_title_asc'
  | 'session_title_desc'
  | 'sync_asc'
  | 'sync_desc'

export type AuthSession = {
  __typename?: 'AuthSession'
  createdAt: Scalars['DateTime']['output']
  hasPassword?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  ipAddress: Scalars['String']['output']
  secret: Scalars['String']['output']
  type: Scalars['String']['output']
  user?: Maybe<User>
  userAgent: Scalars['String']['output']
  userId: Scalars['ID']['output']
}

export type AuthSessionFilter = {
  AND?: InputMaybe<Array<AuthSessionFilter>>
  NOT?: InputMaybe<AuthSessionFilter>
  OR?: InputMaybe<Array<AuthSessionFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  ipAddress?: InputMaybe<Scalars['String']['input']>
  ipAddress_endsWith?: InputMaybe<Scalars['String']['input']>
  ipAddress_gt?: InputMaybe<Scalars['String']['input']>
  ipAddress_gte?: InputMaybe<Scalars['String']['input']>
  ipAddress_iLike?: InputMaybe<Scalars['String']['input']>
  ipAddress_in?: InputMaybe<Array<Scalars['String']['input']>>
  ipAddress_like?: InputMaybe<Scalars['String']['input']>
  ipAddress_lt?: InputMaybe<Scalars['String']['input']>
  ipAddress_lte?: InputMaybe<Scalars['String']['input']>
  ipAddress_ne?: InputMaybe<Scalars['String']['input']>
  ipAddress_notILike?: InputMaybe<Scalars['String']['input']>
  ipAddress_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  ipAddress_notLike?: InputMaybe<Scalars['String']['input']>
  ipAddress_startsWith?: InputMaybe<Scalars['String']['input']>
  ipAddress_substring?: InputMaybe<Scalars['String']['input']>
  secret?: InputMaybe<Scalars['String']['input']>
  secret_endsWith?: InputMaybe<Scalars['String']['input']>
  secret_gt?: InputMaybe<Scalars['String']['input']>
  secret_gte?: InputMaybe<Scalars['String']['input']>
  secret_iLike?: InputMaybe<Scalars['String']['input']>
  secret_in?: InputMaybe<Array<Scalars['String']['input']>>
  secret_like?: InputMaybe<Scalars['String']['input']>
  secret_lt?: InputMaybe<Scalars['String']['input']>
  secret_lte?: InputMaybe<Scalars['String']['input']>
  secret_ne?: InputMaybe<Scalars['String']['input']>
  secret_notILike?: InputMaybe<Scalars['String']['input']>
  secret_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  secret_notLike?: InputMaybe<Scalars['String']['input']>
  secret_startsWith?: InputMaybe<Scalars['String']['input']>
  secret_substring?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
  userAgent?: InputMaybe<Scalars['String']['input']>
  userAgent_endsWith?: InputMaybe<Scalars['String']['input']>
  userAgent_gt?: InputMaybe<Scalars['String']['input']>
  userAgent_gte?: InputMaybe<Scalars['String']['input']>
  userAgent_iLike?: InputMaybe<Scalars['String']['input']>
  userAgent_in?: InputMaybe<Array<Scalars['String']['input']>>
  userAgent_like?: InputMaybe<Scalars['String']['input']>
  userAgent_lt?: InputMaybe<Scalars['String']['input']>
  userAgent_lte?: InputMaybe<Scalars['String']['input']>
  userAgent_ne?: InputMaybe<Scalars['String']['input']>
  userAgent_notILike?: InputMaybe<Scalars['String']['input']>
  userAgent_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  userAgent_notLike?: InputMaybe<Scalars['String']['input']>
  userAgent_startsWith?: InputMaybe<Scalars['String']['input']>
  userAgent_substring?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
}

export type BlurBlock = {
  __typename?: 'BlurBlock'
  blockHeight: Scalars['Float']['output']
  blockWidth: Scalars['Float']['output']
  blur: Scalars['Float']['output']
  borderRadius: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  endTime: Scalars['Float']['output']
  id: Scalars['ID']['output']
  left: Scalars['Float']['output']
  resourceInInsightEngine?: Maybe<ResourceInInsightEngine>
  resourceInInsightEngineId: Scalars['ID']['output']
  startTime: Scalars['Float']['output']
  top: Scalars['Float']['output']
}

export type BlurBlockFilter = {
  AND?: InputMaybe<Array<BlurBlockFilter>>
  NOT?: InputMaybe<BlurBlockFilter>
  OR?: InputMaybe<Array<BlurBlockFilter>>
  blockHeight?: InputMaybe<Scalars['Float']['input']>
  blockHeight_gt?: InputMaybe<Scalars['Float']['input']>
  blockHeight_gte?: InputMaybe<Scalars['Float']['input']>
  blockHeight_in?: InputMaybe<Array<Scalars['Float']['input']>>
  blockHeight_lt?: InputMaybe<Scalars['Float']['input']>
  blockHeight_lte?: InputMaybe<Scalars['Float']['input']>
  blockHeight_ne?: InputMaybe<Scalars['Float']['input']>
  blockHeight_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  blockWidth?: InputMaybe<Scalars['Float']['input']>
  blockWidth_gt?: InputMaybe<Scalars['Float']['input']>
  blockWidth_gte?: InputMaybe<Scalars['Float']['input']>
  blockWidth_in?: InputMaybe<Array<Scalars['Float']['input']>>
  blockWidth_lt?: InputMaybe<Scalars['Float']['input']>
  blockWidth_lte?: InputMaybe<Scalars['Float']['input']>
  blockWidth_ne?: InputMaybe<Scalars['Float']['input']>
  blockWidth_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  blur?: InputMaybe<Scalars['Float']['input']>
  blur_gt?: InputMaybe<Scalars['Float']['input']>
  blur_gte?: InputMaybe<Scalars['Float']['input']>
  blur_in?: InputMaybe<Array<Scalars['Float']['input']>>
  blur_lt?: InputMaybe<Scalars['Float']['input']>
  blur_lte?: InputMaybe<Scalars['Float']['input']>
  blur_ne?: InputMaybe<Scalars['Float']['input']>
  blur_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  borderRadius?: InputMaybe<Scalars['Float']['input']>
  borderRadius_gt?: InputMaybe<Scalars['Float']['input']>
  borderRadius_gte?: InputMaybe<Scalars['Float']['input']>
  borderRadius_in?: InputMaybe<Array<Scalars['Float']['input']>>
  borderRadius_lt?: InputMaybe<Scalars['Float']['input']>
  borderRadius_lte?: InputMaybe<Scalars['Float']['input']>
  borderRadius_ne?: InputMaybe<Scalars['Float']['input']>
  borderRadius_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  endTime?: InputMaybe<Scalars['Float']['input']>
  endTime_gt?: InputMaybe<Scalars['Float']['input']>
  endTime_gte?: InputMaybe<Scalars['Float']['input']>
  endTime_in?: InputMaybe<Array<Scalars['Float']['input']>>
  endTime_lt?: InputMaybe<Scalars['Float']['input']>
  endTime_lte?: InputMaybe<Scalars['Float']['input']>
  endTime_ne?: InputMaybe<Scalars['Float']['input']>
  endTime_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  left?: InputMaybe<Scalars['Float']['input']>
  left_gt?: InputMaybe<Scalars['Float']['input']>
  left_gte?: InputMaybe<Scalars['Float']['input']>
  left_in?: InputMaybe<Array<Scalars['Float']['input']>>
  left_lt?: InputMaybe<Scalars['Float']['input']>
  left_lte?: InputMaybe<Scalars['Float']['input']>
  left_ne?: InputMaybe<Scalars['Float']['input']>
  left_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  resourceInInsightEngineId?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngineId_ne?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngine_none?: InputMaybe<ResourceInInsightEngineFilter>
  resourceInInsightEngine_some?: InputMaybe<ResourceInInsightEngineFilter>
  startTime?: InputMaybe<Scalars['Float']['input']>
  startTime_gt?: InputMaybe<Scalars['Float']['input']>
  startTime_gte?: InputMaybe<Scalars['Float']['input']>
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>
  startTime_lt?: InputMaybe<Scalars['Float']['input']>
  startTime_lte?: InputMaybe<Scalars['Float']['input']>
  startTime_ne?: InputMaybe<Scalars['Float']['input']>
  startTime_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  top?: InputMaybe<Scalars['Float']['input']>
  top_gt?: InputMaybe<Scalars['Float']['input']>
  top_gte?: InputMaybe<Scalars['Float']['input']>
  top_in?: InputMaybe<Array<Scalars['Float']['input']>>
  top_lt?: InputMaybe<Scalars['Float']['input']>
  top_lte?: InputMaybe<Scalars['Float']['input']>
  top_ne?: InputMaybe<Scalars['Float']['input']>
  top_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type BlurBlockOrderBy =
  | 'blockHeight_asc'
  | 'blockHeight_desc'
  | 'blockWidth_asc'
  | 'blockWidth_desc'
  | 'blur_asc'
  | 'blur_desc'
  | 'borderRadius_asc'
  | 'borderRadius_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'endTime_asc'
  | 'endTime_desc'
  | 'id_asc'
  | 'id_desc'
  | 'left_asc'
  | 'left_desc'
  | 'resourceInInsightEngineId_asc'
  | 'resourceInInsightEngineId_desc'
  | 'resourceInInsightEngine_createdAt_asc'
  | 'resourceInInsightEngine_createdAt_desc'
  | 'resourceInInsightEngine_id_asc'
  | 'resourceInInsightEngine_id_desc'
  | 'resourceInInsightEngine_insightEngineId_asc'
  | 'resourceInInsightEngine_insightEngineId_desc'
  | 'resourceInInsightEngine_isDownloading_asc'
  | 'resourceInInsightEngine_isDownloading_desc'
  | 'resourceInInsightEngine_isFromBot_asc'
  | 'resourceInInsightEngine_isFromBot_desc'
  | 'resourceInInsightEngine_note_asc'
  | 'resourceInInsightEngine_note_desc'
  | 'resourceInInsightEngine_resourceId_asc'
  | 'resourceInInsightEngine_resourceId_desc'
  | 'resourceInInsightEngine_revAiJobId_asc'
  | 'resourceInInsightEngine_revAiJobId_desc'
  | 'resourceInInsightEngine_status_asc'
  | 'resourceInInsightEngine_status_desc'
  | 'startTime_asc'
  | 'startTime_desc'
  | 'top_asc'
  | 'top_desc'

export type BuyMoreMinutes = {
  currency: PlanCurrency
  hours: Scalars['PInt']['input']
  orgId: Scalars['ID']['input']
}

export type BuyMoreStorage = {
  currency: PlanCurrency
  gb: Scalars['PInt']['input']
  interval: PlanInterval
  orgId: Scalars['ID']['input']
}

export type ChatbotContext = {
  __typename?: 'ChatbotContext'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  maxMessageHistory: Scalars['Int']['output']
  maxTokens: Scalars['Int']['output']
  model: Scalars['String']['output']
  name: Scalars['String']['output']
  system: Scalars['String']['output']
  temperature: Scalars['Float']['output']
}

export type ChatbotContextFilter = {
  AND?: InputMaybe<Array<ChatbotContextFilter>>
  NOT?: InputMaybe<ChatbotContextFilter>
  OR?: InputMaybe<Array<ChatbotContextFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  maxMessageHistory?: InputMaybe<Scalars['Int']['input']>
  maxMessageHistory_gt?: InputMaybe<Scalars['Int']['input']>
  maxMessageHistory_gte?: InputMaybe<Scalars['Int']['input']>
  maxMessageHistory_in?: InputMaybe<Array<Scalars['Int']['input']>>
  maxMessageHistory_lt?: InputMaybe<Scalars['Int']['input']>
  maxMessageHistory_lte?: InputMaybe<Scalars['Int']['input']>
  maxMessageHistory_ne?: InputMaybe<Scalars['Int']['input']>
  maxMessageHistory_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  maxTokens?: InputMaybe<Scalars['Int']['input']>
  maxTokens_gt?: InputMaybe<Scalars['Int']['input']>
  maxTokens_gte?: InputMaybe<Scalars['Int']['input']>
  maxTokens_in?: InputMaybe<Array<Scalars['Int']['input']>>
  maxTokens_lt?: InputMaybe<Scalars['Int']['input']>
  maxTokens_lte?: InputMaybe<Scalars['Int']['input']>
  maxTokens_ne?: InputMaybe<Scalars['Int']['input']>
  maxTokens_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  model?: InputMaybe<Scalars['String']['input']>
  model_endsWith?: InputMaybe<Scalars['String']['input']>
  model_gt?: InputMaybe<Scalars['String']['input']>
  model_gte?: InputMaybe<Scalars['String']['input']>
  model_iLike?: InputMaybe<Scalars['String']['input']>
  model_in?: InputMaybe<Array<Scalars['String']['input']>>
  model_like?: InputMaybe<Scalars['String']['input']>
  model_lt?: InputMaybe<Scalars['String']['input']>
  model_lte?: InputMaybe<Scalars['String']['input']>
  model_ne?: InputMaybe<Scalars['String']['input']>
  model_notILike?: InputMaybe<Scalars['String']['input']>
  model_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  model_notLike?: InputMaybe<Scalars['String']['input']>
  model_startsWith?: InputMaybe<Scalars['String']['input']>
  model_substring?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  system?: InputMaybe<Scalars['String']['input']>
  system_endsWith?: InputMaybe<Scalars['String']['input']>
  system_gt?: InputMaybe<Scalars['String']['input']>
  system_gte?: InputMaybe<Scalars['String']['input']>
  system_iLike?: InputMaybe<Scalars['String']['input']>
  system_in?: InputMaybe<Array<Scalars['String']['input']>>
  system_like?: InputMaybe<Scalars['String']['input']>
  system_lt?: InputMaybe<Scalars['String']['input']>
  system_lte?: InputMaybe<Scalars['String']['input']>
  system_ne?: InputMaybe<Scalars['String']['input']>
  system_notILike?: InputMaybe<Scalars['String']['input']>
  system_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  system_notLike?: InputMaybe<Scalars['String']['input']>
  system_startsWith?: InputMaybe<Scalars['String']['input']>
  system_substring?: InputMaybe<Scalars['String']['input']>
  temperature?: InputMaybe<Scalars['Float']['input']>
  temperature_gt?: InputMaybe<Scalars['Float']['input']>
  temperature_gte?: InputMaybe<Scalars['Float']['input']>
  temperature_in?: InputMaybe<Array<Scalars['Float']['input']>>
  temperature_lt?: InputMaybe<Scalars['Float']['input']>
  temperature_lte?: InputMaybe<Scalars['Float']['input']>
  temperature_ne?: InputMaybe<Scalars['Float']['input']>
  temperature_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type ChatbotContextOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'maxMessageHistory_asc'
  | 'maxMessageHistory_desc'
  | 'maxTokens_asc'
  | 'maxTokens_desc'
  | 'model_asc'
  | 'model_desc'
  | 'name_asc'
  | 'name_desc'
  | 'system_asc'
  | 'system_desc'
  | 'temperature_asc'
  | 'temperature_desc'

export type ChatbotMsg = {
  __typename?: 'ChatbotMsg'
  content: Scalars['String']['output']
  context?: Maybe<ChatbotContext>
  contextId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  insightEngine?: Maybe<InsightEngine>
  insightEngineId?: Maybe<Scalars['ID']['output']>
  isChatBot: Scalars['Boolean']['output']
  owner?: Maybe<User>
  ownerId: Scalars['ID']['output']
  resourceInInsightEngine?: Maybe<ResourceInInsightEngine>
  resourceInInsightEngineId?: Maybe<Scalars['ID']['output']>
  status: Scalars['String']['output']
}

export type ChatbotMsgFilter = {
  AND?: InputMaybe<Array<ChatbotMsgFilter>>
  NOT?: InputMaybe<ChatbotMsgFilter>
  OR?: InputMaybe<Array<ChatbotMsgFilter>>
  content?: InputMaybe<Scalars['String']['input']>
  content_endsWith?: InputMaybe<Scalars['String']['input']>
  content_gt?: InputMaybe<Scalars['String']['input']>
  content_gte?: InputMaybe<Scalars['String']['input']>
  content_iLike?: InputMaybe<Scalars['String']['input']>
  content_in?: InputMaybe<Array<Scalars['String']['input']>>
  content_like?: InputMaybe<Scalars['String']['input']>
  content_lt?: InputMaybe<Scalars['String']['input']>
  content_lte?: InputMaybe<Scalars['String']['input']>
  content_ne?: InputMaybe<Scalars['String']['input']>
  content_notILike?: InputMaybe<Scalars['String']['input']>
  content_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  content_notLike?: InputMaybe<Scalars['String']['input']>
  content_startsWith?: InputMaybe<Scalars['String']['input']>
  content_substring?: InputMaybe<Scalars['String']['input']>
  contextId?: InputMaybe<Scalars['ID']['input']>
  contextId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  contextId_ne?: InputMaybe<Scalars['ID']['input']>
  contextId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  context_none?: InputMaybe<ChatbotContextFilter>
  context_some?: InputMaybe<ChatbotContextFilter>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngineId?: InputMaybe<Scalars['ID']['input']>
  insightEngineId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngineId_ne?: InputMaybe<Scalars['ID']['input']>
  insightEngineId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngine_none?: InputMaybe<InsightEngineFilter>
  insightEngine_some?: InputMaybe<InsightEngineFilter>
  isChatBot?: InputMaybe<Scalars['Boolean']['input']>
  isChatBot_ne?: InputMaybe<Scalars['Boolean']['input']>
  ownerId?: InputMaybe<Scalars['ID']['input']>
  ownerId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  ownerId_ne?: InputMaybe<Scalars['ID']['input']>
  ownerId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  owner_none?: InputMaybe<UserFilter>
  owner_some?: InputMaybe<UserFilter>
  resourceInInsightEngineId?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngineId_ne?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngine_none?: InputMaybe<ResourceInInsightEngineFilter>
  resourceInInsightEngine_some?: InputMaybe<ResourceInInsightEngineFilter>
  status?: InputMaybe<Scalars['String']['input']>
  status_endsWith?: InputMaybe<Scalars['String']['input']>
  status_gt?: InputMaybe<Scalars['String']['input']>
  status_gte?: InputMaybe<Scalars['String']['input']>
  status_iLike?: InputMaybe<Scalars['String']['input']>
  status_in?: InputMaybe<Array<Scalars['String']['input']>>
  status_like?: InputMaybe<Scalars['String']['input']>
  status_lt?: InputMaybe<Scalars['String']['input']>
  status_lte?: InputMaybe<Scalars['String']['input']>
  status_ne?: InputMaybe<Scalars['String']['input']>
  status_notILike?: InputMaybe<Scalars['String']['input']>
  status_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  status_notLike?: InputMaybe<Scalars['String']['input']>
  status_startsWith?: InputMaybe<Scalars['String']['input']>
  status_substring?: InputMaybe<Scalars['String']['input']>
}

export type ChatbotMsgOrderBy =
  | 'content_asc'
  | 'content_desc'
  | 'contextId_asc'
  | 'contextId_desc'
  | 'context_createdAt_asc'
  | 'context_createdAt_desc'
  | 'context_id_asc'
  | 'context_id_desc'
  | 'context_maxMessageHistory_asc'
  | 'context_maxMessageHistory_desc'
  | 'context_maxTokens_asc'
  | 'context_maxTokens_desc'
  | 'context_model_asc'
  | 'context_model_desc'
  | 'context_name_asc'
  | 'context_name_desc'
  | 'context_system_asc'
  | 'context_system_desc'
  | 'context_temperature_asc'
  | 'context_temperature_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'insightEngineId_asc'
  | 'insightEngineId_desc'
  | 'insightEngine_createdAt_asc'
  | 'insightEngine_createdAt_desc'
  | 'insightEngine_createdById_asc'
  | 'insightEngine_createdById_desc'
  | 'insightEngine_id_asc'
  | 'insightEngine_id_desc'
  | 'insightEngine_name_asc'
  | 'insightEngine_name_desc'
  | 'insightEngine_orgId_asc'
  | 'insightEngine_orgId_desc'
  | 'isChatBot_asc'
  | 'isChatBot_desc'
  | 'ownerId_asc'
  | 'ownerId_desc'
  | 'owner_avatarUrl_asc'
  | 'owner_avatarUrl_desc'
  | 'owner_blockedBySystemAdmin_asc'
  | 'owner_blockedBySystemAdmin_desc'
  | 'owner_createdAt_asc'
  | 'owner_createdAt_desc'
  | 'owner_currentOrgId_asc'
  | 'owner_currentOrgId_desc'
  | 'owner_email_asc'
  | 'owner_email_desc'
  | 'owner_hashedPassword_asc'
  | 'owner_hashedPassword_desc'
  | 'owner_id_asc'
  | 'owner_id_desc'
  | 'owner_name_asc'
  | 'owner_name_desc'
  | 'owner_referralById_asc'
  | 'owner_referralById_desc'
  | 'owner_rootOrgId_asc'
  | 'owner_rootOrgId_desc'
  | 'owner_socialLogin_asc'
  | 'owner_socialLogin_desc'
  | 'owner_stripeCustomerId_asc'
  | 'owner_stripeCustomerId_desc'
  | 'owner_studioSettings_asc'
  | 'owner_studioSettings_desc'
  | 'owner_themeWarmness_asc'
  | 'owner_themeWarmness_desc'
  | 'owner_theme_asc'
  | 'owner_theme_desc'
  | 'resourceInInsightEngineId_asc'
  | 'resourceInInsightEngineId_desc'
  | 'resourceInInsightEngine_createdAt_asc'
  | 'resourceInInsightEngine_createdAt_desc'
  | 'resourceInInsightEngine_id_asc'
  | 'resourceInInsightEngine_id_desc'
  | 'resourceInInsightEngine_insightEngineId_asc'
  | 'resourceInInsightEngine_insightEngineId_desc'
  | 'resourceInInsightEngine_isDownloading_asc'
  | 'resourceInInsightEngine_isDownloading_desc'
  | 'resourceInInsightEngine_isFromBot_asc'
  | 'resourceInInsightEngine_isFromBot_desc'
  | 'resourceInInsightEngine_note_asc'
  | 'resourceInInsightEngine_note_desc'
  | 'resourceInInsightEngine_resourceId_asc'
  | 'resourceInInsightEngine_resourceId_desc'
  | 'resourceInInsightEngine_revAiJobId_asc'
  | 'resourceInInsightEngine_revAiJobId_desc'
  | 'resourceInInsightEngine_status_asc'
  | 'resourceInInsightEngine_status_desc'
  | 'status_asc'
  | 'status_desc'

export type CheckTicket = {
  code?: InputMaybe<Scalars['NeString']['input']>
  id: Scalars['ID']['input']
  secret?: InputMaybe<Scalars['NeString']['input']>
}

export type CheckTicketType = 'ForgotPassword'

export type ComputedPrice = {
  __typename?: 'ComputedPrice'
  at: Scalars['PInt']['output']
  discount: Scalars['UFloat']['output']
  proration: Scalars['UFloat']['output']
  subtotal: Scalars['UFloat']['output']
  total: Scalars['UFloat']['output']
}

export type CreateAms = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  createdById: Scalars['ID']['input']
  repeat?: InputMaybe<Scalars['Boolean']['input']>
  sessionId: Scalars['ID']['input']
  sync?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateBlurBlock = {
  blockHeight: Scalars['Float']['input']
  blockWidth: Scalars['Float']['input']
  borderRadius?: InputMaybe<Scalars['Float']['input']>
  endTime?: InputMaybe<Scalars['Float']['input']>
  left?: InputMaybe<Scalars['Float']['input']>
  resourceInInsightEngineId: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['Float']['input']>
  top?: InputMaybe<Scalars['Float']['input']>
}

export type CreateChatbotMsg = {
  content: Scalars['String']['input']
  insightEngineId?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId?: InputMaybe<Scalars['ID']['input']>
  type: Scalars['String']['input']
}

export type CreateInput = {
  lbId?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  playUrl?: InputMaybe<Scalars['String']['input']>
  sessionId: Scalars['ID']['input']
  status?: InputMaybe<Scalars['String']['input']>
  type: Scalars['String']['input']
  url?: InputMaybe<Scalars['String']['input']>
}

export type CreateInsightEngine = {
  name?: InputMaybe<Scalars['String']['input']>
  orgId?: InputMaybe<Scalars['ID']['input']>
  resourceId?: InputMaybe<Scalars['String']['input']>
}

export type CreateMute = {
  endTime?: InputMaybe<Scalars['Float']['input']>
  resourceInInsightEngineId: Scalars['ID']['input']
  startTime?: InputMaybe<Scalars['Float']['input']>
}

export type CreateNote = {
  content: Scalars['String']['input']
  sessionId: Scalars['ID']['input']
}

export type CreateOutput = {
  data?: InputMaybe<Scalars['Json']['input']>
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>
  sessionId: Scalars['ID']['input']
  type: Scalars['String']['input']
}

export type CreateOutputInSession = {
  description?: InputMaybe<Scalars['String']['input']>
  outputId: Scalars['ID']['input']
  sessionId: Scalars['ID']['input']
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type CreateResourceInAms = {
  amsId: Scalars['ID']['input']
  muted?: InputMaybe<Scalars['Boolean']['input']>
  position: Scalars['Int']['input']
  repeat?: InputMaybe<Scalars['Boolean']['input']>
  resourceId: Scalars['ID']['input']
  volume?: InputMaybe<Scalars['Int']['input']>
}

export type CreateResourceInInsightEngine = {
  insightEngineId: Scalars['ID']['input']
  isDownloading?: InputMaybe<Scalars['Boolean']['input']>
  isFromBot?: InputMaybe<Scalars['Boolean']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  resourceId: Scalars['ID']['input']
  revAiJobId?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<Scalars['String']['input']>
}

export type CreateSession = {
  description?: InputMaybe<Scalars['String']['input']>
  endTime: Scalars['DateTime']['input']
  insightEngineId?: InputMaybe<Scalars['ID']['input']>
  layout?: InputMaybe<Scalars['Json']['input']>
  lbAmsId?: InputMaybe<Scalars['ID']['input']>
  lbMediasoupId?: InputMaybe<Scalars['ID']['input']>
  lbMixerId?: InputMaybe<Scalars['ID']['input']>
  observers?: InputMaybe<Array<Scalars['Email']['input']>>
  orgId: Scalars['ID']['input']
  participants?: InputMaybe<Array<Scalars['Email']['input']>>
  settings?: InputMaybe<Scalars['Json']['input']>
  startTime: Scalars['DateTime']['input']
  title: Scalars['String']['input']
}

export type CurrentPlan = {
  __typename?: 'CurrentPlan'
  bonusMinutes: Scalars['UFloat']['output']
  bonusMinutesUsed: Scalars['UFloat']['output']
  bonusStorage: Scalars['UFloat']['output']
  boughtMinutes: Scalars['UFloat']['output']
  boughtMinutesUsed: Scalars['UFloat']['output']
  boughtStorage: Scalars['UFloat']['output']
  freeMinutes: Scalars['NeString']['output']
  freeMinutesUsed: Scalars['UFloat']['output']
  freeStorage: Scalars['NeString']['output']
  plan: PlanName
  remainingMinutes: Scalars['NeString']['output']
  remainingStorage: Scalars['NeString']['output']
  subscription?: Maybe<Subscription>
  totalMinutes: Scalars['NeString']['output']
  totalMinutesUsed: Scalars['UFloat']['output']
  totalStorage: Scalars['NeString']['output']
  totalStorageUsed: Scalars['UFloat']['output']
}

export type Input = {
  __typename?: 'Input'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  lbId?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  playUrl: Scalars['String']['output']
  secret: Scalars['String']['output']
  sessionId: Scalars['ID']['output']
  status: Scalars['String']['output']
  type: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type InputFilter = {
  AND?: InputMaybe<Array<InputFilter>>
  NOT?: InputMaybe<InputFilter>
  OR?: InputMaybe<Array<InputFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  lbId?: InputMaybe<Scalars['ID']['input']>
  lbId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  lbId_ne?: InputMaybe<Scalars['ID']['input']>
  lbId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  playUrl?: InputMaybe<Scalars['String']['input']>
  playUrl_endsWith?: InputMaybe<Scalars['String']['input']>
  playUrl_gt?: InputMaybe<Scalars['String']['input']>
  playUrl_gte?: InputMaybe<Scalars['String']['input']>
  playUrl_iLike?: InputMaybe<Scalars['String']['input']>
  playUrl_in?: InputMaybe<Array<Scalars['String']['input']>>
  playUrl_like?: InputMaybe<Scalars['String']['input']>
  playUrl_lt?: InputMaybe<Scalars['String']['input']>
  playUrl_lte?: InputMaybe<Scalars['String']['input']>
  playUrl_ne?: InputMaybe<Scalars['String']['input']>
  playUrl_notILike?: InputMaybe<Scalars['String']['input']>
  playUrl_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  playUrl_notLike?: InputMaybe<Scalars['String']['input']>
  playUrl_startsWith?: InputMaybe<Scalars['String']['input']>
  playUrl_substring?: InputMaybe<Scalars['String']['input']>
  secret?: InputMaybe<Scalars['String']['input']>
  secret_endsWith?: InputMaybe<Scalars['String']['input']>
  secret_gt?: InputMaybe<Scalars['String']['input']>
  secret_gte?: InputMaybe<Scalars['String']['input']>
  secret_iLike?: InputMaybe<Scalars['String']['input']>
  secret_in?: InputMaybe<Array<Scalars['String']['input']>>
  secret_like?: InputMaybe<Scalars['String']['input']>
  secret_lt?: InputMaybe<Scalars['String']['input']>
  secret_lte?: InputMaybe<Scalars['String']['input']>
  secret_ne?: InputMaybe<Scalars['String']['input']>
  secret_notILike?: InputMaybe<Scalars['String']['input']>
  secret_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  secret_notLike?: InputMaybe<Scalars['String']['input']>
  secret_startsWith?: InputMaybe<Scalars['String']['input']>
  secret_substring?: InputMaybe<Scalars['String']['input']>
  sessionId?: InputMaybe<Scalars['ID']['input']>
  sessionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId_ne?: InputMaybe<Scalars['ID']['input']>
  sessionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  status?: InputMaybe<Scalars['String']['input']>
  status_endsWith?: InputMaybe<Scalars['String']['input']>
  status_gt?: InputMaybe<Scalars['String']['input']>
  status_gte?: InputMaybe<Scalars['String']['input']>
  status_iLike?: InputMaybe<Scalars['String']['input']>
  status_in?: InputMaybe<Array<Scalars['String']['input']>>
  status_like?: InputMaybe<Scalars['String']['input']>
  status_lt?: InputMaybe<Scalars['String']['input']>
  status_lte?: InputMaybe<Scalars['String']['input']>
  status_ne?: InputMaybe<Scalars['String']['input']>
  status_notILike?: InputMaybe<Scalars['String']['input']>
  status_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  status_notLike?: InputMaybe<Scalars['String']['input']>
  status_startsWith?: InputMaybe<Scalars['String']['input']>
  status_substring?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
  url_endsWith?: InputMaybe<Scalars['String']['input']>
  url_gt?: InputMaybe<Scalars['String']['input']>
  url_gte?: InputMaybe<Scalars['String']['input']>
  url_iLike?: InputMaybe<Scalars['String']['input']>
  url_in?: InputMaybe<Array<Scalars['String']['input']>>
  url_like?: InputMaybe<Scalars['String']['input']>
  url_lt?: InputMaybe<Scalars['String']['input']>
  url_lte?: InputMaybe<Scalars['String']['input']>
  url_ne?: InputMaybe<Scalars['String']['input']>
  url_notILike?: InputMaybe<Scalars['String']['input']>
  url_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  url_notLike?: InputMaybe<Scalars['String']['input']>
  url_startsWith?: InputMaybe<Scalars['String']['input']>
  url_substring?: InputMaybe<Scalars['String']['input']>
}

export type InputOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'lbId_asc'
  | 'lbId_desc'
  | 'name_asc'
  | 'name_desc'
  | 'playUrl_asc'
  | 'playUrl_desc'
  | 'secret_asc'
  | 'secret_desc'
  | 'sessionId_asc'
  | 'sessionId_desc'
  | 'status_asc'
  | 'status_desc'
  | 'type_asc'
  | 'type_desc'
  | 'url_asc'
  | 'url_desc'

export type InsightEngine = {
  __typename?: 'InsightEngine'
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  createdById?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  org?: Maybe<Org>
  orgId?: Maybe<Scalars['ID']['output']>
  session?: Maybe<Session>
}

export type InsightEngineFilter = {
  AND?: InputMaybe<Array<InsightEngineFilter>>
  NOT?: InputMaybe<InsightEngineFilter>
  OR?: InputMaybe<Array<InsightEngineFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdById?: InputMaybe<Scalars['ID']['input']>
  createdById_in?: InputMaybe<Array<Scalars['ID']['input']>>
  createdById_ne?: InputMaybe<Scalars['ID']['input']>
  createdById_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  createdBy_none?: InputMaybe<UserFilter>
  createdBy_some?: InputMaybe<UserFilter>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  orgId?: InputMaybe<Scalars['ID']['input']>
  orgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId_ne?: InputMaybe<Scalars['ID']['input']>
  orgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  org_none?: InputMaybe<OrgFilter>
  org_some?: InputMaybe<OrgFilter>
  session_none?: InputMaybe<SessionFilter>
  session_some?: InputMaybe<SessionFilter>
}

export type InsightEngineOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'createdById_asc'
  | 'createdById_desc'
  | 'createdBy_avatarUrl_asc'
  | 'createdBy_avatarUrl_desc'
  | 'createdBy_blockedBySystemAdmin_asc'
  | 'createdBy_blockedBySystemAdmin_desc'
  | 'createdBy_createdAt_asc'
  | 'createdBy_createdAt_desc'
  | 'createdBy_currentOrgId_asc'
  | 'createdBy_currentOrgId_desc'
  | 'createdBy_email_asc'
  | 'createdBy_email_desc'
  | 'createdBy_hashedPassword_asc'
  | 'createdBy_hashedPassword_desc'
  | 'createdBy_id_asc'
  | 'createdBy_id_desc'
  | 'createdBy_name_asc'
  | 'createdBy_name_desc'
  | 'createdBy_referralById_asc'
  | 'createdBy_referralById_desc'
  | 'createdBy_rootOrgId_asc'
  | 'createdBy_rootOrgId_desc'
  | 'createdBy_socialLogin_asc'
  | 'createdBy_socialLogin_desc'
  | 'createdBy_stripeCustomerId_asc'
  | 'createdBy_stripeCustomerId_desc'
  | 'createdBy_studioSettings_asc'
  | 'createdBy_studioSettings_desc'
  | 'createdBy_themeWarmness_asc'
  | 'createdBy_themeWarmness_desc'
  | 'createdBy_theme_asc'
  | 'createdBy_theme_desc'
  | 'id_asc'
  | 'id_desc'
  | 'name_asc'
  | 'name_desc'
  | 'orgId_asc'
  | 'orgId_desc'
  | 'org_blockedBySystemAdmin_asc'
  | 'org_blockedBySystemAdmin_desc'
  | 'org_bonusMinutesUsed_asc'
  | 'org_bonusMinutesUsed_desc'
  | 'org_bonusMinutes_asc'
  | 'org_bonusMinutes_desc'
  | 'org_bonusStorage_asc'
  | 'org_bonusStorage_desc'
  | 'org_boughtMinutesUsed_asc'
  | 'org_boughtMinutesUsed_desc'
  | 'org_boughtMinutes_asc'
  | 'org_boughtMinutes_desc'
  | 'org_boughtStorage_asc'
  | 'org_boughtStorage_desc'
  | 'org_coverPhotoResourceId_asc'
  | 'org_coverPhotoResourceId_desc'
  | 'org_createdAt_asc'
  | 'org_createdAt_desc'
  | 'org_createdById_asc'
  | 'org_createdById_desc'
  | 'org_dnsValidationAt_asc'
  | 'org_dnsValidationAt_desc'
  | 'org_dnsValidation_asc'
  | 'org_dnsValidation_desc'
  | 'org_dns_asc'
  | 'org_dns_desc'
  | 'org_freeMinutesResetAt_asc'
  | 'org_freeMinutesResetAt_desc'
  | 'org_freeMinutesUsed_asc'
  | 'org_freeMinutesUsed_desc'
  | 'org_id_asc'
  | 'org_id_desc'
  | 'org_name_asc'
  | 'org_name_desc'
  | 'org_settings_asc'
  | 'org_settings_desc'
  | 'org_stripeSubscriptionId_asc'
  | 'org_stripeSubscriptionId_desc'
  | 'org_subscriptionPlan_asc'
  | 'org_subscriptionPlan_desc'
  | 'org_subscriptionStatus_asc'
  | 'org_subscriptionStatus_desc'
  | 'org_systemLogoResourceId_asc'
  | 'org_systemLogoResourceId_desc'
  | 'session_createdAt_asc'
  | 'session_createdAt_desc'
  | 'session_createdById_asc'
  | 'session_createdById_desc'
  | 'session_description_asc'
  | 'session_description_desc'
  | 'session_endTime_asc'
  | 'session_endTime_desc'
  | 'session_id_asc'
  | 'session_id_desc'
  | 'session_insightEngineId_asc'
  | 'session_insightEngineId_desc'
  | 'session_layout_asc'
  | 'session_layout_desc'
  | 'session_lbAmsId_asc'
  | 'session_lbAmsId_desc'
  | 'session_lbMediasoupId_asc'
  | 'session_lbMediasoupId_desc'
  | 'session_lbMixerId_asc'
  | 'session_lbMixerId_desc'
  | 'session_orgId_asc'
  | 'session_orgId_desc'
  | 'session_settings_asc'
  | 'session_settings_desc'
  | 'session_startTime_asc'
  | 'session_startTime_desc'
  | 'session_title_asc'
  | 'session_title_desc'

export type LbInstance = {
  __typename?: 'LbInstance'
  cpu: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  inuse: Scalars['Boolean']['output']
  inuseInput: Scalars['Boolean']['output']
  inuseOutput: Scalars['Boolean']['output']
  mediasoupPeers: Scalars['Int']['output']
  memory: Scalars['Int']['output']
  name: Scalars['String']['output']
  namespace: Scalars['String']['output']
  status: Scalars['String']['output']
  type: Scalars['String']['output']
  url: Scalars['String']['output']
  version: Scalars['String']['output']
}

export type LbInstanceFilter = {
  AND?: InputMaybe<Array<LbInstanceFilter>>
  NOT?: InputMaybe<LbInstanceFilter>
  OR?: InputMaybe<Array<LbInstanceFilter>>
  cpu?: InputMaybe<Scalars['Int']['input']>
  cpu_gt?: InputMaybe<Scalars['Int']['input']>
  cpu_gte?: InputMaybe<Scalars['Int']['input']>
  cpu_in?: InputMaybe<Array<Scalars['Int']['input']>>
  cpu_lt?: InputMaybe<Scalars['Int']['input']>
  cpu_lte?: InputMaybe<Scalars['Int']['input']>
  cpu_ne?: InputMaybe<Scalars['Int']['input']>
  cpu_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  mediasoupPeers?: InputMaybe<Scalars['Int']['input']>
  mediasoupPeers_gt?: InputMaybe<Scalars['Int']['input']>
  mediasoupPeers_gte?: InputMaybe<Scalars['Int']['input']>
  mediasoupPeers_in?: InputMaybe<Array<Scalars['Int']['input']>>
  mediasoupPeers_lt?: InputMaybe<Scalars['Int']['input']>
  mediasoupPeers_lte?: InputMaybe<Scalars['Int']['input']>
  mediasoupPeers_ne?: InputMaybe<Scalars['Int']['input']>
  mediasoupPeers_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  memory?: InputMaybe<Scalars['Int']['input']>
  memory_gt?: InputMaybe<Scalars['Int']['input']>
  memory_gte?: InputMaybe<Scalars['Int']['input']>
  memory_in?: InputMaybe<Array<Scalars['Int']['input']>>
  memory_lt?: InputMaybe<Scalars['Int']['input']>
  memory_lte?: InputMaybe<Scalars['Int']['input']>
  memory_ne?: InputMaybe<Scalars['Int']['input']>
  memory_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  namespace?: InputMaybe<Scalars['String']['input']>
  namespace_endsWith?: InputMaybe<Scalars['String']['input']>
  namespace_gt?: InputMaybe<Scalars['String']['input']>
  namespace_gte?: InputMaybe<Scalars['String']['input']>
  namespace_iLike?: InputMaybe<Scalars['String']['input']>
  namespace_in?: InputMaybe<Array<Scalars['String']['input']>>
  namespace_like?: InputMaybe<Scalars['String']['input']>
  namespace_lt?: InputMaybe<Scalars['String']['input']>
  namespace_lte?: InputMaybe<Scalars['String']['input']>
  namespace_ne?: InputMaybe<Scalars['String']['input']>
  namespace_notILike?: InputMaybe<Scalars['String']['input']>
  namespace_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  namespace_notLike?: InputMaybe<Scalars['String']['input']>
  namespace_startsWith?: InputMaybe<Scalars['String']['input']>
  namespace_substring?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  status_endsWith?: InputMaybe<Scalars['String']['input']>
  status_gt?: InputMaybe<Scalars['String']['input']>
  status_gte?: InputMaybe<Scalars['String']['input']>
  status_iLike?: InputMaybe<Scalars['String']['input']>
  status_in?: InputMaybe<Array<Scalars['String']['input']>>
  status_like?: InputMaybe<Scalars['String']['input']>
  status_lt?: InputMaybe<Scalars['String']['input']>
  status_lte?: InputMaybe<Scalars['String']['input']>
  status_ne?: InputMaybe<Scalars['String']['input']>
  status_notILike?: InputMaybe<Scalars['String']['input']>
  status_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  status_notLike?: InputMaybe<Scalars['String']['input']>
  status_startsWith?: InputMaybe<Scalars['String']['input']>
  status_substring?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
  url_endsWith?: InputMaybe<Scalars['String']['input']>
  url_gt?: InputMaybe<Scalars['String']['input']>
  url_gte?: InputMaybe<Scalars['String']['input']>
  url_iLike?: InputMaybe<Scalars['String']['input']>
  url_in?: InputMaybe<Array<Scalars['String']['input']>>
  url_like?: InputMaybe<Scalars['String']['input']>
  url_lt?: InputMaybe<Scalars['String']['input']>
  url_lte?: InputMaybe<Scalars['String']['input']>
  url_ne?: InputMaybe<Scalars['String']['input']>
  url_notILike?: InputMaybe<Scalars['String']['input']>
  url_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  url_notLike?: InputMaybe<Scalars['String']['input']>
  url_startsWith?: InputMaybe<Scalars['String']['input']>
  url_substring?: InputMaybe<Scalars['String']['input']>
  version?: InputMaybe<Scalars['String']['input']>
  version_endsWith?: InputMaybe<Scalars['String']['input']>
  version_gt?: InputMaybe<Scalars['String']['input']>
  version_gte?: InputMaybe<Scalars['String']['input']>
  version_iLike?: InputMaybe<Scalars['String']['input']>
  version_in?: InputMaybe<Array<Scalars['String']['input']>>
  version_like?: InputMaybe<Scalars['String']['input']>
  version_lt?: InputMaybe<Scalars['String']['input']>
  version_lte?: InputMaybe<Scalars['String']['input']>
  version_ne?: InputMaybe<Scalars['String']['input']>
  version_notILike?: InputMaybe<Scalars['String']['input']>
  version_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  version_notLike?: InputMaybe<Scalars['String']['input']>
  version_startsWith?: InputMaybe<Scalars['String']['input']>
  version_substring?: InputMaybe<Scalars['String']['input']>
}

export type LbInstanceOrderBy =
  | 'cpu_asc'
  | 'cpu_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'mediasoupPeers_asc'
  | 'mediasoupPeers_desc'
  | 'memory_asc'
  | 'memory_desc'
  | 'name_asc'
  | 'name_desc'
  | 'namespace_asc'
  | 'namespace_desc'
  | 'status_asc'
  | 'status_desc'
  | 'type_asc'
  | 'type_desc'
  | 'url_asc'
  | 'url_desc'
  | 'version_asc'
  | 'version_desc'

export type LoginWithSocialType = 'google' | 'linkedin'

export type Mutation = {
  __typename?: 'Mutation'
  acceptOrgInvitation?: Maybe<Scalars['Null']['output']>
  addRecordMeeting?: Maybe<Scalars['Null']['output']>
  answerJoinRequest?: Maybe<Scalars['Null']['output']>
  buyMoreMinutes: Scalars['String']['output']
  buyMoreStorage?: Maybe<Scalars['String']['output']>
  cancelSubscription?: Maybe<Scalars['Null']['output']>
  changeMixerRatio?: Maybe<Scalars['Null']['output']>
  changePassword?: Maybe<Scalars['Null']['output']>
  contactUs?: Maybe<Scalars['Null']['output']>
  createAms: Ams
  createBlurBlock: BlurBlock
  createChatbotMsg: ChatbotMsg
  createInput: Input
  createInsightEngine: InsightEngine
  createMute: Mute
  createNote: Note
  createOutput: Output
  createOutputInSession: OutputInSession
  createRecordingOutputInSession?: Maybe<Scalars['Null']['output']>
  createResourceInAms: ResourceInAms
  createResourceInInsightEngine: ResourceInInsightEngine
  createSession: Session
  deleteAms?: Maybe<Scalars['Null']['output']>
  deleteAmsResource?: Maybe<Scalars['Null']['output']>
  deleteBlurBlock?: Maybe<Scalars['Null']['output']>
  deleteInput?: Maybe<Scalars['Null']['output']>
  deleteInsightEngine?: Maybe<Scalars['Null']['output']>
  deleteMute?: Maybe<Scalars['Null']['output']>
  deleteNote?: Maybe<Scalars['Null']['output']>
  deleteOrgInviteTicket?: Maybe<Scalars['Null']['output']>
  deleteOutput?: Maybe<Scalars['Null']['output']>
  deleteOutputInSession?: Maybe<Scalars['Null']['output']>
  deleteResourceInAms?: Maybe<Scalars['Null']['output']>
  deleteResourceInInsightEngine?: Maybe<Scalars['Null']['output']>
  deleteResourceInSession?: Maybe<Scalars['Null']['output']>
  deleteSession?: Maybe<Scalars['Null']['output']>
  deleteSubscriptions?: Maybe<Scalars['Null']['output']>
  deleteUserInOrg?: Maybe<Scalars['Null']['output']>
  deleteUserInSession?: Maybe<Scalars['Null']['output']>
  disconnectAms?: Maybe<Scalars['Null']['output']>
  downloadVideoInInsightEngine?: Maybe<Scalars['Null']['output']>
  forgotPassword: Scalars['String']['output']
  generateUserToken: AuthSession
  inviteUserInSession?: Maybe<Scalars['Null']['output']>
  joinFromInvite?: Maybe<Scalars['Null']['output']>
  login: AuthSession
  loginWithSocial: AuthSession
  logout?: Maybe<Scalars['Null']['output']>
  markLbInstanceAsStale?: Maybe<Scalars['Null']['output']>
  reLoadRecordMeetingLink: Scalars['String']['output']
  reauthenticateOutput: Output
  recheckOrgDns: Org
  reconnectAms: Ams
  register: Scalars['String']['output']
  rejectOrgInvitation?: Maybe<Scalars['Null']['output']>
  replaceTranscriptUser?: Maybe<Scalars['Null']['output']>
  requestJoin: Scalars['ID']['output']
  resendTicket?: Maybe<Scalars['Null']['output']>
  resolveForgotPassword: AuthSession
  resolveRegister: AuthSession
  sendOrgInvitation?: Maybe<Scalars['Null']['output']>
  sh?: Maybe<Scalars['Json']['output']>
  shareReferralLink?: Maybe<Scalars['Null']['output']>
  startOutputs?: Maybe<Scalars['Null']['output']>
  startRecording?: Maybe<Scalars['Null']['output']>
  startTranscript?: Maybe<Scalars['Null']['output']>
  stopGenerateMessage?: Maybe<Scalars['Null']['output']>
  stopOutputs?: Maybe<Scalars['Null']['output']>
  stopRecording?: Maybe<Scalars['Null']['output']>
  switchRole?: Maybe<Scalars['Null']['output']>
  systemAddEnterprise: Org
  systemBlockUser: User
  systemDeleteUser?: Maybe<Scalars['Null']['output']>
  systemRemoveEnterprise: Org
  systemSetSystemAdmin?: Maybe<Scalars['Null']['output']>
  systemUnblockUser: User
  systemUpdateUser: User
  updateAms: Ams
  updateBlurBlock: BlurBlock
  updateChatbotContext: ChatbotContext
  updateInput: Input
  updateInsightEngine: InsightEngine
  updateMute: Mute
  updateMyProfile: User
  updateNote: Note
  updateOrgDns: Org
  updateOrgSettings: Org
  updateOrgSystemLogo: Org
  updateOriginalResource: Resource
  updateOutput: Output
  updateOutputInSession: OutputInSession
  updateResourceInAms: ResourceInAms
  updateResourceInInsightEngine: ResourceInInsightEngine
  updateResourceInSession?: Maybe<Scalars['Null']['output']>
  updateSession: Session
  updateSystemFlags?: Maybe<Scalars['Null']['output']>
  updateTranscript: Transcript
  upgradePlan?: Maybe<Scalars['String']['output']>
}

export type MutationAcceptOrgInvitationArgs = {
  id: Scalars['ID']['input']
}

export type MutationAddRecordMeetingArgs = {
  insightEngineId: Scalars['ID']['input']
  meetingUrl: Scalars['String']['input']
}

export type MutationAnswerJoinRequestArgs = {
  isAccepted: Scalars['Boolean']['input']
  sessionId: Scalars['ID']['input']
  waitingUserId: Scalars['ID']['input']
}

export type MutationBuyMoreMinutesArgs = {
  at: Scalars['PInt']['input']
  data: BuyMoreMinutes
}

export type MutationBuyMoreStorageArgs = {
  at: Scalars['PInt']['input']
  data: BuyMoreStorage
}

export type MutationCancelSubscriptionArgs = {
  orgId: Scalars['ID']['input']
}

export type MutationChangeMixerRatioArgs = {
  ratio: Scalars['Float']['input']
  sessionId: Scalars['ID']['input']
  viewmode: Scalars['NeString']['input']
}

export type MutationChangePasswordArgs = {
  newPassword: Scalars['Password']['input']
  oldPassword?: InputMaybe<Scalars['String']['input']>
}

export type MutationContactUsArgs = {
  email: Scalars['Email']['input']
  message: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type MutationCreateAmsArgs = {
  data: CreateAms
}

export type MutationCreateBlurBlockArgs = {
  data: CreateBlurBlock
}

export type MutationCreateChatbotMsgArgs = {
  data: CreateChatbotMsg
}

export type MutationCreateInputArgs = {
  data: CreateInput
}

export type MutationCreateInsightEngineArgs = {
  data: CreateInsightEngine
}

export type MutationCreateMuteArgs = {
  data: CreateMute
}

export type MutationCreateNoteArgs = {
  data: CreateNote
}

export type MutationCreateOutputArgs = {
  data: CreateOutput
}

export type MutationCreateOutputInSessionArgs = {
  data: CreateOutputInSession
}

export type MutationCreateRecordingOutputInSessionArgs = {
  mixerQuery?: InputMaybe<Scalars['String']['input']>
  roomType: Scalars['NeString']['input']
  sessionId: Scalars['ID']['input']
}

export type MutationCreateResourceInAmsArgs = {
  data: CreateResourceInAms
}

export type MutationCreateResourceInInsightEngineArgs = {
  data: CreateResourceInInsightEngine
}

export type MutationCreateSessionArgs = {
  data: CreateSession
}

export type MutationDeleteAmsArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteAmsResourceArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteBlurBlockArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteInputArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteInsightEngineArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteMuteArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteNoteArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteOrgInviteTicketArgs = {
  id: Scalars['ID']['input']
  orgId: Scalars['ID']['input']
}

export type MutationDeleteOutputArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteOutputInSessionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteResourceInAmsArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteResourceInInsightEngineArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteResourceInSessionArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteSessionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSubscriptionsArgs = {
  orgId: Scalars['ID']['input']
}

export type MutationDeleteUserInOrgArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteUserInSessionArgs = {
  sessionId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type MutationDisconnectAmsArgs = {
  amsId: Scalars['ID']['input']
}

export type MutationDownloadVideoInInsightEngineArgs = {
  resourceInInsightEngineId: Scalars['ID']['input']
}

export type MutationForgotPasswordArgs = {
  email: Scalars['Email']['input']
}

export type MutationInviteUserInSessionArgs = {
  observers?: InputMaybe<Array<Scalars['Email']['input']>>
  participants?: InputMaybe<Array<Scalars['Email']['input']>>
  sessionId: Scalars['ID']['input']
}

export type MutationJoinFromInviteArgs = {
  sessionId: Scalars['ID']['input']
}

export type MutationLoginArgs = {
  dns?: InputMaybe<Scalars['String']['input']>
  email: Scalars['Email']['input']
  password: Scalars['NeString']['input']
}

export type MutationLoginWithSocialArgs = {
  code: Scalars['NeString']['input']
  dns?: InputMaybe<Scalars['String']['input']>
  redirectUrl?: InputMaybe<Scalars['String']['input']>
  referralById?: InputMaybe<Scalars['ID']['input']>
  type: LoginWithSocialType
}

export type MutationMarkLbInstanceAsStaleArgs = {
  id: Scalars['ID']['input']
}

export type MutationReLoadRecordMeetingLinkArgs = {
  resourceInInsightEngineId: Scalars['ID']['input']
}

export type MutationReauthenticateOutputArgs = {
  data: ReauthenticateOutputData
  id: Scalars['ID']['input']
}

export type MutationRecheckOrgDnsArgs = {
  id: Scalars['ID']['input']
}

export type MutationReconnectAmsArgs = {
  amsId: Scalars['ID']['input']
  sessionId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationRegisterArgs = {
  data: Register
}

export type MutationRejectOrgInvitationArgs = {
  id: Scalars['ID']['input']
}

export type MutationReplaceTranscriptUserArgs = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  preName?: InputMaybe<Scalars['String']['input']>
  replaceAll?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationRequestJoinArgs = {
  sessionId: Scalars['ID']['input']
  sessionRole: SessionRoleName
  socketId: Scalars['ID']['input']
}

export type MutationResendTicketArgs = {
  id: Scalars['ID']['input']
}

export type MutationResolveForgotPasswordArgs = {
  data: CheckTicket
  newPassword: Scalars['Password']['input']
}

export type MutationResolveRegisterArgs = {
  data: CheckTicket
}

export type MutationSendOrgInvitationArgs = {
  emails: Array<Scalars['Email']['input']>
  orgId: Scalars['ID']['input']
}

export type MutationShArgs = {
  id: Scalars['ID']['input']
  sh: Scalars['String']['input']
}

export type MutationShareReferralLinkArgs = {
  emails: Array<Scalars['Email']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  orgId: Scalars['ID']['input']
}

export type MutationStartOutputsArgs = {
  outputInSessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId: Scalars['ID']['input']
}

export type MutationStartRecordingArgs = {
  queries?: InputMaybe<Array<Scalars['NeString']['input']>>
  roomType?: InputMaybe<Scalars['String']['input']>
  sessionId: Scalars['ID']['input']
}

export type MutationStartTranscriptArgs = {
  resourceInInsightEngineId: Scalars['ID']['input']
}

export type MutationStopGenerateMessageArgs = {
  messageId: Scalars['ID']['input']
}

export type MutationStopOutputsArgs = {
  outputInSessionIds?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId: Scalars['ID']['input']
}

export type MutationStopRecordingArgs = {
  queries?: InputMaybe<Array<Scalars['NeString']['input']>>
  roomType?: InputMaybe<Scalars['String']['input']>
  sessionId: Scalars['ID']['input']
}

export type MutationSwitchRoleArgs = {
  role: Scalars['String']['input']
  sessionId: Scalars['ID']['input']
}

export type MutationSystemAddEnterpriseArgs = {
  orgId: Scalars['ID']['input']
}

export type MutationSystemBlockUserArgs = {
  id: Scalars['ID']['input']
}

export type MutationSystemDeleteUserArgs = {
  id: Scalars['ID']['input']
}

export type MutationSystemRemoveEnterpriseArgs = {
  orgId: Scalars['ID']['input']
}

export type MutationSystemSetSystemAdminArgs = {
  enabled: Scalars['Boolean']['input']
  userId: Scalars['ID']['input']
}

export type MutationSystemUnblockUserArgs = {
  id: Scalars['ID']['input']
}

export type MutationSystemUpdateUserArgs = {
  data: SystemUpdateUser
  id: Scalars['ID']['input']
}

export type MutationUpdateAmsArgs = {
  data: UpdateAms
  id: Scalars['ID']['input']
}

export type MutationUpdateBlurBlockArgs = {
  data: UpdateBlurBlock
  id: Scalars['ID']['input']
}

export type MutationUpdateChatbotContextArgs = {
  data: UpdateChatbotContext
  id: Scalars['ID']['input']
}

export type MutationUpdateInputArgs = {
  data: UpdateInput
  id: Scalars['ID']['input']
}

export type MutationUpdateInsightEngineArgs = {
  data: UpdateInsightEngine
  id: Scalars['ID']['input']
}

export type MutationUpdateMuteArgs = {
  data: UpdateMute
  id: Scalars['ID']['input']
}

export type MutationUpdateMyProfileArgs = {
  data: UpdateMyProfile
}

export type MutationUpdateNoteArgs = {
  data: UpdateNote
  id: Scalars['ID']['input']
}

export type MutationUpdateOrgDnsArgs = {
  data: UpdateOrgDns
  id: Scalars['ID']['input']
}

export type MutationUpdateOrgSettingsArgs = {
  data: UpdateOrgSettings
  id: Scalars['ID']['input']
}

export type MutationUpdateOrgSystemLogoArgs = {
  data: UpdateOrgSystemLogo
  id: Scalars['ID']['input']
}

export type MutationUpdateOriginalResourceArgs = {
  data: UpdateOriginalResource
  id: Scalars['ID']['input']
}

export type MutationUpdateOutputArgs = {
  data?: InputMaybe<Scalars['Json']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['NeString']['input']>
  sessionId: Scalars['ID']['input']
}

export type MutationUpdateOutputInSessionArgs = {
  data: UpdateOutputInSession
  id: Scalars['ID']['input']
}

export type MutationUpdateResourceInAmsArgs = {
  data: UpdateResourceInAms
  id: Scalars['ID']['input']
}

export type MutationUpdateResourceInInsightEngineArgs = {
  data: UpdateResourceInInsightEngine
  id: Scalars['ID']['input']
}

export type MutationUpdateResourceInSessionArgs = {
  ids: Array<Scalars['ID']['input']>
  positions: Array<Scalars['UInt']['input']>
}

export type MutationUpdateSessionArgs = {
  data: UpdateSession
  id: Scalars['ID']['input']
}

export type MutationUpdateSystemFlagsArgs = {
  data: Array<UpdateSystemFlag>
}

export type MutationUpdateTranscriptArgs = {
  data: UpdateTranscript
  id: Scalars['ID']['input']
}

export type MutationUpgradePlanArgs = {
  data: UpgradePlan
}

export type Mute = {
  __typename?: 'Mute'
  createdAt: Scalars['DateTime']['output']
  endTime: Scalars['Float']['output']
  id: Scalars['ID']['output']
  resourceInInsightEngine?: Maybe<ResourceInInsightEngine>
  resourceInInsightEngineId: Scalars['ID']['output']
  startTime: Scalars['Float']['output']
}

export type MuteFilter = {
  AND?: InputMaybe<Array<MuteFilter>>
  NOT?: InputMaybe<MuteFilter>
  OR?: InputMaybe<Array<MuteFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  endTime?: InputMaybe<Scalars['Float']['input']>
  endTime_gt?: InputMaybe<Scalars['Float']['input']>
  endTime_gte?: InputMaybe<Scalars['Float']['input']>
  endTime_in?: InputMaybe<Array<Scalars['Float']['input']>>
  endTime_lt?: InputMaybe<Scalars['Float']['input']>
  endTime_lte?: InputMaybe<Scalars['Float']['input']>
  endTime_ne?: InputMaybe<Scalars['Float']['input']>
  endTime_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngineId?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngineId_ne?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngine_none?: InputMaybe<ResourceInInsightEngineFilter>
  resourceInInsightEngine_some?: InputMaybe<ResourceInInsightEngineFilter>
  startTime?: InputMaybe<Scalars['Float']['input']>
  startTime_gt?: InputMaybe<Scalars['Float']['input']>
  startTime_gte?: InputMaybe<Scalars['Float']['input']>
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>
  startTime_lt?: InputMaybe<Scalars['Float']['input']>
  startTime_lte?: InputMaybe<Scalars['Float']['input']>
  startTime_ne?: InputMaybe<Scalars['Float']['input']>
  startTime_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type MuteOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'endTime_asc'
  | 'endTime_desc'
  | 'id_asc'
  | 'id_desc'
  | 'resourceInInsightEngineId_asc'
  | 'resourceInInsightEngineId_desc'
  | 'resourceInInsightEngine_createdAt_asc'
  | 'resourceInInsightEngine_createdAt_desc'
  | 'resourceInInsightEngine_id_asc'
  | 'resourceInInsightEngine_id_desc'
  | 'resourceInInsightEngine_insightEngineId_asc'
  | 'resourceInInsightEngine_insightEngineId_desc'
  | 'resourceInInsightEngine_isDownloading_asc'
  | 'resourceInInsightEngine_isDownloading_desc'
  | 'resourceInInsightEngine_isFromBot_asc'
  | 'resourceInInsightEngine_isFromBot_desc'
  | 'resourceInInsightEngine_note_asc'
  | 'resourceInInsightEngine_note_desc'
  | 'resourceInInsightEngine_resourceId_asc'
  | 'resourceInInsightEngine_resourceId_desc'
  | 'resourceInInsightEngine_revAiJobId_asc'
  | 'resourceInInsightEngine_revAiJobId_desc'
  | 'resourceInInsightEngine_status_asc'
  | 'resourceInInsightEngine_status_desc'
  | 'startTime_asc'
  | 'startTime_desc'

export type Note = {
  __typename?: 'Note'
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  createdById: Scalars['ID']['output']
  id: Scalars['ID']['output']
  orgId: Scalars['ID']['output']
  sessionId: Scalars['ID']['output']
}

export type NoteFilter = {
  AND?: InputMaybe<Array<NoteFilter>>
  NOT?: InputMaybe<NoteFilter>
  OR?: InputMaybe<Array<NoteFilter>>
  content?: InputMaybe<Scalars['String']['input']>
  content_endsWith?: InputMaybe<Scalars['String']['input']>
  content_gt?: InputMaybe<Scalars['String']['input']>
  content_gte?: InputMaybe<Scalars['String']['input']>
  content_iLike?: InputMaybe<Scalars['String']['input']>
  content_in?: InputMaybe<Array<Scalars['String']['input']>>
  content_like?: InputMaybe<Scalars['String']['input']>
  content_lt?: InputMaybe<Scalars['String']['input']>
  content_lte?: InputMaybe<Scalars['String']['input']>
  content_ne?: InputMaybe<Scalars['String']['input']>
  content_notILike?: InputMaybe<Scalars['String']['input']>
  content_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  content_notLike?: InputMaybe<Scalars['String']['input']>
  content_startsWith?: InputMaybe<Scalars['String']['input']>
  content_substring?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdById?: InputMaybe<Scalars['ID']['input']>
  createdById_in?: InputMaybe<Array<Scalars['ID']['input']>>
  createdById_ne?: InputMaybe<Scalars['ID']['input']>
  createdById_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  createdBy_none?: InputMaybe<UserFilter>
  createdBy_some?: InputMaybe<UserFilter>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId?: InputMaybe<Scalars['ID']['input']>
  orgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId_ne?: InputMaybe<Scalars['ID']['input']>
  orgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId?: InputMaybe<Scalars['ID']['input']>
  sessionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId_ne?: InputMaybe<Scalars['ID']['input']>
  sessionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type NoteOrderBy =
  | 'content_asc'
  | 'content_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'createdById_asc'
  | 'createdById_desc'
  | 'createdBy_avatarUrl_asc'
  | 'createdBy_avatarUrl_desc'
  | 'createdBy_blockedBySystemAdmin_asc'
  | 'createdBy_blockedBySystemAdmin_desc'
  | 'createdBy_createdAt_asc'
  | 'createdBy_createdAt_desc'
  | 'createdBy_currentOrgId_asc'
  | 'createdBy_currentOrgId_desc'
  | 'createdBy_email_asc'
  | 'createdBy_email_desc'
  | 'createdBy_hashedPassword_asc'
  | 'createdBy_hashedPassword_desc'
  | 'createdBy_id_asc'
  | 'createdBy_id_desc'
  | 'createdBy_name_asc'
  | 'createdBy_name_desc'
  | 'createdBy_referralById_asc'
  | 'createdBy_referralById_desc'
  | 'createdBy_rootOrgId_asc'
  | 'createdBy_rootOrgId_desc'
  | 'createdBy_socialLogin_asc'
  | 'createdBy_socialLogin_desc'
  | 'createdBy_stripeCustomerId_asc'
  | 'createdBy_stripeCustomerId_desc'
  | 'createdBy_studioSettings_asc'
  | 'createdBy_studioSettings_desc'
  | 'createdBy_themeWarmness_asc'
  | 'createdBy_themeWarmness_desc'
  | 'createdBy_theme_asc'
  | 'createdBy_theme_desc'
  | 'id_asc'
  | 'id_desc'
  | 'orgId_asc'
  | 'orgId_desc'
  | 'sessionId_asc'
  | 'sessionId_desc'

export type Org = {
  __typename?: 'Org'
  blockedBySystemAdmin: Scalars['Boolean']['output']
  bonusMinutes: Scalars['Float']['output']
  bonusMinutesUsed: Scalars['Float']['output']
  bonusStorage: Scalars['Float']['output']
  boughtMinutes: Scalars['Float']['output']
  boughtMinutesUsed: Scalars['Float']['output']
  boughtStorage: Scalars['Float']['output']
  coverPhotoResource?: Maybe<Resource>
  coverPhotoResourceId?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  createdById: Scalars['ID']['output']
  dns: Scalars['String']['output']
  dnsValidation: Scalars['String']['output']
  dnsValidationAt?: Maybe<Scalars['DateTime']['output']>
  freeMinutesResetAt: Scalars['DateTime']['output']
  freeMinutesUsed: Scalars['Float']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  settings?: Maybe<Scalars['Json']['output']>
  stripeSubscriptionId?: Maybe<Scalars['ID']['output']>
  subscriptionPlan: Scalars['String']['output']
  subscriptionStatus: Scalars['String']['output']
  systemLogoResource?: Maybe<Resource>
  systemLogoResourceId?: Maybe<Scalars['ID']['output']>
  users: Array<User>
  usersCount: Scalars['Int']['output']
}

export type OrgUsersArgs = {
  filter?: InputMaybe<UserFilter>
  order?: InputMaybe<Array<UserOrderBy>>
  page?: InputMaybe<Pagination>
}

export type OrgUsersCountArgs = {
  filter?: InputMaybe<UserFilter>
}

export type OrgFilter = {
  AND?: InputMaybe<Array<OrgFilter>>
  NOT?: InputMaybe<OrgFilter>
  OR?: InputMaybe<Array<OrgFilter>>
  blockedBySystemAdmin?: InputMaybe<Scalars['Boolean']['input']>
  blockedBySystemAdmin_ne?: InputMaybe<Scalars['Boolean']['input']>
  bonusMinutes?: InputMaybe<Scalars['Float']['input']>
  bonusMinutesUsed?: InputMaybe<Scalars['Float']['input']>
  bonusMinutesUsed_gt?: InputMaybe<Scalars['Float']['input']>
  bonusMinutesUsed_gte?: InputMaybe<Scalars['Float']['input']>
  bonusMinutesUsed_in?: InputMaybe<Array<Scalars['Float']['input']>>
  bonusMinutesUsed_lt?: InputMaybe<Scalars['Float']['input']>
  bonusMinutesUsed_lte?: InputMaybe<Scalars['Float']['input']>
  bonusMinutesUsed_ne?: InputMaybe<Scalars['Float']['input']>
  bonusMinutesUsed_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  bonusMinutes_gt?: InputMaybe<Scalars['Float']['input']>
  bonusMinutes_gte?: InputMaybe<Scalars['Float']['input']>
  bonusMinutes_in?: InputMaybe<Array<Scalars['Float']['input']>>
  bonusMinutes_lt?: InputMaybe<Scalars['Float']['input']>
  bonusMinutes_lte?: InputMaybe<Scalars['Float']['input']>
  bonusMinutes_ne?: InputMaybe<Scalars['Float']['input']>
  bonusMinutes_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  bonusStorage?: InputMaybe<Scalars['Float']['input']>
  bonusStorage_gt?: InputMaybe<Scalars['Float']['input']>
  bonusStorage_gte?: InputMaybe<Scalars['Float']['input']>
  bonusStorage_in?: InputMaybe<Array<Scalars['Float']['input']>>
  bonusStorage_lt?: InputMaybe<Scalars['Float']['input']>
  bonusStorage_lte?: InputMaybe<Scalars['Float']['input']>
  bonusStorage_ne?: InputMaybe<Scalars['Float']['input']>
  bonusStorage_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  boughtMinutes?: InputMaybe<Scalars['Float']['input']>
  boughtMinutesUsed?: InputMaybe<Scalars['Float']['input']>
  boughtMinutesUsed_gt?: InputMaybe<Scalars['Float']['input']>
  boughtMinutesUsed_gte?: InputMaybe<Scalars['Float']['input']>
  boughtMinutesUsed_in?: InputMaybe<Array<Scalars['Float']['input']>>
  boughtMinutesUsed_lt?: InputMaybe<Scalars['Float']['input']>
  boughtMinutesUsed_lte?: InputMaybe<Scalars['Float']['input']>
  boughtMinutesUsed_ne?: InputMaybe<Scalars['Float']['input']>
  boughtMinutesUsed_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  boughtMinutes_gt?: InputMaybe<Scalars['Float']['input']>
  boughtMinutes_gte?: InputMaybe<Scalars['Float']['input']>
  boughtMinutes_in?: InputMaybe<Array<Scalars['Float']['input']>>
  boughtMinutes_lt?: InputMaybe<Scalars['Float']['input']>
  boughtMinutes_lte?: InputMaybe<Scalars['Float']['input']>
  boughtMinutes_ne?: InputMaybe<Scalars['Float']['input']>
  boughtMinutes_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  boughtStorage?: InputMaybe<Scalars['Float']['input']>
  boughtStorage_gt?: InputMaybe<Scalars['Float']['input']>
  boughtStorage_gte?: InputMaybe<Scalars['Float']['input']>
  boughtStorage_in?: InputMaybe<Array<Scalars['Float']['input']>>
  boughtStorage_lt?: InputMaybe<Scalars['Float']['input']>
  boughtStorage_lte?: InputMaybe<Scalars['Float']['input']>
  boughtStorage_ne?: InputMaybe<Scalars['Float']['input']>
  boughtStorage_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  coverPhotoResourceId?: InputMaybe<Scalars['ID']['input']>
  coverPhotoResourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  coverPhotoResourceId_ne?: InputMaybe<Scalars['ID']['input']>
  coverPhotoResourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  coverPhotoResource_none?: InputMaybe<ResourceFilter>
  coverPhotoResource_some?: InputMaybe<ResourceFilter>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdById?: InputMaybe<Scalars['ID']['input']>
  createdById_in?: InputMaybe<Array<Scalars['ID']['input']>>
  createdById_ne?: InputMaybe<Scalars['ID']['input']>
  createdById_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  createdBy_none?: InputMaybe<UserFilter>
  createdBy_some?: InputMaybe<UserFilter>
  dns?: InputMaybe<Scalars['String']['input']>
  dnsValidation?: InputMaybe<Scalars['String']['input']>
  dnsValidationAt?: InputMaybe<Scalars['DateTime']['input']>
  dnsValidationAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  dnsValidationAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  dnsValidationAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  dnsValidationAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  dnsValidationAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  dnsValidationAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  dnsValidationAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  dnsValidation_endsWith?: InputMaybe<Scalars['String']['input']>
  dnsValidation_gt?: InputMaybe<Scalars['String']['input']>
  dnsValidation_gte?: InputMaybe<Scalars['String']['input']>
  dnsValidation_iLike?: InputMaybe<Scalars['String']['input']>
  dnsValidation_in?: InputMaybe<Array<Scalars['String']['input']>>
  dnsValidation_like?: InputMaybe<Scalars['String']['input']>
  dnsValidation_lt?: InputMaybe<Scalars['String']['input']>
  dnsValidation_lte?: InputMaybe<Scalars['String']['input']>
  dnsValidation_ne?: InputMaybe<Scalars['String']['input']>
  dnsValidation_notILike?: InputMaybe<Scalars['String']['input']>
  dnsValidation_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  dnsValidation_notLike?: InputMaybe<Scalars['String']['input']>
  dnsValidation_startsWith?: InputMaybe<Scalars['String']['input']>
  dnsValidation_substring?: InputMaybe<Scalars['String']['input']>
  dns_endsWith?: InputMaybe<Scalars['String']['input']>
  dns_gt?: InputMaybe<Scalars['String']['input']>
  dns_gte?: InputMaybe<Scalars['String']['input']>
  dns_iLike?: InputMaybe<Scalars['String']['input']>
  dns_in?: InputMaybe<Array<Scalars['String']['input']>>
  dns_like?: InputMaybe<Scalars['String']['input']>
  dns_lt?: InputMaybe<Scalars['String']['input']>
  dns_lte?: InputMaybe<Scalars['String']['input']>
  dns_ne?: InputMaybe<Scalars['String']['input']>
  dns_notILike?: InputMaybe<Scalars['String']['input']>
  dns_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  dns_notLike?: InputMaybe<Scalars['String']['input']>
  dns_startsWith?: InputMaybe<Scalars['String']['input']>
  dns_substring?: InputMaybe<Scalars['String']['input']>
  freeMinutesResetAt?: InputMaybe<Scalars['DateTime']['input']>
  freeMinutesResetAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  freeMinutesResetAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  freeMinutesResetAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  freeMinutesResetAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  freeMinutesResetAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  freeMinutesResetAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  freeMinutesResetAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  freeMinutesUsed?: InputMaybe<Scalars['Float']['input']>
  freeMinutesUsed_gt?: InputMaybe<Scalars['Float']['input']>
  freeMinutesUsed_gte?: InputMaybe<Scalars['Float']['input']>
  freeMinutesUsed_in?: InputMaybe<Array<Scalars['Float']['input']>>
  freeMinutesUsed_lt?: InputMaybe<Scalars['Float']['input']>
  freeMinutesUsed_lte?: InputMaybe<Scalars['Float']['input']>
  freeMinutesUsed_ne?: InputMaybe<Scalars['Float']['input']>
  freeMinutesUsed_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  stripeSubscriptionId?: InputMaybe<Scalars['ID']['input']>
  stripeSubscriptionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  stripeSubscriptionId_ne?: InputMaybe<Scalars['ID']['input']>
  stripeSubscriptionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  subscriptionPlan?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_endsWith?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_gt?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_gte?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_iLike?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_in?: InputMaybe<Array<Scalars['String']['input']>>
  subscriptionPlan_like?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_lt?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_lte?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_ne?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_notILike?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  subscriptionPlan_notLike?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_startsWith?: InputMaybe<Scalars['String']['input']>
  subscriptionPlan_substring?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_endsWith?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_gt?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_gte?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_iLike?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_in?: InputMaybe<Array<Scalars['String']['input']>>
  subscriptionStatus_like?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_lt?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_lte?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_ne?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_notILike?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  subscriptionStatus_notLike?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_startsWith?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus_substring?: InputMaybe<Scalars['String']['input']>
  systemLogoResourceId?: InputMaybe<Scalars['ID']['input']>
  systemLogoResourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  systemLogoResourceId_ne?: InputMaybe<Scalars['ID']['input']>
  systemLogoResourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  systemLogoResource_none?: InputMaybe<ResourceFilter>
  systemLogoResource_some?: InputMaybe<ResourceFilter>
  users_none?: InputMaybe<UserFilter>
  users_some?: InputMaybe<UserFilter>
}

export type OrgOrderBy =
  | 'blockedBySystemAdmin_asc'
  | 'blockedBySystemAdmin_desc'
  | 'bonusMinutesUsed_asc'
  | 'bonusMinutesUsed_desc'
  | 'bonusMinutes_asc'
  | 'bonusMinutes_desc'
  | 'bonusStorage_asc'
  | 'bonusStorage_desc'
  | 'boughtMinutesUsed_asc'
  | 'boughtMinutesUsed_desc'
  | 'boughtMinutes_asc'
  | 'boughtMinutes_desc'
  | 'boughtStorage_asc'
  | 'boughtStorage_desc'
  | 'coverPhotoResourceId_asc'
  | 'coverPhotoResourceId_desc'
  | 'coverPhotoResource_createdAt_asc'
  | 'coverPhotoResource_createdAt_desc'
  | 'coverPhotoResource_createdById_asc'
  | 'coverPhotoResource_createdById_desc'
  | 'coverPhotoResource_duration_asc'
  | 'coverPhotoResource_duration_desc'
  | 'coverPhotoResource_ffprobe_asc'
  | 'coverPhotoResource_ffprobe_desc'
  | 'coverPhotoResource_fileSize_asc'
  | 'coverPhotoResource_fileSize_desc'
  | 'coverPhotoResource_id_asc'
  | 'coverPhotoResource_id_desc'
  | 'coverPhotoResource_name_asc'
  | 'coverPhotoResource_name_desc'
  | 'coverPhotoResource_orgId_asc'
  | 'coverPhotoResource_orgId_desc'
  | 'coverPhotoResource_originalResourceId_asc'
  | 'coverPhotoResource_originalResourceId_desc'
  | 'coverPhotoResource_originalResourceState_asc'
  | 'coverPhotoResource_originalResourceState_desc'
  | 'coverPhotoResource_sessionId_asc'
  | 'coverPhotoResource_sessionId_desc'
  | 'coverPhotoResource_thumbnailResourceId_asc'
  | 'coverPhotoResource_thumbnailResourceId_desc'
  | 'coverPhotoResource_type_asc'
  | 'coverPhotoResource_type_desc'
  | 'coverPhotoResource_url_asc'
  | 'coverPhotoResource_url_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'createdById_asc'
  | 'createdById_desc'
  | 'createdBy_avatarUrl_asc'
  | 'createdBy_avatarUrl_desc'
  | 'createdBy_blockedBySystemAdmin_asc'
  | 'createdBy_blockedBySystemAdmin_desc'
  | 'createdBy_createdAt_asc'
  | 'createdBy_createdAt_desc'
  | 'createdBy_currentOrgId_asc'
  | 'createdBy_currentOrgId_desc'
  | 'createdBy_email_asc'
  | 'createdBy_email_desc'
  | 'createdBy_hashedPassword_asc'
  | 'createdBy_hashedPassword_desc'
  | 'createdBy_id_asc'
  | 'createdBy_id_desc'
  | 'createdBy_name_asc'
  | 'createdBy_name_desc'
  | 'createdBy_referralById_asc'
  | 'createdBy_referralById_desc'
  | 'createdBy_rootOrgId_asc'
  | 'createdBy_rootOrgId_desc'
  | 'createdBy_socialLogin_asc'
  | 'createdBy_socialLogin_desc'
  | 'createdBy_stripeCustomerId_asc'
  | 'createdBy_stripeCustomerId_desc'
  | 'createdBy_studioSettings_asc'
  | 'createdBy_studioSettings_desc'
  | 'createdBy_themeWarmness_asc'
  | 'createdBy_themeWarmness_desc'
  | 'createdBy_theme_asc'
  | 'createdBy_theme_desc'
  | 'dnsValidationAt_asc'
  | 'dnsValidationAt_desc'
  | 'dnsValidation_asc'
  | 'dnsValidation_desc'
  | 'dns_asc'
  | 'dns_desc'
  | 'freeMinutesResetAt_asc'
  | 'freeMinutesResetAt_desc'
  | 'freeMinutesUsed_asc'
  | 'freeMinutesUsed_desc'
  | 'id_asc'
  | 'id_desc'
  | 'name_asc'
  | 'name_desc'
  | 'stripeSubscriptionId_asc'
  | 'stripeSubscriptionId_desc'
  | 'subscriptionPlan_asc'
  | 'subscriptionPlan_desc'
  | 'subscriptionStatus_asc'
  | 'subscriptionStatus_desc'
  | 'systemLogoResourceId_asc'
  | 'systemLogoResourceId_desc'
  | 'systemLogoResource_createdAt_asc'
  | 'systemLogoResource_createdAt_desc'
  | 'systemLogoResource_createdById_asc'
  | 'systemLogoResource_createdById_desc'
  | 'systemLogoResource_duration_asc'
  | 'systemLogoResource_duration_desc'
  | 'systemLogoResource_ffprobe_asc'
  | 'systemLogoResource_ffprobe_desc'
  | 'systemLogoResource_fileSize_asc'
  | 'systemLogoResource_fileSize_desc'
  | 'systemLogoResource_id_asc'
  | 'systemLogoResource_id_desc'
  | 'systemLogoResource_name_asc'
  | 'systemLogoResource_name_desc'
  | 'systemLogoResource_orgId_asc'
  | 'systemLogoResource_orgId_desc'
  | 'systemLogoResource_originalResourceId_asc'
  | 'systemLogoResource_originalResourceId_desc'
  | 'systemLogoResource_originalResourceState_asc'
  | 'systemLogoResource_originalResourceState_desc'
  | 'systemLogoResource_sessionId_asc'
  | 'systemLogoResource_sessionId_desc'
  | 'systemLogoResource_thumbnailResourceId_asc'
  | 'systemLogoResource_thumbnailResourceId_desc'
  | 'systemLogoResource_type_asc'
  | 'systemLogoResource_type_desc'
  | 'systemLogoResource_url_asc'
  | 'systemLogoResource_url_desc'
  | 'users_count_asc'
  | 'users_count_desc'

export type OrgPermission =
  | 'cancelOrgPlan'
  | 'createSession'
  | 'deleteOutput'
  | 'deleteUserInOrg'
  | 'inviteUserInOrg'
  | 'updateOrgDetail'
  | 'updateOrgPlan'
  | 'viewOrgDetail'
  | 'viewOrgPlan'
  | 'viewUserInOrg'

export type OrgRole = {
  __typename?: 'OrgRole'
  name: OrgRoleName
  permissions: Scalars['OrgPermissions']['output']
}

export type OrgRoleName = 'Admin' | 'Member'

export type Output = {
  __typename?: 'Output'
  avatarUrl: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  data?: Maybe<Scalars['Json']['output']>
  expiredAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  orgId: Scalars['ID']['output']
  type: Scalars['String']['output']
}

export type OutputFilter = {
  AND?: InputMaybe<Array<OutputFilter>>
  NOT?: InputMaybe<OutputFilter>
  OR?: InputMaybe<Array<OutputFilter>>
  accessToken?: InputMaybe<Scalars['String']['input']>
  accessToken_endsWith?: InputMaybe<Scalars['String']['input']>
  accessToken_gt?: InputMaybe<Scalars['String']['input']>
  accessToken_gte?: InputMaybe<Scalars['String']['input']>
  accessToken_iLike?: InputMaybe<Scalars['String']['input']>
  accessToken_in?: InputMaybe<Array<Scalars['String']['input']>>
  accessToken_like?: InputMaybe<Scalars['String']['input']>
  accessToken_lt?: InputMaybe<Scalars['String']['input']>
  accessToken_lte?: InputMaybe<Scalars['String']['input']>
  accessToken_ne?: InputMaybe<Scalars['String']['input']>
  accessToken_notILike?: InputMaybe<Scalars['String']['input']>
  accessToken_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  accessToken_notLike?: InputMaybe<Scalars['String']['input']>
  accessToken_startsWith?: InputMaybe<Scalars['String']['input']>
  accessToken_substring?: InputMaybe<Scalars['String']['input']>
  avatarUrl?: InputMaybe<Scalars['String']['input']>
  avatarUrl_endsWith?: InputMaybe<Scalars['String']['input']>
  avatarUrl_gt?: InputMaybe<Scalars['String']['input']>
  avatarUrl_gte?: InputMaybe<Scalars['String']['input']>
  avatarUrl_iLike?: InputMaybe<Scalars['String']['input']>
  avatarUrl_in?: InputMaybe<Array<Scalars['String']['input']>>
  avatarUrl_like?: InputMaybe<Scalars['String']['input']>
  avatarUrl_lt?: InputMaybe<Scalars['String']['input']>
  avatarUrl_lte?: InputMaybe<Scalars['String']['input']>
  avatarUrl_ne?: InputMaybe<Scalars['String']['input']>
  avatarUrl_notILike?: InputMaybe<Scalars['String']['input']>
  avatarUrl_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  avatarUrl_notLike?: InputMaybe<Scalars['String']['input']>
  avatarUrl_startsWith?: InputMaybe<Scalars['String']['input']>
  avatarUrl_substring?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  expiredAt?: InputMaybe<Scalars['DateTime']['input']>
  expiredAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  expiredAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  expiredAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  expiredAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  expiredAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  expiredAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  expiredAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  orgId?: InputMaybe<Scalars['ID']['input']>
  orgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId_ne?: InputMaybe<Scalars['ID']['input']>
  orgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  refreshToken?: InputMaybe<Scalars['String']['input']>
  refreshToken_endsWith?: InputMaybe<Scalars['String']['input']>
  refreshToken_gt?: InputMaybe<Scalars['String']['input']>
  refreshToken_gte?: InputMaybe<Scalars['String']['input']>
  refreshToken_iLike?: InputMaybe<Scalars['String']['input']>
  refreshToken_in?: InputMaybe<Array<Scalars['String']['input']>>
  refreshToken_like?: InputMaybe<Scalars['String']['input']>
  refreshToken_lt?: InputMaybe<Scalars['String']['input']>
  refreshToken_lte?: InputMaybe<Scalars['String']['input']>
  refreshToken_ne?: InputMaybe<Scalars['String']['input']>
  refreshToken_notILike?: InputMaybe<Scalars['String']['input']>
  refreshToken_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  refreshToken_notLike?: InputMaybe<Scalars['String']['input']>
  refreshToken_startsWith?: InputMaybe<Scalars['String']['input']>
  refreshToken_substring?: InputMaybe<Scalars['String']['input']>
  socialId?: InputMaybe<Scalars['ID']['input']>
  socialId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  socialId_ne?: InputMaybe<Scalars['ID']['input']>
  socialId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
}

export type OutputInSession = {
  __typename?: 'OutputInSession'
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  lbId?: Maybe<Scalars['ID']['output']>
  minutesUsedUpdatedAt: Scalars['DateTime']['output']
  mixerQuery: Scalars['String']['output']
  orgId: Scalars['ID']['output']
  output?: Maybe<Output>
  outputId: Scalars['ID']['output']
  publishingAt?: Maybe<Scalars['DateTime']['output']>
  roomType: Scalars['String']['output']
  sessionId: Scalars['ID']['output']
  status: Scalars['String']['output']
  thumbnail?: Maybe<Resource>
  thumbnailId?: Maybe<Scalars['ID']['output']>
  title: Scalars['String']['output']
}

export type OutputInSessionFilter = {
  AND?: InputMaybe<Array<OutputInSessionFilter>>
  NOT?: InputMaybe<OutputInSessionFilter>
  OR?: InputMaybe<Array<OutputInSessionFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  description_endsWith?: InputMaybe<Scalars['String']['input']>
  description_gt?: InputMaybe<Scalars['String']['input']>
  description_gte?: InputMaybe<Scalars['String']['input']>
  description_iLike?: InputMaybe<Scalars['String']['input']>
  description_in?: InputMaybe<Array<Scalars['String']['input']>>
  description_like?: InputMaybe<Scalars['String']['input']>
  description_lt?: InputMaybe<Scalars['String']['input']>
  description_lte?: InputMaybe<Scalars['String']['input']>
  description_ne?: InputMaybe<Scalars['String']['input']>
  description_notILike?: InputMaybe<Scalars['String']['input']>
  description_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  description_notLike?: InputMaybe<Scalars['String']['input']>
  description_startsWith?: InputMaybe<Scalars['String']['input']>
  description_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  lbId?: InputMaybe<Scalars['ID']['input']>
  lbId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  lbId_ne?: InputMaybe<Scalars['ID']['input']>
  lbId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  minutesUsedUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>
  minutesUsedUpdatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  minutesUsedUpdatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  minutesUsedUpdatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  minutesUsedUpdatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  minutesUsedUpdatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  minutesUsedUpdatedAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  minutesUsedUpdatedAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  mixerQuery?: InputMaybe<Scalars['String']['input']>
  mixerQuery_endsWith?: InputMaybe<Scalars['String']['input']>
  mixerQuery_gt?: InputMaybe<Scalars['String']['input']>
  mixerQuery_gte?: InputMaybe<Scalars['String']['input']>
  mixerQuery_iLike?: InputMaybe<Scalars['String']['input']>
  mixerQuery_in?: InputMaybe<Array<Scalars['String']['input']>>
  mixerQuery_like?: InputMaybe<Scalars['String']['input']>
  mixerQuery_lt?: InputMaybe<Scalars['String']['input']>
  mixerQuery_lte?: InputMaybe<Scalars['String']['input']>
  mixerQuery_ne?: InputMaybe<Scalars['String']['input']>
  mixerQuery_notILike?: InputMaybe<Scalars['String']['input']>
  mixerQuery_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  mixerQuery_notLike?: InputMaybe<Scalars['String']['input']>
  mixerQuery_startsWith?: InputMaybe<Scalars['String']['input']>
  mixerQuery_substring?: InputMaybe<Scalars['String']['input']>
  orgId?: InputMaybe<Scalars['ID']['input']>
  orgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId_ne?: InputMaybe<Scalars['ID']['input']>
  orgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  outputId?: InputMaybe<Scalars['ID']['input']>
  outputId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  outputId_ne?: InputMaybe<Scalars['ID']['input']>
  outputId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  output_none?: InputMaybe<OutputFilter>
  output_some?: InputMaybe<OutputFilter>
  publishingAt?: InputMaybe<Scalars['DateTime']['input']>
  publishingAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  publishingAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  publishingAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  publishingAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  publishingAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  publishingAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  publishingAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  roomType?: InputMaybe<Scalars['String']['input']>
  roomType_endsWith?: InputMaybe<Scalars['String']['input']>
  roomType_gt?: InputMaybe<Scalars['String']['input']>
  roomType_gte?: InputMaybe<Scalars['String']['input']>
  roomType_iLike?: InputMaybe<Scalars['String']['input']>
  roomType_in?: InputMaybe<Array<Scalars['String']['input']>>
  roomType_like?: InputMaybe<Scalars['String']['input']>
  roomType_lt?: InputMaybe<Scalars['String']['input']>
  roomType_lte?: InputMaybe<Scalars['String']['input']>
  roomType_ne?: InputMaybe<Scalars['String']['input']>
  roomType_notILike?: InputMaybe<Scalars['String']['input']>
  roomType_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  roomType_notLike?: InputMaybe<Scalars['String']['input']>
  roomType_startsWith?: InputMaybe<Scalars['String']['input']>
  roomType_substring?: InputMaybe<Scalars['String']['input']>
  sessionId?: InputMaybe<Scalars['ID']['input']>
  sessionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId_ne?: InputMaybe<Scalars['ID']['input']>
  sessionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  status?: InputMaybe<Scalars['String']['input']>
  status_endsWith?: InputMaybe<Scalars['String']['input']>
  status_gt?: InputMaybe<Scalars['String']['input']>
  status_gte?: InputMaybe<Scalars['String']['input']>
  status_iLike?: InputMaybe<Scalars['String']['input']>
  status_in?: InputMaybe<Array<Scalars['String']['input']>>
  status_like?: InputMaybe<Scalars['String']['input']>
  status_lt?: InputMaybe<Scalars['String']['input']>
  status_lte?: InputMaybe<Scalars['String']['input']>
  status_ne?: InputMaybe<Scalars['String']['input']>
  status_notILike?: InputMaybe<Scalars['String']['input']>
  status_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  status_notLike?: InputMaybe<Scalars['String']['input']>
  status_startsWith?: InputMaybe<Scalars['String']['input']>
  status_substring?: InputMaybe<Scalars['String']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailId_ne?: InputMaybe<Scalars['ID']['input']>
  thumbnailId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnail_none?: InputMaybe<ResourceFilter>
  thumbnail_some?: InputMaybe<ResourceFilter>
  title?: InputMaybe<Scalars['String']['input']>
  title_endsWith?: InputMaybe<Scalars['String']['input']>
  title_gt?: InputMaybe<Scalars['String']['input']>
  title_gte?: InputMaybe<Scalars['String']['input']>
  title_iLike?: InputMaybe<Scalars['String']['input']>
  title_in?: InputMaybe<Array<Scalars['String']['input']>>
  title_like?: InputMaybe<Scalars['String']['input']>
  title_lt?: InputMaybe<Scalars['String']['input']>
  title_lte?: InputMaybe<Scalars['String']['input']>
  title_ne?: InputMaybe<Scalars['String']['input']>
  title_notILike?: InputMaybe<Scalars['String']['input']>
  title_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  title_notLike?: InputMaybe<Scalars['String']['input']>
  title_startsWith?: InputMaybe<Scalars['String']['input']>
  title_substring?: InputMaybe<Scalars['String']['input']>
}

export type OutputInSessionOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'description_asc'
  | 'description_desc'
  | 'id_asc'
  | 'id_desc'
  | 'lbId_asc'
  | 'lbId_desc'
  | 'minutesUsedUpdatedAt_asc'
  | 'minutesUsedUpdatedAt_desc'
  | 'mixerQuery_asc'
  | 'mixerQuery_desc'
  | 'orgId_asc'
  | 'orgId_desc'
  | 'outputId_asc'
  | 'outputId_desc'
  | 'output_accessToken_asc'
  | 'output_accessToken_desc'
  | 'output_avatarUrl_asc'
  | 'output_avatarUrl_desc'
  | 'output_createdAt_asc'
  | 'output_createdAt_desc'
  | 'output_data_asc'
  | 'output_data_desc'
  | 'output_expiredAt_asc'
  | 'output_expiredAt_desc'
  | 'output_id_asc'
  | 'output_id_desc'
  | 'output_name_asc'
  | 'output_name_desc'
  | 'output_orgId_asc'
  | 'output_orgId_desc'
  | 'output_refreshToken_asc'
  | 'output_refreshToken_desc'
  | 'output_socialId_asc'
  | 'output_socialId_desc'
  | 'output_type_asc'
  | 'output_type_desc'
  | 'publishingAt_asc'
  | 'publishingAt_desc'
  | 'roomType_asc'
  | 'roomType_desc'
  | 'sessionId_asc'
  | 'sessionId_desc'
  | 'status_asc'
  | 'status_desc'
  | 'thumbnailId_asc'
  | 'thumbnailId_desc'
  | 'thumbnail_createdAt_asc'
  | 'thumbnail_createdAt_desc'
  | 'thumbnail_createdById_asc'
  | 'thumbnail_createdById_desc'
  | 'thumbnail_duration_asc'
  | 'thumbnail_duration_desc'
  | 'thumbnail_ffprobe_asc'
  | 'thumbnail_ffprobe_desc'
  | 'thumbnail_fileSize_asc'
  | 'thumbnail_fileSize_desc'
  | 'thumbnail_id_asc'
  | 'thumbnail_id_desc'
  | 'thumbnail_name_asc'
  | 'thumbnail_name_desc'
  | 'thumbnail_orgId_asc'
  | 'thumbnail_orgId_desc'
  | 'thumbnail_originalResourceId_asc'
  | 'thumbnail_originalResourceId_desc'
  | 'thumbnail_originalResourceState_asc'
  | 'thumbnail_originalResourceState_desc'
  | 'thumbnail_sessionId_asc'
  | 'thumbnail_sessionId_desc'
  | 'thumbnail_thumbnailResourceId_asc'
  | 'thumbnail_thumbnailResourceId_desc'
  | 'thumbnail_type_asc'
  | 'thumbnail_type_desc'
  | 'thumbnail_url_asc'
  | 'thumbnail_url_desc'
  | 'title_asc'
  | 'title_desc'

export type OutputOrderBy =
  | 'accessToken_asc'
  | 'accessToken_desc'
  | 'avatarUrl_asc'
  | 'avatarUrl_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'expiredAt_asc'
  | 'expiredAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'name_asc'
  | 'name_desc'
  | 'orgId_asc'
  | 'orgId_desc'
  | 'refreshToken_asc'
  | 'refreshToken_desc'
  | 'socialId_asc'
  | 'socialId_desc'
  | 'type_asc'
  | 'type_desc'

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type Plan = {
  __typename?: 'Plan'
  freeMinutesPerMonth: Scalars['NeString']['output']
  freeStoragePerMonth: Scalars['NeString']['output']
  maxParticipants: Scalars['PInt']['output']
  name: PlanName
  prices: Array<PlanPrice>
}

export type PlanCurrency = 'GBP' | 'USD'

export type PlanInterval = 'Monthly' | 'Yearly'

export type PlanName = 'Enterprise' | 'Freemium' | 'Professional'

export type PlanPrice = {
  __typename?: 'PlanPrice'
  currency: PlanCurrency
  interval: PlanInterval
  price: Scalars['UInt']['output']
  priceMinutes?: Maybe<Scalars['UInt']['output']>
  priceMinutesTiers?: Maybe<Array<PlanPriceTier>>
  priceStorage?: Maybe<Scalars['UInt']['output']>
  priceStorageTiers?: Maybe<Array<PlanPriceTier>>
}

export type PlanPriceTier = {
  __typename?: 'PlanPriceTier'
  from: Scalars['UInt']['output']
  price: Scalars['UInt']['output']
  to?: Maybe<Scalars['PInt']['output']>
}

export type Query = {
  __typename?: 'Query'
  buyMoreMinutesCompute: ComputedPrice
  buyMoreStorageCompute: ComputedPrice
  checkTicket: Scalars['Boolean']['output']
  checkUserInSession: Scalars['Boolean']['output']
  countBlurBlock: Scalars['Int']['output']
  countChatbotMsg: Scalars['Int']['output']
  countInput: Scalars['Int']['output']
  countInsightEngine: Scalars['Int']['output']
  countInsightEngineOfResource: Scalars['Int']['output']
  countLbInstance: Scalars['Int']['output']
  countMute: Scalars['Int']['output']
  countMyOrgInvitations: Scalars['Int']['output']
  countMyOrgs: Scalars['Int']['output']
  countNote: Scalars['Int']['output']
  countOrgInviteTickets: Scalars['Int']['output']
  countOutput: Scalars['Int']['output']
  countOutputInSession: Scalars['Int']['output']
  countResourceInInsightEngine: Scalars['Int']['output']
  countResourceInSession: Scalars['Int']['output']
  countSession: Scalars['Int']['output']
  countSystemFlag: Scalars['Int']['output']
  countTransaction: Scalars['Int']['output']
  countTranscript: Scalars['Int']['output']
  countUserInOrg: Scalars['Int']['output']
  countUserInResource: Scalars['Int']['output']
  countUserInSession: Scalars['Int']['output']
  countWaitingUser: Scalars['Int']['output']
  currentAuthSession?: Maybe<AuthSession>
  currentPlan: CurrentPlan
  detailInsightEngine: InsightEngine
  detailOrg: Org
  detailSession: Session
  insightEnginePlan: CurrentPlan
  lbMediasoupUrl?: Maybe<Scalars['NeString']['output']>
  myOrgInvitations: Array<Ticket>
  myOrgs: Array<UserInOrg>
  orgInviteTickets: Array<Ticket>
  orgRole?: Maybe<OrgRole>
  plans: Array<Plan>
  searchAms: Array<Ams>
  searchAmsResource: Array<Resource>
  searchBlurBlock: Array<BlurBlock>
  searchChatbotContext: Array<ChatbotContext>
  searchChatbotMsg: Array<ChatbotMsg>
  searchInput: Array<Input>
  searchInsightEngine: Array<InsightEngine>
  searchInsightEngineOfResource: Array<ResourceInInsightEngine>
  searchLbInstance: Array<LbInstance>
  searchMute: Array<Mute>
  searchNote: Array<Note>
  searchOutput: Array<Output>
  searchOutputInSession: Array<OutputInSession>
  searchReports: Report
  searchResourceInAms: Array<ResourceInAms>
  searchResourceInInsightEngine: Array<ResourceInInsightEngine>
  searchResourceInSession: Array<ResourceInSession>
  searchSession: Array<Session>
  searchSessionInLb: Array<Session>
  searchSystemFlag: Array<SystemFlag>
  searchTransaction: Array<Transaction>
  searchTranscript: Array<Transcript>
  searchUserInOrg: Array<UserInOrg>
  searchUserInResource: Array<UserInResource>
  searchUserInSession: Array<UserInSession>
  searchWaitingUser: Array<WaitingUser>
  sessionPlan: CurrentPlan
  sessionRole?: Maybe<SessionRole>
  systemCountOrg: Scalars['Int']['output']
  systemCountUser: Scalars['Int']['output']
  systemCountUserInOrg: Scalars['Int']['output']
  systemDetailOrg: Org
  systemDetailUser: User
  systemRole?: Maybe<SystemRole>
  systemSearchOrg: Array<Org>
  systemSearchUser: Array<User>
  systemSearchUserInOrg: Array<UserInOrg>
  systemViewCurrentPlan: CurrentPlan
  version: Scalars['Json']['output']
}

export type QueryBuyMoreMinutesComputeArgs = {
  data: BuyMoreMinutes
}

export type QueryBuyMoreStorageComputeArgs = {
  data: BuyMoreStorage
}

export type QueryCheckTicketArgs = {
  data: CheckTicket
  type: CheckTicketType
}

export type QueryCheckUserInSessionArgs = {
  sessionId: Scalars['String']['input']
}

export type QueryCountBlurBlockArgs = {
  filter?: InputMaybe<BlurBlockFilter>
  resourceInInsightEngineId: Scalars['ID']['input']
}

export type QueryCountChatbotMsgArgs = {
  filter?: InputMaybe<ChatbotMsgFilter>
  type: Scalars['String']['input']
}

export type QueryCountInputArgs = {
  filter?: InputMaybe<InputFilter>
  sessionId: Scalars['ID']['input']
}

export type QueryCountInsightEngineArgs = {
  filter?: InputMaybe<InsightEngineFilter>
}

export type QueryCountInsightEngineOfResourceArgs = {
  filter?: InputMaybe<ResourceInInsightEngineFilter>
  resourceId: Scalars['ID']['input']
}

export type QueryCountLbInstanceArgs = {
  filter?: InputMaybe<LbInstanceFilter>
}

export type QueryCountMuteArgs = {
  filter?: InputMaybe<MuteFilter>
  resourceInInsightEngineId: Scalars['ID']['input']
}

export type QueryCountMyOrgInvitationsArgs = {
  filter?: InputMaybe<TicketFilter>
}

export type QueryCountMyOrgsArgs = {
  filter?: InputMaybe<UserInOrgFilter>
}

export type QueryCountNoteArgs = {
  filter?: InputMaybe<NoteFilter>
  sessionId: Scalars['ID']['input']
}

export type QueryCountOrgInviteTicketsArgs = {
  filter?: InputMaybe<TicketFilter>
  orgId: Scalars['ID']['input']
}

export type QueryCountOutputArgs = {
  filter?: InputMaybe<OutputFilter>
  sessionId: Scalars['ID']['input']
}

export type QueryCountOutputInSessionArgs = {
  filter?: InputMaybe<OutputInSessionFilter>
  sessionId: Scalars['ID']['input']
}

export type QueryCountResourceInInsightEngineArgs = {
  filter?: InputMaybe<ResourceInInsightEngineFilter>
  insightEngineId: Scalars['ID']['input']
}

export type QueryCountResourceInSessionArgs = {
  filter?: InputMaybe<ResourceInSessionFilter>
  sessionId: Scalars['ID']['input']
}

export type QueryCountSessionArgs = {
  filter?: InputMaybe<SessionFilter>
}

export type QueryCountSystemFlagArgs = {
  filter?: InputMaybe<SystemFlagFilter>
}

export type QueryCountTransactionArgs = {
  filter?: InputMaybe<TransactionFilter>
  orgId: Scalars['ID']['input']
}

export type QueryCountTranscriptArgs = {
  filter?: InputMaybe<TranscriptFilter>
  resourceInInsightEngineId: Scalars['ID']['input']
}

export type QueryCountUserInOrgArgs = {
  filter?: InputMaybe<UserInOrgFilter>
  orgId: Scalars['ID']['input']
}

export type QueryCountUserInResourceArgs = {
  filter?: InputMaybe<UserInResourceFilter>
}

export type QueryCountUserInSessionArgs = {
  filter?: InputMaybe<UserInSessionFilter>
  sessionId: Scalars['ID']['input']
}

export type QueryCountWaitingUserArgs = {
  filter?: InputMaybe<WaitingUserFilter>
  sessionId: Scalars['ID']['input']
}

export type QueryCurrentPlanArgs = {
  orgId: Scalars['ID']['input']
}

export type QueryDetailInsightEngineArgs = {
  id: Scalars['ID']['input']
}

export type QueryDetailOrgArgs = {
  id: Scalars['ID']['input']
}

export type QueryDetailSessionArgs = {
  id: Scalars['ID']['input']
}

export type QueryInsightEnginePlanArgs = {
  insightEngineId: Scalars['ID']['input']
}

export type QueryLbMediasoupUrlArgs = {
  sessionId: Scalars['ID']['input']
}

export type QueryMyOrgInvitationsArgs = {
  filter?: InputMaybe<TicketFilter>
  order?: InputMaybe<Array<TicketOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QueryMyOrgsArgs = {
  filter?: InputMaybe<UserInOrgFilter>
  order?: InputMaybe<Array<UserInOrgOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QueryOrgInviteTicketsArgs = {
  filter?: InputMaybe<TicketFilter>
  order?: InputMaybe<Array<TicketOrderBy>>
  orgId: Scalars['ID']['input']
  page?: InputMaybe<Pagination>
}

export type QueryOrgRoleArgs = {
  orgId: Scalars['ID']['input']
}

export type QuerySearchAmsArgs = {
  filter?: InputMaybe<AmsFilter>
  order?: InputMaybe<Array<AmsOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchAmsResourceArgs = {
  filter?: InputMaybe<ResourceFilter>
  order?: InputMaybe<Array<ResourceOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchBlurBlockArgs = {
  filter?: InputMaybe<BlurBlockFilter>
  order?: InputMaybe<Array<BlurBlockOrderBy>>
  page?: InputMaybe<Pagination>
  resourceInInsightEngineId: Scalars['ID']['input']
}

export type QuerySearchChatbotContextArgs = {
  filter?: InputMaybe<ChatbotContextFilter>
  order?: InputMaybe<Array<ChatbotContextOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchChatbotMsgArgs = {
  filter?: InputMaybe<ChatbotMsgFilter>
  order?: InputMaybe<Array<ChatbotMsgOrderBy>>
  page?: InputMaybe<Pagination>
  type: Scalars['String']['input']
}

export type QuerySearchInputArgs = {
  filter?: InputMaybe<InputFilter>
  order?: InputMaybe<Array<InputOrderBy>>
  page?: InputMaybe<Pagination>
  sessionId: Scalars['ID']['input']
}

export type QuerySearchInsightEngineArgs = {
  filter?: InputMaybe<InsightEngineFilter>
  order?: InputMaybe<Array<InsightEngineOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchInsightEngineOfResourceArgs = {
  filter?: InputMaybe<ResourceInInsightEngineFilter>
  order?: InputMaybe<Array<ResourceInInsightEngineOrderBy>>
  page?: InputMaybe<Pagination>
  resourceId: Scalars['ID']['input']
}

export type QuerySearchLbInstanceArgs = {
  filter?: InputMaybe<LbInstanceFilter>
  order?: InputMaybe<Array<LbInstanceOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchMuteArgs = {
  filter?: InputMaybe<MuteFilter>
  order?: InputMaybe<Array<MuteOrderBy>>
  page?: InputMaybe<Pagination>
  resourceInInsightEngineId: Scalars['ID']['input']
}

export type QuerySearchNoteArgs = {
  filter?: InputMaybe<NoteFilter>
  order?: InputMaybe<Array<NoteOrderBy>>
  page?: InputMaybe<Pagination>
  sessionId: Scalars['ID']['input']
}

export type QuerySearchOutputArgs = {
  filter?: InputMaybe<OutputFilter>
  order?: InputMaybe<Array<OutputOrderBy>>
  page?: InputMaybe<Pagination>
  sessionId: Scalars['ID']['input']
}

export type QuerySearchOutputInSessionArgs = {
  filter?: InputMaybe<OutputInSessionFilter>
  order?: InputMaybe<Array<OutputInSessionOrderBy>>
  page?: InputMaybe<Pagination>
  sessionId: Scalars['ID']['input']
}

export type QuerySearchReportsArgs = {
  filter?: InputMaybe<SearchReportFilter>
}

export type QuerySearchResourceInAmsArgs = {
  filter?: InputMaybe<ResourceInAmsFilter>
  order?: InputMaybe<Array<ResourceInAmsOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchResourceInInsightEngineArgs = {
  filter?: InputMaybe<ResourceInInsightEngineFilter>
  insightEngineId: Scalars['ID']['input']
  order?: InputMaybe<Array<ResourceInInsightEngineOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchResourceInSessionArgs = {
  filter?: InputMaybe<ResourceInSessionFilter>
  order?: InputMaybe<Array<ResourceInSessionOrderBy>>
  page?: InputMaybe<Pagination>
  sessionId: Scalars['ID']['input']
}

export type QuerySearchSessionArgs = {
  filter?: InputMaybe<SessionFilter>
  order?: InputMaybe<Array<SessionOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchSessionInLbArgs = {
  filter?: InputMaybe<SessionFilter>
  lbId: Scalars['ID']['input']
  order?: InputMaybe<Array<SessionOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchSystemFlagArgs = {
  filter?: InputMaybe<SystemFlagFilter>
  order?: InputMaybe<Array<SystemFlagOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchTransactionArgs = {
  filter?: InputMaybe<TransactionFilter>
  order?: InputMaybe<Array<TransactionOrderBy>>
  orgId: Scalars['ID']['input']
  page?: InputMaybe<Pagination>
}

export type QuerySearchTranscriptArgs = {
  filter?: InputMaybe<TranscriptFilter>
  order?: InputMaybe<Array<TranscriptOrderBy>>
  page?: InputMaybe<Pagination>
  resourceInInsightEngineId: Scalars['ID']['input']
}

export type QuerySearchUserInOrgArgs = {
  filter?: InputMaybe<UserInOrgFilter>
  order?: InputMaybe<Array<UserInOrgOrderBy>>
  orgId: Scalars['ID']['input']
  page?: InputMaybe<Pagination>
}

export type QuerySearchUserInResourceArgs = {
  filter?: InputMaybe<UserInResourceFilter>
  order?: InputMaybe<Array<UserInResourceOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySearchUserInSessionArgs = {
  filter?: InputMaybe<UserInSessionFilter>
  order?: InputMaybe<Array<UserInSessionOrderBy>>
  page?: InputMaybe<Pagination>
  sessionId: Scalars['ID']['input']
}

export type QuerySearchWaitingUserArgs = {
  filter?: InputMaybe<WaitingUserFilter>
  order?: InputMaybe<Array<WaitingUserOrderBy>>
  page?: InputMaybe<Pagination>
  sessionId: Scalars['ID']['input']
}

export type QuerySessionPlanArgs = {
  sessionId: Scalars['ID']['input']
}

export type QuerySessionRoleArgs = {
  sessionId: Scalars['ID']['input']
}

export type QuerySystemCountOrgArgs = {
  filter?: InputMaybe<OrgFilter>
}

export type QuerySystemCountUserArgs = {
  filter?: InputMaybe<UserFilter>
}

export type QuerySystemCountUserInOrgArgs = {
  filter?: InputMaybe<UserInOrgFilter>
}

export type QuerySystemDetailOrgArgs = {
  id: Scalars['ID']['input']
}

export type QuerySystemDetailUserArgs = {
  id: Scalars['ID']['input']
}

export type QuerySystemSearchOrgArgs = {
  filter?: InputMaybe<OrgFilter>
  order?: InputMaybe<Array<OrgOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySystemSearchUserArgs = {
  filter?: InputMaybe<UserFilter>
  order?: InputMaybe<Array<UserOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySystemSearchUserInOrgArgs = {
  filter?: InputMaybe<UserInOrgFilter>
  order?: InputMaybe<Array<UserInOrgOrderBy>>
  page?: InputMaybe<Pagination>
}

export type QuerySystemViewCurrentPlanArgs = {
  orgId: Scalars['ID']['input']
}

export type ReauthenticateOutputData = {
  code: Scalars['String']['input']
  redirectUrl: Scalars['String']['input']
  sessionId: Scalars['String']['input']
}

export type RecallBot = {
  __typename?: 'RecallBot'
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  createdById?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  insightEngine?: Maybe<InsightEngine>
  insightEngineId: Scalars['ID']['output']
  recallBotId: Scalars['ID']['output']
  resourceInInsightEngine?: Maybe<ResourceInInsightEngine>
  resourceInInsightEngineId: Scalars['ID']['output']
}

export type RecallBotFilter = {
  AND?: InputMaybe<Array<RecallBotFilter>>
  NOT?: InputMaybe<RecallBotFilter>
  OR?: InputMaybe<Array<RecallBotFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdById?: InputMaybe<Scalars['ID']['input']>
  createdById_in?: InputMaybe<Array<Scalars['ID']['input']>>
  createdById_ne?: InputMaybe<Scalars['ID']['input']>
  createdById_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  createdBy_none?: InputMaybe<UserFilter>
  createdBy_some?: InputMaybe<UserFilter>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngineId?: InputMaybe<Scalars['ID']['input']>
  insightEngineId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngineId_ne?: InputMaybe<Scalars['ID']['input']>
  insightEngineId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngine_none?: InputMaybe<InsightEngineFilter>
  insightEngine_some?: InputMaybe<InsightEngineFilter>
  recallBotId?: InputMaybe<Scalars['ID']['input']>
  recallBotId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  recallBotId_ne?: InputMaybe<Scalars['ID']['input']>
  recallBotId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngineId?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngineId_ne?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngine_none?: InputMaybe<ResourceInInsightEngineFilter>
  resourceInInsightEngine_some?: InputMaybe<ResourceInInsightEngineFilter>
}

export type Register = {
  email: Scalars['Email']['input']
  name: Scalars['String']['input']
  password: Scalars['Password']['input']
  referralById?: InputMaybe<Scalars['ID']['input']>
}

export type Report = {
  __typename?: 'Report'
  totalMinutesOfVideo: Scalars['Float']['output']
  totalNumberOfLogin: Scalars['Int']['output']
  totalOfCompletedSession: Scalars['Int']['output']
}

export type Resource = {
  __typename?: 'Resource'
  createdAt: Scalars['DateTime']['output']
  createdById?: Maybe<Scalars['ID']['output']>
  duration: Scalars['Float']['output']
  ffprobe?: Maybe<Scalars['Json']['output']>
  fileSize: Scalars['Float']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  orgId?: Maybe<Scalars['ID']['output']>
  originalResource?: Maybe<Resource>
  originalResourceId?: Maybe<Scalars['ID']['output']>
  originalResourceState?: Maybe<Scalars['Json']['output']>
  sessionId?: Maybe<Scalars['ID']['output']>
  thumbnailResource?: Maybe<Resource>
  thumbnailResourceId?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ResourceFilter = {
  AND?: InputMaybe<Array<ResourceFilter>>
  NOT?: InputMaybe<ResourceFilter>
  OR?: InputMaybe<Array<ResourceFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdById?: InputMaybe<Scalars['ID']['input']>
  createdById_in?: InputMaybe<Array<Scalars['ID']['input']>>
  createdById_ne?: InputMaybe<Scalars['ID']['input']>
  createdById_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  duration?: InputMaybe<Scalars['Float']['input']>
  duration_gt?: InputMaybe<Scalars['Float']['input']>
  duration_gte?: InputMaybe<Scalars['Float']['input']>
  duration_in?: InputMaybe<Array<Scalars['Float']['input']>>
  duration_lt?: InputMaybe<Scalars['Float']['input']>
  duration_lte?: InputMaybe<Scalars['Float']['input']>
  duration_ne?: InputMaybe<Scalars['Float']['input']>
  duration_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  fileSize?: InputMaybe<Scalars['Float']['input']>
  fileSize_gt?: InputMaybe<Scalars['Float']['input']>
  fileSize_gte?: InputMaybe<Scalars['Float']['input']>
  fileSize_in?: InputMaybe<Array<Scalars['Float']['input']>>
  fileSize_lt?: InputMaybe<Scalars['Float']['input']>
  fileSize_lte?: InputMaybe<Scalars['Float']['input']>
  fileSize_ne?: InputMaybe<Scalars['Float']['input']>
  fileSize_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  orgId?: InputMaybe<Scalars['ID']['input']>
  orgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId_ne?: InputMaybe<Scalars['ID']['input']>
  orgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  originalResourceId?: InputMaybe<Scalars['ID']['input']>
  originalResourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  originalResourceId_ne?: InputMaybe<Scalars['ID']['input']>
  originalResourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  originalResource_none?: InputMaybe<ResourceFilter>
  originalResource_some?: InputMaybe<ResourceFilter>
  sessionId?: InputMaybe<Scalars['ID']['input']>
  sessionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId_ne?: InputMaybe<Scalars['ID']['input']>
  sessionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailResourceId?: InputMaybe<Scalars['ID']['input']>
  thumbnailResourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailResourceId_ne?: InputMaybe<Scalars['ID']['input']>
  thumbnailResourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  thumbnailResource_none?: InputMaybe<ResourceFilter>
  thumbnailResource_some?: InputMaybe<ResourceFilter>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
  url_endsWith?: InputMaybe<Scalars['String']['input']>
  url_gt?: InputMaybe<Scalars['String']['input']>
  url_gte?: InputMaybe<Scalars['String']['input']>
  url_iLike?: InputMaybe<Scalars['String']['input']>
  url_in?: InputMaybe<Array<Scalars['String']['input']>>
  url_like?: InputMaybe<Scalars['String']['input']>
  url_lt?: InputMaybe<Scalars['String']['input']>
  url_lte?: InputMaybe<Scalars['String']['input']>
  url_ne?: InputMaybe<Scalars['String']['input']>
  url_notILike?: InputMaybe<Scalars['String']['input']>
  url_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  url_notLike?: InputMaybe<Scalars['String']['input']>
  url_startsWith?: InputMaybe<Scalars['String']['input']>
  url_substring?: InputMaybe<Scalars['String']['input']>
}

export type ResourceInAms = {
  __typename?: 'ResourceInAms'
  ams?: Maybe<Ams>
  amsId: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  muted: Scalars['Boolean']['output']
  position: Scalars['Int']['output']
  repeat: Scalars['Boolean']['output']
  resource?: Maybe<Resource>
  resourceId: Scalars['ID']['output']
  volume: Scalars['Int']['output']
}

export type ResourceInAmsFilter = {
  AND?: InputMaybe<Array<ResourceInAmsFilter>>
  NOT?: InputMaybe<ResourceInAmsFilter>
  OR?: InputMaybe<Array<ResourceInAmsFilter>>
  amsId?: InputMaybe<Scalars['ID']['input']>
  amsId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  amsId_ne?: InputMaybe<Scalars['ID']['input']>
  amsId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  ams_none?: InputMaybe<AmsFilter>
  ams_some?: InputMaybe<AmsFilter>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  muted?: InputMaybe<Scalars['Boolean']['input']>
  muted_ne?: InputMaybe<Scalars['Boolean']['input']>
  position?: InputMaybe<Scalars['Int']['input']>
  position_gt?: InputMaybe<Scalars['Int']['input']>
  position_gte?: InputMaybe<Scalars['Int']['input']>
  position_in?: InputMaybe<Array<Scalars['Int']['input']>>
  position_lt?: InputMaybe<Scalars['Int']['input']>
  position_lte?: InputMaybe<Scalars['Int']['input']>
  position_ne?: InputMaybe<Scalars['Int']['input']>
  position_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  repeat?: InputMaybe<Scalars['Boolean']['input']>
  repeat_ne?: InputMaybe<Scalars['Boolean']['input']>
  resourceId?: InputMaybe<Scalars['ID']['input']>
  resourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceId_ne?: InputMaybe<Scalars['ID']['input']>
  resourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resource_none?: InputMaybe<ResourceFilter>
  resource_some?: InputMaybe<ResourceFilter>
  volume?: InputMaybe<Scalars['Int']['input']>
  volume_gt?: InputMaybe<Scalars['Int']['input']>
  volume_gte?: InputMaybe<Scalars['Int']['input']>
  volume_in?: InputMaybe<Array<Scalars['Int']['input']>>
  volume_lt?: InputMaybe<Scalars['Int']['input']>
  volume_lte?: InputMaybe<Scalars['Int']['input']>
  volume_ne?: InputMaybe<Scalars['Int']['input']>
  volume_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type ResourceInAmsOrderBy =
  | 'amsId_asc'
  | 'amsId_desc'
  | 'ams_active_asc'
  | 'ams_active_desc'
  | 'ams_createdAt_asc'
  | 'ams_createdAt_desc'
  | 'ams_createdById_asc'
  | 'ams_createdById_desc'
  | 'ams_id_asc'
  | 'ams_id_desc'
  | 'ams_repeat_asc'
  | 'ams_repeat_desc'
  | 'ams_sessionId_asc'
  | 'ams_sessionId_desc'
  | 'ams_sync_asc'
  | 'ams_sync_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'muted_asc'
  | 'muted_desc'
  | 'position_asc'
  | 'position_desc'
  | 'repeat_asc'
  | 'repeat_desc'
  | 'resourceId_asc'
  | 'resourceId_desc'
  | 'resource_createdAt_asc'
  | 'resource_createdAt_desc'
  | 'resource_createdById_asc'
  | 'resource_createdById_desc'
  | 'resource_duration_asc'
  | 'resource_duration_desc'
  | 'resource_ffprobe_asc'
  | 'resource_ffprobe_desc'
  | 'resource_fileSize_asc'
  | 'resource_fileSize_desc'
  | 'resource_id_asc'
  | 'resource_id_desc'
  | 'resource_name_asc'
  | 'resource_name_desc'
  | 'resource_orgId_asc'
  | 'resource_orgId_desc'
  | 'resource_originalResourceId_asc'
  | 'resource_originalResourceId_desc'
  | 'resource_originalResourceState_asc'
  | 'resource_originalResourceState_desc'
  | 'resource_sessionId_asc'
  | 'resource_sessionId_desc'
  | 'resource_thumbnailResourceId_asc'
  | 'resource_thumbnailResourceId_desc'
  | 'resource_type_asc'
  | 'resource_type_desc'
  | 'resource_url_asc'
  | 'resource_url_desc'
  | 'volume_asc'
  | 'volume_desc'

export type ResourceInInsightEngine = {
  __typename?: 'ResourceInInsightEngine'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  insightEngine?: Maybe<InsightEngine>
  insightEngineId: Scalars['ID']['output']
  isDownloading: Scalars['Boolean']['output']
  isFromBot: Scalars['Boolean']['output']
  note: Scalars['String']['output']
  resource?: Maybe<Resource>
  resourceId: Scalars['ID']['output']
  revAiJobId?: Maybe<Scalars['ID']['output']>
  status: Scalars['String']['output']
}

export type ResourceInInsightEngineFilter = {
  AND?: InputMaybe<Array<ResourceInInsightEngineFilter>>
  NOT?: InputMaybe<ResourceInInsightEngineFilter>
  OR?: InputMaybe<Array<ResourceInInsightEngineFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngineId?: InputMaybe<Scalars['ID']['input']>
  insightEngineId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngineId_ne?: InputMaybe<Scalars['ID']['input']>
  insightEngineId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngine_none?: InputMaybe<InsightEngineFilter>
  insightEngine_some?: InputMaybe<InsightEngineFilter>
  isDownloading?: InputMaybe<Scalars['Boolean']['input']>
  isDownloading_ne?: InputMaybe<Scalars['Boolean']['input']>
  isFromBot?: InputMaybe<Scalars['Boolean']['input']>
  isFromBot_ne?: InputMaybe<Scalars['Boolean']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  note_endsWith?: InputMaybe<Scalars['String']['input']>
  note_gt?: InputMaybe<Scalars['String']['input']>
  note_gte?: InputMaybe<Scalars['String']['input']>
  note_iLike?: InputMaybe<Scalars['String']['input']>
  note_in?: InputMaybe<Array<Scalars['String']['input']>>
  note_like?: InputMaybe<Scalars['String']['input']>
  note_lt?: InputMaybe<Scalars['String']['input']>
  note_lte?: InputMaybe<Scalars['String']['input']>
  note_ne?: InputMaybe<Scalars['String']['input']>
  note_notILike?: InputMaybe<Scalars['String']['input']>
  note_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  note_notLike?: InputMaybe<Scalars['String']['input']>
  note_startsWith?: InputMaybe<Scalars['String']['input']>
  note_substring?: InputMaybe<Scalars['String']['input']>
  resourceId?: InputMaybe<Scalars['ID']['input']>
  resourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceId_ne?: InputMaybe<Scalars['ID']['input']>
  resourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resource_none?: InputMaybe<ResourceFilter>
  resource_some?: InputMaybe<ResourceFilter>
  revAiJobId?: InputMaybe<Scalars['ID']['input']>
  revAiJobId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  revAiJobId_ne?: InputMaybe<Scalars['ID']['input']>
  revAiJobId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  status?: InputMaybe<Scalars['String']['input']>
  status_endsWith?: InputMaybe<Scalars['String']['input']>
  status_gt?: InputMaybe<Scalars['String']['input']>
  status_gte?: InputMaybe<Scalars['String']['input']>
  status_iLike?: InputMaybe<Scalars['String']['input']>
  status_in?: InputMaybe<Array<Scalars['String']['input']>>
  status_like?: InputMaybe<Scalars['String']['input']>
  status_lt?: InputMaybe<Scalars['String']['input']>
  status_lte?: InputMaybe<Scalars['String']['input']>
  status_ne?: InputMaybe<Scalars['String']['input']>
  status_notILike?: InputMaybe<Scalars['String']['input']>
  status_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  status_notLike?: InputMaybe<Scalars['String']['input']>
  status_startsWith?: InputMaybe<Scalars['String']['input']>
  status_substring?: InputMaybe<Scalars['String']['input']>
}

export type ResourceInInsightEngineOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'insightEngineId_asc'
  | 'insightEngineId_desc'
  | 'insightEngine_createdAt_asc'
  | 'insightEngine_createdAt_desc'
  | 'insightEngine_createdById_asc'
  | 'insightEngine_createdById_desc'
  | 'insightEngine_id_asc'
  | 'insightEngine_id_desc'
  | 'insightEngine_name_asc'
  | 'insightEngine_name_desc'
  | 'insightEngine_orgId_asc'
  | 'insightEngine_orgId_desc'
  | 'isDownloading_asc'
  | 'isDownloading_desc'
  | 'isFromBot_asc'
  | 'isFromBot_desc'
  | 'note_asc'
  | 'note_desc'
  | 'resourceId_asc'
  | 'resourceId_desc'
  | 'resource_createdAt_asc'
  | 'resource_createdAt_desc'
  | 'resource_createdById_asc'
  | 'resource_createdById_desc'
  | 'resource_duration_asc'
  | 'resource_duration_desc'
  | 'resource_ffprobe_asc'
  | 'resource_ffprobe_desc'
  | 'resource_fileSize_asc'
  | 'resource_fileSize_desc'
  | 'resource_id_asc'
  | 'resource_id_desc'
  | 'resource_name_asc'
  | 'resource_name_desc'
  | 'resource_orgId_asc'
  | 'resource_orgId_desc'
  | 'resource_originalResourceId_asc'
  | 'resource_originalResourceId_desc'
  | 'resource_originalResourceState_asc'
  | 'resource_originalResourceState_desc'
  | 'resource_sessionId_asc'
  | 'resource_sessionId_desc'
  | 'resource_thumbnailResourceId_asc'
  | 'resource_thumbnailResourceId_desc'
  | 'resource_type_asc'
  | 'resource_type_desc'
  | 'resource_url_asc'
  | 'resource_url_desc'
  | 'revAiJobId_asc'
  | 'revAiJobId_desc'
  | 'status_asc'
  | 'status_desc'

export type ResourceInSession = {
  __typename?: 'ResourceInSession'
  createdAt: Scalars['DateTime']['output']
  createdById?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  position: Scalars['Int']['output']
  resource?: Maybe<Resource>
  resourceId: Scalars['ID']['output']
  sessionId: Scalars['ID']['output']
  type: Scalars['String']['output']
}

export type ResourceInSessionFilter = {
  AND?: InputMaybe<Array<ResourceInSessionFilter>>
  NOT?: InputMaybe<ResourceInSessionFilter>
  OR?: InputMaybe<Array<ResourceInSessionFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdById?: InputMaybe<Scalars['ID']['input']>
  createdById_in?: InputMaybe<Array<Scalars['ID']['input']>>
  createdById_ne?: InputMaybe<Scalars['ID']['input']>
  createdById_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  position?: InputMaybe<Scalars['Int']['input']>
  position_gt?: InputMaybe<Scalars['Int']['input']>
  position_gte?: InputMaybe<Scalars['Int']['input']>
  position_in?: InputMaybe<Array<Scalars['Int']['input']>>
  position_lt?: InputMaybe<Scalars['Int']['input']>
  position_lte?: InputMaybe<Scalars['Int']['input']>
  position_ne?: InputMaybe<Scalars['Int']['input']>
  position_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  resourceId?: InputMaybe<Scalars['ID']['input']>
  resourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceId_ne?: InputMaybe<Scalars['ID']['input']>
  resourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resource_none?: InputMaybe<ResourceFilter>
  resource_some?: InputMaybe<ResourceFilter>
  sessionId?: InputMaybe<Scalars['ID']['input']>
  sessionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId_ne?: InputMaybe<Scalars['ID']['input']>
  sessionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
}

export type ResourceInSessionOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'createdById_asc'
  | 'createdById_desc'
  | 'id_asc'
  | 'id_desc'
  | 'position_asc'
  | 'position_desc'
  | 'resourceId_asc'
  | 'resourceId_desc'
  | 'resource_createdAt_asc'
  | 'resource_createdAt_desc'
  | 'resource_createdById_asc'
  | 'resource_createdById_desc'
  | 'resource_duration_asc'
  | 'resource_duration_desc'
  | 'resource_ffprobe_asc'
  | 'resource_ffprobe_desc'
  | 'resource_fileSize_asc'
  | 'resource_fileSize_desc'
  | 'resource_id_asc'
  | 'resource_id_desc'
  | 'resource_name_asc'
  | 'resource_name_desc'
  | 'resource_orgId_asc'
  | 'resource_orgId_desc'
  | 'resource_originalResourceId_asc'
  | 'resource_originalResourceId_desc'
  | 'resource_originalResourceState_asc'
  | 'resource_originalResourceState_desc'
  | 'resource_sessionId_asc'
  | 'resource_sessionId_desc'
  | 'resource_thumbnailResourceId_asc'
  | 'resource_thumbnailResourceId_desc'
  | 'resource_type_asc'
  | 'resource_type_desc'
  | 'resource_url_asc'
  | 'resource_url_desc'
  | 'sessionId_asc'
  | 'sessionId_desc'
  | 'type_asc'
  | 'type_desc'

export type ResourceOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'createdById_asc'
  | 'createdById_desc'
  | 'duration_asc'
  | 'duration_desc'
  | 'fileSize_asc'
  | 'fileSize_desc'
  | 'id_asc'
  | 'id_desc'
  | 'name_asc'
  | 'name_desc'
  | 'orgId_asc'
  | 'orgId_desc'
  | 'originalResourceId_asc'
  | 'originalResourceId_desc'
  | 'originalResource_createdAt_asc'
  | 'originalResource_createdAt_desc'
  | 'originalResource_createdById_asc'
  | 'originalResource_createdById_desc'
  | 'originalResource_duration_asc'
  | 'originalResource_duration_desc'
  | 'originalResource_ffprobe_asc'
  | 'originalResource_ffprobe_desc'
  | 'originalResource_fileSize_asc'
  | 'originalResource_fileSize_desc'
  | 'originalResource_id_asc'
  | 'originalResource_id_desc'
  | 'originalResource_name_asc'
  | 'originalResource_name_desc'
  | 'originalResource_orgId_asc'
  | 'originalResource_orgId_desc'
  | 'originalResource_originalResourceId_asc'
  | 'originalResource_originalResourceId_desc'
  | 'originalResource_originalResourceState_asc'
  | 'originalResource_originalResourceState_desc'
  | 'originalResource_sessionId_asc'
  | 'originalResource_sessionId_desc'
  | 'originalResource_thumbnailResourceId_asc'
  | 'originalResource_thumbnailResourceId_desc'
  | 'originalResource_type_asc'
  | 'originalResource_type_desc'
  | 'originalResource_url_asc'
  | 'originalResource_url_desc'
  | 'sessionId_asc'
  | 'sessionId_desc'
  | 'thumbnailResourceId_asc'
  | 'thumbnailResourceId_desc'
  | 'thumbnailResource_createdAt_asc'
  | 'thumbnailResource_createdAt_desc'
  | 'thumbnailResource_createdById_asc'
  | 'thumbnailResource_createdById_desc'
  | 'thumbnailResource_duration_asc'
  | 'thumbnailResource_duration_desc'
  | 'thumbnailResource_ffprobe_asc'
  | 'thumbnailResource_ffprobe_desc'
  | 'thumbnailResource_fileSize_asc'
  | 'thumbnailResource_fileSize_desc'
  | 'thumbnailResource_id_asc'
  | 'thumbnailResource_id_desc'
  | 'thumbnailResource_name_asc'
  | 'thumbnailResource_name_desc'
  | 'thumbnailResource_orgId_asc'
  | 'thumbnailResource_orgId_desc'
  | 'thumbnailResource_originalResourceId_asc'
  | 'thumbnailResource_originalResourceId_desc'
  | 'thumbnailResource_originalResourceState_asc'
  | 'thumbnailResource_originalResourceState_desc'
  | 'thumbnailResource_sessionId_asc'
  | 'thumbnailResource_sessionId_desc'
  | 'thumbnailResource_thumbnailResourceId_asc'
  | 'thumbnailResource_thumbnailResourceId_desc'
  | 'thumbnailResource_type_asc'
  | 'thumbnailResource_type_desc'
  | 'thumbnailResource_url_asc'
  | 'thumbnailResource_url_desc'
  | 'type_asc'
  | 'type_desc'
  | 'url_asc'
  | 'url_desc'

export type SearchReportFilter = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type Session = {
  __typename?: 'Session'
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<User>
  createdById: Scalars['ID']['output']
  description: Scalars['String']['output']
  endTime: Scalars['DateTime']['output']
  hasRecording: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  insightEngine?: Maybe<InsightEngine>
  insightEngineId: Scalars['ID']['output']
  layout?: Maybe<Scalars['Json']['output']>
  lbAmsId?: Maybe<Scalars['ID']['output']>
  lbMediasoupId?: Maybe<Scalars['ID']['output']>
  lbMixerId?: Maybe<Scalars['ID']['output']>
  org?: Maybe<Org>
  orgId: Scalars['ID']['output']
  settings?: Maybe<Scalars['Json']['output']>
  startTime: Scalars['DateTime']['output']
  title: Scalars['String']['output']
}

export type SessionFilter = {
  AND?: InputMaybe<Array<SessionFilter>>
  NOT?: InputMaybe<SessionFilter>
  OR?: InputMaybe<Array<SessionFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdById?: InputMaybe<Scalars['ID']['input']>
  createdById_in?: InputMaybe<Array<Scalars['ID']['input']>>
  createdById_ne?: InputMaybe<Scalars['ID']['input']>
  createdById_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  createdBy_none?: InputMaybe<UserFilter>
  createdBy_some?: InputMaybe<UserFilter>
  description?: InputMaybe<Scalars['String']['input']>
  description_endsWith?: InputMaybe<Scalars['String']['input']>
  description_gt?: InputMaybe<Scalars['String']['input']>
  description_gte?: InputMaybe<Scalars['String']['input']>
  description_iLike?: InputMaybe<Scalars['String']['input']>
  description_in?: InputMaybe<Array<Scalars['String']['input']>>
  description_like?: InputMaybe<Scalars['String']['input']>
  description_lt?: InputMaybe<Scalars['String']['input']>
  description_lte?: InputMaybe<Scalars['String']['input']>
  description_ne?: InputMaybe<Scalars['String']['input']>
  description_notILike?: InputMaybe<Scalars['String']['input']>
  description_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  description_notLike?: InputMaybe<Scalars['String']['input']>
  description_startsWith?: InputMaybe<Scalars['String']['input']>
  description_substring?: InputMaybe<Scalars['String']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  endTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  endTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  endTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  endTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  endTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  endTime_ne?: InputMaybe<Scalars['DateTime']['input']>
  endTime_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngineId?: InputMaybe<Scalars['ID']['input']>
  insightEngineId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngineId_ne?: InputMaybe<Scalars['ID']['input']>
  insightEngineId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngine_none?: InputMaybe<InsightEngineFilter>
  insightEngine_some?: InputMaybe<InsightEngineFilter>
  lbAmsId?: InputMaybe<Scalars['ID']['input']>
  lbAmsId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  lbAmsId_ne?: InputMaybe<Scalars['ID']['input']>
  lbAmsId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  lbMediasoupId?: InputMaybe<Scalars['ID']['input']>
  lbMediasoupId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  lbMediasoupId_ne?: InputMaybe<Scalars['ID']['input']>
  lbMediasoupId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  lbMixerId?: InputMaybe<Scalars['ID']['input']>
  lbMixerId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  lbMixerId_ne?: InputMaybe<Scalars['ID']['input']>
  lbMixerId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId?: InputMaybe<Scalars['ID']['input']>
  orgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId_ne?: InputMaybe<Scalars['ID']['input']>
  orgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  org_none?: InputMaybe<OrgFilter>
  org_some?: InputMaybe<OrgFilter>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  startTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  startTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  startTime_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  startTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  startTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  startTime_ne?: InputMaybe<Scalars['DateTime']['input']>
  startTime_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
  title_endsWith?: InputMaybe<Scalars['String']['input']>
  title_gt?: InputMaybe<Scalars['String']['input']>
  title_gte?: InputMaybe<Scalars['String']['input']>
  title_iLike?: InputMaybe<Scalars['String']['input']>
  title_in?: InputMaybe<Array<Scalars['String']['input']>>
  title_like?: InputMaybe<Scalars['String']['input']>
  title_lt?: InputMaybe<Scalars['String']['input']>
  title_lte?: InputMaybe<Scalars['String']['input']>
  title_ne?: InputMaybe<Scalars['String']['input']>
  title_notILike?: InputMaybe<Scalars['String']['input']>
  title_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  title_notLike?: InputMaybe<Scalars['String']['input']>
  title_startsWith?: InputMaybe<Scalars['String']['input']>
  title_substring?: InputMaybe<Scalars['String']['input']>
}

export type SessionOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'createdById_asc'
  | 'createdById_desc'
  | 'createdBy_avatarUrl_asc'
  | 'createdBy_avatarUrl_desc'
  | 'createdBy_blockedBySystemAdmin_asc'
  | 'createdBy_blockedBySystemAdmin_desc'
  | 'createdBy_createdAt_asc'
  | 'createdBy_createdAt_desc'
  | 'createdBy_currentOrgId_asc'
  | 'createdBy_currentOrgId_desc'
  | 'createdBy_email_asc'
  | 'createdBy_email_desc'
  | 'createdBy_hashedPassword_asc'
  | 'createdBy_hashedPassword_desc'
  | 'createdBy_id_asc'
  | 'createdBy_id_desc'
  | 'createdBy_name_asc'
  | 'createdBy_name_desc'
  | 'createdBy_referralById_asc'
  | 'createdBy_referralById_desc'
  | 'createdBy_rootOrgId_asc'
  | 'createdBy_rootOrgId_desc'
  | 'createdBy_socialLogin_asc'
  | 'createdBy_socialLogin_desc'
  | 'createdBy_stripeCustomerId_asc'
  | 'createdBy_stripeCustomerId_desc'
  | 'createdBy_studioSettings_asc'
  | 'createdBy_studioSettings_desc'
  | 'createdBy_themeWarmness_asc'
  | 'createdBy_themeWarmness_desc'
  | 'createdBy_theme_asc'
  | 'createdBy_theme_desc'
  | 'description_asc'
  | 'description_desc'
  | 'endTime_asc'
  | 'endTime_desc'
  | 'id_asc'
  | 'id_desc'
  | 'insightEngineId_asc'
  | 'insightEngineId_desc'
  | 'insightEngine_createdAt_asc'
  | 'insightEngine_createdAt_desc'
  | 'insightEngine_createdById_asc'
  | 'insightEngine_createdById_desc'
  | 'insightEngine_id_asc'
  | 'insightEngine_id_desc'
  | 'insightEngine_name_asc'
  | 'insightEngine_name_desc'
  | 'insightEngine_orgId_asc'
  | 'insightEngine_orgId_desc'
  | 'lbAmsId_asc'
  | 'lbAmsId_desc'
  | 'lbMediasoupId_asc'
  | 'lbMediasoupId_desc'
  | 'lbMixerId_asc'
  | 'lbMixerId_desc'
  | 'orgId_asc'
  | 'orgId_desc'
  | 'org_blockedBySystemAdmin_asc'
  | 'org_blockedBySystemAdmin_desc'
  | 'org_bonusMinutesUsed_asc'
  | 'org_bonusMinutesUsed_desc'
  | 'org_bonusMinutes_asc'
  | 'org_bonusMinutes_desc'
  | 'org_bonusStorage_asc'
  | 'org_bonusStorage_desc'
  | 'org_boughtMinutesUsed_asc'
  | 'org_boughtMinutesUsed_desc'
  | 'org_boughtMinutes_asc'
  | 'org_boughtMinutes_desc'
  | 'org_boughtStorage_asc'
  | 'org_boughtStorage_desc'
  | 'org_coverPhotoResourceId_asc'
  | 'org_coverPhotoResourceId_desc'
  | 'org_createdAt_asc'
  | 'org_createdAt_desc'
  | 'org_createdById_asc'
  | 'org_createdById_desc'
  | 'org_dnsValidationAt_asc'
  | 'org_dnsValidationAt_desc'
  | 'org_dnsValidation_asc'
  | 'org_dnsValidation_desc'
  | 'org_dns_asc'
  | 'org_dns_desc'
  | 'org_freeMinutesResetAt_asc'
  | 'org_freeMinutesResetAt_desc'
  | 'org_freeMinutesUsed_asc'
  | 'org_freeMinutesUsed_desc'
  | 'org_id_asc'
  | 'org_id_desc'
  | 'org_name_asc'
  | 'org_name_desc'
  | 'org_settings_asc'
  | 'org_settings_desc'
  | 'org_stripeSubscriptionId_asc'
  | 'org_stripeSubscriptionId_desc'
  | 'org_subscriptionPlan_asc'
  | 'org_subscriptionPlan_desc'
  | 'org_subscriptionStatus_asc'
  | 'org_subscriptionStatus_desc'
  | 'org_systemLogoResourceId_asc'
  | 'org_systemLogoResourceId_desc'
  | 'startTime_asc'
  | 'startTime_desc'
  | 'title_asc'
  | 'title_desc'

export type SessionPermission =
  | 'chat'
  | 'deleteInput'
  | 'deleteNote'
  | 'deleteOutput'
  | 'deleteResourceInSession'
  | 'deleteSession'
  | 'deleteUserInSession'
  | 'inviteUserInSession'
  | 'switchRole'
  | 'updateResourceInSession'
  | 'updateSession'
  | 'uploadResourceInSession'
  | 'upsertInput'
  | 'upsertNote'
  | 'upsertOutput'
  | 'viewInput'
  | 'viewNote'
  | 'viewOutput'
  | 'viewPlan'
  | 'viewResourceInSession'
  | 'viewSession'
  | 'viewUserInSession'
  | 'viewWaitingUser'

export type SessionRole = {
  __typename?: 'SessionRole'
  name: SessionRoleName
  permissions: Scalars['SessionPermissions']['output']
}

export type SessionRoleName = 'Host' | 'Observer' | 'Participant'

export type Subscription = {
  __typename?: 'Subscription'
  boughtStorage: Scalars['UFloat']['output']
  createdAt: Scalars['DateTime']['output']
  currency: PlanCurrency
  interval: PlanInterval
  mainPrice: Scalars['UFloat']['output']
  nextInvoiceAt?: Maybe<Scalars['DateTime']['output']>
  recurrence: Scalars['Boolean']['output']
  status: SubscriptionStatus
  totalPrice: Scalars['UFloat']['output']
  totalStoragePrice: Scalars['UFloat']['output']
}

export type SubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'paused'
  | 'trialing'
  | 'unpaid'

export type SystemFlag = {
  __typename?: 'SystemFlag'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type SystemFlagFilter = {
  AND?: InputMaybe<Array<SystemFlagFilter>>
  NOT?: InputMaybe<SystemFlagFilter>
  OR?: InputMaybe<Array<SystemFlagFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  key?: InputMaybe<Scalars['String']['input']>
  key_endsWith?: InputMaybe<Scalars['String']['input']>
  key_gt?: InputMaybe<Scalars['String']['input']>
  key_gte?: InputMaybe<Scalars['String']['input']>
  key_iLike?: InputMaybe<Scalars['String']['input']>
  key_in?: InputMaybe<Array<Scalars['String']['input']>>
  key_like?: InputMaybe<Scalars['String']['input']>
  key_lt?: InputMaybe<Scalars['String']['input']>
  key_lte?: InputMaybe<Scalars['String']['input']>
  key_ne?: InputMaybe<Scalars['String']['input']>
  key_notILike?: InputMaybe<Scalars['String']['input']>
  key_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  key_notLike?: InputMaybe<Scalars['String']['input']>
  key_startsWith?: InputMaybe<Scalars['String']['input']>
  key_substring?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
  value_endsWith?: InputMaybe<Scalars['String']['input']>
  value_gt?: InputMaybe<Scalars['String']['input']>
  value_gte?: InputMaybe<Scalars['String']['input']>
  value_iLike?: InputMaybe<Scalars['String']['input']>
  value_in?: InputMaybe<Array<Scalars['String']['input']>>
  value_like?: InputMaybe<Scalars['String']['input']>
  value_lt?: InputMaybe<Scalars['String']['input']>
  value_lte?: InputMaybe<Scalars['String']['input']>
  value_ne?: InputMaybe<Scalars['String']['input']>
  value_notILike?: InputMaybe<Scalars['String']['input']>
  value_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  value_notLike?: InputMaybe<Scalars['String']['input']>
  value_startsWith?: InputMaybe<Scalars['String']['input']>
  value_substring?: InputMaybe<Scalars['String']['input']>
}

export type SystemFlagOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'key_asc'
  | 'key_desc'
  | 'value_asc'
  | 'value_desc'

export type SystemPermission =
  | 'ams'
  | 'blockUser'
  | 'deleteUser'
  | 'updateAdmin'
  | 'updateChatbotContext'
  | 'updateLbInstances'
  | 'updateOrgPlan'
  | 'updateSystemFlag'
  | 'updateUser'
  | 'viewOrg'
  | 'viewUser'

export type SystemRole = {
  __typename?: 'SystemRole'
  name: Scalars['String']['output']
  permissions: Scalars['SystemPermissions']['output']
}

export type SystemUpdateUser = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>
  currentOrgId?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  hashedPassword?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  referralById?: InputMaybe<Scalars['ID']['input']>
  rootOrgId?: InputMaybe<Scalars['ID']['input']>
  socialLogin?: InputMaybe<Scalars['String']['input']>
  stripeCustomerId?: InputMaybe<Scalars['ID']['input']>
  studioSettings?: InputMaybe<Scalars['Json']['input']>
  theme?: InputMaybe<Scalars['String']['input']>
  themeWarmness?: InputMaybe<Scalars['Int']['input']>
}

export type Ticket = {
  __typename?: 'Ticket'
  createdAt: Scalars['DateTime']['output']
  data: Scalars['Json']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  invitationExpired: Scalars['Boolean']['output']
  org?: Maybe<Org>
  orgId?: Maybe<Scalars['ID']['output']>
  rejected: Scalars['Boolean']['output']
  resentAt: Scalars['DateTime']['output']
  sessionId?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
}

export type TicketFilter = {
  AND?: InputMaybe<Array<TicketFilter>>
  NOT?: InputMaybe<TicketFilter>
  OR?: InputMaybe<Array<TicketFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  email?: InputMaybe<Scalars['String']['input']>
  email_endsWith?: InputMaybe<Scalars['String']['input']>
  email_gt?: InputMaybe<Scalars['String']['input']>
  email_gte?: InputMaybe<Scalars['String']['input']>
  email_iLike?: InputMaybe<Scalars['String']['input']>
  email_in?: InputMaybe<Array<Scalars['String']['input']>>
  email_like?: InputMaybe<Scalars['String']['input']>
  email_lt?: InputMaybe<Scalars['String']['input']>
  email_lte?: InputMaybe<Scalars['String']['input']>
  email_ne?: InputMaybe<Scalars['String']['input']>
  email_notILike?: InputMaybe<Scalars['String']['input']>
  email_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  email_notLike?: InputMaybe<Scalars['String']['input']>
  email_startsWith?: InputMaybe<Scalars['String']['input']>
  email_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId?: InputMaybe<Scalars['ID']['input']>
  orgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId_ne?: InputMaybe<Scalars['ID']['input']>
  orgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  org_none?: InputMaybe<OrgFilter>
  org_some?: InputMaybe<OrgFilter>
  rejected?: InputMaybe<Scalars['Boolean']['input']>
  rejected_ne?: InputMaybe<Scalars['Boolean']['input']>
  resentAt?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  resentAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  resentAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  sessionId?: InputMaybe<Scalars['ID']['input']>
  sessionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId_ne?: InputMaybe<Scalars['ID']['input']>
  sessionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
}

export type TicketOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'email_asc'
  | 'email_desc'
  | 'id_asc'
  | 'id_desc'
  | 'orgId_asc'
  | 'orgId_desc'
  | 'org_blockedBySystemAdmin_asc'
  | 'org_blockedBySystemAdmin_desc'
  | 'org_bonusMinutesUsed_asc'
  | 'org_bonusMinutesUsed_desc'
  | 'org_bonusMinutes_asc'
  | 'org_bonusMinutes_desc'
  | 'org_bonusStorage_asc'
  | 'org_bonusStorage_desc'
  | 'org_boughtMinutesUsed_asc'
  | 'org_boughtMinutesUsed_desc'
  | 'org_boughtMinutes_asc'
  | 'org_boughtMinutes_desc'
  | 'org_boughtStorage_asc'
  | 'org_boughtStorage_desc'
  | 'org_coverPhotoResourceId_asc'
  | 'org_coverPhotoResourceId_desc'
  | 'org_createdAt_asc'
  | 'org_createdAt_desc'
  | 'org_createdById_asc'
  | 'org_createdById_desc'
  | 'org_dnsValidationAt_asc'
  | 'org_dnsValidationAt_desc'
  | 'org_dnsValidation_asc'
  | 'org_dnsValidation_desc'
  | 'org_dns_asc'
  | 'org_dns_desc'
  | 'org_freeMinutesResetAt_asc'
  | 'org_freeMinutesResetAt_desc'
  | 'org_freeMinutesUsed_asc'
  | 'org_freeMinutesUsed_desc'
  | 'org_id_asc'
  | 'org_id_desc'
  | 'org_name_asc'
  | 'org_name_desc'
  | 'org_settings_asc'
  | 'org_settings_desc'
  | 'org_stripeSubscriptionId_asc'
  | 'org_stripeSubscriptionId_desc'
  | 'org_subscriptionPlan_asc'
  | 'org_subscriptionPlan_desc'
  | 'org_subscriptionStatus_asc'
  | 'org_subscriptionStatus_desc'
  | 'org_systemLogoResourceId_asc'
  | 'org_systemLogoResourceId_desc'
  | 'rejected_asc'
  | 'rejected_desc'
  | 'resentAt_asc'
  | 'resentAt_desc'
  | 'sessionId_asc'
  | 'sessionId_desc'
  | 'type_asc'
  | 'type_desc'

export type Transaction = {
  __typename?: 'Transaction'
  createdAt: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  orgId: Scalars['ID']['output']
  status: Scalars['String']['output']
  stripeInvoiceData?: Maybe<Scalars['Json']['output']>
  stripeInvoiceId?: Maybe<Scalars['ID']['output']>
  total: Scalars['String']['output']
}

export type TransactionFilter = {
  AND?: InputMaybe<Array<TransactionFilter>>
  NOT?: InputMaybe<TransactionFilter>
  OR?: InputMaybe<Array<TransactionFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  description_endsWith?: InputMaybe<Scalars['String']['input']>
  description_gt?: InputMaybe<Scalars['String']['input']>
  description_gte?: InputMaybe<Scalars['String']['input']>
  description_iLike?: InputMaybe<Scalars['String']['input']>
  description_in?: InputMaybe<Array<Scalars['String']['input']>>
  description_like?: InputMaybe<Scalars['String']['input']>
  description_lt?: InputMaybe<Scalars['String']['input']>
  description_lte?: InputMaybe<Scalars['String']['input']>
  description_ne?: InputMaybe<Scalars['String']['input']>
  description_notILike?: InputMaybe<Scalars['String']['input']>
  description_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  description_notLike?: InputMaybe<Scalars['String']['input']>
  description_startsWith?: InputMaybe<Scalars['String']['input']>
  description_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId?: InputMaybe<Scalars['ID']['input']>
  orgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId_ne?: InputMaybe<Scalars['ID']['input']>
  orgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  status?: InputMaybe<Scalars['String']['input']>
  status_endsWith?: InputMaybe<Scalars['String']['input']>
  status_gt?: InputMaybe<Scalars['String']['input']>
  status_gte?: InputMaybe<Scalars['String']['input']>
  status_iLike?: InputMaybe<Scalars['String']['input']>
  status_in?: InputMaybe<Array<Scalars['String']['input']>>
  status_like?: InputMaybe<Scalars['String']['input']>
  status_lt?: InputMaybe<Scalars['String']['input']>
  status_lte?: InputMaybe<Scalars['String']['input']>
  status_ne?: InputMaybe<Scalars['String']['input']>
  status_notILike?: InputMaybe<Scalars['String']['input']>
  status_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  status_notLike?: InputMaybe<Scalars['String']['input']>
  status_startsWith?: InputMaybe<Scalars['String']['input']>
  status_substring?: InputMaybe<Scalars['String']['input']>
  stripeInvoiceId?: InputMaybe<Scalars['ID']['input']>
  stripeInvoiceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  stripeInvoiceId_ne?: InputMaybe<Scalars['ID']['input']>
  stripeInvoiceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  total?: InputMaybe<Scalars['String']['input']>
  total_endsWith?: InputMaybe<Scalars['String']['input']>
  total_gt?: InputMaybe<Scalars['String']['input']>
  total_gte?: InputMaybe<Scalars['String']['input']>
  total_iLike?: InputMaybe<Scalars['String']['input']>
  total_in?: InputMaybe<Array<Scalars['String']['input']>>
  total_like?: InputMaybe<Scalars['String']['input']>
  total_lt?: InputMaybe<Scalars['String']['input']>
  total_lte?: InputMaybe<Scalars['String']['input']>
  total_ne?: InputMaybe<Scalars['String']['input']>
  total_notILike?: InputMaybe<Scalars['String']['input']>
  total_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  total_notLike?: InputMaybe<Scalars['String']['input']>
  total_startsWith?: InputMaybe<Scalars['String']['input']>
  total_substring?: InputMaybe<Scalars['String']['input']>
}

export type TransactionOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'description_asc'
  | 'description_desc'
  | 'id_asc'
  | 'id_desc'
  | 'orgId_asc'
  | 'orgId_desc'
  | 'status_asc'
  | 'status_desc'
  | 'stripeInvoiceId_asc'
  | 'stripeInvoiceId_desc'
  | 'total_asc'
  | 'total_desc'

export type Transcript = {
  __typename?: 'Transcript'
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  endTime: Scalars['Float']['output']
  id: Scalars['ID']['output']
  nameFromRevAi: Scalars['String']['output']
  resourceInInsightEngine?: Maybe<ResourceInInsightEngine>
  resourceInInsightEngineId: Scalars['ID']['output']
  speaker?: Maybe<User>
  speakerId?: Maybe<Scalars['ID']['output']>
  startTime: Scalars['Float']['output']
}

export type TranscriptFilter = {
  AND?: InputMaybe<Array<TranscriptFilter>>
  NOT?: InputMaybe<TranscriptFilter>
  OR?: InputMaybe<Array<TranscriptFilter>>
  content?: InputMaybe<Scalars['String']['input']>
  content_endsWith?: InputMaybe<Scalars['String']['input']>
  content_gt?: InputMaybe<Scalars['String']['input']>
  content_gte?: InputMaybe<Scalars['String']['input']>
  content_iLike?: InputMaybe<Scalars['String']['input']>
  content_in?: InputMaybe<Array<Scalars['String']['input']>>
  content_like?: InputMaybe<Scalars['String']['input']>
  content_lt?: InputMaybe<Scalars['String']['input']>
  content_lte?: InputMaybe<Scalars['String']['input']>
  content_ne?: InputMaybe<Scalars['String']['input']>
  content_notILike?: InputMaybe<Scalars['String']['input']>
  content_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  content_notLike?: InputMaybe<Scalars['String']['input']>
  content_startsWith?: InputMaybe<Scalars['String']['input']>
  content_substring?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  endTime?: InputMaybe<Scalars['Float']['input']>
  endTime_gt?: InputMaybe<Scalars['Float']['input']>
  endTime_gte?: InputMaybe<Scalars['Float']['input']>
  endTime_in?: InputMaybe<Array<Scalars['Float']['input']>>
  endTime_lt?: InputMaybe<Scalars['Float']['input']>
  endTime_lte?: InputMaybe<Scalars['Float']['input']>
  endTime_ne?: InputMaybe<Scalars['Float']['input']>
  endTime_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  nameFromRevAi?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_endsWith?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_gt?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_gte?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_iLike?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_in?: InputMaybe<Array<Scalars['String']['input']>>
  nameFromRevAi_like?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_lt?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_lte?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_ne?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_notILike?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  nameFromRevAi_notLike?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_startsWith?: InputMaybe<Scalars['String']['input']>
  nameFromRevAi_substring?: InputMaybe<Scalars['String']['input']>
  resourceInInsightEngineId?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngineId_ne?: InputMaybe<Scalars['ID']['input']>
  resourceInInsightEngineId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceInInsightEngine_none?: InputMaybe<ResourceInInsightEngineFilter>
  resourceInInsightEngine_some?: InputMaybe<ResourceInInsightEngineFilter>
  speakerId?: InputMaybe<Scalars['ID']['input']>
  speakerId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  speakerId_ne?: InputMaybe<Scalars['ID']['input']>
  speakerId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  speaker_none?: InputMaybe<UserFilter>
  speaker_some?: InputMaybe<UserFilter>
  startTime?: InputMaybe<Scalars['Float']['input']>
  startTime_gt?: InputMaybe<Scalars['Float']['input']>
  startTime_gte?: InputMaybe<Scalars['Float']['input']>
  startTime_in?: InputMaybe<Array<Scalars['Float']['input']>>
  startTime_lt?: InputMaybe<Scalars['Float']['input']>
  startTime_lte?: InputMaybe<Scalars['Float']['input']>
  startTime_ne?: InputMaybe<Scalars['Float']['input']>
  startTime_notIn?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type TranscriptOrderBy =
  | 'content_asc'
  | 'content_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'endTime_asc'
  | 'endTime_desc'
  | 'id_asc'
  | 'id_desc'
  | 'nameFromRevAi_asc'
  | 'nameFromRevAi_desc'
  | 'resourceInInsightEngineId_asc'
  | 'resourceInInsightEngineId_desc'
  | 'resourceInInsightEngine_createdAt_asc'
  | 'resourceInInsightEngine_createdAt_desc'
  | 'resourceInInsightEngine_id_asc'
  | 'resourceInInsightEngine_id_desc'
  | 'resourceInInsightEngine_insightEngineId_asc'
  | 'resourceInInsightEngine_insightEngineId_desc'
  | 'resourceInInsightEngine_isDownloading_asc'
  | 'resourceInInsightEngine_isDownloading_desc'
  | 'resourceInInsightEngine_isFromBot_asc'
  | 'resourceInInsightEngine_isFromBot_desc'
  | 'resourceInInsightEngine_note_asc'
  | 'resourceInInsightEngine_note_desc'
  | 'resourceInInsightEngine_resourceId_asc'
  | 'resourceInInsightEngine_resourceId_desc'
  | 'resourceInInsightEngine_revAiJobId_asc'
  | 'resourceInInsightEngine_revAiJobId_desc'
  | 'resourceInInsightEngine_status_asc'
  | 'resourceInInsightEngine_status_desc'
  | 'speakerId_asc'
  | 'speakerId_desc'
  | 'speaker_avatarUrl_asc'
  | 'speaker_avatarUrl_desc'
  | 'speaker_blockedBySystemAdmin_asc'
  | 'speaker_blockedBySystemAdmin_desc'
  | 'speaker_createdAt_asc'
  | 'speaker_createdAt_desc'
  | 'speaker_currentOrgId_asc'
  | 'speaker_currentOrgId_desc'
  | 'speaker_email_asc'
  | 'speaker_email_desc'
  | 'speaker_hashedPassword_asc'
  | 'speaker_hashedPassword_desc'
  | 'speaker_id_asc'
  | 'speaker_id_desc'
  | 'speaker_name_asc'
  | 'speaker_name_desc'
  | 'speaker_referralById_asc'
  | 'speaker_referralById_desc'
  | 'speaker_rootOrgId_asc'
  | 'speaker_rootOrgId_desc'
  | 'speaker_socialLogin_asc'
  | 'speaker_socialLogin_desc'
  | 'speaker_stripeCustomerId_asc'
  | 'speaker_stripeCustomerId_desc'
  | 'speaker_studioSettings_asc'
  | 'speaker_studioSettings_desc'
  | 'speaker_themeWarmness_asc'
  | 'speaker_themeWarmness_desc'
  | 'speaker_theme_asc'
  | 'speaker_theme_desc'
  | 'startTime_asc'
  | 'startTime_desc'

export type UpdateAms = {
  repeat?: InputMaybe<Scalars['Boolean']['input']>
  sync?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateBlurBlock = {
  blockHeight?: InputMaybe<Scalars['Float']['input']>
  blockWidth?: InputMaybe<Scalars['Float']['input']>
  borderRadius?: InputMaybe<Scalars['Float']['input']>
  endTime?: InputMaybe<Scalars['Float']['input']>
  left?: InputMaybe<Scalars['Float']['input']>
  startTime?: InputMaybe<Scalars['Float']['input']>
  top?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateChatbotContext = {
  maxMessageHistory?: InputMaybe<Scalars['Int']['input']>
  maxTokens?: InputMaybe<Scalars['Int']['input']>
  model?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  system?: InputMaybe<Scalars['String']['input']>
  temperature?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateInsightEngine = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateMute = {
  endTime?: InputMaybe<Scalars['Float']['input']>
  startTime?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateMyProfile = {
  currentOrgId?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  studioSettings?: InputMaybe<Scalars['Json']['input']>
  theme?: InputMaybe<Scalars['String']['input']>
  themeWarmness?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateNote = {
  content?: InputMaybe<Scalars['String']['input']>
}

export type UpdateOrgDns = {
  dns?: InputMaybe<Scalars['String']['input']>
}

export type UpdateOrgSettings = {
  settings?: InputMaybe<Scalars['Json']['input']>
}

export type UpdateOrgSystemLogo = {
  originalSystemLogoResourceId?: InputMaybe<Scalars['ID']['input']>
  originalSystemLogoResourceState?: InputMaybe<Scalars['Json']['input']>
  systemLogoResourceId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateOriginalResource = {
  originalResourceId?: InputMaybe<Scalars['ID']['input']>
  originalResourceState?: InputMaybe<Scalars['Json']['input']>
  sessionId: Scalars['ID']['input']
}

export type UpdateOutputInSession = {
  description?: InputMaybe<Scalars['String']['input']>
  thumbnailId?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateResourceInAms = {
  muted?: InputMaybe<Scalars['Boolean']['input']>
  position?: InputMaybe<Scalars['Int']['input']>
  repeat?: InputMaybe<Scalars['Boolean']['input']>
  resourceId?: InputMaybe<Scalars['ID']['input']>
  volume?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateResourceInInsightEngine = {
  note?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSession = {
  description?: InputMaybe<Scalars['String']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  insightEngineId?: InputMaybe<Scalars['ID']['input']>
  layout?: InputMaybe<Scalars['Json']['input']>
  lbAmsId?: InputMaybe<Scalars['ID']['input']>
  lbMediasoupId?: InputMaybe<Scalars['ID']['input']>
  lbMixerId?: InputMaybe<Scalars['ID']['input']>
  observers?: InputMaybe<Array<Scalars['Email']['input']>>
  participants?: InputMaybe<Array<Scalars['Email']['input']>>
  settings?: InputMaybe<Scalars['Json']['input']>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSystemFlag = {
  key: Scalars['NeString']['input']
  value: Scalars['NeString']['input']
}

export type UpdateTranscript = {
  content?: InputMaybe<Scalars['String']['input']>
}

export type UpgradePlan = {
  currency: PlanCurrency
  interval: PlanInterval
  orgId: Scalars['ID']['input']
  plan: PlanName
}

export type User = {
  __typename?: 'User'
  avatarUrl: Scalars['String']['output']
  blockedBySystemAdmin: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  currentOrg?: Maybe<Org>
  currentOrgId: Scalars['ID']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  orgs: Array<Org>
  orgsCount: Scalars['Int']['output']
  referralById?: Maybe<Scalars['ID']['output']>
  rootOrg?: Maybe<Org>
  rootOrgId: Scalars['ID']['output']
  socialLogin: Scalars['String']['output']
  stripeCustomerId?: Maybe<Scalars['ID']['output']>
  studioSettings?: Maybe<Scalars['Json']['output']>
  theme: Scalars['String']['output']
  themeWarmness: Scalars['Int']['output']
  userInSystem?: Maybe<UserInSystem>
}

export type UserOrgsArgs = {
  filter?: InputMaybe<OrgFilter>
  order?: InputMaybe<Array<OrgOrderBy>>
  page?: InputMaybe<Pagination>
}

export type UserOrgsCountArgs = {
  filter?: InputMaybe<OrgFilter>
}

export type UserFilter = {
  AND?: InputMaybe<Array<UserFilter>>
  NOT?: InputMaybe<UserFilter>
  OR?: InputMaybe<Array<UserFilter>>
  avatarUrl?: InputMaybe<Scalars['String']['input']>
  avatarUrl_endsWith?: InputMaybe<Scalars['String']['input']>
  avatarUrl_gt?: InputMaybe<Scalars['String']['input']>
  avatarUrl_gte?: InputMaybe<Scalars['String']['input']>
  avatarUrl_iLike?: InputMaybe<Scalars['String']['input']>
  avatarUrl_in?: InputMaybe<Array<Scalars['String']['input']>>
  avatarUrl_like?: InputMaybe<Scalars['String']['input']>
  avatarUrl_lt?: InputMaybe<Scalars['String']['input']>
  avatarUrl_lte?: InputMaybe<Scalars['String']['input']>
  avatarUrl_ne?: InputMaybe<Scalars['String']['input']>
  avatarUrl_notILike?: InputMaybe<Scalars['String']['input']>
  avatarUrl_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  avatarUrl_notLike?: InputMaybe<Scalars['String']['input']>
  avatarUrl_startsWith?: InputMaybe<Scalars['String']['input']>
  avatarUrl_substring?: InputMaybe<Scalars['String']['input']>
  blockedBySystemAdmin?: InputMaybe<Scalars['Boolean']['input']>
  blockedBySystemAdmin_ne?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  currentOrgId?: InputMaybe<Scalars['ID']['input']>
  currentOrgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  currentOrgId_ne?: InputMaybe<Scalars['ID']['input']>
  currentOrgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  currentOrg_none?: InputMaybe<OrgFilter>
  currentOrg_some?: InputMaybe<OrgFilter>
  email?: InputMaybe<Scalars['String']['input']>
  email_endsWith?: InputMaybe<Scalars['String']['input']>
  email_gt?: InputMaybe<Scalars['String']['input']>
  email_gte?: InputMaybe<Scalars['String']['input']>
  email_iLike?: InputMaybe<Scalars['String']['input']>
  email_in?: InputMaybe<Array<Scalars['String']['input']>>
  email_like?: InputMaybe<Scalars['String']['input']>
  email_lt?: InputMaybe<Scalars['String']['input']>
  email_lte?: InputMaybe<Scalars['String']['input']>
  email_ne?: InputMaybe<Scalars['String']['input']>
  email_notILike?: InputMaybe<Scalars['String']['input']>
  email_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  email_notLike?: InputMaybe<Scalars['String']['input']>
  email_startsWith?: InputMaybe<Scalars['String']['input']>
  email_substring?: InputMaybe<Scalars['String']['input']>
  hashedPassword?: InputMaybe<Scalars['String']['input']>
  hashedPassword_endsWith?: InputMaybe<Scalars['String']['input']>
  hashedPassword_gt?: InputMaybe<Scalars['String']['input']>
  hashedPassword_gte?: InputMaybe<Scalars['String']['input']>
  hashedPassword_iLike?: InputMaybe<Scalars['String']['input']>
  hashedPassword_in?: InputMaybe<Array<Scalars['String']['input']>>
  hashedPassword_like?: InputMaybe<Scalars['String']['input']>
  hashedPassword_lt?: InputMaybe<Scalars['String']['input']>
  hashedPassword_lte?: InputMaybe<Scalars['String']['input']>
  hashedPassword_ne?: InputMaybe<Scalars['String']['input']>
  hashedPassword_notILike?: InputMaybe<Scalars['String']['input']>
  hashedPassword_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  hashedPassword_notLike?: InputMaybe<Scalars['String']['input']>
  hashedPassword_startsWith?: InputMaybe<Scalars['String']['input']>
  hashedPassword_substring?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  name_endsWith?: InputMaybe<Scalars['String']['input']>
  name_gt?: InputMaybe<Scalars['String']['input']>
  name_gte?: InputMaybe<Scalars['String']['input']>
  name_iLike?: InputMaybe<Scalars['String']['input']>
  name_in?: InputMaybe<Array<Scalars['String']['input']>>
  name_like?: InputMaybe<Scalars['String']['input']>
  name_lt?: InputMaybe<Scalars['String']['input']>
  name_lte?: InputMaybe<Scalars['String']['input']>
  name_ne?: InputMaybe<Scalars['String']['input']>
  name_notILike?: InputMaybe<Scalars['String']['input']>
  name_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  name_notLike?: InputMaybe<Scalars['String']['input']>
  name_startsWith?: InputMaybe<Scalars['String']['input']>
  name_substring?: InputMaybe<Scalars['String']['input']>
  orgs_none?: InputMaybe<OrgFilter>
  orgs_some?: InputMaybe<OrgFilter>
  referralById?: InputMaybe<Scalars['ID']['input']>
  referralById_in?: InputMaybe<Array<Scalars['ID']['input']>>
  referralById_ne?: InputMaybe<Scalars['ID']['input']>
  referralById_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  rootOrgId?: InputMaybe<Scalars['ID']['input']>
  rootOrgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  rootOrgId_ne?: InputMaybe<Scalars['ID']['input']>
  rootOrgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  rootOrg_none?: InputMaybe<OrgFilter>
  rootOrg_some?: InputMaybe<OrgFilter>
  socialLogin?: InputMaybe<Scalars['String']['input']>
  socialLogin_endsWith?: InputMaybe<Scalars['String']['input']>
  socialLogin_gt?: InputMaybe<Scalars['String']['input']>
  socialLogin_gte?: InputMaybe<Scalars['String']['input']>
  socialLogin_iLike?: InputMaybe<Scalars['String']['input']>
  socialLogin_in?: InputMaybe<Array<Scalars['String']['input']>>
  socialLogin_like?: InputMaybe<Scalars['String']['input']>
  socialLogin_lt?: InputMaybe<Scalars['String']['input']>
  socialLogin_lte?: InputMaybe<Scalars['String']['input']>
  socialLogin_ne?: InputMaybe<Scalars['String']['input']>
  socialLogin_notILike?: InputMaybe<Scalars['String']['input']>
  socialLogin_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  socialLogin_notLike?: InputMaybe<Scalars['String']['input']>
  socialLogin_startsWith?: InputMaybe<Scalars['String']['input']>
  socialLogin_substring?: InputMaybe<Scalars['String']['input']>
  stripeCustomerId?: InputMaybe<Scalars['ID']['input']>
  stripeCustomerId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  stripeCustomerId_ne?: InputMaybe<Scalars['ID']['input']>
  stripeCustomerId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  theme?: InputMaybe<Scalars['String']['input']>
  themeWarmness?: InputMaybe<Scalars['Int']['input']>
  themeWarmness_gt?: InputMaybe<Scalars['Int']['input']>
  themeWarmness_gte?: InputMaybe<Scalars['Int']['input']>
  themeWarmness_in?: InputMaybe<Array<Scalars['Int']['input']>>
  themeWarmness_lt?: InputMaybe<Scalars['Int']['input']>
  themeWarmness_lte?: InputMaybe<Scalars['Int']['input']>
  themeWarmness_ne?: InputMaybe<Scalars['Int']['input']>
  themeWarmness_notIn?: InputMaybe<Array<Scalars['Int']['input']>>
  theme_endsWith?: InputMaybe<Scalars['String']['input']>
  theme_gt?: InputMaybe<Scalars['String']['input']>
  theme_gte?: InputMaybe<Scalars['String']['input']>
  theme_iLike?: InputMaybe<Scalars['String']['input']>
  theme_in?: InputMaybe<Array<Scalars['String']['input']>>
  theme_like?: InputMaybe<Scalars['String']['input']>
  theme_lt?: InputMaybe<Scalars['String']['input']>
  theme_lte?: InputMaybe<Scalars['String']['input']>
  theme_ne?: InputMaybe<Scalars['String']['input']>
  theme_notILike?: InputMaybe<Scalars['String']['input']>
  theme_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  theme_notLike?: InputMaybe<Scalars['String']['input']>
  theme_startsWith?: InputMaybe<Scalars['String']['input']>
  theme_substring?: InputMaybe<Scalars['String']['input']>
  userInSystem_none?: InputMaybe<UserInSystemFilter>
  userInSystem_some?: InputMaybe<UserInSystemFilter>
}

export type UserInInsightEngine = {
  __typename?: 'UserInInsightEngine'
  canEdit: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  insightEngine?: Maybe<InsightEngine>
  insightEngineId: Scalars['ID']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type UserInInsightEngineFilter = {
  AND?: InputMaybe<Array<UserInInsightEngineFilter>>
  NOT?: InputMaybe<UserInInsightEngineFilter>
  OR?: InputMaybe<Array<UserInInsightEngineFilter>>
  canEdit?: InputMaybe<Scalars['Boolean']['input']>
  canEdit_ne?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngineId?: InputMaybe<Scalars['ID']['input']>
  insightEngineId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngineId_ne?: InputMaybe<Scalars['ID']['input']>
  insightEngineId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  insightEngine_none?: InputMaybe<InsightEngineFilter>
  insightEngine_some?: InputMaybe<InsightEngineFilter>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
}

export type UserInOrg = {
  __typename?: 'UserInOrg'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  org?: Maybe<Org>
  orgId: Scalars['ID']['output']
  role: Scalars['String']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type UserInOrgFilter = {
  AND?: InputMaybe<Array<UserInOrgFilter>>
  NOT?: InputMaybe<UserInOrgFilter>
  OR?: InputMaybe<Array<UserInOrgFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId?: InputMaybe<Scalars['ID']['input']>
  orgId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  orgId_ne?: InputMaybe<Scalars['ID']['input']>
  orgId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  org_none?: InputMaybe<OrgFilter>
  org_some?: InputMaybe<OrgFilter>
  role?: InputMaybe<Scalars['String']['input']>
  role_endsWith?: InputMaybe<Scalars['String']['input']>
  role_gt?: InputMaybe<Scalars['String']['input']>
  role_gte?: InputMaybe<Scalars['String']['input']>
  role_iLike?: InputMaybe<Scalars['String']['input']>
  role_in?: InputMaybe<Array<Scalars['String']['input']>>
  role_like?: InputMaybe<Scalars['String']['input']>
  role_lt?: InputMaybe<Scalars['String']['input']>
  role_lte?: InputMaybe<Scalars['String']['input']>
  role_ne?: InputMaybe<Scalars['String']['input']>
  role_notILike?: InputMaybe<Scalars['String']['input']>
  role_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  role_notLike?: InputMaybe<Scalars['String']['input']>
  role_startsWith?: InputMaybe<Scalars['String']['input']>
  role_substring?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
}

export type UserInOrgOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'orgId_asc'
  | 'orgId_desc'
  | 'org_blockedBySystemAdmin_asc'
  | 'org_blockedBySystemAdmin_desc'
  | 'org_bonusMinutesUsed_asc'
  | 'org_bonusMinutesUsed_desc'
  | 'org_bonusMinutes_asc'
  | 'org_bonusMinutes_desc'
  | 'org_bonusStorage_asc'
  | 'org_bonusStorage_desc'
  | 'org_boughtMinutesUsed_asc'
  | 'org_boughtMinutesUsed_desc'
  | 'org_boughtMinutes_asc'
  | 'org_boughtMinutes_desc'
  | 'org_boughtStorage_asc'
  | 'org_boughtStorage_desc'
  | 'org_coverPhotoResourceId_asc'
  | 'org_coverPhotoResourceId_desc'
  | 'org_createdAt_asc'
  | 'org_createdAt_desc'
  | 'org_createdById_asc'
  | 'org_createdById_desc'
  | 'org_dnsValidationAt_asc'
  | 'org_dnsValidationAt_desc'
  | 'org_dnsValidation_asc'
  | 'org_dnsValidation_desc'
  | 'org_dns_asc'
  | 'org_dns_desc'
  | 'org_freeMinutesResetAt_asc'
  | 'org_freeMinutesResetAt_desc'
  | 'org_freeMinutesUsed_asc'
  | 'org_freeMinutesUsed_desc'
  | 'org_id_asc'
  | 'org_id_desc'
  | 'org_name_asc'
  | 'org_name_desc'
  | 'org_settings_asc'
  | 'org_settings_desc'
  | 'org_stripeSubscriptionId_asc'
  | 'org_stripeSubscriptionId_desc'
  | 'org_subscriptionPlan_asc'
  | 'org_subscriptionPlan_desc'
  | 'org_subscriptionStatus_asc'
  | 'org_subscriptionStatus_desc'
  | 'org_systemLogoResourceId_asc'
  | 'org_systemLogoResourceId_desc'
  | 'role_asc'
  | 'role_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'user_avatarUrl_asc'
  | 'user_avatarUrl_desc'
  | 'user_blockedBySystemAdmin_asc'
  | 'user_blockedBySystemAdmin_desc'
  | 'user_createdAt_asc'
  | 'user_createdAt_desc'
  | 'user_currentOrgId_asc'
  | 'user_currentOrgId_desc'
  | 'user_email_asc'
  | 'user_email_desc'
  | 'user_hashedPassword_asc'
  | 'user_hashedPassword_desc'
  | 'user_id_asc'
  | 'user_id_desc'
  | 'user_name_asc'
  | 'user_name_desc'
  | 'user_referralById_asc'
  | 'user_referralById_desc'
  | 'user_rootOrgId_asc'
  | 'user_rootOrgId_desc'
  | 'user_socialLogin_asc'
  | 'user_socialLogin_desc'
  | 'user_stripeCustomerId_asc'
  | 'user_stripeCustomerId_desc'
  | 'user_studioSettings_asc'
  | 'user_studioSettings_desc'
  | 'user_themeWarmness_asc'
  | 'user_themeWarmness_desc'
  | 'user_theme_asc'
  | 'user_theme_desc'

export type UserInResource = {
  __typename?: 'UserInResource'
  createdAt: Scalars['DateTime']['output']
  filterResource?: Maybe<Resource>
  filterResourceId?: Maybe<Scalars['ID']['output']>
  filterState?: Maybe<Scalars['Json']['output']>
  id: Scalars['ID']['output']
  isDownloading: Scalars['Boolean']['output']
  lbId?: Maybe<Scalars['ID']['output']>
  resource?: Maybe<Resource>
  resourceId: Scalars['ID']['output']
  type: Scalars['String']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type UserInResourceFilter = {
  AND?: InputMaybe<Array<UserInResourceFilter>>
  NOT?: InputMaybe<UserInResourceFilter>
  OR?: InputMaybe<Array<UserInResourceFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  filterResourceId?: InputMaybe<Scalars['ID']['input']>
  filterResourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  filterResourceId_ne?: InputMaybe<Scalars['ID']['input']>
  filterResourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  filterResource_none?: InputMaybe<ResourceFilter>
  filterResource_some?: InputMaybe<ResourceFilter>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  isDownloading?: InputMaybe<Scalars['Boolean']['input']>
  isDownloading_ne?: InputMaybe<Scalars['Boolean']['input']>
  lbId?: InputMaybe<Scalars['ID']['input']>
  lbId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  lbId_ne?: InputMaybe<Scalars['ID']['input']>
  lbId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceId?: InputMaybe<Scalars['ID']['input']>
  resourceId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  resourceId_ne?: InputMaybe<Scalars['ID']['input']>
  resourceId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  resource_none?: InputMaybe<ResourceFilter>
  resource_some?: InputMaybe<ResourceFilter>
  type?: InputMaybe<Scalars['String']['input']>
  type_endsWith?: InputMaybe<Scalars['String']['input']>
  type_gt?: InputMaybe<Scalars['String']['input']>
  type_gte?: InputMaybe<Scalars['String']['input']>
  type_iLike?: InputMaybe<Scalars['String']['input']>
  type_in?: InputMaybe<Array<Scalars['String']['input']>>
  type_like?: InputMaybe<Scalars['String']['input']>
  type_lt?: InputMaybe<Scalars['String']['input']>
  type_lte?: InputMaybe<Scalars['String']['input']>
  type_ne?: InputMaybe<Scalars['String']['input']>
  type_notILike?: InputMaybe<Scalars['String']['input']>
  type_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  type_notLike?: InputMaybe<Scalars['String']['input']>
  type_startsWith?: InputMaybe<Scalars['String']['input']>
  type_substring?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
}

export type UserInResourceOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'filterResourceId_asc'
  | 'filterResourceId_desc'
  | 'filterResource_createdAt_asc'
  | 'filterResource_createdAt_desc'
  | 'filterResource_createdById_asc'
  | 'filterResource_createdById_desc'
  | 'filterResource_duration_asc'
  | 'filterResource_duration_desc'
  | 'filterResource_ffprobe_asc'
  | 'filterResource_ffprobe_desc'
  | 'filterResource_fileSize_asc'
  | 'filterResource_fileSize_desc'
  | 'filterResource_id_asc'
  | 'filterResource_id_desc'
  | 'filterResource_name_asc'
  | 'filterResource_name_desc'
  | 'filterResource_orgId_asc'
  | 'filterResource_orgId_desc'
  | 'filterResource_originalResourceId_asc'
  | 'filterResource_originalResourceId_desc'
  | 'filterResource_originalResourceState_asc'
  | 'filterResource_originalResourceState_desc'
  | 'filterResource_sessionId_asc'
  | 'filterResource_sessionId_desc'
  | 'filterResource_thumbnailResourceId_asc'
  | 'filterResource_thumbnailResourceId_desc'
  | 'filterResource_type_asc'
  | 'filterResource_type_desc'
  | 'filterResource_url_asc'
  | 'filterResource_url_desc'
  | 'id_asc'
  | 'id_desc'
  | 'isDownloading_asc'
  | 'isDownloading_desc'
  | 'lbId_asc'
  | 'lbId_desc'
  | 'resourceId_asc'
  | 'resourceId_desc'
  | 'resource_createdAt_asc'
  | 'resource_createdAt_desc'
  | 'resource_createdById_asc'
  | 'resource_createdById_desc'
  | 'resource_duration_asc'
  | 'resource_duration_desc'
  | 'resource_ffprobe_asc'
  | 'resource_ffprobe_desc'
  | 'resource_fileSize_asc'
  | 'resource_fileSize_desc'
  | 'resource_id_asc'
  | 'resource_id_desc'
  | 'resource_name_asc'
  | 'resource_name_desc'
  | 'resource_orgId_asc'
  | 'resource_orgId_desc'
  | 'resource_originalResourceId_asc'
  | 'resource_originalResourceId_desc'
  | 'resource_originalResourceState_asc'
  | 'resource_originalResourceState_desc'
  | 'resource_sessionId_asc'
  | 'resource_sessionId_desc'
  | 'resource_thumbnailResourceId_asc'
  | 'resource_thumbnailResourceId_desc'
  | 'resource_type_asc'
  | 'resource_type_desc'
  | 'resource_url_asc'
  | 'resource_url_desc'
  | 'type_asc'
  | 'type_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'user_avatarUrl_asc'
  | 'user_avatarUrl_desc'
  | 'user_blockedBySystemAdmin_asc'
  | 'user_blockedBySystemAdmin_desc'
  | 'user_createdAt_asc'
  | 'user_createdAt_desc'
  | 'user_currentOrgId_asc'
  | 'user_currentOrgId_desc'
  | 'user_email_asc'
  | 'user_email_desc'
  | 'user_hashedPassword_asc'
  | 'user_hashedPassword_desc'
  | 'user_id_asc'
  | 'user_id_desc'
  | 'user_name_asc'
  | 'user_name_desc'
  | 'user_referralById_asc'
  | 'user_referralById_desc'
  | 'user_rootOrgId_asc'
  | 'user_rootOrgId_desc'
  | 'user_socialLogin_asc'
  | 'user_socialLogin_desc'
  | 'user_stripeCustomerId_asc'
  | 'user_stripeCustomerId_desc'
  | 'user_studioSettings_asc'
  | 'user_studioSettings_desc'
  | 'user_themeWarmness_asc'
  | 'user_themeWarmness_desc'
  | 'user_theme_asc'
  | 'user_theme_desc'

export type UserInSession = {
  __typename?: 'UserInSession'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  role: Scalars['String']['output']
  session?: Maybe<Session>
  sessionId: Scalars['ID']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type UserInSessionFilter = {
  AND?: InputMaybe<Array<UserInSessionFilter>>
  NOT?: InputMaybe<UserInSessionFilter>
  OR?: InputMaybe<Array<UserInSessionFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  role?: InputMaybe<Scalars['String']['input']>
  role_endsWith?: InputMaybe<Scalars['String']['input']>
  role_gt?: InputMaybe<Scalars['String']['input']>
  role_gte?: InputMaybe<Scalars['String']['input']>
  role_iLike?: InputMaybe<Scalars['String']['input']>
  role_in?: InputMaybe<Array<Scalars['String']['input']>>
  role_like?: InputMaybe<Scalars['String']['input']>
  role_lt?: InputMaybe<Scalars['String']['input']>
  role_lte?: InputMaybe<Scalars['String']['input']>
  role_ne?: InputMaybe<Scalars['String']['input']>
  role_notILike?: InputMaybe<Scalars['String']['input']>
  role_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  role_notLike?: InputMaybe<Scalars['String']['input']>
  role_startsWith?: InputMaybe<Scalars['String']['input']>
  role_substring?: InputMaybe<Scalars['String']['input']>
  sessionId?: InputMaybe<Scalars['ID']['input']>
  sessionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId_ne?: InputMaybe<Scalars['ID']['input']>
  sessionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  session_none?: InputMaybe<SessionFilter>
  session_some?: InputMaybe<SessionFilter>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
}

export type UserInSessionOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'role_asc'
  | 'role_desc'
  | 'sessionId_asc'
  | 'sessionId_desc'
  | 'session_createdAt_asc'
  | 'session_createdAt_desc'
  | 'session_createdById_asc'
  | 'session_createdById_desc'
  | 'session_description_asc'
  | 'session_description_desc'
  | 'session_endTime_asc'
  | 'session_endTime_desc'
  | 'session_id_asc'
  | 'session_id_desc'
  | 'session_insightEngineId_asc'
  | 'session_insightEngineId_desc'
  | 'session_layout_asc'
  | 'session_layout_desc'
  | 'session_lbAmsId_asc'
  | 'session_lbAmsId_desc'
  | 'session_lbMediasoupId_asc'
  | 'session_lbMediasoupId_desc'
  | 'session_lbMixerId_asc'
  | 'session_lbMixerId_desc'
  | 'session_orgId_asc'
  | 'session_orgId_desc'
  | 'session_settings_asc'
  | 'session_settings_desc'
  | 'session_startTime_asc'
  | 'session_startTime_desc'
  | 'session_title_asc'
  | 'session_title_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'user_avatarUrl_asc'
  | 'user_avatarUrl_desc'
  | 'user_blockedBySystemAdmin_asc'
  | 'user_blockedBySystemAdmin_desc'
  | 'user_createdAt_asc'
  | 'user_createdAt_desc'
  | 'user_currentOrgId_asc'
  | 'user_currentOrgId_desc'
  | 'user_email_asc'
  | 'user_email_desc'
  | 'user_hashedPassword_asc'
  | 'user_hashedPassword_desc'
  | 'user_id_asc'
  | 'user_id_desc'
  | 'user_name_asc'
  | 'user_name_desc'
  | 'user_referralById_asc'
  | 'user_referralById_desc'
  | 'user_rootOrgId_asc'
  | 'user_rootOrgId_desc'
  | 'user_socialLogin_asc'
  | 'user_socialLogin_desc'
  | 'user_stripeCustomerId_asc'
  | 'user_stripeCustomerId_desc'
  | 'user_studioSettings_asc'
  | 'user_studioSettings_desc'
  | 'user_themeWarmness_asc'
  | 'user_themeWarmness_desc'
  | 'user_theme_asc'
  | 'user_theme_desc'

export type UserInSystem = {
  __typename?: 'UserInSystem'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  role: Scalars['String']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type UserInSystemFilter = {
  AND?: InputMaybe<Array<UserInSystemFilter>>
  NOT?: InputMaybe<UserInSystemFilter>
  OR?: InputMaybe<Array<UserInSystemFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  role?: InputMaybe<Scalars['String']['input']>
  role_endsWith?: InputMaybe<Scalars['String']['input']>
  role_gt?: InputMaybe<Scalars['String']['input']>
  role_gte?: InputMaybe<Scalars['String']['input']>
  role_iLike?: InputMaybe<Scalars['String']['input']>
  role_in?: InputMaybe<Array<Scalars['String']['input']>>
  role_like?: InputMaybe<Scalars['String']['input']>
  role_lt?: InputMaybe<Scalars['String']['input']>
  role_lte?: InputMaybe<Scalars['String']['input']>
  role_ne?: InputMaybe<Scalars['String']['input']>
  role_notILike?: InputMaybe<Scalars['String']['input']>
  role_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  role_notLike?: InputMaybe<Scalars['String']['input']>
  role_startsWith?: InputMaybe<Scalars['String']['input']>
  role_substring?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
}

export type UserOrderBy =
  | 'avatarUrl_asc'
  | 'avatarUrl_desc'
  | 'blockedBySystemAdmin_asc'
  | 'blockedBySystemAdmin_desc'
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'currentOrgId_asc'
  | 'currentOrgId_desc'
  | 'currentOrg_blockedBySystemAdmin_asc'
  | 'currentOrg_blockedBySystemAdmin_desc'
  | 'currentOrg_bonusMinutesUsed_asc'
  | 'currentOrg_bonusMinutesUsed_desc'
  | 'currentOrg_bonusMinutes_asc'
  | 'currentOrg_bonusMinutes_desc'
  | 'currentOrg_bonusStorage_asc'
  | 'currentOrg_bonusStorage_desc'
  | 'currentOrg_boughtMinutesUsed_asc'
  | 'currentOrg_boughtMinutesUsed_desc'
  | 'currentOrg_boughtMinutes_asc'
  | 'currentOrg_boughtMinutes_desc'
  | 'currentOrg_boughtStorage_asc'
  | 'currentOrg_boughtStorage_desc'
  | 'currentOrg_coverPhotoResourceId_asc'
  | 'currentOrg_coverPhotoResourceId_desc'
  | 'currentOrg_createdAt_asc'
  | 'currentOrg_createdAt_desc'
  | 'currentOrg_createdById_asc'
  | 'currentOrg_createdById_desc'
  | 'currentOrg_dnsValidationAt_asc'
  | 'currentOrg_dnsValidationAt_desc'
  | 'currentOrg_dnsValidation_asc'
  | 'currentOrg_dnsValidation_desc'
  | 'currentOrg_dns_asc'
  | 'currentOrg_dns_desc'
  | 'currentOrg_freeMinutesResetAt_asc'
  | 'currentOrg_freeMinutesResetAt_desc'
  | 'currentOrg_freeMinutesUsed_asc'
  | 'currentOrg_freeMinutesUsed_desc'
  | 'currentOrg_id_asc'
  | 'currentOrg_id_desc'
  | 'currentOrg_name_asc'
  | 'currentOrg_name_desc'
  | 'currentOrg_settings_asc'
  | 'currentOrg_settings_desc'
  | 'currentOrg_stripeSubscriptionId_asc'
  | 'currentOrg_stripeSubscriptionId_desc'
  | 'currentOrg_subscriptionPlan_asc'
  | 'currentOrg_subscriptionPlan_desc'
  | 'currentOrg_subscriptionStatus_asc'
  | 'currentOrg_subscriptionStatus_desc'
  | 'currentOrg_systemLogoResourceId_asc'
  | 'currentOrg_systemLogoResourceId_desc'
  | 'email_asc'
  | 'email_desc'
  | 'hashedPassword_asc'
  | 'hashedPassword_desc'
  | 'id_asc'
  | 'id_desc'
  | 'name_asc'
  | 'name_desc'
  | 'orgs_count_asc'
  | 'orgs_count_desc'
  | 'referralById_asc'
  | 'referralById_desc'
  | 'rootOrgId_asc'
  | 'rootOrgId_desc'
  | 'rootOrg_blockedBySystemAdmin_asc'
  | 'rootOrg_blockedBySystemAdmin_desc'
  | 'rootOrg_bonusMinutesUsed_asc'
  | 'rootOrg_bonusMinutesUsed_desc'
  | 'rootOrg_bonusMinutes_asc'
  | 'rootOrg_bonusMinutes_desc'
  | 'rootOrg_bonusStorage_asc'
  | 'rootOrg_bonusStorage_desc'
  | 'rootOrg_boughtMinutesUsed_asc'
  | 'rootOrg_boughtMinutesUsed_desc'
  | 'rootOrg_boughtMinutes_asc'
  | 'rootOrg_boughtMinutes_desc'
  | 'rootOrg_boughtStorage_asc'
  | 'rootOrg_boughtStorage_desc'
  | 'rootOrg_coverPhotoResourceId_asc'
  | 'rootOrg_coverPhotoResourceId_desc'
  | 'rootOrg_createdAt_asc'
  | 'rootOrg_createdAt_desc'
  | 'rootOrg_createdById_asc'
  | 'rootOrg_createdById_desc'
  | 'rootOrg_dnsValidationAt_asc'
  | 'rootOrg_dnsValidationAt_desc'
  | 'rootOrg_dnsValidation_asc'
  | 'rootOrg_dnsValidation_desc'
  | 'rootOrg_dns_asc'
  | 'rootOrg_dns_desc'
  | 'rootOrg_freeMinutesResetAt_asc'
  | 'rootOrg_freeMinutesResetAt_desc'
  | 'rootOrg_freeMinutesUsed_asc'
  | 'rootOrg_freeMinutesUsed_desc'
  | 'rootOrg_id_asc'
  | 'rootOrg_id_desc'
  | 'rootOrg_name_asc'
  | 'rootOrg_name_desc'
  | 'rootOrg_settings_asc'
  | 'rootOrg_settings_desc'
  | 'rootOrg_stripeSubscriptionId_asc'
  | 'rootOrg_stripeSubscriptionId_desc'
  | 'rootOrg_subscriptionPlan_asc'
  | 'rootOrg_subscriptionPlan_desc'
  | 'rootOrg_subscriptionStatus_asc'
  | 'rootOrg_subscriptionStatus_desc'
  | 'rootOrg_systemLogoResourceId_asc'
  | 'rootOrg_systemLogoResourceId_desc'
  | 'socialLogin_asc'
  | 'socialLogin_desc'
  | 'stripeCustomerId_asc'
  | 'stripeCustomerId_desc'
  | 'themeWarmness_asc'
  | 'themeWarmness_desc'
  | 'theme_asc'
  | 'theme_desc'
  | 'userInSystem_createdAt_asc'
  | 'userInSystem_createdAt_desc'
  | 'userInSystem_id_asc'
  | 'userInSystem_id_desc'
  | 'userInSystem_role_asc'
  | 'userInSystem_role_desc'
  | 'userInSystem_userId_asc'
  | 'userInSystem_userId_desc'

export type WaitingUser = {
  __typename?: 'WaitingUser'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  session?: Maybe<Session>
  sessionId: Scalars['ID']['output']
  sessionRole: Scalars['String']['output']
  socketId: Scalars['ID']['output']
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type WaitingUserFilter = {
  AND?: InputMaybe<Array<WaitingUserFilter>>
  NOT?: InputMaybe<WaitingUserFilter>
  OR?: InputMaybe<Array<WaitingUserFilter>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_ne?: InputMaybe<Scalars['DateTime']['input']>
  createdAt_notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
  id?: InputMaybe<Scalars['ID']['input']>
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>
  id_ne?: InputMaybe<Scalars['ID']['input']>
  id_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId?: InputMaybe<Scalars['ID']['input']>
  sessionId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionId_ne?: InputMaybe<Scalars['ID']['input']>
  sessionId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  sessionRole?: InputMaybe<Scalars['String']['input']>
  sessionRole_endsWith?: InputMaybe<Scalars['String']['input']>
  sessionRole_gt?: InputMaybe<Scalars['String']['input']>
  sessionRole_gte?: InputMaybe<Scalars['String']['input']>
  sessionRole_iLike?: InputMaybe<Scalars['String']['input']>
  sessionRole_in?: InputMaybe<Array<Scalars['String']['input']>>
  sessionRole_like?: InputMaybe<Scalars['String']['input']>
  sessionRole_lt?: InputMaybe<Scalars['String']['input']>
  sessionRole_lte?: InputMaybe<Scalars['String']['input']>
  sessionRole_ne?: InputMaybe<Scalars['String']['input']>
  sessionRole_notILike?: InputMaybe<Scalars['String']['input']>
  sessionRole_notIn?: InputMaybe<Array<Scalars['String']['input']>>
  sessionRole_notLike?: InputMaybe<Scalars['String']['input']>
  sessionRole_startsWith?: InputMaybe<Scalars['String']['input']>
  sessionRole_substring?: InputMaybe<Scalars['String']['input']>
  session_none?: InputMaybe<SessionFilter>
  session_some?: InputMaybe<SessionFilter>
  socketId?: InputMaybe<Scalars['ID']['input']>
  socketId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  socketId_ne?: InputMaybe<Scalars['ID']['input']>
  socketId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  userId?: InputMaybe<Scalars['ID']['input']>
  userId_in?: InputMaybe<Array<Scalars['ID']['input']>>
  userId_ne?: InputMaybe<Scalars['ID']['input']>
  userId_notIn?: InputMaybe<Array<Scalars['ID']['input']>>
  user_none?: InputMaybe<UserFilter>
  user_some?: InputMaybe<UserFilter>
}

export type WaitingUserOrderBy =
  | 'createdAt_asc'
  | 'createdAt_desc'
  | 'id_asc'
  | 'id_desc'
  | 'sessionId_asc'
  | 'sessionId_desc'
  | 'sessionRole_asc'
  | 'sessionRole_desc'
  | 'session_createdAt_asc'
  | 'session_createdAt_desc'
  | 'session_createdById_asc'
  | 'session_createdById_desc'
  | 'session_description_asc'
  | 'session_description_desc'
  | 'session_endTime_asc'
  | 'session_endTime_desc'
  | 'session_id_asc'
  | 'session_id_desc'
  | 'session_insightEngineId_asc'
  | 'session_insightEngineId_desc'
  | 'session_layout_asc'
  | 'session_layout_desc'
  | 'session_lbAmsId_asc'
  | 'session_lbAmsId_desc'
  | 'session_lbMediasoupId_asc'
  | 'session_lbMediasoupId_desc'
  | 'session_lbMixerId_asc'
  | 'session_lbMixerId_desc'
  | 'session_orgId_asc'
  | 'session_orgId_desc'
  | 'session_settings_asc'
  | 'session_settings_desc'
  | 'session_startTime_asc'
  | 'session_startTime_desc'
  | 'session_title_asc'
  | 'session_title_desc'
  | 'socketId_asc'
  | 'socketId_desc'
  | 'userId_asc'
  | 'userId_desc'
  | 'user_avatarUrl_asc'
  | 'user_avatarUrl_desc'
  | 'user_blockedBySystemAdmin_asc'
  | 'user_blockedBySystemAdmin_desc'
  | 'user_createdAt_asc'
  | 'user_createdAt_desc'
  | 'user_currentOrgId_asc'
  | 'user_currentOrgId_desc'
  | 'user_email_asc'
  | 'user_email_desc'
  | 'user_hashedPassword_asc'
  | 'user_hashedPassword_desc'
  | 'user_id_asc'
  | 'user_id_desc'
  | 'user_name_asc'
  | 'user_name_desc'
  | 'user_referralById_asc'
  | 'user_referralById_desc'
  | 'user_rootOrgId_asc'
  | 'user_rootOrgId_desc'
  | 'user_socialLogin_asc'
  | 'user_socialLogin_desc'
  | 'user_stripeCustomerId_asc'
  | 'user_stripeCustomerId_desc'
  | 'user_studioSettings_asc'
  | 'user_studioSettings_desc'
  | 'user_themeWarmness_asc'
  | 'user_themeWarmness_desc'
  | 'user_theme_asc'
  | 'user_theme_desc'

export type GetProtooUrlQueryVariables = Exact<{
  sessionId: Scalars['ID']['input']
}>

export type GetProtooUrlQuery = {
  __typename?: 'Query'
  lbMediasoupUrl?: string | null
}

export type CheckUserInSessionQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type CheckUserInSessionQuery = {
  __typename?: 'Query'
  checkUserInSession: boolean
}

export type OnStudioEnterQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type OnStudioEnterQuery = {
  __typename?: 'Query'
  sessionRole?: {
    __typename?: 'SessionRole'
    name: SessionRoleName
    permissions: Record<SessionPermission, boolean>
  } | null
  detailSession: {
    __typename?: 'Session'
    id: string
    title: string
    description: string
    startTime: Date
    endTime: Date
    settings?: any | null
    insightEngineId: string
  }
}

export type ChangePasswordMutationVariables = Exact<{
  oldPassword?: InputMaybe<Scalars['String']['input']>
  newPassword: Scalars['Password']['input']
}>

export type ChangePasswordMutation = {
  __typename?: 'Mutation'
  changePassword?: null | null
}

export type CreateInputMutationVariables = Exact<{
  data: CreateInput
}>

export type CreateInputMutation = {
  __typename?: 'Mutation'
  createInput: { __typename?: 'Input'; id: string; status: string }
}

export type DeleteInputMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteInputMutation = {
  __typename?: 'Mutation'
  deleteInput?: null | null
}

export type GenerateUserTokenMutationVariables = Exact<{ [key: string]: never }>

export type GenerateUserTokenMutation = {
  __typename?: 'Mutation'
  generateUserToken: {
    __typename?: 'AuthSession'
    type: string
    userId: string
    secret: string
    ipAddress: string
    userAgent: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      id: string
      name: string
      rootOrgId: string
      email: string
      socialLogin: string
      avatarUrl: string
      referralById?: string | null
      currentOrgId: string
      theme: string
      themeWarmness: number
      studioSettings?: any | null
      blockedBySystemAdmin: boolean
    } | null
  }
}

export type CurrentAuthSessionQueryVariables = Exact<{ [key: string]: never }>

export type CurrentAuthSessionQuery = {
  __typename?: 'Query'
  currentAuthSession?: {
    __typename?: 'AuthSession'
    id: string
    secret: string
    user?: {
      __typename?: 'User'
      id: string
      name: string
      rootOrgId: string
      email: string
      socialLogin: string
      avatarUrl: string
      referralById?: string | null
      currentOrgId: string
      theme: string
      themeWarmness: number
      studioSettings?: any | null
      blockedBySystemAdmin: boolean
    } | null
  } | null
}

export type CurrentPlanFragment = {
  __typename?: 'CurrentPlan'
  bonusStorage: number
  boughtStorage: number
  freeStorage: string
  bonusMinutesUsed: number
  bonusMinutes: number
  boughtMinutesUsed: number
  boughtMinutes: number
  freeMinutesUsed: number
  freeMinutes: string
  remainingStorage: string
  remainingMinutes: string
  totalStorageUsed: number
  totalMinutesUsed: number
  totalStorage: string
  totalMinutes: string
  plan: PlanName
  subscription?: {
    __typename?: 'Subscription'
    nextInvoiceAt?: Date | null
    recurrence: boolean
    totalStoragePrice: number
    boughtStorage: number
    mainPrice: number
    totalPrice: number
    currency: PlanCurrency
    interval: PlanInterval
    status: SubscriptionStatus
  } | null
}

export type CurrentPlanQueryVariables = Exact<{
  orgId: Scalars['ID']['input']
}>

export type CurrentPlanQuery = {
  __typename?: 'Query'
  currentPlan: {
    __typename?: 'CurrentPlan'
    bonusStorage: number
    boughtStorage: number
    freeStorage: string
    bonusMinutesUsed: number
    bonusMinutes: number
    boughtMinutesUsed: number
    boughtMinutes: number
    freeMinutesUsed: number
    freeMinutes: string
    remainingStorage: string
    remainingMinutes: string
    totalStorageUsed: number
    totalMinutesUsed: number
    totalStorage: string
    totalMinutes: string
    plan: PlanName
    subscription?: {
      __typename?: 'Subscription'
      nextInvoiceAt?: Date | null
      recurrence: boolean
      totalStoragePrice: number
      boughtStorage: number
      mainPrice: number
      totalPrice: number
      currency: PlanCurrency
      interval: PlanInterval
      status: SubscriptionStatus
    } | null
  }
}

export type OrgInfoFragment = {
  __typename?: 'Org'
  id: string
  name: string
  settings?: any | null
  dns: string
  dnsValidation: string
  systemLogoResource?: {
    __typename?: 'Resource'
    name: string
    url: string
    fileSize: number
    duration: number
    originalResourceId?: string | null
    originalResourceState?: any | null
  } | null
  coverPhotoResource?: {
    __typename?: 'Resource'
    name: string
    url: string
    fileSize: number
    duration: number
    originalResourceId?: string | null
    originalResourceState?: any | null
  } | null
}

export type OrgInfoAdminFragment = {
  __typename?: 'Org'
  id: string
  name: string
  usersCount: number
  settings?: any | null
  subscriptionPlan: string
  systemLogoResource?: {
    __typename?: 'Resource'
    name: string
    url: string
    fileSize: number
    duration: number
    originalResourceId?: string | null
    originalResourceState?: any | null
  } | null
  createdBy?: {
    __typename?: 'User'
    id: string
    name: string
    avatarUrl: string
    blockedBySystemAdmin: boolean
  } | null
}

export type DetailOrgQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DetailOrgQuery = {
  __typename?: 'Query'
  detailOrg: {
    __typename?: 'Org'
    id: string
    name: string
    settings?: any | null
    dns: string
    dnsValidation: string
    systemLogoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
    coverPhotoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
  }
}

export type MyOrgInvitationsQueryVariables = Exact<{
  filter?: InputMaybe<TicketFilter>
  order?: InputMaybe<Array<TicketOrderBy> | TicketOrderBy>
  page?: InputMaybe<Pagination>
}>

export type MyOrgInvitationsQuery = {
  __typename?: 'Query'
  countMyOrgInvitations: number
  myOrgInvitations: Array<{
    __typename?: 'Ticket'
    id: string
    type: string
    data: any
    rejected: boolean
    createdAt: Date
    resentAt: Date
    invitationExpired: boolean
    org?: {
      __typename?: 'Org'
      id: string
      name: string
      settings?: any | null
      dns: string
      dnsValidation: string
      createdBy?: {
        __typename?: 'User'
        name: string
        avatarUrl: string
      } | null
      systemLogoResource?: {
        __typename?: 'Resource'
        name: string
        url: string
        fileSize: number
        duration: number
        originalResourceId?: string | null
        originalResourceState?: any | null
      } | null
      coverPhotoResource?: {
        __typename?: 'Resource'
        name: string
        url: string
        fileSize: number
        duration: number
        originalResourceId?: string | null
        originalResourceState?: any | null
      } | null
    } | null
  }>
}

export type MyOrgsQueryVariables = Exact<{
  filter?: InputMaybe<UserInOrgFilter>
  order?: InputMaybe<Array<UserInOrgOrderBy> | UserInOrgOrderBy>
  page?: InputMaybe<Pagination>
}>

export type MyOrgsQuery = {
  __typename?: 'Query'
  myOrgs: Array<{
    __typename?: 'UserInOrg'
    org?: {
      __typename?: 'Org'
      id: string
      name: string
      dns: string
      dnsValidation: string
      systemLogoResource?: { __typename?: 'Resource'; url: string } | null
    } | null
  }>
}

export type OrgInviteTicketsQueryVariables = Exact<{
  orgId: Scalars['ID']['input']
  filter?: InputMaybe<TicketFilter>
  order?: InputMaybe<Array<TicketOrderBy> | TicketOrderBy>
  page?: InputMaybe<Pagination>
}>

export type OrgInviteTicketsQuery = {
  __typename?: 'Query'
  countOrgInviteTickets: number
  orgInviteTickets: Array<{
    __typename?: 'Ticket'
    id: string
    type: string
    email: string
    resentAt: Date
    data: any
    rejected: boolean
  }>
}

export type OrgRoleQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type OrgRoleQuery = {
  __typename?: 'Query'
  orgRole?: {
    __typename?: 'OrgRole'
    permissions: Record<OrgPermission, boolean>
    name: OrgRoleName
  } | null
}

export type SystemRoleQueryVariables = Exact<{ [key: string]: never }>

export type SystemRoleQuery = {
  __typename?: 'Query'
  systemRole?: {
    __typename?: 'SystemRole'
    permissions: Record<SystemPermission, boolean>
    name: string
  } | null
}

export type InitialOrgDataQueryVariables = Exact<{
  orgId: Scalars['ID']['input']
}>

export type InitialOrgDataQuery = {
  __typename?: 'Query'
  orgRole?: {
    __typename?: 'OrgRole'
    permissions: Record<OrgPermission, boolean>
    name: OrgRoleName
  } | null
  detailOrg: {
    __typename?: 'Org'
    id: string
    name: string
    settings?: any | null
    dns: string
    dnsValidation: string
    systemLogoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
    coverPhotoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
  }
}

export type InitialDataUserQueryVariables = Exact<{
  orgId: Scalars['ID']['input']
}>

export type InitialDataUserQuery = {
  __typename?: 'Query'
  countMyOrgInvitations: number
  myOrgs: Array<{
    __typename?: 'UserInOrg'
    org?: {
      __typename?: 'Org'
      id: string
      name: string
      systemLogoResource?: { __typename?: 'Resource'; url: string } | null
    } | null
  }>
  systemRole?: {
    __typename?: 'SystemRole'
    permissions: Record<SystemPermission, boolean>
    name: string
  } | null
  plans: Array<{
    __typename?: 'Plan'
    name: PlanName
    maxParticipants: number
    freeMinutesPerMonth: string
    freeStoragePerMonth: string
    prices: Array<{
      __typename?: 'PlanPrice'
      priceMinutes?: number | null
      price: number
      currency: PlanCurrency
      interval: PlanInterval
      priceStorage?: number | null
      priceMinutesTiers?: Array<{
        __typename?: 'PlanPriceTier'
        from: number
        to?: number | null
        price: number
      }> | null
      priceStorageTiers?: Array<{
        __typename?: 'PlanPriceTier'
        from: number
        to?: number | null
        price: number
      }> | null
    }>
  }>
  orgRole?: {
    __typename?: 'OrgRole'
    permissions: Record<OrgPermission, boolean>
    name: OrgRoleName
  } | null
  detailOrg: {
    __typename?: 'Org'
    id: string
    name: string
    settings?: any | null
    dns: string
    dnsValidation: string
    systemLogoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
    coverPhotoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
  }
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation'; logout?: null | null }

export type RejectOrgInvitationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RejectOrgInvitationMutation = {
  __typename?: 'Mutation'
  rejectOrgInvitation?: null | null
}

export type AcceptOrgInvitationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AcceptOrgInvitationMutation = {
  __typename?: 'Mutation'
  acceptOrgInvitation?: null | null
}

export type SearchInputQueryVariables = Exact<{
  sessionId: Scalars['ID']['input']
  filter?: InputMaybe<InputFilter>
  order?: InputMaybe<Array<InputOrderBy> | InputOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchInputQuery = {
  __typename?: 'Query'
  searchInput: Array<{
    __typename?: 'Input'
    id: string
    sessionId: string
    type: string
    status: string
    name: string
    secret: string
    url: string
    createdAt: Date
  }>
}

export type ShareReferralLinkMutationVariables = Exact<{
  orgId: Scalars['ID']['input']
  emails: Array<Scalars['Email']['input']> | Scalars['Email']['input']
  message?: InputMaybe<Scalars['String']['input']>
}>

export type ShareReferralLinkMutation = {
  __typename?: 'Mutation'
  shareReferralLink?: null | null
}

export type SystemAddEnterpriseMutationVariables = Exact<{
  orgId: Scalars['ID']['input']
}>

export type SystemAddEnterpriseMutation = {
  __typename?: 'Mutation'
  systemAddEnterprise: {
    __typename?: 'Org'
    id: string
    name: string
    settings?: any | null
    dns: string
    dnsValidation: string
    systemLogoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
    coverPhotoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
  }
}

export type SystemBlockUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SystemBlockUserMutation = {
  __typename?: 'Mutation'
  systemBlockUser: { __typename?: 'User'; name: string; email: string }
}

export type SystemDeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SystemDeleteUserMutation = {
  __typename?: 'Mutation'
  systemDeleteUser?: null | null
}

export type SystemRemoveEnterpriseMutationVariables = Exact<{
  orgId: Scalars['ID']['input']
}>

export type SystemRemoveEnterpriseMutation = {
  __typename?: 'Mutation'
  systemRemoveEnterprise: {
    __typename?: 'Org'
    id: string
    name: string
    settings?: any | null
    dns: string
    dnsValidation: string
    systemLogoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
    coverPhotoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
  }
}

export type SystemUnblockUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SystemUnblockUserMutation = {
  __typename?: 'Mutation'
  systemUnblockUser: {
    __typename?: 'User'
    name: string
    email: string
    currentOrg?: { __typename?: 'Org'; blockedBySystemAdmin: boolean } | null
  }
}

export type UpdateInputMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateInput
}>

export type UpdateInputMutation = {
  __typename?: 'Mutation'
  updateInput: { __typename?: 'Input'; id: string }
}

export type UpdateOrgSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateOrgSettings
}>

export type UpdateOrgSettingsMutation = {
  __typename?: 'Mutation'
  updateOrgSettings: {
    __typename?: 'Org'
    id: string
    name: string
    settings?: any | null
    dns: string
    dnsValidation: string
    systemLogoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
    coverPhotoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
  }
}

export type UpdateOrgSystemLogoMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateOrgSystemLogo
}>

export type UpdateOrgSystemLogoMutation = {
  __typename?: 'Mutation'
  updateOrgSystemLogo: {
    __typename?: 'Org'
    id: string
    name: string
    settings?: any | null
    dns: string
    dnsValidation: string
    systemLogoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
    coverPhotoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
  }
}

export type UpdateMyProfileMutationVariables = Exact<{
  data: UpdateMyProfile
}>

export type UpdateMyProfileMutation = {
  __typename?: 'Mutation'
  updateMyProfile: {
    __typename?: 'User'
    id: string
    name: string
    rootOrgId: string
    email: string
    socialLogin: string
    avatarUrl: string
    referralById?: string | null
    currentOrgId: string
    theme: string
    themeWarmness: number
    studioSettings?: any | null
    blockedBySystemAdmin: boolean
  }
}

export type SystemSetSystemAdminMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  enabled: Scalars['Boolean']['input']
}>

export type SystemSetSystemAdminMutation = {
  __typename?: 'Mutation'
  systemSetSystemAdmin?: null | null
}

export type CreateAmsMutationVariables = Exact<{
  data: CreateAms
}>

export type CreateAmsMutation = {
  __typename?: 'Mutation'
  createAms: {
    __typename?: 'Ams'
    id: string
    sessionId: string
    active: boolean
    repeat: boolean
    sync: boolean
    session?: {
      __typename?: 'Session'
      id: string
      title: string
      description: string
      hasRecording: boolean
    } | null
  }
}

export type CreateResourceInAmsMutationVariables = Exact<{
  data: CreateResourceInAms
}>

export type CreateResourceInAmsMutation = {
  __typename?: 'Mutation'
  createResourceInAms: {
    __typename?: 'ResourceInAms'
    id: string
    amsId: string
    resourceId: string
    position: number
    repeat: boolean
    volume: number
    muted: boolean
    createdAt: Date
    ams?: { __typename?: 'Ams'; id: string } | null
    resource?: {
      __typename?: 'Resource'
      id: string
      type: string
      name: string
      url: string
      fileSize: number
      sessionId?: string | null
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      createdById?: string | null
    } | null
  }
}

export type DeleteAmsMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteAmsMutation = {
  __typename?: 'Mutation'
  deleteAms?: null | null
}

export type DeleteAmsResourceMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteAmsResourceMutation = {
  __typename?: 'Mutation'
  deleteAmsResource?: null | null
}

export type DeleteResourceInAmsMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteResourceInAmsMutation = {
  __typename?: 'Mutation'
  deleteResourceInAms?: null | null
}

export type DisconnectAmsMutationVariables = Exact<{
  amsId: Scalars['ID']['input']
}>

export type DisconnectAmsMutation = {
  __typename?: 'Mutation'
  disconnectAms?: null | null
}

export type ReconnectAmsMutationVariables = Exact<{
  amsId: Scalars['ID']['input']
  sessionId?: InputMaybe<Scalars['ID']['input']>
}>

export type ReconnectAmsMutation = {
  __typename?: 'Mutation'
  reconnectAms: {
    __typename?: 'Ams'
    id: string
    sessionId: string
    active: boolean
    repeat: boolean
    sync: boolean
    session?: {
      __typename?: 'Session'
      id: string
      title: string
      description: string
      hasRecording: boolean
    } | null
  }
}

export type AmsFragment = {
  __typename?: 'Ams'
  id: string
  sessionId: string
  active: boolean
  repeat: boolean
  sync: boolean
  session?: {
    __typename?: 'Session'
    id: string
    title: string
    description: string
    hasRecording: boolean
  } | null
}

export type SearchAmsQueryVariables = Exact<{
  filter?: InputMaybe<AmsFilter>
  order?: InputMaybe<Array<AmsOrderBy> | AmsOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchAmsQuery = {
  __typename?: 'Query'
  searchAms: Array<{
    __typename?: 'Ams'
    id: string
    sessionId: string
    active: boolean
    repeat: boolean
    sync: boolean
    session?: {
      __typename?: 'Session'
      id: string
      title: string
      description: string
      hasRecording: boolean
    } | null
  }>
}

export type AmsResourceFragment = {
  __typename?: 'Resource'
  id: string
  type: string
  name: string
  url: string
  fileSize: number
  sessionId?: string | null
  originalResourceId?: string | null
  originalResourceState?: any | null
  duration: number
  createdById?: string | null
}

export type SearchAmsResourceQueryVariables = Exact<{
  filter?: InputMaybe<ResourceFilter>
  order?: InputMaybe<Array<ResourceOrderBy> | ResourceOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchAmsResourceQuery = {
  __typename?: 'Query'
  searchAmsResource: Array<{
    __typename?: 'Resource'
    id: string
    type: string
    name: string
    url: string
    fileSize: number
    sessionId?: string | null
    originalResourceId?: string | null
    originalResourceState?: any | null
    duration: number
    createdById?: string | null
  }>
}

export type ResourceInAmsFragment = {
  __typename?: 'ResourceInAms'
  id: string
  amsId: string
  resourceId: string
  position: number
  repeat: boolean
  volume: number
  muted: boolean
  createdAt: Date
  ams?: { __typename?: 'Ams'; id: string } | null
  resource?: {
    __typename?: 'Resource'
    id: string
    type: string
    name: string
    url: string
    fileSize: number
    sessionId?: string | null
    originalResourceId?: string | null
    originalResourceState?: any | null
    duration: number
    createdById?: string | null
  } | null
}

export type SearchResourceInAmsQueryVariables = Exact<{
  filter?: InputMaybe<ResourceInAmsFilter>
  order?: InputMaybe<Array<ResourceInAmsOrderBy> | ResourceInAmsOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchResourceInAmsQuery = {
  __typename?: 'Query'
  searchResourceInAms: Array<{
    __typename?: 'ResourceInAms'
    id: string
    amsId: string
    resourceId: string
    position: number
    repeat: boolean
    volume: number
    muted: boolean
    createdAt: Date
    ams?: { __typename?: 'Ams'; id: string } | null
    resource?: {
      __typename?: 'Resource'
      id: string
      type: string
      name: string
      url: string
      fileSize: number
      sessionId?: string | null
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      createdById?: string | null
    } | null
  }>
}

export type UpdateAmsMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateAms
}>

export type UpdateAmsMutation = {
  __typename?: 'Mutation'
  updateAms: { __typename?: 'Ams'; id: string }
}

export type UpdateResourceInAmsMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateResourceInAms
}>

export type UpdateResourceInAmsMutation = {
  __typename?: 'Mutation'
  updateResourceInAms: {
    __typename?: 'ResourceInAms'
    id: string
    amsId: string
    resourceId: string
    position: number
    repeat: boolean
    volume: number
    muted: boolean
    createdAt: Date
    ams?: { __typename?: 'Ams'; id: string } | null
    resource?: {
      __typename?: 'Resource'
      id: string
      type: string
      name: string
      url: string
      fileSize: number
      sessionId?: string | null
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      createdById?: string | null
    } | null
  }
}

export type SystemDetailUserQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SystemDetailUserQuery = {
  __typename?: 'Query'
  systemDetailUser: {
    __typename?: 'User'
    id: string
    avatarUrl: string
    name: string
    email: string
    rootOrgId: string
    blockedBySystemAdmin: boolean
    createdAt: Date
    currentOrg?: {
      __typename?: 'Org'
      name: string
      systemLogoResource?: { __typename?: 'Resource'; url: string } | null
    } | null
    rootOrg?: {
      __typename?: 'Org'
      id: string
      name: string
      usersCount: number
      settings?: any | null
      subscriptionPlan: string
      systemLogoResource?: {
        __typename?: 'Resource'
        name: string
        url: string
        fileSize: number
        duration: number
        originalResourceId?: string | null
        originalResourceState?: any | null
      } | null
      createdBy?: {
        __typename?: 'User'
        id: string
        name: string
        avatarUrl: string
        blockedBySystemAdmin: boolean
      } | null
    } | null
  }
}

export type SystemSearchUserQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>
  order?: InputMaybe<Array<UserOrderBy> | UserOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SystemSearchUserQuery = {
  __typename?: 'Query'
  systemCountUser: number
  systemSearchUser: Array<{
    __typename?: 'User'
    name: string
    email: string
    avatarUrl: string
    id: string
    blockedBySystemAdmin: boolean
    rootOrgId: string
    socialLogin: string
    referralById?: string | null
    currentOrgId: string
    theme: string
    themeWarmness: number
    studioSettings?: any | null
    rootOrg?: {
      __typename?: 'Org'
      id: string
      name: string
      usersCount: number
      settings?: any | null
      subscriptionPlan: string
      systemLogoResource?: {
        __typename?: 'Resource'
        name: string
        url: string
        fileSize: number
        duration: number
        originalResourceId?: string | null
        originalResourceState?: any | null
      } | null
      createdBy?: {
        __typename?: 'User'
        id: string
        name: string
        avatarUrl: string
        blockedBySystemAdmin: boolean
      } | null
    } | null
    userInSystem?: { __typename?: 'UserInSystem'; role: string } | null
  }>
}

export type SystemSearchUserInOrgQueryVariables = Exact<{
  filter?: InputMaybe<UserInOrgFilter>
  order?: InputMaybe<Array<UserInOrgOrderBy> | UserInOrgOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SystemSearchUserInOrgQuery = {
  __typename?: 'Query'
  systemCountUserInOrg: number
  systemSearchUserInOrg: Array<{
    __typename?: 'UserInOrg'
    userId: string
    orgId: string
    id: string
    role: string
    user?: {
      __typename?: 'User'
      name: string
      email: string
      avatarUrl: string
    } | null
    org?: {
      __typename?: 'Org'
      name: string
      systemLogoResource?: { __typename?: 'Resource'; url: string } | null
    } | null
  }>
}

export type SystemViewCurrentPlanQueryVariables = Exact<{
  orgId: Scalars['ID']['input']
}>

export type SystemViewCurrentPlanQuery = {
  __typename?: 'Query'
  systemViewCurrentPlan: {
    __typename?: 'CurrentPlan'
    subscription?: {
      __typename?: 'Subscription'
      nextInvoiceAt?: Date | null
    } | null
  }
}

export type CreateSessionMutationVariables = Exact<{
  data: CreateSession
}>

export type CreateSessionMutation = {
  __typename?: 'Mutation'
  createSession: {
    __typename?: 'Session'
    id: string
    orgId: string
    createdById: string
    title: string
    description: string
    settings?: any | null
    layout?: any | null
    startTime: Date
    endTime: Date
    hasRecording: boolean
  }
}

export type DeleteResourceInSessionMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type DeleteResourceInSessionMutation = {
  __typename?: 'Mutation'
  deleteResourceInSession?: null | null
}

export type UpdateResourceInSessionMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  positions: Array<Scalars['UInt']['input']> | Scalars['UInt']['input']
}>

export type UpdateResourceInSessionMutation = {
  __typename?: 'Mutation'
  updateResourceInSession?: null | null
}

export type UpdateOriginalResourceMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateOriginalResource
}>

export type UpdateOriginalResourceMutation = {
  __typename?: 'Mutation'
  updateOriginalResource: { __typename?: 'Resource'; id: string }
}

export type UpdateSessionMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateSession
}>

export type UpdateSessionMutation = {
  __typename?: 'Mutation'
  updateSession: {
    __typename?: 'Session'
    id: string
    orgId: string
    createdById: string
    title: string
    description: string
    settings?: any | null
    layout?: any | null
    startTime: Date
    endTime: Date
    hasRecording: boolean
  }
}

export type SearchChatbotContextQueryVariables = Exact<{
  filter?: InputMaybe<ChatbotContextFilter>
  order?: InputMaybe<Array<ChatbotContextOrderBy> | ChatbotContextOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchChatbotContextQuery = {
  __typename?: 'Query'
  searchChatbotContext: Array<{
    __typename?: 'ChatbotContext'
    id: string
    name: string
    system: string
    model: string
    temperature: number
    maxTokens: number
    maxMessageHistory: number
  }>
}

export type UpdateChatbotContextMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateChatbotContext
}>

export type UpdateChatbotContextMutation = {
  __typename?: 'Mutation'
  updateChatbotContext: {
    __typename?: 'ChatbotContext'
    id: string
    name: string
  }
}

export type RecheckOrgDnsMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RecheckOrgDnsMutation = {
  __typename?: 'Mutation'
  recheckOrgDns: { __typename?: 'Org'; dns: string; dnsValidation: string }
}

export type UpdateOrgDnsMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateOrgDns
}>

export type UpdateOrgDnsMutation = {
  __typename?: 'Mutation'
  updateOrgDns: { __typename?: 'Org'; dns: string; dnsValidation: string }
}

export type CheckTicketQueryVariables = Exact<{
  data: CheckTicket
  type: CheckTicketType
}>

export type CheckTicketQuery = { __typename?: 'Query'; checkTicket: boolean }

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['Email']['input']
}>

export type ForgotPasswordMutation = {
  __typename?: 'Mutation'
  forgotPassword: string
}

export type ResendTicketMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ResendTicketMutation = {
  __typename?: 'Mutation'
  resendTicket?: null | null
}

export type ResolveForgotPasswordMutationVariables = Exact<{
  data: CheckTicket
  newPassword: Scalars['Password']['input']
}>

export type ResolveForgotPasswordMutation = {
  __typename?: 'Mutation'
  resolveForgotPassword: {
    __typename?: 'AuthSession'
    id: string
    secret: string
    user?: {
      __typename?: 'User'
      id: string
      name: string
      rootOrgId: string
      email: string
      socialLogin: string
      avatarUrl: string
      referralById?: string | null
      currentOrgId: string
      theme: string
      themeWarmness: number
      studioSettings?: any | null
      blockedBySystemAdmin: boolean
    } | null
  }
}

export type AddRecordMeetingMutationVariables = Exact<{
  meetingUrl: Scalars['String']['input']
  insightEngineId: Scalars['ID']['input']
}>

export type AddRecordMeetingMutation = {
  __typename?: 'Mutation'
  addRecordMeeting?: null | null
}

export type CountTranscriptQueryVariables = Exact<{
  resourceInInsightEngineId: Scalars['ID']['input']
  filter?: InputMaybe<TranscriptFilter>
}>

export type CountTranscriptQuery = {
  __typename?: 'Query'
  countTranscript: number
}

export type CreateBlurBlockMutationVariables = Exact<{
  data: CreateBlurBlock
}>

export type CreateBlurBlockMutation = {
  __typename?: 'Mutation'
  createBlurBlock: { __typename?: 'BlurBlock'; id: string }
}

export type CreateChatbotMsgMutationVariables = Exact<{
  data: CreateChatbotMsg
}>

export type CreateChatbotMsgMutation = {
  __typename?: 'Mutation'
  createChatbotMsg: {
    __typename?: 'ChatbotMsg'
    contextId: string
    content: string
    ownerId: string
    isChatBot: boolean
    status: string
    id: string
    createdAt: Date
    context?: {
      __typename?: 'ChatbotContext'
      name: string
      id: string
      createdAt: Date
    } | null
    owner?: {
      __typename?: 'User'
      name: string
      email: string
      avatarUrl: string
    } | null
  }
}

export type CreateMuteMutationVariables = Exact<{
  data: CreateMute
}>

export type CreateMuteMutation = {
  __typename?: 'Mutation'
  createMute: {
    __typename?: 'Mute'
    startTime: number
    endTime: number
    resourceInInsightEngineId: string
    id: string
    createdAt: Date
    resourceInInsightEngine?: {
      __typename?: 'ResourceInInsightEngine'
      id: string
    } | null
  }
}

export type DeleteBlurBlockMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteBlurBlockMutation = {
  __typename?: 'Mutation'
  deleteBlurBlock?: null | null
}

export type DeleteMuteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteMuteMutation = {
  __typename?: 'Mutation'
  deleteMute?: null | null
}

export type DeleteResourceInInsightEngineMutationVariables = Exact<{
  id: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type DeleteResourceInInsightEngineMutation = {
  __typename?: 'Mutation'
  deleteResourceInInsightEngine?: null | null
}

export type DetailInsightEngineQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DetailInsightEngineQuery = {
  __typename?: 'Query'
  detailInsightEngine: { __typename?: 'InsightEngine'; name: string }
}

export type DownloadVideoInInsightEngineMutationVariables = Exact<{
  resourceInInsightEngineId: Scalars['ID']['input']
}>

export type DownloadVideoInInsightEngineMutation = {
  __typename?: 'Mutation'
  downloadVideoInInsightEngine?: null | null
}

export type InsightEnginePlanQueryVariables = Exact<{
  insightEngineId: Scalars['ID']['input']
}>

export type InsightEnginePlanQuery = {
  __typename?: 'Query'
  insightEnginePlan: {
    __typename?: 'CurrentPlan'
    bonusStorage: number
    boughtStorage: number
    freeStorage: string
    bonusMinutesUsed: number
    bonusMinutes: number
    boughtMinutesUsed: number
    boughtMinutes: number
    freeMinutesUsed: number
    freeMinutes: string
    remainingStorage: string
    remainingMinutes: string
    totalStorageUsed: number
    totalMinutesUsed: number
    totalStorage: string
    totalMinutes: string
    plan: PlanName
    subscription?: {
      __typename?: 'Subscription'
      nextInvoiceAt?: Date | null
      recurrence: boolean
      totalStoragePrice: number
      boughtStorage: number
      mainPrice: number
      totalPrice: number
      currency: PlanCurrency
      interval: PlanInterval
      status: SubscriptionStatus
    } | null
  }
}

export type ReLoadRecordMeetingLinkMutationVariables = Exact<{
  resourceInInsightEngineId: Scalars['ID']['input']
}>

export type ReLoadRecordMeetingLinkMutation = {
  __typename?: 'Mutation'
  reLoadRecordMeetingLink: string
}

export type ReplaceTranscriptUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  preName?: InputMaybe<Scalars['String']['input']>
  replaceAll?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ReplaceTranscriptUserMutation = {
  __typename?: 'Mutation'
  replaceTranscriptUser?: null | null
}

export type SearchBlurBlockQueryVariables = Exact<{
  resourceInInsightEngineId: Scalars['ID']['input']
}>

export type SearchBlurBlockQuery = {
  __typename?: 'Query'
  searchBlurBlock: Array<{
    __typename?: 'BlurBlock'
    blur: number
    startTime: number
    endTime: number
    borderRadius: number
    blockWidth: number
    blockHeight: number
    top: number
    left: number
    resourceInInsightEngineId: string
    id: string
    createdAt: Date
  }>
}

export type SearchChatbotMsgQueryVariables = Exact<{
  type: Scalars['String']['input']
  filter?: InputMaybe<ChatbotMsgFilter>
  order?: InputMaybe<Array<ChatbotMsgOrderBy> | ChatbotMsgOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchChatbotMsgQuery = {
  __typename?: 'Query'
  searchChatbotMsg: Array<{
    __typename?: 'ChatbotMsg'
    contextId: string
    content: string
    isChatBot: boolean
    status: string
    id: string
    createdAt: Date
    context?: { __typename?: 'ChatbotContext'; name: string; id: string } | null
    owner?: { __typename?: 'User'; name: string; avatarUrl: string } | null
  }>
}

export type SearchMuteQueryVariables = Exact<{
  filter?: InputMaybe<MuteFilter>
  order?: InputMaybe<Array<MuteOrderBy> | MuteOrderBy>
  page?: InputMaybe<Pagination>
  resourceInInsightEngineId: Scalars['ID']['input']
}>

export type SearchMuteQuery = {
  __typename?: 'Query'
  searchMute: Array<{
    __typename?: 'Mute'
    id: string
    startTime: number
    endTime: number
  }>
}

export type SearchResourceInInsightEngineQueryVariables = Exact<{
  insightEngineId: Scalars['ID']['input']
  filter?: InputMaybe<ResourceInInsightEngineFilter>
  order?: InputMaybe<
    Array<ResourceInInsightEngineOrderBy> | ResourceInInsightEngineOrderBy
  >
  page?: InputMaybe<Pagination>
}>

export type SearchResourceInInsightEngineQuery = {
  __typename?: 'Query'
  searchResourceInInsightEngine: Array<{
    __typename?: 'ResourceInInsightEngine'
    id: string
    insightEngineId: string
    resourceId: string
    note: string
    isFromBot: boolean
    isDownloading: boolean
    status: string
    resource?: {
      __typename?: 'Resource'
      id: string
      name: string
      duration: number
      url: string
      thumbnailResource?: { __typename?: 'Resource'; url: string } | null
    } | null
  }>
}

export type SearchTranscriptQueryVariables = Exact<{
  resourceInInsightEngineId: Scalars['ID']['input']
  filter?: InputMaybe<TranscriptFilter>
  order?: InputMaybe<Array<TranscriptOrderBy> | TranscriptOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchTranscriptQuery = {
  __typename?: 'Query'
  searchTranscript: Array<{
    __typename?: 'Transcript'
    id: string
    content: string
    nameFromRevAi: string
    startTime: number
    endTime: number
    speaker?: {
      __typename?: 'User'
      id: string
      name: string
      avatarUrl: string
    } | null
  }>
}

export type SearchUserInResourceQueryVariables = Exact<{
  filter?: InputMaybe<UserInResourceFilter>
  order?: InputMaybe<Array<UserInResourceOrderBy> | UserInResourceOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchUserInResourceQuery = {
  __typename?: 'Query'
  searchUserInResource: Array<{
    __typename?: 'UserInResource'
    userId: string
    resourceId: string
    type: string
    isDownloading: boolean
    id: string
    createdAt: Date
  }>
}

export type StartTranscriptMutationVariables = Exact<{
  resourceInInsightEngineId: Scalars['ID']['input']
}>

export type StartTranscriptMutation = {
  __typename?: 'Mutation'
  startTranscript?: null | null
}

export type UpdateBlurBlockMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateBlurBlock
}>

export type UpdateBlurBlockMutation = {
  __typename?: 'Mutation'
  updateBlurBlock: {
    __typename?: 'BlurBlock'
    resourceInInsightEngineId: string
  }
}

export type UpdateInsightEngineMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateInsightEngine
}>

export type UpdateInsightEngineMutation = {
  __typename?: 'Mutation'
  updateInsightEngine: { __typename?: 'InsightEngine'; name: string }
}

export type UpdateMuteMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateMute
}>

export type UpdateMuteMutation = {
  __typename?: 'Mutation'
  updateMute: { __typename?: 'Mute'; startTime: number; endTime: number }
}

export type UpdateResourceInIeMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateResourceInInsightEngine
}>

export type UpdateResourceInIeMutation = {
  __typename?: 'Mutation'
  updateResourceInInsightEngine: {
    __typename?: 'ResourceInInsightEngine'
    id: string
    status: string
  }
}

export type UpdateTranscriptMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateTranscript
}>

export type UpdateTranscriptMutation = {
  __typename?: 'Mutation'
  updateTranscript: {
    __typename?: 'Transcript'
    content: string
    resourceInInsightEngineId: string
  }
}

export type ResourceInfoFragment = {
  __typename?: 'Resource'
  name: string
  url: string
  fileSize: number
  duration: number
  originalResourceId?: string | null
  originalResourceState?: any | null
}

export type UserFragment = {
  __typename?: 'User'
  id: string
  name: string
  rootOrgId: string
  email: string
  socialLogin: string
  avatarUrl: string
  referralById?: string | null
  currentOrgId: string
  theme: string
  themeWarmness: number
  studioSettings?: any | null
  blockedBySystemAdmin: boolean
}

export type AuthInfoFragment = {
  __typename?: 'AuthSession'
  id: string
  secret: string
  user?: {
    __typename?: 'User'
    id: string
    name: string
    rootOrgId: string
    email: string
    socialLogin: string
    avatarUrl: string
    referralById?: string | null
    currentOrgId: string
    theme: string
    themeWarmness: number
    studioSettings?: any | null
    blockedBySystemAdmin: boolean
  } | null
}

export type LoginMutationVariables = Exact<{
  email: Scalars['Email']['input']
  password: Scalars['NeString']['input']
  dns?: InputMaybe<Scalars['String']['input']>
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'AuthSession'
    id: string
    secret: string
    user?: {
      __typename?: 'User'
      id: string
      name: string
      rootOrgId: string
      email: string
      socialLogin: string
      avatarUrl: string
      referralById?: string | null
      currentOrgId: string
      theme: string
      themeWarmness: number
      studioSettings?: any | null
      blockedBySystemAdmin: boolean
    } | null
  }
}

export type RnLoginMutationVariables = Exact<{
  email: Scalars['Email']['input']
  password: Scalars['NeString']['input']
}>

export type RnLoginMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'AuthSession'
    id: string
    secret: string
    user?: {
      __typename?: 'User'
      id: string
      name: string
      rootOrgId: string
      email: string
      socialLogin: string
      avatarUrl: string
      referralById?: string | null
      currentOrgId: string
      theme: string
      themeWarmness: number
      studioSettings?: any | null
      blockedBySystemAdmin: boolean
    } | null
  }
}

export type LoginWithSocialMutationVariables = Exact<{
  code: Scalars['NeString']['input']
  type: LoginWithSocialType
  referralById?: InputMaybe<Scalars['ID']['input']>
  dns?: InputMaybe<Scalars['String']['input']>
  redirectUrl: Scalars['String']['input']
}>

export type LoginWithSocialMutation = {
  __typename?: 'Mutation'
  loginWithSocial: {
    __typename?: 'AuthSession'
    id: string
    secret: string
    user?: {
      __typename?: 'User'
      id: string
      name: string
      rootOrgId: string
      email: string
      socialLogin: string
      avatarUrl: string
      referralById?: string | null
      currentOrgId: string
      theme: string
      themeWarmness: number
      studioSettings?: any | null
      blockedBySystemAdmin: boolean
    } | null
  }
}

export type MarkLbInstanceAsStaleMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type MarkLbInstanceAsStaleMutation = {
  __typename?: 'Mutation'
  markLbInstanceAsStale?: null | null
}

export type SearchLbInstanceQueryVariables = Exact<{
  filter?: InputMaybe<LbInstanceFilter>
  order?: InputMaybe<Array<LbInstanceOrderBy> | LbInstanceOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchLbInstanceQuery = {
  __typename?: 'Query'
  countLbInstance: number
  searchLbInstance: Array<{
    __typename?: 'LbInstance'
    id: string
    type: string
    status: string
    version: string
    name: string
    namespace: string
    cpu: number
    memory: number
    url: string
    inuse: boolean
    inuseInput: boolean
    inuseOutput: boolean
    mediasoupPeers: number
    createdAt: Date
  }>
}

export type SearchSessionInLbQueryVariables = Exact<{
  lbId: Scalars['ID']['input']
  filter?: InputMaybe<SessionFilter>
  order?: InputMaybe<Array<SessionOrderBy> | SessionOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchSessionInLbQuery = {
  __typename?: 'Query'
  searchSessionInLb: Array<{
    __typename?: 'Session'
    id: string
    title: string
    startTime: Date
    endTime: Date
    createdBy?: { __typename?: 'User'; name: string } | null
  }>
}

export type PlansQueryVariables = Exact<{ [key: string]: never }>

export type PlansQuery = {
  __typename?: 'Query'
  plans: Array<{
    __typename?: 'Plan'
    name: PlanName
    maxParticipants: number
    freeMinutesPerMonth: string
    freeStoragePerMonth: string
    prices: Array<{
      __typename?: 'PlanPrice'
      priceMinutes?: number | null
      price: number
      currency: PlanCurrency
      interval: PlanInterval
      priceStorage?: number | null
      priceMinutesTiers?: Array<{
        __typename?: 'PlanPriceTier'
        from: number
        to?: number | null
        price: number
      }> | null
      priceStorageTiers?: Array<{
        __typename?: 'PlanPriceTier'
        from: number
        to?: number | null
        price: number
      }> | null
    }>
  }>
}

export type DeleteSessionMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteSessionMutation = {
  __typename?: 'Mutation'
  deleteSession?: null | null
}

export type DetailSessionQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DetailSessionQuery = {
  __typename?: 'Query'
  detailSession: {
    __typename?: 'Session'
    id: string
    orgId: string
    createdById: string
    title: string
    description: string
    settings?: any | null
    layout?: any | null
    startTime: Date
    endTime: Date
    hasRecording: boolean
  }
}

export type LbMediasoupUrlQueryVariables = Exact<{
  sessionId: Scalars['ID']['input']
}>

export type LbMediasoupUrlQuery = {
  __typename?: 'Query'
  lbMediasoupUrl?: string | null
}

export type SessionRoleQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SessionRoleQuery = {
  __typename?: 'Query'
  sessionRole?: {
    __typename?: 'SessionRole'
    permissions: Record<SessionPermission, boolean>
    name: SessionRoleName
  } | null
}

export type SearchResourceInSessionQueryVariables = Exact<{
  sessionId: Scalars['ID']['input']
  filter?: InputMaybe<ResourceInSessionFilter>
  order?: InputMaybe<Array<ResourceInSessionOrderBy> | ResourceInSessionOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchResourceInSessionQuery = {
  __typename?: 'Query'
  searchResourceInSession: Array<{
    __typename?: 'ResourceInSession'
    type: string
    sessionId: string
    resourceId: string
    position: number
    createdById?: string | null
    id: string
    createdAt: Date
    resource?: {
      __typename?: 'Resource'
      id: string
      orgId?: string | null
      type: string
      name: string
      url: string
      fileSize: number
      originalResourceId?: string | null
      originalResourceState?: any | null
      duration: number
      ffprobe?: any | null
      thumbnailResourceId?: string | null
      createdAt: Date
      createdById?: string | null
      thumbnailResource?: { __typename?: 'Resource'; url: string } | null
    } | null
  }>
}

export type CountSessionQueryVariables = Exact<{
  filter?: InputMaybe<SessionFilter>
}>

export type CountSessionQuery = { __typename?: 'Query'; countSession: number }

export type DeleteInsightEngineMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteInsightEngineMutation = {
  __typename?: 'Mutation'
  deleteInsightEngine?: null | null
}

export type SearchInsightEngineQueryVariables = Exact<{
  filter?: InputMaybe<InsightEngineFilter>
  order?: InputMaybe<Array<InsightEngineOrderBy> | InsightEngineOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchInsightEngineQuery = {
  __typename?: 'Query'
  searchInsightEngine: Array<{
    __typename?: 'InsightEngine'
    id: string
    name: string
    createdAt: Date
    createdBy?: { __typename?: 'User'; id: string; name: string } | null
  }>
}

export type SessionInfoFragment = {
  __typename?: 'Session'
  id: string
  orgId: string
  createdById: string
  title: string
  description: string
  settings?: any | null
  layout?: any | null
  startTime: Date
  endTime: Date
  hasRecording: boolean
}

export type ResourceFragment = {
  __typename?: 'Resource'
  id: string
  orgId?: string | null
  type: string
  name: string
  url: string
  fileSize: number
  originalResourceId?: string | null
  originalResourceState?: any | null
  duration: number
  ffprobe?: any | null
  thumbnailResourceId?: string | null
  createdAt: Date
  createdById?: string | null
  thumbnailResource?: { __typename?: 'Resource'; url: string } | null
}

export type ResourceInSessionFragment = {
  __typename?: 'ResourceInSession'
  type: string
  sessionId: string
  resourceId: string
  position: number
  createdById?: string | null
  id: string
  createdAt: Date
  resource?: {
    __typename?: 'Resource'
    id: string
    orgId?: string | null
    type: string
    name: string
    url: string
    fileSize: number
    originalResourceId?: string | null
    originalResourceState?: any | null
    duration: number
    ffprobe?: any | null
    thumbnailResourceId?: string | null
    createdAt: Date
    createdById?: string | null
    thumbnailResource?: { __typename?: 'Resource'; url: string } | null
  } | null
}

export type SearchSessionQueryVariables = Exact<{
  filter?: InputMaybe<SessionFilter>
  order?: InputMaybe<Array<SessionOrderBy> | SessionOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchSessionQuery = {
  __typename?: 'Query'
  searchSession: Array<{
    __typename?: 'Session'
    id: string
    orgId: string
    createdById: string
    title: string
    description: string
    settings?: any | null
    layout?: any | null
    startTime: Date
    endTime: Date
    hasRecording: boolean
  }>
}

export type RegisterMutationVariables = Exact<{
  data: Register
}>

export type RegisterMutation = { __typename?: 'Mutation'; register: string }

export type ResolveRegisterMutationVariables = Exact<{
  data: CheckTicket
}>

export type ResolveRegisterMutation = {
  __typename?: 'Mutation'
  resolveRegister: {
    __typename?: 'AuthSession'
    id: string
    secret: string
    user?: {
      __typename?: 'User'
      id: string
      name: string
      rootOrgId: string
      email: string
      socialLogin: string
      avatarUrl: string
      referralById?: string | null
      currentOrgId: string
      theme: string
      themeWarmness: number
      studioSettings?: any | null
      blockedBySystemAdmin: boolean
    } | null
  }
}

export type AnswerJoinRequestMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  waitingUserId: Scalars['ID']['input']
  isAccepted: Scalars['Boolean']['input']
}>

export type AnswerJoinRequestMutation = {
  __typename?: 'Mutation'
  answerJoinRequest?: null | null
}

export type ChangeMixerRatioMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  ratio: Scalars['Float']['input']
  viewmode: Scalars['NeString']['input']
}>

export type ChangeMixerRatioMutation = {
  __typename?: 'Mutation'
  changeMixerRatio?: null | null
}

export type CreateInsightEngineMutationVariables = Exact<{
  data: CreateInsightEngine
}>

export type CreateInsightEngineMutation = {
  __typename?: 'Mutation'
  createInsightEngine: {
    __typename?: 'InsightEngine'
    id: string
    name: string
    createdById?: string | null
  }
}

export type CreateNoteMutationVariables = Exact<{
  data: CreateNote
}>

export type CreateNoteMutation = {
  __typename?: 'Mutation'
  createNote: {
    __typename?: 'Note'
    id: string
    orgId: string
    sessionId: string
    createdById: string
    content: string
  }
}

export type CreateOutputMutationVariables = Exact<{
  data: CreateOutput
}>

export type CreateOutputMutation = {
  __typename?: 'Mutation'
  createOutput: {
    __typename?: 'Output'
    id: string
    orgId: string
    type: string
    name: string
    data?: any | null
    createdAt: Date
    avatarUrl: string
    expiredAt?: Date | null
  }
}

export type CreateOutputInSessionMutationVariables = Exact<{
  data: CreateOutputInSession
}>

export type CreateOutputInSessionMutation = {
  __typename?: 'Mutation'
  createOutputInSession: {
    __typename?: 'OutputInSession'
    id: string
    title: string
    description: string
    thumbnail?: { __typename?: 'Resource'; url: string } | null
  }
}

export type CreateRecordingOutputInSessionMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  mixerQuery?: InputMaybe<Scalars['String']['input']>
  roomType: Scalars['NeString']['input']
}>

export type CreateRecordingOutputInSessionMutation = {
  __typename?: 'Mutation'
  createRecordingOutputInSession?: null | null
}

export type CreateResourceInInsightEngineMutationVariables = Exact<{
  data: CreateResourceInInsightEngine
}>

export type CreateResourceInInsightEngineMutation = {
  __typename?: 'Mutation'
  createResourceInInsightEngine: {
    __typename?: 'ResourceInInsightEngine'
    id: string
    insightEngineId: string
    resourceId: string
    note: string
    isFromBot: boolean
    isDownloading: boolean
    status: string
    resource?: {
      __typename?: 'Resource'
      id: string
      name: string
      duration: number
      url: string
      thumbnailResource?: { __typename?: 'Resource'; url: string } | null
    } | null
  }
}

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteNoteMutation = {
  __typename?: 'Mutation'
  deleteNote?: null | null
}

export type DeleteOutputMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteOutputMutation = {
  __typename?: 'Mutation'
  deleteOutput?: null | null
}

export type DeleteOutputInSessionMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteOutputInSessionMutation = {
  __typename?: 'Mutation'
  deleteOutputInSession?: null | null
}

export type DeleteUserInSessionMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  sessionId: Scalars['ID']['input']
}>

export type DeleteUserInSessionMutation = {
  __typename?: 'Mutation'
  deleteUserInSession?: null | null
}

export type InviteUserInSessionMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  observers?: InputMaybe<
    Array<Scalars['Email']['input']> | Scalars['Email']['input']
  >
  participants?: InputMaybe<
    Array<Scalars['Email']['input']> | Scalars['Email']['input']
  >
}>

export type InviteUserInSessionMutation = {
  __typename?: 'Mutation'
  inviteUserInSession?: null | null
}

export type JoinFromInviteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type JoinFromInviteMutation = {
  __typename?: 'Mutation'
  joinFromInvite?: null | null
}

export type ReauthenticateOutputMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: ReauthenticateOutputData
}>

export type ReauthenticateOutputMutation = {
  __typename?: 'Mutation'
  reauthenticateOutput: { __typename?: 'Output'; id: string }
}

export type RequestJoinMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  socketId: Scalars['ID']['input']
  sessionRole: SessionRoleName
}>

export type RequestJoinMutation = {
  __typename?: 'Mutation'
  requestJoin: string
}

export type SearchInsightEngineOfResourceQueryVariables = Exact<{
  resourceId: Scalars['ID']['input']
  filter?: InputMaybe<ResourceInInsightEngineFilter>
  order?: InputMaybe<
    Array<ResourceInInsightEngineOrderBy> | ResourceInInsightEngineOrderBy
  >
  page?: InputMaybe<Pagination>
}>

export type SearchInsightEngineOfResourceQuery = {
  __typename?: 'Query'
  searchInsightEngineOfResource: Array<{
    __typename?: 'ResourceInInsightEngine'
    id: string
    insightEngineId: string
  }>
}

export type SearchNoteQueryVariables = Exact<{
  sessionId: Scalars['ID']['input']
  filter?: InputMaybe<NoteFilter>
  order?: InputMaybe<Array<NoteOrderBy> | NoteOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchNoteQuery = {
  __typename?: 'Query'
  searchNote: Array<{
    __typename?: 'Note'
    id: string
    content: string
    createdBy?: { __typename?: 'User'; name: string; avatarUrl: string } | null
  }>
}

export type OutputFragment = {
  __typename?: 'Output'
  id: string
  orgId: string
  type: string
  name: string
  data?: any | null
  createdAt: Date
  avatarUrl: string
  expiredAt?: Date | null
}

export type SearchOutputQueryVariables = Exact<{
  sessionId: Scalars['ID']['input']
  filter?: InputMaybe<OutputFilter>
  order?: InputMaybe<Array<OutputOrderBy> | OutputOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchOutputQuery = {
  __typename?: 'Query'
  searchOutput: Array<{
    __typename?: 'Output'
    id: string
    orgId: string
    type: string
    name: string
    data?: any | null
    createdAt: Date
    avatarUrl: string
    expiredAt?: Date | null
  }>
}

export type OutputInSessionFragment = {
  __typename?: 'OutputInSession'
  id: string
  sessionId: string
  outputId: string
  mixerQuery: string
  status: string
  publishingAt?: Date | null
  createdAt: Date
  description: string
  title: string
  output?: { __typename?: 'Output'; type: string } | null
  thumbnail?: { __typename?: 'Resource'; url: string } | null
}

export type SearchOutputInSessionQueryVariables = Exact<{
  sessionId: Scalars['ID']['input']
  filter?: InputMaybe<OutputInSessionFilter>
  order?: InputMaybe<Array<OutputInSessionOrderBy> | OutputInSessionOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchOutputInSessionQuery = {
  __typename?: 'Query'
  searchOutputInSession: Array<{
    __typename?: 'OutputInSession'
    id: string
    sessionId: string
    outputId: string
    mixerQuery: string
    status: string
    publishingAt?: Date | null
    createdAt: Date
    description: string
    title: string
    output?: { __typename?: 'Output'; type: string } | null
    thumbnail?: { __typename?: 'Resource'; url: string } | null
  }>
}

export type UserInSessionFragment = {
  __typename?: 'UserInSession'
  id: string
  role: string
  userId: string
  user?: {
    __typename?: 'User'
    email: string
    name: string
    avatarUrl: string
  } | null
}

export type SearchUserInSessionQueryVariables = Exact<{
  sessionId: Scalars['ID']['input']
  filter?: InputMaybe<UserInSessionFilter>
  order?: InputMaybe<Array<UserInSessionOrderBy> | UserInSessionOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchUserInSessionQuery = {
  __typename?: 'Query'
  searchUserInSession: Array<{
    __typename?: 'UserInSession'
    id: string
    role: string
    userId: string
    user?: {
      __typename?: 'User'
      email: string
      name: string
      avatarUrl: string
    } | null
  }>
}

export type SearchWaitingUserQueryVariables = Exact<{
  sessionId: Scalars['ID']['input']
  filter?: InputMaybe<WaitingUserFilter>
  order?: InputMaybe<Array<WaitingUserOrderBy> | WaitingUserOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchWaitingUserQuery = {
  __typename?: 'Query'
  searchWaitingUser: Array<{
    __typename?: 'WaitingUser'
    id: string
    sessionRole: string
    user?: { __typename?: 'User'; avatarUrl: string; name: string } | null
  }>
}

export type SessionPlanQueryVariables = Exact<{
  sessionId: Scalars['ID']['input']
}>

export type SessionPlanQuery = {
  __typename?: 'Query'
  sessionPlan: {
    __typename?: 'CurrentPlan'
    bonusStorage: number
    boughtStorage: number
    freeStorage: string
    bonusMinutesUsed: number
    bonusMinutes: number
    boughtMinutesUsed: number
    boughtMinutes: number
    freeMinutesUsed: number
    freeMinutes: string
    remainingStorage: string
    remainingMinutes: string
    totalStorageUsed: number
    totalMinutesUsed: number
    totalStorage: string
    totalMinutes: string
    plan: PlanName
    subscription?: {
      __typename?: 'Subscription'
      nextInvoiceAt?: Date | null
      recurrence: boolean
      totalStoragePrice: number
      boughtStorage: number
      mainPrice: number
      totalPrice: number
      currency: PlanCurrency
      interval: PlanInterval
      status: SubscriptionStatus
    } | null
  }
}

export type StartOutputsMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  outputInSessionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
}>

export type StartOutputsMutation = {
  __typename?: 'Mutation'
  startOutputs?: null | null
}

export type StartRecordingMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  queries?: InputMaybe<
    Array<Scalars['NeString']['input']> | Scalars['NeString']['input']
  >
  roomType?: InputMaybe<Scalars['String']['input']>
}>

export type StartRecordingMutation = {
  __typename?: 'Mutation'
  startRecording?: null | null
}

export type StopOutputsMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  outputInSessionIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
}>

export type StopOutputsMutation = {
  __typename?: 'Mutation'
  stopOutputs?: null | null
}

export type StopRecordingMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  queries?: InputMaybe<
    Array<Scalars['NeString']['input']> | Scalars['NeString']['input']
  >
  roomType?: InputMaybe<Scalars['String']['input']>
}>

export type StopRecordingMutation = {
  __typename?: 'Mutation'
  stopRecording?: null | null
}

export type SwitchRoleMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  role: Scalars['String']['input']
}>

export type SwitchRoleMutation = {
  __typename?: 'Mutation'
  switchRole?: null | null
}

export type UpdateNoteMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateNote
}>

export type UpdateNoteMutation = {
  __typename?: 'Mutation'
  updateNote: {
    __typename?: 'Note'
    id: string
    orgId: string
    sessionId: string
    createdById: string
    content: string
  }
}

export type UpdateOutputInSessionMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: UpdateOutputInSession
}>

export type UpdateOutputInSessionMutation = {
  __typename?: 'Mutation'
  updateOutputInSession: {
    __typename?: 'OutputInSession'
    id: string
    title: string
    description: string
    thumbnail?: { __typename?: 'Resource'; url: string } | null
  }
}

export type UpdateOutputMutationVariables = Exact<{
  sessionId: Scalars['ID']['input']
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['NeString']['input']>
  data?: InputMaybe<Scalars['Json']['input']>
}>

export type UpdateOutputMutation = {
  __typename?: 'Mutation'
  updateOutput: {
    __typename?: 'Output'
    id: string
    orgId: string
    type: string
    name: string
    data?: any | null
    createdAt: Date
    avatarUrl: string
    expiredAt?: Date | null
  }
}

export type UpgradePlanMutationVariables = Exact<{
  data: UpgradePlan
}>

export type UpgradePlanMutation = {
  __typename?: 'Mutation'
  upgradePlan?: string | null
}

export type CancelSubscriptionMutationVariables = Exact<{
  orgId: Scalars['ID']['input']
}>

export type CancelSubscriptionMutation = {
  __typename?: 'Mutation'
  cancelSubscription?: null | null
}

export type BuyMoreStorageMutationVariables = Exact<{
  data: BuyMoreStorage
  at: Scalars['PInt']['input']
}>

export type BuyMoreStorageMutation = {
  __typename?: 'Mutation'
  buyMoreStorage?: string | null
}

export type BuyMoreMinutesMutationVariables = Exact<{
  data: BuyMoreMinutes
  at: Scalars['PInt']['input']
}>

export type BuyMoreMinutesMutation = {
  __typename?: 'Mutation'
  buyMoreMinutes: string
}

export type ComputedPriceFragment = {
  __typename?: 'ComputedPrice'
  at: number
  subtotal: number
  proration: number
  discount: number
  total: number
}

export type BuyMoreStorageComputeQueryVariables = Exact<{
  data: BuyMoreStorage
}>

export type BuyMoreStorageComputeQuery = {
  __typename?: 'Query'
  buyMoreStorageCompute: {
    __typename?: 'ComputedPrice'
    at: number
    subtotal: number
    proration: number
    discount: number
    total: number
  }
}

export type BuyMoreMinutesComputeQueryVariables = Exact<{
  data: BuyMoreMinutes
}>

export type BuyMoreMinutesComputeQuery = {
  __typename?: 'Query'
  buyMoreMinutesCompute: {
    __typename?: 'ComputedPrice'
    at: number
    subtotal: number
    proration: number
    discount: number
    total: number
  }
}

export type DeleteSubscriptionsMutationVariables = Exact<{
  orgId: Scalars['ID']['input']
}>

export type DeleteSubscriptionsMutation = {
  __typename?: 'Mutation'
  deleteSubscriptions?: null | null
}

export type SystemFlagFragment = {
  __typename?: 'SystemFlag'
  id: string
  key: string
  value: string
}

export type SearchSystemFlagQueryVariables = Exact<{
  filter?: InputMaybe<SystemFlagFilter>
  order?: InputMaybe<Array<SystemFlagOrderBy> | SystemFlagOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchSystemFlagQuery = {
  __typename?: 'Query'
  countSystemFlag: number
  searchSystemFlag: Array<{
    __typename?: 'SystemFlag'
    id: string
    key: string
    value: string
  }>
}

export type UpdateSystemFlagsMutationVariables = Exact<{
  data: Array<UpdateSystemFlag> | UpdateSystemFlag
}>

export type UpdateSystemFlagsMutation = {
  __typename?: 'Mutation'
  updateSystemFlags?: null | null
}

export type DeleteOrgInviteTicketMutationVariables = Exact<{
  orgId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}>

export type DeleteOrgInviteTicketMutation = {
  __typename?: 'Mutation'
  deleteOrgInviteTicket?: null | null
}

export type DeleteUserInOrgMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteUserInOrgMutation = {
  __typename?: 'Mutation'
  deleteUserInOrg?: null | null
}

export type SendOrgInvitationMutationVariables = Exact<{
  orgId: Scalars['ID']['input']
  emails: Array<Scalars['Email']['input']> | Scalars['Email']['input']
}>

export type SendOrgInvitationMutation = {
  __typename?: 'Mutation'
  sendOrgInvitation?: null | null
}

export type SearchUserInOrgQueryVariables = Exact<{
  orgId: Scalars['ID']['input']
  filter?: InputMaybe<UserInOrgFilter>
  order?: InputMaybe<Array<UserInOrgOrderBy> | UserInOrgOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SearchUserInOrgQuery = {
  __typename?: 'Query'
  countUserInOrg: number
  searchUserInOrg: Array<{
    __typename?: 'UserInOrg'
    userId: string
    orgId: string
    role: string
    id: string
    createdAt: Date
    user?: {
      __typename?: 'User'
      id: string
      name: string
      email: string
      avatarUrl: string
    } | null
  }>
}

export type SystemSearchOrgQueryVariables = Exact<{
  filter?: InputMaybe<OrgFilter>
  order?: InputMaybe<Array<OrgOrderBy> | OrgOrderBy>
  page?: InputMaybe<Pagination>
}>

export type SystemSearchOrgQuery = {
  __typename?: 'Query'
  systemCountOrg: number
  systemSearchOrg: Array<{
    __typename?: 'Org'
    id: string
    name: string
    usersCount: number
    settings?: any | null
    subscriptionPlan: string
    systemLogoResource?: {
      __typename?: 'Resource'
      name: string
      url: string
      fileSize: number
      duration: number
      originalResourceId?: string | null
      originalResourceState?: any | null
    } | null
    createdBy?: {
      __typename?: 'User'
      id: string
      name: string
      avatarUrl: string
      blockedBySystemAdmin: boolean
    } | null
  }>
}

export type SearchReportsQueryVariables = Exact<{
  filter?: InputMaybe<SearchReportFilter>
}>

export type SearchReportsQuery = {
  __typename?: 'Query'
  searchReports: {
    __typename?: 'Report'
    totalMinutesOfVideo: number
    totalNumberOfLogin: number
    totalOfCompletedSession: number
  }
}

export const CurrentPlanFragmentDoc = gql`
  fragment currentPlan on CurrentPlan {
    subscription {
      nextInvoiceAt
      recurrence
      totalStoragePrice
      boughtStorage
      mainPrice
      totalPrice
      currency
      interval
      status
    }
    bonusStorage
    boughtStorage
    freeStorage
    bonusMinutesUsed
    bonusMinutes
    boughtMinutesUsed
    boughtMinutes
    freeMinutesUsed
    freeMinutes
    remainingStorage
    remainingMinutes
    totalStorageUsed
    totalMinutesUsed
    totalStorage
    totalMinutes
    plan
  }
`
export const ResourceInfoFragmentDoc = gql`
  fragment resourceInfo on Resource {
    name
    url
    fileSize
    duration
    originalResourceId
    originalResourceState
  }
`
export const OrgInfoFragmentDoc = gql`
  fragment orgInfo on Org {
    id
    name
    settings
    systemLogoResource {
      ...resourceInfo
    }
    coverPhotoResource {
      ...resourceInfo
    }
    dns
    dnsValidation
  }
  ${ResourceInfoFragmentDoc}
`
export const OrgInfoAdminFragmentDoc = gql`
  fragment orgInfoAdmin on Org {
    id
    name
    usersCount
    systemLogoResource {
      ...resourceInfo
    }
    settings
    subscriptionPlan
    createdBy {
      id
      name
      avatarUrl
      blockedBySystemAdmin
    }
  }
  ${ResourceInfoFragmentDoc}
`
export const AmsFragmentDoc = gql`
  fragment ams on Ams {
    id
    sessionId
    active
    repeat
    sync
    session {
      id
      title
      description
      hasRecording
    }
  }
`
export const AmsResourceFragmentDoc = gql`
  fragment amsResource on Resource {
    id
    type
    name
    url
    fileSize
    sessionId
    originalResourceId
    originalResourceState
    duration
    createdById
  }
`
export const ResourceInAmsFragmentDoc = gql`
  fragment resourceInAms on ResourceInAms {
    id
    amsId
    resourceId
    position
    repeat
    volume
    muted
    createdAt
    ams {
      id
    }
    resource {
      ...amsResource
    }
  }
  ${AmsResourceFragmentDoc}
`
export const UserFragmentDoc = gql`
  fragment user on User {
    id
    name
    rootOrgId
    email
    socialLogin
    avatarUrl
    referralById
    currentOrgId
    theme
    socialLogin
    themeWarmness
    studioSettings
    avatarUrl
    blockedBySystemAdmin
  }
`
export const AuthInfoFragmentDoc = gql`
  fragment authInfo on AuthSession {
    id
    secret
    user {
      ...user
    }
  }
  ${UserFragmentDoc}
`
export const SessionInfoFragmentDoc = gql`
  fragment sessionInfo on Session {
    id
    orgId
    createdById
    title
    description
    settings
    layout
    startTime
    endTime
    hasRecording
  }
`
export const ResourceFragmentDoc = gql`
  fragment resource on Resource {
    id
    orgId
    type
    name
    url
    fileSize
    originalResourceId
    originalResourceState
    duration
    ffprobe
    thumbnailResourceId
    thumbnailResource {
      url
    }
    createdAt
    createdById
  }
`
export const ResourceInSessionFragmentDoc = gql`
  fragment resourceInSession on ResourceInSession {
    type
    sessionId
    resourceId
    position
    createdById
    id
    createdAt
    resource {
      ...resource
    }
  }
  ${ResourceFragmentDoc}
`
export const OutputFragmentDoc = gql`
  fragment output on Output {
    id
    orgId
    type
    name
    data
    createdAt
    avatarUrl
    expiredAt
  }
`
export const OutputInSessionFragmentDoc = gql`
  fragment outputInSession on OutputInSession {
    id
    sessionId
    outputId
    mixerQuery
    status
    publishingAt
    createdAt
    output {
      type
    }
    thumbnail {
      url
    }
    description
    title
  }
`
export const UserInSessionFragmentDoc = gql`
  fragment userInSession on UserInSession {
    id
    role
    userId
    user {
      email
      name
      avatarUrl
    }
  }
`
export const ComputedPriceFragmentDoc = gql`
  fragment computedPrice on ComputedPrice {
    at
    subtotal
    proration
    discount
    total
  }
`
export const SystemFlagFragmentDoc = gql`
  fragment systemFlag on SystemFlag {
    id
    key
    value
  }
`
export const GetProtooUrlDocument = gql`
  query getProtooUrl($sessionId: ID!) {
    lbMediasoupUrl(sessionId: $sessionId)
  }
`
export const useGqlGetProtooUrl = (
  options: Omit<Urql.UseQueryArgs<GetProtooUrlQueryVariables>, 'query'>,
) =>
  Urql.useQuery<GetProtooUrlQuery, GetProtooUrlQueryVariables>({
    query: GetProtooUrlDocument,
    ...options,
  })
export interface GqlOperations {
  getProtooUrl: (
    variables: GetProtooUrlQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<GetProtooUrlQuery, GetProtooUrlQueryVariables>
  >
}
interface GqlOperationsFactory {
  getProtooUrl: (
    client: Urql.Client,
  ) => (
    variables: GetProtooUrlQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<GetProtooUrlQuery, GetProtooUrlQueryVariables>
  >
}
gqlOperationsFactory.getProtooUrl = client => (variables, context) =>
  client.query(GetProtooUrlDocument, variables, context).toPromise() as any
export const CheckUserInSessionDocument = gql`
  query checkUserInSession($id: String!) {
    checkUserInSession(sessionId: $id)
  }
`
export const useGqlCheckUserInSession = (
  options: Omit<Urql.UseQueryArgs<CheckUserInSessionQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CheckUserInSessionQuery, CheckUserInSessionQueryVariables>({
    query: CheckUserInSessionDocument,
    ...options,
  })
export interface GqlOperations {
  checkUserInSession: (
    variables: CheckUserInSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CheckUserInSessionQuery,
      CheckUserInSessionQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  checkUserInSession: (
    client: Urql.Client,
  ) => (
    variables: CheckUserInSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CheckUserInSessionQuery,
      CheckUserInSessionQueryVariables
    >
  >
}
gqlOperationsFactory.checkUserInSession = client => (variables, context) =>
  client
    .query(CheckUserInSessionDocument, variables, context)
    .toPromise() as any
export const OnStudioEnterDocument = gql`
  query onStudioEnter($id: ID!) {
    sessionRole(sessionId: $id) {
      name
      permissions
    }
    detailSession(id: $id) {
      id
      title
      description
      startTime
      endTime
      settings
      insightEngineId
    }
  }
`
export const useGqlOnStudioEnter = (
  options: Omit<Urql.UseQueryArgs<OnStudioEnterQueryVariables>, 'query'>,
) =>
  Urql.useQuery<OnStudioEnterQuery, OnStudioEnterQueryVariables>({
    query: OnStudioEnterDocument,
    ...options,
  })
export interface GqlOperations {
  onStudioEnter: (
    variables: OnStudioEnterQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<OnStudioEnterQuery, OnStudioEnterQueryVariables>
  >
}
interface GqlOperationsFactory {
  onStudioEnter: (
    client: Urql.Client,
  ) => (
    variables: OnStudioEnterQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<OnStudioEnterQuery, OnStudioEnterQueryVariables>
  >
}
gqlOperationsFactory.onStudioEnter = client => (variables, context) =>
  client.query(OnStudioEnterDocument, variables, context).toPromise() as any
export const ChangePasswordDocument = gql`
  mutation changePassword($oldPassword: String, $newPassword: Password!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`
export const useGqlChangePassword = () =>
  Urql.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
  )
export interface GqlOperations {
  changePassword: (
    variables: ChangePasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ChangePasswordMutation,
      ChangePasswordMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  changePassword: (
    client: Urql.Client,
  ) => (
    variables: ChangePasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ChangePasswordMutation,
      ChangePasswordMutationVariables
    >
  >
}
gqlOperationsFactory.changePassword = client => (variables, context) =>
  client.mutation(ChangePasswordDocument, variables, context).toPromise() as any
export const CreateInputDocument = gql`
  mutation createInput($data: CreateInput!) {
    createInput(data: $data) {
      id
      status
    }
  }
`
export const useGqlCreateInput = () =>
  Urql.useMutation<CreateInputMutation, CreateInputMutationVariables>(
    CreateInputDocument,
  )
export interface GqlOperations {
  createInput: (
    variables: CreateInputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateInputMutation, CreateInputMutationVariables>
  >
}
interface GqlOperationsFactory {
  createInput: (
    client: Urql.Client,
  ) => (
    variables: CreateInputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateInputMutation, CreateInputMutationVariables>
  >
}
gqlOperationsFactory.createInput = client => (variables, context) =>
  client.mutation(CreateInputDocument, variables, context).toPromise() as any
export const DeleteInputDocument = gql`
  mutation deleteInput($id: ID!) {
    deleteInput(id: $id)
  }
`
export const useGqlDeleteInput = () =>
  Urql.useMutation<DeleteInputMutation, DeleteInputMutationVariables>(
    DeleteInputDocument,
  )
export interface GqlOperations {
  deleteInput: (
    variables: DeleteInputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteInputMutation, DeleteInputMutationVariables>
  >
}
interface GqlOperationsFactory {
  deleteInput: (
    client: Urql.Client,
  ) => (
    variables: DeleteInputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteInputMutation, DeleteInputMutationVariables>
  >
}
gqlOperationsFactory.deleteInput = client => (variables, context) =>
  client.mutation(DeleteInputDocument, variables, context).toPromise() as any
export const GenerateUserTokenDocument = gql`
  mutation generateUserToken {
    generateUserToken {
      type
      userId
      secret
      ipAddress
      userAgent
      id
      createdAt
      user {
        ...user
      }
    }
  }
  ${UserFragmentDoc}
`
export const useGqlGenerateUserToken = () =>
  Urql.useMutation<
    GenerateUserTokenMutation,
    GenerateUserTokenMutationVariables | void
  >(GenerateUserTokenDocument)
export interface GqlOperations {
  generateUserToken: (
    variables?: GenerateUserTokenMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      GenerateUserTokenMutation,
      GenerateUserTokenMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  generateUserToken: (
    client: Urql.Client,
  ) => (
    variables?: GenerateUserTokenMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      GenerateUserTokenMutation,
      GenerateUserTokenMutationVariables
    >
  >
}
gqlOperationsFactory.generateUserToken = client => (variables, context) =>
  client
    .mutation(GenerateUserTokenDocument, variables, context)
    .toPromise() as any
export const CurrentAuthSessionDocument = gql`
  query currentAuthSession {
    currentAuthSession {
      ...authInfo
    }
  }
  ${AuthInfoFragmentDoc}
`
export const useGqlCurrentAuthSession = (
  options?: Omit<Urql.UseQueryArgs<CurrentAuthSessionQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    CurrentAuthSessionQuery,
    CurrentAuthSessionQueryVariables | void
  >({ query: CurrentAuthSessionDocument, ...options })
export interface GqlOperations {
  currentAuthSession: (
    variables?: CurrentAuthSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CurrentAuthSessionQuery,
      CurrentAuthSessionQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  currentAuthSession: (
    client: Urql.Client,
  ) => (
    variables?: CurrentAuthSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CurrentAuthSessionQuery,
      CurrentAuthSessionQueryVariables
    >
  >
}
gqlOperationsFactory.currentAuthSession = client => (variables, context) =>
  client
    .query(CurrentAuthSessionDocument, variables, context)
    .toPromise() as any
export const CurrentPlanDocument = gql`
  query currentPlan($orgId: ID!) {
    currentPlan(orgId: $orgId) {
      ...currentPlan
    }
  }
  ${CurrentPlanFragmentDoc}
`
export const useGqlCurrentPlan = (
  options: Omit<Urql.UseQueryArgs<CurrentPlanQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CurrentPlanQuery, CurrentPlanQueryVariables>({
    query: CurrentPlanDocument,
    ...options,
  })
export interface GqlOperations {
  currentPlan: (
    variables: CurrentPlanQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CurrentPlanQuery, CurrentPlanQueryVariables>
  >
}
interface GqlOperationsFactory {
  currentPlan: (
    client: Urql.Client,
  ) => (
    variables: CurrentPlanQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CurrentPlanQuery, CurrentPlanQueryVariables>
  >
}
gqlOperationsFactory.currentPlan = client => (variables, context) =>
  client.query(CurrentPlanDocument, variables, context).toPromise() as any
export const DetailOrgDocument = gql`
  query detailOrg($id: ID!) {
    detailOrg(id: $id) {
      ...orgInfo
    }
  }
  ${OrgInfoFragmentDoc}
`
export const useGqlDetailOrg = (
  options: Omit<Urql.UseQueryArgs<DetailOrgQueryVariables>, 'query'>,
) =>
  Urql.useQuery<DetailOrgQuery, DetailOrgQueryVariables>({
    query: DetailOrgDocument,
    ...options,
  })
export interface GqlOperations {
  detailOrg: (
    variables: DetailOrgQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<DetailOrgQuery, DetailOrgQueryVariables>>
}
interface GqlOperationsFactory {
  detailOrg: (
    client: Urql.Client,
  ) => (
    variables: DetailOrgQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<DetailOrgQuery, DetailOrgQueryVariables>>
}
gqlOperationsFactory.detailOrg = client => (variables, context) =>
  client.query(DetailOrgDocument, variables, context).toPromise() as any
export const MyOrgInvitationsDocument = gql`
  query myOrgInvitations(
    $filter: TicketFilter
    $order: [TicketOrderBy!]
    $page: Pagination
  ) {
    myOrgInvitations(filter: $filter, order: $order, page: $page) {
      id
      type
      data
      rejected
      createdAt
      resentAt
      invitationExpired
      org {
        ...orgInfo
        createdBy {
          name
          avatarUrl
        }
      }
    }
    countMyOrgInvitations(filter: $filter)
  }
  ${OrgInfoFragmentDoc}
`
export const useGqlMyOrgInvitations = (
  options?: Omit<Urql.UseQueryArgs<MyOrgInvitationsQueryVariables>, 'query'>,
) =>
  Urql.useQuery<MyOrgInvitationsQuery, MyOrgInvitationsQueryVariables | void>({
    query: MyOrgInvitationsDocument,
    ...options,
  })
export interface GqlOperations {
  myOrgInvitations: (
    variables?: MyOrgInvitationsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<MyOrgInvitationsQuery, MyOrgInvitationsQueryVariables>
  >
}
interface GqlOperationsFactory {
  myOrgInvitations: (
    client: Urql.Client,
  ) => (
    variables?: MyOrgInvitationsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<MyOrgInvitationsQuery, MyOrgInvitationsQueryVariables>
  >
}
gqlOperationsFactory.myOrgInvitations = client => (variables, context) =>
  client.query(MyOrgInvitationsDocument, variables, context).toPromise() as any
export const MyOrgsDocument = gql`
  query myOrgs(
    $filter: UserInOrgFilter
    $order: [UserInOrgOrderBy!]
    $page: Pagination
  ) {
    myOrgs(filter: $filter, order: $order, page: $page) {
      org {
        id
        name
        systemLogoResource {
          url
        }
        dns
        dnsValidation
      }
    }
  }
`
export const useGqlMyOrgs = (
  options?: Omit<Urql.UseQueryArgs<MyOrgsQueryVariables>, 'query'>,
) =>
  Urql.useQuery<MyOrgsQuery, MyOrgsQueryVariables | void>({
    query: MyOrgsDocument,
    ...options,
  })
export interface GqlOperations {
  myOrgs: (
    variables?: MyOrgsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<MyOrgsQuery, MyOrgsQueryVariables>>
}
interface GqlOperationsFactory {
  myOrgs: (
    client: Urql.Client,
  ) => (
    variables?: MyOrgsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<MyOrgsQuery, MyOrgsQueryVariables>>
}
gqlOperationsFactory.myOrgs = client => (variables, context) =>
  client.query(MyOrgsDocument, variables, context).toPromise() as any
export const OrgInviteTicketsDocument = gql`
  query orgInviteTickets(
    $orgId: ID!
    $filter: TicketFilter
    $order: [TicketOrderBy!]
    $page: Pagination
  ) {
    orgInviteTickets(
      orgId: $orgId
      filter: $filter
      page: $page
      order: $order
    ) {
      id
      type
      email
      resentAt
      data
      rejected
    }
    countOrgInviteTickets(orgId: $orgId, filter: $filter)
  }
`
export const useGqlOrgInviteTickets = (
  options: Omit<Urql.UseQueryArgs<OrgInviteTicketsQueryVariables>, 'query'>,
) =>
  Urql.useQuery<OrgInviteTicketsQuery, OrgInviteTicketsQueryVariables>({
    query: OrgInviteTicketsDocument,
    ...options,
  })
export interface GqlOperations {
  orgInviteTickets: (
    variables: OrgInviteTicketsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<OrgInviteTicketsQuery, OrgInviteTicketsQueryVariables>
  >
}
interface GqlOperationsFactory {
  orgInviteTickets: (
    client: Urql.Client,
  ) => (
    variables: OrgInviteTicketsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<OrgInviteTicketsQuery, OrgInviteTicketsQueryVariables>
  >
}
gqlOperationsFactory.orgInviteTickets = client => (variables, context) =>
  client.query(OrgInviteTicketsDocument, variables, context).toPromise() as any
export const OrgRoleDocument = gql`
  query orgRole($id: ID!) {
    orgRole(orgId: $id) {
      permissions
      name
    }
  }
`
export const useGqlOrgRole = (
  options: Omit<Urql.UseQueryArgs<OrgRoleQueryVariables>, 'query'>,
) =>
  Urql.useQuery<OrgRoleQuery, OrgRoleQueryVariables>({
    query: OrgRoleDocument,
    ...options,
  })
export interface GqlOperations {
  orgRole: (
    variables: OrgRoleQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<OrgRoleQuery, OrgRoleQueryVariables>>
}
interface GqlOperationsFactory {
  orgRole: (
    client: Urql.Client,
  ) => (
    variables: OrgRoleQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<OrgRoleQuery, OrgRoleQueryVariables>>
}
gqlOperationsFactory.orgRole = client => (variables, context) =>
  client.query(OrgRoleDocument, variables, context).toPromise() as any
export const SystemRoleDocument = gql`
  query systemRole {
    systemRole {
      permissions
      name
    }
  }
`
export const useGqlSystemRole = (
  options?: Omit<Urql.UseQueryArgs<SystemRoleQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SystemRoleQuery, SystemRoleQueryVariables | void>({
    query: SystemRoleDocument,
    ...options,
  })
export interface GqlOperations {
  systemRole: (
    variables?: SystemRoleQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SystemRoleQuery, SystemRoleQueryVariables>>
}
interface GqlOperationsFactory {
  systemRole: (
    client: Urql.Client,
  ) => (
    variables?: SystemRoleQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SystemRoleQuery, SystemRoleQueryVariables>>
}
gqlOperationsFactory.systemRole = client => (variables, context) =>
  client.query(SystemRoleDocument, variables, context).toPromise() as any
export const InitialOrgDataDocument = gql`
  query initialOrgData($orgId: ID!) {
    orgRole(orgId: $orgId) {
      permissions
      name
    }
    detailOrg(id: $orgId) {
      ...orgInfo
    }
  }
  ${OrgInfoFragmentDoc}
`
export const useGqlInitialOrgData = (
  options: Omit<Urql.UseQueryArgs<InitialOrgDataQueryVariables>, 'query'>,
) =>
  Urql.useQuery<InitialOrgDataQuery, InitialOrgDataQueryVariables>({
    query: InitialOrgDataDocument,
    ...options,
  })
export interface GqlOperations {
  initialOrgData: (
    variables: InitialOrgDataQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<InitialOrgDataQuery, InitialOrgDataQueryVariables>
  >
}
interface GqlOperationsFactory {
  initialOrgData: (
    client: Urql.Client,
  ) => (
    variables: InitialOrgDataQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<InitialOrgDataQuery, InitialOrgDataQueryVariables>
  >
}
gqlOperationsFactory.initialOrgData = client => (variables, context) =>
  client.query(InitialOrgDataDocument, variables, context).toPromise() as any
export const InitialDataUserDocument = gql`
  query initialDataUser($orgId: ID!) {
    myOrgs {
      org {
        id
        name
        systemLogoResource {
          url
        }
      }
    }
    systemRole {
      permissions
      name
    }
    plans {
      prices {
        priceMinutes
        price
        currency
        interval
        priceMinutesTiers {
          from
          to
          price
        }
        priceStorageTiers {
          from
          to
          price
        }
        priceStorage
      }
      name
      maxParticipants
      freeMinutesPerMonth
      freeStoragePerMonth
    }
    orgRole(orgId: $orgId) {
      permissions
      name
    }
    detailOrg(id: $orgId) {
      ...orgInfo
    }
    countMyOrgInvitations
  }
  ${OrgInfoFragmentDoc}
`
export const useGqlInitialDataUser = (
  options: Omit<Urql.UseQueryArgs<InitialDataUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<InitialDataUserQuery, InitialDataUserQueryVariables>({
    query: InitialDataUserDocument,
    ...options,
  })
export interface GqlOperations {
  initialDataUser: (
    variables: InitialDataUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<InitialDataUserQuery, InitialDataUserQueryVariables>
  >
}
interface GqlOperationsFactory {
  initialDataUser: (
    client: Urql.Client,
  ) => (
    variables: InitialDataUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<InitialDataUserQuery, InitialDataUserQueryVariables>
  >
}
gqlOperationsFactory.initialDataUser = client => (variables, context) =>
  client.query(InitialDataUserDocument, variables, context).toPromise() as any
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`
export const useGqlLogout = () =>
  Urql.useMutation<LogoutMutation, LogoutMutationVariables | void>(
    LogoutDocument,
  )
export interface GqlOperations {
  logout: (
    variables?: LogoutMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<LogoutMutation, LogoutMutationVariables>>
}
interface GqlOperationsFactory {
  logout: (
    client: Urql.Client,
  ) => (
    variables?: LogoutMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<LogoutMutation, LogoutMutationVariables>>
}
gqlOperationsFactory.logout = client => (variables, context) =>
  client.mutation(LogoutDocument, variables, context).toPromise() as any
export const RejectOrgInvitationDocument = gql`
  mutation rejectOrgInvitation($id: ID!) {
    rejectOrgInvitation(id: $id)
  }
`
export const useGqlRejectOrgInvitation = () =>
  Urql.useMutation<
    RejectOrgInvitationMutation,
    RejectOrgInvitationMutationVariables
  >(RejectOrgInvitationDocument)
export interface GqlOperations {
  rejectOrgInvitation: (
    variables: RejectOrgInvitationMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      RejectOrgInvitationMutation,
      RejectOrgInvitationMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  rejectOrgInvitation: (
    client: Urql.Client,
  ) => (
    variables: RejectOrgInvitationMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      RejectOrgInvitationMutation,
      RejectOrgInvitationMutationVariables
    >
  >
}
gqlOperationsFactory.rejectOrgInvitation = client => (variables, context) =>
  client
    .mutation(RejectOrgInvitationDocument, variables, context)
    .toPromise() as any
export const AcceptOrgInvitationDocument = gql`
  mutation acceptOrgInvitation($id: ID!) {
    acceptOrgInvitation(id: $id)
  }
`
export const useGqlAcceptOrgInvitation = () =>
  Urql.useMutation<
    AcceptOrgInvitationMutation,
    AcceptOrgInvitationMutationVariables
  >(AcceptOrgInvitationDocument)
export interface GqlOperations {
  acceptOrgInvitation: (
    variables: AcceptOrgInvitationMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      AcceptOrgInvitationMutation,
      AcceptOrgInvitationMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  acceptOrgInvitation: (
    client: Urql.Client,
  ) => (
    variables: AcceptOrgInvitationMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      AcceptOrgInvitationMutation,
      AcceptOrgInvitationMutationVariables
    >
  >
}
gqlOperationsFactory.acceptOrgInvitation = client => (variables, context) =>
  client
    .mutation(AcceptOrgInvitationDocument, variables, context)
    .toPromise() as any
export const SearchInputDocument = gql`
  query searchInput(
    $sessionId: ID!
    $filter: InputFilter
    $order: [InputOrderBy!]
    $page: Pagination
  ) {
    searchInput(
      sessionId: $sessionId
      filter: $filter
      order: $order
      page: $page
    ) {
      id
      sessionId
      type
      status
      name
      secret
      url
      createdAt
    }
  }
`
export const useGqlSearchInput = (
  options: Omit<Urql.UseQueryArgs<SearchInputQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchInputQuery, SearchInputQueryVariables>({
    query: SearchInputDocument,
    ...options,
  })
export interface GqlOperations {
  searchInput: (
    variables: SearchInputQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchInputQuery, SearchInputQueryVariables>
  >
}
interface GqlOperationsFactory {
  searchInput: (
    client: Urql.Client,
  ) => (
    variables: SearchInputQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchInputQuery, SearchInputQueryVariables>
  >
}
gqlOperationsFactory.searchInput = client => (variables, context) =>
  client.query(SearchInputDocument, variables, context).toPromise() as any
export const ShareReferralLinkDocument = gql`
  mutation shareReferralLink(
    $orgId: ID!
    $emails: [Email!]!
    $message: String
  ) {
    shareReferralLink(orgId: $orgId, emails: $emails, message: $message)
  }
`
export const useGqlShareReferralLink = () =>
  Urql.useMutation<
    ShareReferralLinkMutation,
    ShareReferralLinkMutationVariables
  >(ShareReferralLinkDocument)
export interface GqlOperations {
  shareReferralLink: (
    variables: ShareReferralLinkMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ShareReferralLinkMutation,
      ShareReferralLinkMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  shareReferralLink: (
    client: Urql.Client,
  ) => (
    variables: ShareReferralLinkMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ShareReferralLinkMutation,
      ShareReferralLinkMutationVariables
    >
  >
}
gqlOperationsFactory.shareReferralLink = client => (variables, context) =>
  client
    .mutation(ShareReferralLinkDocument, variables, context)
    .toPromise() as any
export const SystemAddEnterpriseDocument = gql`
  mutation systemAddEnterprise($orgId: ID!) {
    systemAddEnterprise(orgId: $orgId) {
      ...orgInfo
    }
  }
  ${OrgInfoFragmentDoc}
`
export const useGqlSystemAddEnterprise = () =>
  Urql.useMutation<
    SystemAddEnterpriseMutation,
    SystemAddEnterpriseMutationVariables
  >(SystemAddEnterpriseDocument)
export interface GqlOperations {
  systemAddEnterprise: (
    variables: SystemAddEnterpriseMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemAddEnterpriseMutation,
      SystemAddEnterpriseMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  systemAddEnterprise: (
    client: Urql.Client,
  ) => (
    variables: SystemAddEnterpriseMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemAddEnterpriseMutation,
      SystemAddEnterpriseMutationVariables
    >
  >
}
gqlOperationsFactory.systemAddEnterprise = client => (variables, context) =>
  client
    .mutation(SystemAddEnterpriseDocument, variables, context)
    .toPromise() as any
export const SystemBlockUserDocument = gql`
  mutation systemBlockUser($id: ID!) {
    systemBlockUser(id: $id) {
      name
      email
    }
  }
`
export const useGqlSystemBlockUser = () =>
  Urql.useMutation<SystemBlockUserMutation, SystemBlockUserMutationVariables>(
    SystemBlockUserDocument,
  )
export interface GqlOperations {
  systemBlockUser: (
    variables: SystemBlockUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemBlockUserMutation,
      SystemBlockUserMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  systemBlockUser: (
    client: Urql.Client,
  ) => (
    variables: SystemBlockUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemBlockUserMutation,
      SystemBlockUserMutationVariables
    >
  >
}
gqlOperationsFactory.systemBlockUser = client => (variables, context) =>
  client
    .mutation(SystemBlockUserDocument, variables, context)
    .toPromise() as any
export const SystemDeleteUserDocument = gql`
  mutation systemDeleteUser($id: ID!) {
    systemDeleteUser(id: $id)
  }
`
export const useGqlSystemDeleteUser = () =>
  Urql.useMutation<SystemDeleteUserMutation, SystemDeleteUserMutationVariables>(
    SystemDeleteUserDocument,
  )
export interface GqlOperations {
  systemDeleteUser: (
    variables: SystemDeleteUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemDeleteUserMutation,
      SystemDeleteUserMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  systemDeleteUser: (
    client: Urql.Client,
  ) => (
    variables: SystemDeleteUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemDeleteUserMutation,
      SystemDeleteUserMutationVariables
    >
  >
}
gqlOperationsFactory.systemDeleteUser = client => (variables, context) =>
  client
    .mutation(SystemDeleteUserDocument, variables, context)
    .toPromise() as any
export const SystemRemoveEnterpriseDocument = gql`
  mutation systemRemoveEnterprise($orgId: ID!) {
    systemRemoveEnterprise(orgId: $orgId) {
      ...orgInfo
    }
  }
  ${OrgInfoFragmentDoc}
`
export const useGqlSystemRemoveEnterprise = () =>
  Urql.useMutation<
    SystemRemoveEnterpriseMutation,
    SystemRemoveEnterpriseMutationVariables
  >(SystemRemoveEnterpriseDocument)
export interface GqlOperations {
  systemRemoveEnterprise: (
    variables: SystemRemoveEnterpriseMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemRemoveEnterpriseMutation,
      SystemRemoveEnterpriseMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  systemRemoveEnterprise: (
    client: Urql.Client,
  ) => (
    variables: SystemRemoveEnterpriseMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemRemoveEnterpriseMutation,
      SystemRemoveEnterpriseMutationVariables
    >
  >
}
gqlOperationsFactory.systemRemoveEnterprise = client => (variables, context) =>
  client
    .mutation(SystemRemoveEnterpriseDocument, variables, context)
    .toPromise() as any
export const SystemUnblockUserDocument = gql`
  mutation systemUnblockUser($id: ID!) {
    systemUnblockUser(id: $id) {
      name
      email
      currentOrg {
        blockedBySystemAdmin
      }
    }
  }
`
export const useGqlSystemUnblockUser = () =>
  Urql.useMutation<
    SystemUnblockUserMutation,
    SystemUnblockUserMutationVariables
  >(SystemUnblockUserDocument)
export interface GqlOperations {
  systemUnblockUser: (
    variables: SystemUnblockUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemUnblockUserMutation,
      SystemUnblockUserMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  systemUnblockUser: (
    client: Urql.Client,
  ) => (
    variables: SystemUnblockUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemUnblockUserMutation,
      SystemUnblockUserMutationVariables
    >
  >
}
gqlOperationsFactory.systemUnblockUser = client => (variables, context) =>
  client
    .mutation(SystemUnblockUserDocument, variables, context)
    .toPromise() as any
export const UpdateInputDocument = gql`
  mutation updateInput($id: ID!, $data: UpdateInput!) {
    updateInput(id: $id, data: $data) {
      id
    }
  }
`
export const useGqlUpdateInput = () =>
  Urql.useMutation<UpdateInputMutation, UpdateInputMutationVariables>(
    UpdateInputDocument,
  )
export interface GqlOperations {
  updateInput: (
    variables: UpdateInputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateInputMutation, UpdateInputMutationVariables>
  >
}
interface GqlOperationsFactory {
  updateInput: (
    client: Urql.Client,
  ) => (
    variables: UpdateInputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateInputMutation, UpdateInputMutationVariables>
  >
}
gqlOperationsFactory.updateInput = client => (variables, context) =>
  client.mutation(UpdateInputDocument, variables, context).toPromise() as any
export const UpdateOrgSettingsDocument = gql`
  mutation updateOrgSettings($id: ID!, $data: UpdateOrgSettings!) {
    updateOrgSettings(id: $id, data: $data) {
      ...orgInfo
    }
  }
  ${OrgInfoFragmentDoc}
`
export const useGqlUpdateOrgSettings = () =>
  Urql.useMutation<
    UpdateOrgSettingsMutation,
    UpdateOrgSettingsMutationVariables
  >(UpdateOrgSettingsDocument)
export interface GqlOperations {
  updateOrgSettings: (
    variables: UpdateOrgSettingsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateOrgSettingsMutation,
      UpdateOrgSettingsMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateOrgSettings: (
    client: Urql.Client,
  ) => (
    variables: UpdateOrgSettingsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateOrgSettingsMutation,
      UpdateOrgSettingsMutationVariables
    >
  >
}
gqlOperationsFactory.updateOrgSettings = client => (variables, context) =>
  client
    .mutation(UpdateOrgSettingsDocument, variables, context)
    .toPromise() as any
export const UpdateOrgSystemLogoDocument = gql`
  mutation updateOrgSystemLogo($id: ID!, $data: UpdateOrgSystemLogo!) {
    updateOrgSystemLogo(id: $id, data: $data) {
      ...orgInfo
    }
  }
  ${OrgInfoFragmentDoc}
`
export const useGqlUpdateOrgSystemLogo = () =>
  Urql.useMutation<
    UpdateOrgSystemLogoMutation,
    UpdateOrgSystemLogoMutationVariables
  >(UpdateOrgSystemLogoDocument)
export interface GqlOperations {
  updateOrgSystemLogo: (
    variables: UpdateOrgSystemLogoMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateOrgSystemLogoMutation,
      UpdateOrgSystemLogoMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateOrgSystemLogo: (
    client: Urql.Client,
  ) => (
    variables: UpdateOrgSystemLogoMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateOrgSystemLogoMutation,
      UpdateOrgSystemLogoMutationVariables
    >
  >
}
gqlOperationsFactory.updateOrgSystemLogo = client => (variables, context) =>
  client
    .mutation(UpdateOrgSystemLogoDocument, variables, context)
    .toPromise() as any
export const UpdateMyProfileDocument = gql`
  mutation updateMyProfile($data: UpdateMyProfile!) {
    updateMyProfile(data: $data) {
      ...user
    }
  }
  ${UserFragmentDoc}
`
export const useGqlUpdateMyProfile = () =>
  Urql.useMutation<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>(
    UpdateMyProfileDocument,
  )
export interface GqlOperations {
  updateMyProfile: (
    variables: UpdateMyProfileMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateMyProfileMutation,
      UpdateMyProfileMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateMyProfile: (
    client: Urql.Client,
  ) => (
    variables: UpdateMyProfileMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateMyProfileMutation,
      UpdateMyProfileMutationVariables
    >
  >
}
gqlOperationsFactory.updateMyProfile = client => (variables, context) =>
  client
    .mutation(UpdateMyProfileDocument, variables, context)
    .toPromise() as any
export const SystemSetSystemAdminDocument = gql`
  mutation systemSetSystemAdmin($userId: ID!, $enabled: Boolean!) {
    systemSetSystemAdmin(userId: $userId, enabled: $enabled)
  }
`
export const useGqlSystemSetSystemAdmin = () =>
  Urql.useMutation<
    SystemSetSystemAdminMutation,
    SystemSetSystemAdminMutationVariables
  >(SystemSetSystemAdminDocument)
export interface GqlOperations {
  systemSetSystemAdmin: (
    variables: SystemSetSystemAdminMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemSetSystemAdminMutation,
      SystemSetSystemAdminMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  systemSetSystemAdmin: (
    client: Urql.Client,
  ) => (
    variables: SystemSetSystemAdminMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemSetSystemAdminMutation,
      SystemSetSystemAdminMutationVariables
    >
  >
}
gqlOperationsFactory.systemSetSystemAdmin = client => (variables, context) =>
  client
    .mutation(SystemSetSystemAdminDocument, variables, context)
    .toPromise() as any
export const CreateAmsDocument = gql`
  mutation createAms($data: CreateAms!) {
    createAms(data: $data) {
      ...ams
    }
  }
  ${AmsFragmentDoc}
`
export const useGqlCreateAms = () =>
  Urql.useMutation<CreateAmsMutation, CreateAmsMutationVariables>(
    CreateAmsDocument,
  )
export interface GqlOperations {
  createAms: (
    variables: CreateAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateAmsMutation, CreateAmsMutationVariables>
  >
}
interface GqlOperationsFactory {
  createAms: (
    client: Urql.Client,
  ) => (
    variables: CreateAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateAmsMutation, CreateAmsMutationVariables>
  >
}
gqlOperationsFactory.createAms = client => (variables, context) =>
  client.mutation(CreateAmsDocument, variables, context).toPromise() as any
export const CreateResourceInAmsDocument = gql`
  mutation createResourceInAms($data: CreateResourceInAms!) {
    createResourceInAms(data: $data) {
      ...resourceInAms
    }
  }
  ${ResourceInAmsFragmentDoc}
`
export const useGqlCreateResourceInAms = () =>
  Urql.useMutation<
    CreateResourceInAmsMutation,
    CreateResourceInAmsMutationVariables
  >(CreateResourceInAmsDocument)
export interface GqlOperations {
  createResourceInAms: (
    variables: CreateResourceInAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateResourceInAmsMutation,
      CreateResourceInAmsMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  createResourceInAms: (
    client: Urql.Client,
  ) => (
    variables: CreateResourceInAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateResourceInAmsMutation,
      CreateResourceInAmsMutationVariables
    >
  >
}
gqlOperationsFactory.createResourceInAms = client => (variables, context) =>
  client
    .mutation(CreateResourceInAmsDocument, variables, context)
    .toPromise() as any
export const DeleteAmsDocument = gql`
  mutation deleteAms($id: ID!) {
    deleteAms(id: $id)
  }
`
export const useGqlDeleteAms = () =>
  Urql.useMutation<DeleteAmsMutation, DeleteAmsMutationVariables>(
    DeleteAmsDocument,
  )
export interface GqlOperations {
  deleteAms: (
    variables: DeleteAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteAmsMutation, DeleteAmsMutationVariables>
  >
}
interface GqlOperationsFactory {
  deleteAms: (
    client: Urql.Client,
  ) => (
    variables: DeleteAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteAmsMutation, DeleteAmsMutationVariables>
  >
}
gqlOperationsFactory.deleteAms = client => (variables, context) =>
  client.mutation(DeleteAmsDocument, variables, context).toPromise() as any
export const DeleteAmsResourceDocument = gql`
  mutation deleteAmsResource($id: ID!) {
    deleteAmsResource(id: $id)
  }
`
export const useGqlDeleteAmsResource = () =>
  Urql.useMutation<
    DeleteAmsResourceMutation,
    DeleteAmsResourceMutationVariables
  >(DeleteAmsResourceDocument)
export interface GqlOperations {
  deleteAmsResource: (
    variables: DeleteAmsResourceMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteAmsResourceMutation,
      DeleteAmsResourceMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteAmsResource: (
    client: Urql.Client,
  ) => (
    variables: DeleteAmsResourceMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteAmsResourceMutation,
      DeleteAmsResourceMutationVariables
    >
  >
}
gqlOperationsFactory.deleteAmsResource = client => (variables, context) =>
  client
    .mutation(DeleteAmsResourceDocument, variables, context)
    .toPromise() as any
export const DeleteResourceInAmsDocument = gql`
  mutation deleteResourceInAms($id: ID!) {
    deleteResourceInAms(id: $id)
  }
`
export const useGqlDeleteResourceInAms = () =>
  Urql.useMutation<
    DeleteResourceInAmsMutation,
    DeleteResourceInAmsMutationVariables
  >(DeleteResourceInAmsDocument)
export interface GqlOperations {
  deleteResourceInAms: (
    variables: DeleteResourceInAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteResourceInAmsMutation,
      DeleteResourceInAmsMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteResourceInAms: (
    client: Urql.Client,
  ) => (
    variables: DeleteResourceInAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteResourceInAmsMutation,
      DeleteResourceInAmsMutationVariables
    >
  >
}
gqlOperationsFactory.deleteResourceInAms = client => (variables, context) =>
  client
    .mutation(DeleteResourceInAmsDocument, variables, context)
    .toPromise() as any
export const DisconnectAmsDocument = gql`
  mutation disconnectAms($amsId: ID!) {
    disconnectAms(amsId: $amsId)
  }
`
export const useGqlDisconnectAms = () =>
  Urql.useMutation<DisconnectAmsMutation, DisconnectAmsMutationVariables>(
    DisconnectAmsDocument,
  )
export interface GqlOperations {
  disconnectAms: (
    variables: DisconnectAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DisconnectAmsMutation, DisconnectAmsMutationVariables>
  >
}
interface GqlOperationsFactory {
  disconnectAms: (
    client: Urql.Client,
  ) => (
    variables: DisconnectAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DisconnectAmsMutation, DisconnectAmsMutationVariables>
  >
}
gqlOperationsFactory.disconnectAms = client => (variables, context) =>
  client.mutation(DisconnectAmsDocument, variables, context).toPromise() as any
export const ReconnectAmsDocument = gql`
  mutation reconnectAms($amsId: ID!, $sessionId: ID) {
    reconnectAms(amsId: $amsId, sessionId: $sessionId) {
      ...ams
    }
  }
  ${AmsFragmentDoc}
`
export const useGqlReconnectAms = () =>
  Urql.useMutation<ReconnectAmsMutation, ReconnectAmsMutationVariables>(
    ReconnectAmsDocument,
  )
export interface GqlOperations {
  reconnectAms: (
    variables: ReconnectAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<ReconnectAmsMutation, ReconnectAmsMutationVariables>
  >
}
interface GqlOperationsFactory {
  reconnectAms: (
    client: Urql.Client,
  ) => (
    variables: ReconnectAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<ReconnectAmsMutation, ReconnectAmsMutationVariables>
  >
}
gqlOperationsFactory.reconnectAms = client => (variables, context) =>
  client.mutation(ReconnectAmsDocument, variables, context).toPromise() as any
export const SearchAmsDocument = gql`
  query searchAms(
    $filter: AmsFilter
    $order: [AmsOrderBy!]
    $page: Pagination
  ) {
    searchAms(filter: $filter, order: $order, page: $page) {
      ...ams
    }
  }
  ${AmsFragmentDoc}
`
export const useGqlSearchAms = (
  options?: Omit<Urql.UseQueryArgs<SearchAmsQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchAmsQuery, SearchAmsQueryVariables | void>({
    query: SearchAmsDocument,
    ...options,
  })
export interface GqlOperations {
  searchAms: (
    variables?: SearchAmsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SearchAmsQuery, SearchAmsQueryVariables>>
}
interface GqlOperationsFactory {
  searchAms: (
    client: Urql.Client,
  ) => (
    variables?: SearchAmsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SearchAmsQuery, SearchAmsQueryVariables>>
}
gqlOperationsFactory.searchAms = client => (variables, context) =>
  client.query(SearchAmsDocument, variables, context).toPromise() as any
export const SearchAmsResourceDocument = gql`
  query searchAmsResource(
    $filter: ResourceFilter
    $order: [ResourceOrderBy!]
    $page: Pagination
  ) {
    searchAmsResource(filter: $filter, order: $order, page: $page) {
      ...amsResource
    }
  }
  ${AmsResourceFragmentDoc}
`
export const useGqlSearchAmsResource = (
  options?: Omit<Urql.UseQueryArgs<SearchAmsResourceQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchAmsResourceQuery, SearchAmsResourceQueryVariables | void>(
    { query: SearchAmsResourceDocument, ...options },
  )
export interface GqlOperations {
  searchAmsResource: (
    variables?: SearchAmsResourceQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchAmsResourceQuery,
      SearchAmsResourceQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchAmsResource: (
    client: Urql.Client,
  ) => (
    variables?: SearchAmsResourceQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchAmsResourceQuery,
      SearchAmsResourceQueryVariables
    >
  >
}
gqlOperationsFactory.searchAmsResource = client => (variables, context) =>
  client.query(SearchAmsResourceDocument, variables, context).toPromise() as any
export const SearchResourceInAmsDocument = gql`
  query searchResourceInAms(
    $filter: ResourceInAmsFilter
    $order: [ResourceInAmsOrderBy!]
    $page: Pagination
  ) {
    searchResourceInAms(filter: $filter, order: $order, page: $page) {
      ...resourceInAms
    }
  }
  ${ResourceInAmsFragmentDoc}
`
export const useGqlSearchResourceInAms = (
  options?: Omit<Urql.UseQueryArgs<SearchResourceInAmsQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    SearchResourceInAmsQuery,
    SearchResourceInAmsQueryVariables | void
  >({ query: SearchResourceInAmsDocument, ...options })
export interface GqlOperations {
  searchResourceInAms: (
    variables?: SearchResourceInAmsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchResourceInAmsQuery,
      SearchResourceInAmsQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchResourceInAms: (
    client: Urql.Client,
  ) => (
    variables?: SearchResourceInAmsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchResourceInAmsQuery,
      SearchResourceInAmsQueryVariables
    >
  >
}
gqlOperationsFactory.searchResourceInAms = client => (variables, context) =>
  client
    .query(SearchResourceInAmsDocument, variables, context)
    .toPromise() as any
export const UpdateAmsDocument = gql`
  mutation updateAms($id: ID!, $data: UpdateAms!) {
    updateAms(id: $id, data: $data) {
      id
    }
  }
`
export const useGqlUpdateAms = () =>
  Urql.useMutation<UpdateAmsMutation, UpdateAmsMutationVariables>(
    UpdateAmsDocument,
  )
export interface GqlOperations {
  updateAms: (
    variables: UpdateAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateAmsMutation, UpdateAmsMutationVariables>
  >
}
interface GqlOperationsFactory {
  updateAms: (
    client: Urql.Client,
  ) => (
    variables: UpdateAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateAmsMutation, UpdateAmsMutationVariables>
  >
}
gqlOperationsFactory.updateAms = client => (variables, context) =>
  client.mutation(UpdateAmsDocument, variables, context).toPromise() as any
export const UpdateResourceInAmsDocument = gql`
  mutation updateResourceInAms($id: ID!, $data: UpdateResourceInAms!) {
    updateResourceInAms(id: $id, data: $data) {
      ...resourceInAms
    }
  }
  ${ResourceInAmsFragmentDoc}
`
export const useGqlUpdateResourceInAms = () =>
  Urql.useMutation<
    UpdateResourceInAmsMutation,
    UpdateResourceInAmsMutationVariables
  >(UpdateResourceInAmsDocument)
export interface GqlOperations {
  updateResourceInAms: (
    variables: UpdateResourceInAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateResourceInAmsMutation,
      UpdateResourceInAmsMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateResourceInAms: (
    client: Urql.Client,
  ) => (
    variables: UpdateResourceInAmsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateResourceInAmsMutation,
      UpdateResourceInAmsMutationVariables
    >
  >
}
gqlOperationsFactory.updateResourceInAms = client => (variables, context) =>
  client
    .mutation(UpdateResourceInAmsDocument, variables, context)
    .toPromise() as any
export const SystemDetailUserDocument = gql`
  query systemDetailUser($id: ID!) {
    systemDetailUser(id: $id) {
      id
      avatarUrl
      name
      email
      rootOrgId
      currentOrg {
        name
        systemLogoResource {
          url
        }
      }
      rootOrg {
        ...orgInfoAdmin
      }
      blockedBySystemAdmin
      createdAt
    }
  }
  ${OrgInfoAdminFragmentDoc}
`
export const useGqlSystemDetailUser = (
  options: Omit<Urql.UseQueryArgs<SystemDetailUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SystemDetailUserQuery, SystemDetailUserQueryVariables>({
    query: SystemDetailUserDocument,
    ...options,
  })
export interface GqlOperations {
  systemDetailUser: (
    variables: SystemDetailUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SystemDetailUserQuery, SystemDetailUserQueryVariables>
  >
}
interface GqlOperationsFactory {
  systemDetailUser: (
    client: Urql.Client,
  ) => (
    variables: SystemDetailUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SystemDetailUserQuery, SystemDetailUserQueryVariables>
  >
}
gqlOperationsFactory.systemDetailUser = client => (variables, context) =>
  client.query(SystemDetailUserDocument, variables, context).toPromise() as any
export const SystemSearchUserDocument = gql`
  query systemSearchUser(
    $filter: UserFilter
    $order: [UserOrderBy!]
    $page: Pagination
  ) {
    systemSearchUser(filter: $filter, page: $page, order: $order) {
      name
      email
      avatarUrl
      id
      blockedBySystemAdmin
      rootOrg {
        ...orgInfoAdmin
      }
      ...user
      rootOrg {
        ...orgInfoAdmin
      }
      userInSystem {
        role
      }
    }
    systemCountUser(filter: $filter)
  }
  ${OrgInfoAdminFragmentDoc}
  ${UserFragmentDoc}
`
export const useGqlSystemSearchUser = (
  options?: Omit<Urql.UseQueryArgs<SystemSearchUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SystemSearchUserQuery, SystemSearchUserQueryVariables | void>({
    query: SystemSearchUserDocument,
    ...options,
  })
export interface GqlOperations {
  systemSearchUser: (
    variables?: SystemSearchUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SystemSearchUserQuery, SystemSearchUserQueryVariables>
  >
}
interface GqlOperationsFactory {
  systemSearchUser: (
    client: Urql.Client,
  ) => (
    variables?: SystemSearchUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SystemSearchUserQuery, SystemSearchUserQueryVariables>
  >
}
gqlOperationsFactory.systemSearchUser = client => (variables, context) =>
  client.query(SystemSearchUserDocument, variables, context).toPromise() as any
export const SystemSearchUserInOrgDocument = gql`
  query systemSearchUserInOrg(
    $filter: UserInOrgFilter
    $order: [UserInOrgOrderBy!]
    $page: Pagination
  ) {
    systemSearchUserInOrg(filter: $filter, order: $order, page: $page) {
      userId
      orgId
      id
      user {
        name
        email
        avatarUrl
      }
      role
      org {
        name
        systemLogoResource {
          url
        }
      }
    }
    systemCountUserInOrg(filter: $filter)
  }
`
export const useGqlSystemSearchUserInOrg = (
  options?: Omit<
    Urql.UseQueryArgs<SystemSearchUserInOrgQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SystemSearchUserInOrgQuery,
    SystemSearchUserInOrgQueryVariables | void
  >({ query: SystemSearchUserInOrgDocument, ...options })
export interface GqlOperations {
  systemSearchUserInOrg: (
    variables?: SystemSearchUserInOrgQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemSearchUserInOrgQuery,
      SystemSearchUserInOrgQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  systemSearchUserInOrg: (
    client: Urql.Client,
  ) => (
    variables?: SystemSearchUserInOrgQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemSearchUserInOrgQuery,
      SystemSearchUserInOrgQueryVariables
    >
  >
}
gqlOperationsFactory.systemSearchUserInOrg = client => (variables, context) =>
  client
    .query(SystemSearchUserInOrgDocument, variables, context)
    .toPromise() as any
export const SystemViewCurrentPlanDocument = gql`
  query systemViewCurrentPlan($orgId: ID!) {
    systemViewCurrentPlan(orgId: $orgId) {
      subscription {
        nextInvoiceAt
      }
    }
  }
`
export const useGqlSystemViewCurrentPlan = (
  options: Omit<
    Urql.UseQueryArgs<SystemViewCurrentPlanQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SystemViewCurrentPlanQuery,
    SystemViewCurrentPlanQueryVariables
  >({ query: SystemViewCurrentPlanDocument, ...options })
export interface GqlOperations {
  systemViewCurrentPlan: (
    variables: SystemViewCurrentPlanQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemViewCurrentPlanQuery,
      SystemViewCurrentPlanQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  systemViewCurrentPlan: (
    client: Urql.Client,
  ) => (
    variables: SystemViewCurrentPlanQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SystemViewCurrentPlanQuery,
      SystemViewCurrentPlanQueryVariables
    >
  >
}
gqlOperationsFactory.systemViewCurrentPlan = client => (variables, context) =>
  client
    .query(SystemViewCurrentPlanDocument, variables, context)
    .toPromise() as any
export const CreateSessionDocument = gql`
  mutation createSession($data: CreateSession!) {
    createSession(data: $data) {
      ...sessionInfo
    }
  }
  ${SessionInfoFragmentDoc}
`
export const useGqlCreateSession = () =>
  Urql.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(
    CreateSessionDocument,
  )
export interface GqlOperations {
  createSession: (
    variables: CreateSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateSessionMutation, CreateSessionMutationVariables>
  >
}
interface GqlOperationsFactory {
  createSession: (
    client: Urql.Client,
  ) => (
    variables: CreateSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateSessionMutation, CreateSessionMutationVariables>
  >
}
gqlOperationsFactory.createSession = client => (variables, context) =>
  client.mutation(CreateSessionDocument, variables, context).toPromise() as any
export const DeleteResourceInSessionDocument = gql`
  mutation deleteResourceInSession($ids: [ID!]!) {
    deleteResourceInSession(ids: $ids)
  }
`
export const useGqlDeleteResourceInSession = () =>
  Urql.useMutation<
    DeleteResourceInSessionMutation,
    DeleteResourceInSessionMutationVariables
  >(DeleteResourceInSessionDocument)
export interface GqlOperations {
  deleteResourceInSession: (
    variables: DeleteResourceInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteResourceInSessionMutation,
      DeleteResourceInSessionMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteResourceInSession: (
    client: Urql.Client,
  ) => (
    variables: DeleteResourceInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteResourceInSessionMutation,
      DeleteResourceInSessionMutationVariables
    >
  >
}
gqlOperationsFactory.deleteResourceInSession = client => (variables, context) =>
  client
    .mutation(DeleteResourceInSessionDocument, variables, context)
    .toPromise() as any
export const UpdateResourceInSessionDocument = gql`
  mutation updateResourceInSession($ids: [ID!]!, $positions: [UInt!]!) {
    updateResourceInSession(ids: $ids, positions: $positions)
  }
`
export const useGqlUpdateResourceInSession = () =>
  Urql.useMutation<
    UpdateResourceInSessionMutation,
    UpdateResourceInSessionMutationVariables
  >(UpdateResourceInSessionDocument)
export interface GqlOperations {
  updateResourceInSession: (
    variables: UpdateResourceInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateResourceInSessionMutation,
      UpdateResourceInSessionMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateResourceInSession: (
    client: Urql.Client,
  ) => (
    variables: UpdateResourceInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateResourceInSessionMutation,
      UpdateResourceInSessionMutationVariables
    >
  >
}
gqlOperationsFactory.updateResourceInSession = client => (variables, context) =>
  client
    .mutation(UpdateResourceInSessionDocument, variables, context)
    .toPromise() as any
export const UpdateOriginalResourceDocument = gql`
  mutation updateOriginalResource($id: ID!, $data: UpdateOriginalResource!) {
    updateOriginalResource(id: $id, data: $data) {
      id
    }
  }
`
export const useGqlUpdateOriginalResource = () =>
  Urql.useMutation<
    UpdateOriginalResourceMutation,
    UpdateOriginalResourceMutationVariables
  >(UpdateOriginalResourceDocument)
export interface GqlOperations {
  updateOriginalResource: (
    variables: UpdateOriginalResourceMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateOriginalResourceMutation,
      UpdateOriginalResourceMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateOriginalResource: (
    client: Urql.Client,
  ) => (
    variables: UpdateOriginalResourceMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateOriginalResourceMutation,
      UpdateOriginalResourceMutationVariables
    >
  >
}
gqlOperationsFactory.updateOriginalResource = client => (variables, context) =>
  client
    .mutation(UpdateOriginalResourceDocument, variables, context)
    .toPromise() as any
export const UpdateSessionDocument = gql`
  mutation updateSession($id: ID!, $data: UpdateSession!) {
    updateSession(id: $id, data: $data) {
      ...sessionInfo
    }
  }
  ${SessionInfoFragmentDoc}
`
export const useGqlUpdateSession = () =>
  Urql.useMutation<UpdateSessionMutation, UpdateSessionMutationVariables>(
    UpdateSessionDocument,
  )
export interface GqlOperations {
  updateSession: (
    variables: UpdateSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateSessionMutation, UpdateSessionMutationVariables>
  >
}
interface GqlOperationsFactory {
  updateSession: (
    client: Urql.Client,
  ) => (
    variables: UpdateSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateSessionMutation, UpdateSessionMutationVariables>
  >
}
gqlOperationsFactory.updateSession = client => (variables, context) =>
  client.mutation(UpdateSessionDocument, variables, context).toPromise() as any
export const SearchChatbotContextDocument = gql`
  query searchChatbotContext(
    $filter: ChatbotContextFilter
    $order: [ChatbotContextOrderBy!]
    $page: Pagination
  ) {
    searchChatbotContext(filter: $filter, order: $order, page: $page) {
      id
      name
      system
      model
      temperature
      maxTokens
      maxMessageHistory
    }
  }
`
export const useGqlSearchChatbotContext = (
  options?: Omit<
    Urql.UseQueryArgs<SearchChatbotContextQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchChatbotContextQuery,
    SearchChatbotContextQueryVariables | void
  >({ query: SearchChatbotContextDocument, ...options })
export interface GqlOperations {
  searchChatbotContext: (
    variables?: SearchChatbotContextQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchChatbotContextQuery,
      SearchChatbotContextQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchChatbotContext: (
    client: Urql.Client,
  ) => (
    variables?: SearchChatbotContextQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchChatbotContextQuery,
      SearchChatbotContextQueryVariables
    >
  >
}
gqlOperationsFactory.searchChatbotContext = client => (variables, context) =>
  client
    .query(SearchChatbotContextDocument, variables, context)
    .toPromise() as any
export const UpdateChatbotContextDocument = gql`
  mutation updateChatbotContext($id: ID!, $data: UpdateChatbotContext!) {
    updateChatbotContext(id: $id, data: $data) {
      id
      name
    }
  }
`
export const useGqlUpdateChatbotContext = () =>
  Urql.useMutation<
    UpdateChatbotContextMutation,
    UpdateChatbotContextMutationVariables
  >(UpdateChatbotContextDocument)
export interface GqlOperations {
  updateChatbotContext: (
    variables: UpdateChatbotContextMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateChatbotContextMutation,
      UpdateChatbotContextMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateChatbotContext: (
    client: Urql.Client,
  ) => (
    variables: UpdateChatbotContextMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateChatbotContextMutation,
      UpdateChatbotContextMutationVariables
    >
  >
}
gqlOperationsFactory.updateChatbotContext = client => (variables, context) =>
  client
    .mutation(UpdateChatbotContextDocument, variables, context)
    .toPromise() as any
export const RecheckOrgDnsDocument = gql`
  mutation recheckOrgDns($id: ID!) {
    recheckOrgDns(id: $id) {
      dns
      dnsValidation
    }
  }
`
export const useGqlRecheckOrgDns = () =>
  Urql.useMutation<RecheckOrgDnsMutation, RecheckOrgDnsMutationVariables>(
    RecheckOrgDnsDocument,
  )
export interface GqlOperations {
  recheckOrgDns: (
    variables: RecheckOrgDnsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<RecheckOrgDnsMutation, RecheckOrgDnsMutationVariables>
  >
}
interface GqlOperationsFactory {
  recheckOrgDns: (
    client: Urql.Client,
  ) => (
    variables: RecheckOrgDnsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<RecheckOrgDnsMutation, RecheckOrgDnsMutationVariables>
  >
}
gqlOperationsFactory.recheckOrgDns = client => (variables, context) =>
  client.mutation(RecheckOrgDnsDocument, variables, context).toPromise() as any
export const UpdateOrgDnsDocument = gql`
  mutation updateOrgDns($id: ID!, $data: UpdateOrgDns!) {
    updateOrgDns(id: $id, data: $data) {
      dns
      dnsValidation
    }
  }
`
export const useGqlUpdateOrgDns = () =>
  Urql.useMutation<UpdateOrgDnsMutation, UpdateOrgDnsMutationVariables>(
    UpdateOrgDnsDocument,
  )
export interface GqlOperations {
  updateOrgDns: (
    variables: UpdateOrgDnsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateOrgDnsMutation, UpdateOrgDnsMutationVariables>
  >
}
interface GqlOperationsFactory {
  updateOrgDns: (
    client: Urql.Client,
  ) => (
    variables: UpdateOrgDnsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateOrgDnsMutation, UpdateOrgDnsMutationVariables>
  >
}
gqlOperationsFactory.updateOrgDns = client => (variables, context) =>
  client.mutation(UpdateOrgDnsDocument, variables, context).toPromise() as any
export const CheckTicketDocument = gql`
  query checkTicket($data: CheckTicket!, $type: CheckTicketType!) {
    checkTicket(data: $data, type: $type)
  }
`
export const useGqlCheckTicket = (
  options: Omit<Urql.UseQueryArgs<CheckTicketQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CheckTicketQuery, CheckTicketQueryVariables>({
    query: CheckTicketDocument,
    ...options,
  })
export interface GqlOperations {
  checkTicket: (
    variables: CheckTicketQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CheckTicketQuery, CheckTicketQueryVariables>
  >
}
interface GqlOperationsFactory {
  checkTicket: (
    client: Urql.Client,
  ) => (
    variables: CheckTicketQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CheckTicketQuery, CheckTicketQueryVariables>
  >
}
gqlOperationsFactory.checkTicket = client => (variables, context) =>
  client.query(CheckTicketDocument, variables, context).toPromise() as any
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($email: Email!) {
    forgotPassword(email: $email)
  }
`
export const useGqlForgotPassword = () =>
  Urql.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
    ForgotPasswordDocument,
  )
export interface GqlOperations {
  forgotPassword: (
    variables: ForgotPasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ForgotPasswordMutation,
      ForgotPasswordMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  forgotPassword: (
    client: Urql.Client,
  ) => (
    variables: ForgotPasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ForgotPasswordMutation,
      ForgotPasswordMutationVariables
    >
  >
}
gqlOperationsFactory.forgotPassword = client => (variables, context) =>
  client.mutation(ForgotPasswordDocument, variables, context).toPromise() as any
export const ResendTicketDocument = gql`
  mutation resendTicket($id: ID!) {
    resendTicket(id: $id)
  }
`
export const useGqlResendTicket = () =>
  Urql.useMutation<ResendTicketMutation, ResendTicketMutationVariables>(
    ResendTicketDocument,
  )
export interface GqlOperations {
  resendTicket: (
    variables: ResendTicketMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<ResendTicketMutation, ResendTicketMutationVariables>
  >
}
interface GqlOperationsFactory {
  resendTicket: (
    client: Urql.Client,
  ) => (
    variables: ResendTicketMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<ResendTicketMutation, ResendTicketMutationVariables>
  >
}
gqlOperationsFactory.resendTicket = client => (variables, context) =>
  client.mutation(ResendTicketDocument, variables, context).toPromise() as any
export const ResolveForgotPasswordDocument = gql`
  mutation resolveForgotPassword($data: CheckTicket!, $newPassword: Password!) {
    resolveForgotPassword(data: $data, newPassword: $newPassword) {
      ...authInfo
    }
  }
  ${AuthInfoFragmentDoc}
`
export const useGqlResolveForgotPassword = () =>
  Urql.useMutation<
    ResolveForgotPasswordMutation,
    ResolveForgotPasswordMutationVariables
  >(ResolveForgotPasswordDocument)
export interface GqlOperations {
  resolveForgotPassword: (
    variables: ResolveForgotPasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ResolveForgotPasswordMutation,
      ResolveForgotPasswordMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  resolveForgotPassword: (
    client: Urql.Client,
  ) => (
    variables: ResolveForgotPasswordMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ResolveForgotPasswordMutation,
      ResolveForgotPasswordMutationVariables
    >
  >
}
gqlOperationsFactory.resolveForgotPassword = client => (variables, context) =>
  client
    .mutation(ResolveForgotPasswordDocument, variables, context)
    .toPromise() as any
export const AddRecordMeetingDocument = gql`
  mutation addRecordMeeting($meetingUrl: String!, $insightEngineId: ID!) {
    addRecordMeeting(meetingUrl: $meetingUrl, insightEngineId: $insightEngineId)
  }
`
export const useGqlAddRecordMeeting = () =>
  Urql.useMutation<AddRecordMeetingMutation, AddRecordMeetingMutationVariables>(
    AddRecordMeetingDocument,
  )
export interface GqlOperations {
  addRecordMeeting: (
    variables: AddRecordMeetingMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      AddRecordMeetingMutation,
      AddRecordMeetingMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  addRecordMeeting: (
    client: Urql.Client,
  ) => (
    variables: AddRecordMeetingMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      AddRecordMeetingMutation,
      AddRecordMeetingMutationVariables
    >
  >
}
gqlOperationsFactory.addRecordMeeting = client => (variables, context) =>
  client
    .mutation(AddRecordMeetingDocument, variables, context)
    .toPromise() as any
export const CountTranscriptDocument = gql`
  query countTranscript(
    $resourceInInsightEngineId: ID!
    $filter: TranscriptFilter
  ) {
    countTranscript(
      resourceInInsightEngineId: $resourceInInsightEngineId
      filter: $filter
    )
  }
`
export const useGqlCountTranscript = (
  options: Omit<Urql.UseQueryArgs<CountTranscriptQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountTranscriptQuery, CountTranscriptQueryVariables>({
    query: CountTranscriptDocument,
    ...options,
  })
export interface GqlOperations {
  countTranscript: (
    variables: CountTranscriptQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountTranscriptQuery, CountTranscriptQueryVariables>
  >
}
interface GqlOperationsFactory {
  countTranscript: (
    client: Urql.Client,
  ) => (
    variables: CountTranscriptQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountTranscriptQuery, CountTranscriptQueryVariables>
  >
}
gqlOperationsFactory.countTranscript = client => (variables, context) =>
  client.query(CountTranscriptDocument, variables, context).toPromise() as any
export const CreateBlurBlockDocument = gql`
  mutation createBlurBlock($data: CreateBlurBlock!) {
    createBlurBlock(data: $data) {
      id
    }
  }
`
export const useGqlCreateBlurBlock = () =>
  Urql.useMutation<CreateBlurBlockMutation, CreateBlurBlockMutationVariables>(
    CreateBlurBlockDocument,
  )
export interface GqlOperations {
  createBlurBlock: (
    variables: CreateBlurBlockMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateBlurBlockMutation,
      CreateBlurBlockMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  createBlurBlock: (
    client: Urql.Client,
  ) => (
    variables: CreateBlurBlockMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateBlurBlockMutation,
      CreateBlurBlockMutationVariables
    >
  >
}
gqlOperationsFactory.createBlurBlock = client => (variables, context) =>
  client
    .mutation(CreateBlurBlockDocument, variables, context)
    .toPromise() as any
export const CreateChatbotMsgDocument = gql`
  mutation createChatbotMsg($data: CreateChatbotMsg!) {
    createChatbotMsg(data: $data) {
      contextId
      content
      ownerId
      isChatBot
      status
      id
      createdAt
      context {
        name
        id
        createdAt
      }
      owner {
        name
        email
        avatarUrl
      }
    }
  }
`
export const useGqlCreateChatbotMsg = () =>
  Urql.useMutation<CreateChatbotMsgMutation, CreateChatbotMsgMutationVariables>(
    CreateChatbotMsgDocument,
  )
export interface GqlOperations {
  createChatbotMsg: (
    variables: CreateChatbotMsgMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateChatbotMsgMutation,
      CreateChatbotMsgMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  createChatbotMsg: (
    client: Urql.Client,
  ) => (
    variables: CreateChatbotMsgMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateChatbotMsgMutation,
      CreateChatbotMsgMutationVariables
    >
  >
}
gqlOperationsFactory.createChatbotMsg = client => (variables, context) =>
  client
    .mutation(CreateChatbotMsgDocument, variables, context)
    .toPromise() as any
export const CreateMuteDocument = gql`
  mutation createMute($data: CreateMute!) {
    createMute(data: $data) {
      startTime
      endTime
      resourceInInsightEngineId
      id
      createdAt
      resourceInInsightEngine {
        id
      }
    }
  }
`
export const useGqlCreateMute = () =>
  Urql.useMutation<CreateMuteMutation, CreateMuteMutationVariables>(
    CreateMuteDocument,
  )
export interface GqlOperations {
  createMute: (
    variables: CreateMuteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateMuteMutation, CreateMuteMutationVariables>
  >
}
interface GqlOperationsFactory {
  createMute: (
    client: Urql.Client,
  ) => (
    variables: CreateMuteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateMuteMutation, CreateMuteMutationVariables>
  >
}
gqlOperationsFactory.createMute = client => (variables, context) =>
  client.mutation(CreateMuteDocument, variables, context).toPromise() as any
export const DeleteBlurBlockDocument = gql`
  mutation deleteBlurBlock($id: ID!) {
    deleteBlurBlock(id: $id)
  }
`
export const useGqlDeleteBlurBlock = () =>
  Urql.useMutation<DeleteBlurBlockMutation, DeleteBlurBlockMutationVariables>(
    DeleteBlurBlockDocument,
  )
export interface GqlOperations {
  deleteBlurBlock: (
    variables: DeleteBlurBlockMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteBlurBlockMutation,
      DeleteBlurBlockMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteBlurBlock: (
    client: Urql.Client,
  ) => (
    variables: DeleteBlurBlockMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteBlurBlockMutation,
      DeleteBlurBlockMutationVariables
    >
  >
}
gqlOperationsFactory.deleteBlurBlock = client => (variables, context) =>
  client
    .mutation(DeleteBlurBlockDocument, variables, context)
    .toPromise() as any
export const DeleteMuteDocument = gql`
  mutation deleteMute($id: ID!) {
    deleteMute(id: $id)
  }
`
export const useGqlDeleteMute = () =>
  Urql.useMutation<DeleteMuteMutation, DeleteMuteMutationVariables>(
    DeleteMuteDocument,
  )
export interface GqlOperations {
  deleteMute: (
    variables: DeleteMuteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteMuteMutation, DeleteMuteMutationVariables>
  >
}
interface GqlOperationsFactory {
  deleteMute: (
    client: Urql.Client,
  ) => (
    variables: DeleteMuteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteMuteMutation, DeleteMuteMutationVariables>
  >
}
gqlOperationsFactory.deleteMute = client => (variables, context) =>
  client.mutation(DeleteMuteDocument, variables, context).toPromise() as any
export const DeleteResourceInInsightEngineDocument = gql`
  mutation deleteResourceInInsightEngine($id: [ID!]!) {
    deleteResourceInInsightEngine(ids: $id)
  }
`
export const useGqlDeleteResourceInInsightEngine = () =>
  Urql.useMutation<
    DeleteResourceInInsightEngineMutation,
    DeleteResourceInInsightEngineMutationVariables
  >(DeleteResourceInInsightEngineDocument)
export interface GqlOperations {
  deleteResourceInInsightEngine: (
    variables: DeleteResourceInInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteResourceInInsightEngineMutation,
      DeleteResourceInInsightEngineMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteResourceInInsightEngine: (
    client: Urql.Client,
  ) => (
    variables: DeleteResourceInInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteResourceInInsightEngineMutation,
      DeleteResourceInInsightEngineMutationVariables
    >
  >
}
gqlOperationsFactory.deleteResourceInInsightEngine =
  client => (variables, context) =>
    client
      .mutation(DeleteResourceInInsightEngineDocument, variables, context)
      .toPromise() as any
export const DetailInsightEngineDocument = gql`
  query detailInsightEngine($id: ID!) {
    detailInsightEngine(id: $id) {
      name
    }
  }
`
export const useGqlDetailInsightEngine = (
  options: Omit<Urql.UseQueryArgs<DetailInsightEngineQueryVariables>, 'query'>,
) =>
  Urql.useQuery<DetailInsightEngineQuery, DetailInsightEngineQueryVariables>({
    query: DetailInsightEngineDocument,
    ...options,
  })
export interface GqlOperations {
  detailInsightEngine: (
    variables: DetailInsightEngineQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DetailInsightEngineQuery,
      DetailInsightEngineQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  detailInsightEngine: (
    client: Urql.Client,
  ) => (
    variables: DetailInsightEngineQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DetailInsightEngineQuery,
      DetailInsightEngineQueryVariables
    >
  >
}
gqlOperationsFactory.detailInsightEngine = client => (variables, context) =>
  client
    .query(DetailInsightEngineDocument, variables, context)
    .toPromise() as any
export const DownloadVideoInInsightEngineDocument = gql`
  mutation downloadVideoInInsightEngine($resourceInInsightEngineId: ID!) {
    downloadVideoInInsightEngine(
      resourceInInsightEngineId: $resourceInInsightEngineId
    )
  }
`
export const useGqlDownloadVideoInInsightEngine = () =>
  Urql.useMutation<
    DownloadVideoInInsightEngineMutation,
    DownloadVideoInInsightEngineMutationVariables
  >(DownloadVideoInInsightEngineDocument)
export interface GqlOperations {
  downloadVideoInInsightEngine: (
    variables: DownloadVideoInInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DownloadVideoInInsightEngineMutation,
      DownloadVideoInInsightEngineMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  downloadVideoInInsightEngine: (
    client: Urql.Client,
  ) => (
    variables: DownloadVideoInInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DownloadVideoInInsightEngineMutation,
      DownloadVideoInInsightEngineMutationVariables
    >
  >
}
gqlOperationsFactory.downloadVideoInInsightEngine =
  client => (variables, context) =>
    client
      .mutation(DownloadVideoInInsightEngineDocument, variables, context)
      .toPromise() as any
export const InsightEnginePlanDocument = gql`
  query insightEnginePlan($insightEngineId: ID!) {
    insightEnginePlan(insightEngineId: $insightEngineId) {
      ...currentPlan
    }
  }
  ${CurrentPlanFragmentDoc}
`
export const useGqlInsightEnginePlan = (
  options: Omit<Urql.UseQueryArgs<InsightEnginePlanQueryVariables>, 'query'>,
) =>
  Urql.useQuery<InsightEnginePlanQuery, InsightEnginePlanQueryVariables>({
    query: InsightEnginePlanDocument,
    ...options,
  })
export interface GqlOperations {
  insightEnginePlan: (
    variables: InsightEnginePlanQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      InsightEnginePlanQuery,
      InsightEnginePlanQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  insightEnginePlan: (
    client: Urql.Client,
  ) => (
    variables: InsightEnginePlanQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      InsightEnginePlanQuery,
      InsightEnginePlanQueryVariables
    >
  >
}
gqlOperationsFactory.insightEnginePlan = client => (variables, context) =>
  client.query(InsightEnginePlanDocument, variables, context).toPromise() as any
export const ReLoadRecordMeetingLinkDocument = gql`
  mutation reLoadRecordMeetingLink($resourceInInsightEngineId: ID!) {
    reLoadRecordMeetingLink(
      resourceInInsightEngineId: $resourceInInsightEngineId
    )
  }
`
export const useGqlReLoadRecordMeetingLink = () =>
  Urql.useMutation<
    ReLoadRecordMeetingLinkMutation,
    ReLoadRecordMeetingLinkMutationVariables
  >(ReLoadRecordMeetingLinkDocument)
export interface GqlOperations {
  reLoadRecordMeetingLink: (
    variables: ReLoadRecordMeetingLinkMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ReLoadRecordMeetingLinkMutation,
      ReLoadRecordMeetingLinkMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  reLoadRecordMeetingLink: (
    client: Urql.Client,
  ) => (
    variables: ReLoadRecordMeetingLinkMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ReLoadRecordMeetingLinkMutation,
      ReLoadRecordMeetingLinkMutationVariables
    >
  >
}
gqlOperationsFactory.reLoadRecordMeetingLink = client => (variables, context) =>
  client
    .mutation(ReLoadRecordMeetingLinkDocument, variables, context)
    .toPromise() as any
export const ReplaceTranscriptUserDocument = gql`
  mutation replaceTranscriptUser(
    $id: ID!
    $name: String!
    $preName: String
    $replaceAll: Boolean
  ) {
    replaceTranscriptUser(
      id: $id
      name: $name
      preName: $preName
      replaceAll: $replaceAll
    )
  }
`
export const useGqlReplaceTranscriptUser = () =>
  Urql.useMutation<
    ReplaceTranscriptUserMutation,
    ReplaceTranscriptUserMutationVariables
  >(ReplaceTranscriptUserDocument)
export interface GqlOperations {
  replaceTranscriptUser: (
    variables: ReplaceTranscriptUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ReplaceTranscriptUserMutation,
      ReplaceTranscriptUserMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  replaceTranscriptUser: (
    client: Urql.Client,
  ) => (
    variables: ReplaceTranscriptUserMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ReplaceTranscriptUserMutation,
      ReplaceTranscriptUserMutationVariables
    >
  >
}
gqlOperationsFactory.replaceTranscriptUser = client => (variables, context) =>
  client
    .mutation(ReplaceTranscriptUserDocument, variables, context)
    .toPromise() as any
export const SearchBlurBlockDocument = gql`
  query searchBlurBlock($resourceInInsightEngineId: ID!) {
    searchBlurBlock(resourceInInsightEngineId: $resourceInInsightEngineId) {
      blur
      startTime
      endTime
      borderRadius
      blockWidth
      blockHeight
      top
      left
      resourceInInsightEngineId
      id
      createdAt
    }
  }
`
export const useGqlSearchBlurBlock = (
  options: Omit<Urql.UseQueryArgs<SearchBlurBlockQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchBlurBlockQuery, SearchBlurBlockQueryVariables>({
    query: SearchBlurBlockDocument,
    ...options,
  })
export interface GqlOperations {
  searchBlurBlock: (
    variables: SearchBlurBlockQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchBlurBlockQuery, SearchBlurBlockQueryVariables>
  >
}
interface GqlOperationsFactory {
  searchBlurBlock: (
    client: Urql.Client,
  ) => (
    variables: SearchBlurBlockQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchBlurBlockQuery, SearchBlurBlockQueryVariables>
  >
}
gqlOperationsFactory.searchBlurBlock = client => (variables, context) =>
  client.query(SearchBlurBlockDocument, variables, context).toPromise() as any
export const SearchChatbotMsgDocument = gql`
  query searchChatbotMsg(
    $type: String!
    $filter: ChatbotMsgFilter
    $order: [ChatbotMsgOrderBy!]
    $page: Pagination
  ) {
    searchChatbotMsg(type: $type, filter: $filter, order: $order, page: $page) {
      contextId
      content
      isChatBot
      status
      id
      createdAt
      context {
        name
        id
      }
      owner {
        name
        avatarUrl
      }
    }
  }
`
export const useGqlSearchChatbotMsg = (
  options: Omit<Urql.UseQueryArgs<SearchChatbotMsgQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchChatbotMsgQuery, SearchChatbotMsgQueryVariables>({
    query: SearchChatbotMsgDocument,
    ...options,
  })
export interface GqlOperations {
  searchChatbotMsg: (
    variables: SearchChatbotMsgQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchChatbotMsgQuery, SearchChatbotMsgQueryVariables>
  >
}
interface GqlOperationsFactory {
  searchChatbotMsg: (
    client: Urql.Client,
  ) => (
    variables: SearchChatbotMsgQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchChatbotMsgQuery, SearchChatbotMsgQueryVariables>
  >
}
gqlOperationsFactory.searchChatbotMsg = client => (variables, context) =>
  client.query(SearchChatbotMsgDocument, variables, context).toPromise() as any
export const SearchMuteDocument = gql`
  query searchMute(
    $filter: MuteFilter
    $order: [MuteOrderBy!]
    $page: Pagination
    $resourceInInsightEngineId: ID!
  ) {
    searchMute(
      filter: $filter
      order: $order
      page: $page
      resourceInInsightEngineId: $resourceInInsightEngineId
    ) {
      id
      startTime
      endTime
    }
  }
`
export const useGqlSearchMute = (
  options: Omit<Urql.UseQueryArgs<SearchMuteQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchMuteQuery, SearchMuteQueryVariables>({
    query: SearchMuteDocument,
    ...options,
  })
export interface GqlOperations {
  searchMute: (
    variables: SearchMuteQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SearchMuteQuery, SearchMuteQueryVariables>>
}
interface GqlOperationsFactory {
  searchMute: (
    client: Urql.Client,
  ) => (
    variables: SearchMuteQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SearchMuteQuery, SearchMuteQueryVariables>>
}
gqlOperationsFactory.searchMute = client => (variables, context) =>
  client.query(SearchMuteDocument, variables, context).toPromise() as any
export const SearchResourceInInsightEngineDocument = gql`
  query searchResourceInInsightEngine(
    $insightEngineId: ID!
    $filter: ResourceInInsightEngineFilter
    $order: [ResourceInInsightEngineOrderBy!]
    $page: Pagination
  ) {
    searchResourceInInsightEngine(
      insightEngineId: $insightEngineId
      filter: $filter
      order: $order
      page: $page
    ) {
      id
      insightEngineId
      resourceId
      note
      isFromBot
      isDownloading
      resource {
        id
        name
        duration
        url
        thumbnailResource {
          url
        }
      }
      status
    }
  }
`
export const useGqlSearchResourceInInsightEngine = (
  options: Omit<
    Urql.UseQueryArgs<SearchResourceInInsightEngineQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchResourceInInsightEngineQuery,
    SearchResourceInInsightEngineQueryVariables
  >({ query: SearchResourceInInsightEngineDocument, ...options })
export interface GqlOperations {
  searchResourceInInsightEngine: (
    variables: SearchResourceInInsightEngineQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchResourceInInsightEngineQuery,
      SearchResourceInInsightEngineQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchResourceInInsightEngine: (
    client: Urql.Client,
  ) => (
    variables: SearchResourceInInsightEngineQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchResourceInInsightEngineQuery,
      SearchResourceInInsightEngineQueryVariables
    >
  >
}
gqlOperationsFactory.searchResourceInInsightEngine =
  client => (variables, context) =>
    client
      .query(SearchResourceInInsightEngineDocument, variables, context)
      .toPromise() as any
export const SearchTranscriptDocument = gql`
  query searchTranscript(
    $resourceInInsightEngineId: ID!
    $filter: TranscriptFilter
    $order: [TranscriptOrderBy!]
    $page: Pagination
  ) {
    searchTranscript(
      resourceInInsightEngineId: $resourceInInsightEngineId
      filter: $filter
      order: $order
      page: $page
    ) {
      id
      speaker {
        id
        name
        avatarUrl
      }
      content
      nameFromRevAi
      startTime
      endTime
    }
  }
`
export const useGqlSearchTranscript = (
  options: Omit<Urql.UseQueryArgs<SearchTranscriptQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchTranscriptQuery, SearchTranscriptQueryVariables>({
    query: SearchTranscriptDocument,
    ...options,
  })
export interface GqlOperations {
  searchTranscript: (
    variables: SearchTranscriptQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchTranscriptQuery, SearchTranscriptQueryVariables>
  >
}
interface GqlOperationsFactory {
  searchTranscript: (
    client: Urql.Client,
  ) => (
    variables: SearchTranscriptQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchTranscriptQuery, SearchTranscriptQueryVariables>
  >
}
gqlOperationsFactory.searchTranscript = client => (variables, context) =>
  client.query(SearchTranscriptDocument, variables, context).toPromise() as any
export const SearchUserInResourceDocument = gql`
  query searchUserInResource(
    $filter: UserInResourceFilter
    $order: [UserInResourceOrderBy!]
    $page: Pagination
  ) {
    searchUserInResource(filter: $filter, order: $order, page: $page) {
      userId
      resourceId
      type
      isDownloading
      id
      createdAt
    }
  }
`
export const useGqlSearchUserInResource = (
  options?: Omit<
    Urql.UseQueryArgs<SearchUserInResourceQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchUserInResourceQuery,
    SearchUserInResourceQueryVariables | void
  >({ query: SearchUserInResourceDocument, ...options })
export interface GqlOperations {
  searchUserInResource: (
    variables?: SearchUserInResourceQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchUserInResourceQuery,
      SearchUserInResourceQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchUserInResource: (
    client: Urql.Client,
  ) => (
    variables?: SearchUserInResourceQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchUserInResourceQuery,
      SearchUserInResourceQueryVariables
    >
  >
}
gqlOperationsFactory.searchUserInResource = client => (variables, context) =>
  client
    .query(SearchUserInResourceDocument, variables, context)
    .toPromise() as any
export const StartTranscriptDocument = gql`
  mutation startTranscript($resourceInInsightEngineId: ID!) {
    startTranscript(resourceInInsightEngineId: $resourceInInsightEngineId)
  }
`
export const useGqlStartTranscript = () =>
  Urql.useMutation<StartTranscriptMutation, StartTranscriptMutationVariables>(
    StartTranscriptDocument,
  )
export interface GqlOperations {
  startTranscript: (
    variables: StartTranscriptMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      StartTranscriptMutation,
      StartTranscriptMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  startTranscript: (
    client: Urql.Client,
  ) => (
    variables: StartTranscriptMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      StartTranscriptMutation,
      StartTranscriptMutationVariables
    >
  >
}
gqlOperationsFactory.startTranscript = client => (variables, context) =>
  client
    .mutation(StartTranscriptDocument, variables, context)
    .toPromise() as any
export const UpdateBlurBlockDocument = gql`
  mutation updateBlurBlock($id: ID!, $data: UpdateBlurBlock!) {
    updateBlurBlock(id: $id, data: $data) {
      resourceInInsightEngineId
    }
  }
`
export const useGqlUpdateBlurBlock = () =>
  Urql.useMutation<UpdateBlurBlockMutation, UpdateBlurBlockMutationVariables>(
    UpdateBlurBlockDocument,
  )
export interface GqlOperations {
  updateBlurBlock: (
    variables: UpdateBlurBlockMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateBlurBlockMutation,
      UpdateBlurBlockMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateBlurBlock: (
    client: Urql.Client,
  ) => (
    variables: UpdateBlurBlockMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateBlurBlockMutation,
      UpdateBlurBlockMutationVariables
    >
  >
}
gqlOperationsFactory.updateBlurBlock = client => (variables, context) =>
  client
    .mutation(UpdateBlurBlockDocument, variables, context)
    .toPromise() as any
export const UpdateInsightEngineDocument = gql`
  mutation updateInsightEngine($id: ID!, $data: UpdateInsightEngine!) {
    updateInsightEngine(id: $id, data: $data) {
      name
    }
  }
`
export const useGqlUpdateInsightEngine = () =>
  Urql.useMutation<
    UpdateInsightEngineMutation,
    UpdateInsightEngineMutationVariables
  >(UpdateInsightEngineDocument)
export interface GqlOperations {
  updateInsightEngine: (
    variables: UpdateInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateInsightEngineMutation,
      UpdateInsightEngineMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateInsightEngine: (
    client: Urql.Client,
  ) => (
    variables: UpdateInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateInsightEngineMutation,
      UpdateInsightEngineMutationVariables
    >
  >
}
gqlOperationsFactory.updateInsightEngine = client => (variables, context) =>
  client
    .mutation(UpdateInsightEngineDocument, variables, context)
    .toPromise() as any
export const UpdateMuteDocument = gql`
  mutation updateMute($id: ID!, $data: UpdateMute!) {
    updateMute(id: $id, data: $data) {
      startTime
      endTime
    }
  }
`
export const useGqlUpdateMute = () =>
  Urql.useMutation<UpdateMuteMutation, UpdateMuteMutationVariables>(
    UpdateMuteDocument,
  )
export interface GqlOperations {
  updateMute: (
    variables: UpdateMuteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateMuteMutation, UpdateMuteMutationVariables>
  >
}
interface GqlOperationsFactory {
  updateMute: (
    client: Urql.Client,
  ) => (
    variables: UpdateMuteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateMuteMutation, UpdateMuteMutationVariables>
  >
}
gqlOperationsFactory.updateMute = client => (variables, context) =>
  client.mutation(UpdateMuteDocument, variables, context).toPromise() as any
export const UpdateResourceInIeDocument = gql`
  mutation updateResourceInIE($id: ID!, $data: UpdateResourceInInsightEngine!) {
    updateResourceInInsightEngine(id: $id, data: $data) {
      id
      status
    }
  }
`
export const useGqlUpdateResourceInIe = () =>
  Urql.useMutation<
    UpdateResourceInIeMutation,
    UpdateResourceInIeMutationVariables
  >(UpdateResourceInIeDocument)
export interface GqlOperations {
  updateResourceInIe: (
    variables: UpdateResourceInIeMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateResourceInIeMutation,
      UpdateResourceInIeMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateResourceInIe: (
    client: Urql.Client,
  ) => (
    variables: UpdateResourceInIeMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateResourceInIeMutation,
      UpdateResourceInIeMutationVariables
    >
  >
}
gqlOperationsFactory.updateResourceInIe = client => (variables, context) =>
  client
    .mutation(UpdateResourceInIeDocument, variables, context)
    .toPromise() as any
export const UpdateTranscriptDocument = gql`
  mutation updateTranscript($id: ID!, $data: UpdateTranscript!) {
    updateTranscript(id: $id, data: $data) {
      content
      resourceInInsightEngineId
    }
  }
`
export const useGqlUpdateTranscript = () =>
  Urql.useMutation<UpdateTranscriptMutation, UpdateTranscriptMutationVariables>(
    UpdateTranscriptDocument,
  )
export interface GqlOperations {
  updateTranscript: (
    variables: UpdateTranscriptMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateTranscriptMutation,
      UpdateTranscriptMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateTranscript: (
    client: Urql.Client,
  ) => (
    variables: UpdateTranscriptMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateTranscriptMutation,
      UpdateTranscriptMutationVariables
    >
  >
}
gqlOperationsFactory.updateTranscript = client => (variables, context) =>
  client
    .mutation(UpdateTranscriptDocument, variables, context)
    .toPromise() as any
export const LoginDocument = gql`
  mutation login($email: Email!, $password: NeString!, $dns: String) {
    login(email: $email, password: $password, dns: $dns) {
      ...authInfo
    }
  }
  ${AuthInfoFragmentDoc}
`
export const useGqlLogin = () =>
  Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument)
export interface GqlOperations {
  login: (
    variables: LoginMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<LoginMutation, LoginMutationVariables>>
}
interface GqlOperationsFactory {
  login: (
    client: Urql.Client,
  ) => (
    variables: LoginMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<LoginMutation, LoginMutationVariables>>
}
gqlOperationsFactory.login = client => (variables, context) =>
  client.mutation(LoginDocument, variables, context).toPromise() as any
export const RnLoginDocument = gql`
  mutation rnLogin($email: Email!, $password: NeString!) {
    login(email: $email, password: $password) {
      ...authInfo
    }
  }
  ${AuthInfoFragmentDoc}
`
export const useGqlRnLogin = () =>
  Urql.useMutation<RnLoginMutation, RnLoginMutationVariables>(RnLoginDocument)
export interface GqlOperations {
  rnLogin: (
    variables: RnLoginMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<RnLoginMutation, RnLoginMutationVariables>>
}
interface GqlOperationsFactory {
  rnLogin: (
    client: Urql.Client,
  ) => (
    variables: RnLoginMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<RnLoginMutation, RnLoginMutationVariables>>
}
gqlOperationsFactory.rnLogin = client => (variables, context) =>
  client.mutation(RnLoginDocument, variables, context).toPromise() as any
export const LoginWithSocialDocument = gql`
  mutation loginWithSocial(
    $code: NeString!
    $type: LoginWithSocialType!
    $referralById: ID
    $dns: String
    $redirectUrl: String!
  ) {
    loginWithSocial(
      code: $code
      type: $type
      referralById: $referralById
      dns: $dns
      redirectUrl: $redirectUrl
    ) {
      ...authInfo
    }
  }
  ${AuthInfoFragmentDoc}
`
export const useGqlLoginWithSocial = () =>
  Urql.useMutation<LoginWithSocialMutation, LoginWithSocialMutationVariables>(
    LoginWithSocialDocument,
  )
export interface GqlOperations {
  loginWithSocial: (
    variables: LoginWithSocialMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      LoginWithSocialMutation,
      LoginWithSocialMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  loginWithSocial: (
    client: Urql.Client,
  ) => (
    variables: LoginWithSocialMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      LoginWithSocialMutation,
      LoginWithSocialMutationVariables
    >
  >
}
gqlOperationsFactory.loginWithSocial = client => (variables, context) =>
  client
    .mutation(LoginWithSocialDocument, variables, context)
    .toPromise() as any
export const MarkLbInstanceAsStaleDocument = gql`
  mutation markLbInstanceAsStale($id: ID!) {
    markLbInstanceAsStale(id: $id)
  }
`
export const useGqlMarkLbInstanceAsStale = () =>
  Urql.useMutation<
    MarkLbInstanceAsStaleMutation,
    MarkLbInstanceAsStaleMutationVariables
  >(MarkLbInstanceAsStaleDocument)
export interface GqlOperations {
  markLbInstanceAsStale: (
    variables: MarkLbInstanceAsStaleMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      MarkLbInstanceAsStaleMutation,
      MarkLbInstanceAsStaleMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  markLbInstanceAsStale: (
    client: Urql.Client,
  ) => (
    variables: MarkLbInstanceAsStaleMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      MarkLbInstanceAsStaleMutation,
      MarkLbInstanceAsStaleMutationVariables
    >
  >
}
gqlOperationsFactory.markLbInstanceAsStale = client => (variables, context) =>
  client
    .mutation(MarkLbInstanceAsStaleDocument, variables, context)
    .toPromise() as any
export const SearchLbInstanceDocument = gql`
  query searchLbInstance(
    $filter: LbInstanceFilter
    $order: [LbInstanceOrderBy!]
    $page: Pagination
  ) {
    searchLbInstance(filter: $filter, order: $order, page: $page) {
      id
      type
      status
      version
      name
      namespace
      cpu
      memory
      url
      inuse
      inuseInput
      inuseOutput
      mediasoupPeers
      createdAt
    }
    countLbInstance(filter: $filter)
  }
`
export const useGqlSearchLbInstance = (
  options?: Omit<Urql.UseQueryArgs<SearchLbInstanceQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchLbInstanceQuery, SearchLbInstanceQueryVariables | void>({
    query: SearchLbInstanceDocument,
    ...options,
  })
export interface GqlOperations {
  searchLbInstance: (
    variables?: SearchLbInstanceQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchLbInstanceQuery, SearchLbInstanceQueryVariables>
  >
}
interface GqlOperationsFactory {
  searchLbInstance: (
    client: Urql.Client,
  ) => (
    variables?: SearchLbInstanceQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchLbInstanceQuery, SearchLbInstanceQueryVariables>
  >
}
gqlOperationsFactory.searchLbInstance = client => (variables, context) =>
  client.query(SearchLbInstanceDocument, variables, context).toPromise() as any
export const SearchSessionInLbDocument = gql`
  query searchSessionInLb(
    $lbId: ID!
    $filter: SessionFilter
    $order: [SessionOrderBy!]
    $page: Pagination
  ) {
    searchSessionInLb(
      lbId: $lbId
      filter: $filter
      order: $order
      page: $page
    ) {
      id
      title
      startTime
      endTime
      createdBy {
        name
      }
    }
  }
`
export const useGqlSearchSessionInLb = (
  options: Omit<Urql.UseQueryArgs<SearchSessionInLbQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchSessionInLbQuery, SearchSessionInLbQueryVariables>({
    query: SearchSessionInLbDocument,
    ...options,
  })
export interface GqlOperations {
  searchSessionInLb: (
    variables: SearchSessionInLbQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchSessionInLbQuery,
      SearchSessionInLbQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchSessionInLb: (
    client: Urql.Client,
  ) => (
    variables: SearchSessionInLbQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchSessionInLbQuery,
      SearchSessionInLbQueryVariables
    >
  >
}
gqlOperationsFactory.searchSessionInLb = client => (variables, context) =>
  client.query(SearchSessionInLbDocument, variables, context).toPromise() as any
export const PlansDocument = gql`
  query plans {
    plans {
      prices {
        priceMinutes
        price
        currency
        interval
        priceMinutesTiers {
          from
          to
          price
        }
        priceStorageTiers {
          from
          to
          price
        }
        priceStorage
      }
      name
      maxParticipants
      freeMinutesPerMonth
      freeStoragePerMonth
    }
  }
`
export const useGqlPlans = (
  options?: Omit<Urql.UseQueryArgs<PlansQueryVariables>, 'query'>,
) =>
  Urql.useQuery<PlansQuery, PlansQueryVariables | void>({
    query: PlansDocument,
    ...options,
  })
export interface GqlOperations {
  plans: (
    variables?: PlansQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<PlansQuery, PlansQueryVariables>>
}
interface GqlOperationsFactory {
  plans: (
    client: Urql.Client,
  ) => (
    variables?: PlansQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<PlansQuery, PlansQueryVariables>>
}
gqlOperationsFactory.plans = client => (variables, context) =>
  client.query(PlansDocument, variables, context).toPromise() as any
export const DeleteSessionDocument = gql`
  mutation deleteSession($id: ID!) {
    deleteSession(id: $id)
  }
`
export const useGqlDeleteSession = () =>
  Urql.useMutation<DeleteSessionMutation, DeleteSessionMutationVariables>(
    DeleteSessionDocument,
  )
export interface GqlOperations {
  deleteSession: (
    variables: DeleteSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteSessionMutation, DeleteSessionMutationVariables>
  >
}
interface GqlOperationsFactory {
  deleteSession: (
    client: Urql.Client,
  ) => (
    variables: DeleteSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteSessionMutation, DeleteSessionMutationVariables>
  >
}
gqlOperationsFactory.deleteSession = client => (variables, context) =>
  client.mutation(DeleteSessionDocument, variables, context).toPromise() as any
export const DetailSessionDocument = gql`
  query detailSession($id: ID!) {
    detailSession(id: $id) {
      ...sessionInfo
    }
  }
  ${SessionInfoFragmentDoc}
`
export const useGqlDetailSession = (
  options: Omit<Urql.UseQueryArgs<DetailSessionQueryVariables>, 'query'>,
) =>
  Urql.useQuery<DetailSessionQuery, DetailSessionQueryVariables>({
    query: DetailSessionDocument,
    ...options,
  })
export interface GqlOperations {
  detailSession: (
    variables: DetailSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DetailSessionQuery, DetailSessionQueryVariables>
  >
}
interface GqlOperationsFactory {
  detailSession: (
    client: Urql.Client,
  ) => (
    variables: DetailSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DetailSessionQuery, DetailSessionQueryVariables>
  >
}
gqlOperationsFactory.detailSession = client => (variables, context) =>
  client.query(DetailSessionDocument, variables, context).toPromise() as any
export const LbMediasoupUrlDocument = gql`
  query lbMediasoupUrl($sessionId: ID!) {
    lbMediasoupUrl(sessionId: $sessionId)
  }
`
export const useGqlLbMediasoupUrl = (
  options: Omit<Urql.UseQueryArgs<LbMediasoupUrlQueryVariables>, 'query'>,
) =>
  Urql.useQuery<LbMediasoupUrlQuery, LbMediasoupUrlQueryVariables>({
    query: LbMediasoupUrlDocument,
    ...options,
  })
export interface GqlOperations {
  lbMediasoupUrl: (
    variables: LbMediasoupUrlQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<LbMediasoupUrlQuery, LbMediasoupUrlQueryVariables>
  >
}
interface GqlOperationsFactory {
  lbMediasoupUrl: (
    client: Urql.Client,
  ) => (
    variables: LbMediasoupUrlQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<LbMediasoupUrlQuery, LbMediasoupUrlQueryVariables>
  >
}
gqlOperationsFactory.lbMediasoupUrl = client => (variables, context) =>
  client.query(LbMediasoupUrlDocument, variables, context).toPromise() as any
export const SessionRoleDocument = gql`
  query sessionRole($id: ID!) {
    sessionRole(sessionId: $id) {
      permissions
      name
    }
  }
`
export const useGqlSessionRole = (
  options: Omit<Urql.UseQueryArgs<SessionRoleQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SessionRoleQuery, SessionRoleQueryVariables>({
    query: SessionRoleDocument,
    ...options,
  })
export interface GqlOperations {
  sessionRole: (
    variables: SessionRoleQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SessionRoleQuery, SessionRoleQueryVariables>
  >
}
interface GqlOperationsFactory {
  sessionRole: (
    client: Urql.Client,
  ) => (
    variables: SessionRoleQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SessionRoleQuery, SessionRoleQueryVariables>
  >
}
gqlOperationsFactory.sessionRole = client => (variables, context) =>
  client.query(SessionRoleDocument, variables, context).toPromise() as any
export const SearchResourceInSessionDocument = gql`
  query searchResourceInSession(
    $sessionId: ID!
    $filter: ResourceInSessionFilter
    $order: [ResourceInSessionOrderBy!]
    $page: Pagination
  ) {
    searchResourceInSession(
      sessionId: $sessionId
      filter: $filter
      order: $order
      page: $page
    ) {
      ...resourceInSession
    }
  }
  ${ResourceInSessionFragmentDoc}
`
export const useGqlSearchResourceInSession = (
  options: Omit<
    Urql.UseQueryArgs<SearchResourceInSessionQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchResourceInSessionQuery,
    SearchResourceInSessionQueryVariables
  >({ query: SearchResourceInSessionDocument, ...options })
export interface GqlOperations {
  searchResourceInSession: (
    variables: SearchResourceInSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchResourceInSessionQuery,
      SearchResourceInSessionQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchResourceInSession: (
    client: Urql.Client,
  ) => (
    variables: SearchResourceInSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchResourceInSessionQuery,
      SearchResourceInSessionQueryVariables
    >
  >
}
gqlOperationsFactory.searchResourceInSession = client => (variables, context) =>
  client
    .query(SearchResourceInSessionDocument, variables, context)
    .toPromise() as any
export const CountSessionDocument = gql`
  query countSession($filter: SessionFilter) {
    countSession(filter: $filter)
  }
`
export const useGqlCountSession = (
  options?: Omit<Urql.UseQueryArgs<CountSessionQueryVariables>, 'query'>,
) =>
  Urql.useQuery<CountSessionQuery, CountSessionQueryVariables | void>({
    query: CountSessionDocument,
    ...options,
  })
export interface GqlOperations {
  countSession: (
    variables?: CountSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountSessionQuery, CountSessionQueryVariables>
  >
}
interface GqlOperationsFactory {
  countSession: (
    client: Urql.Client,
  ) => (
    variables?: CountSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CountSessionQuery, CountSessionQueryVariables>
  >
}
gqlOperationsFactory.countSession = client => (variables, context) =>
  client.query(CountSessionDocument, variables, context).toPromise() as any
export const DeleteInsightEngineDocument = gql`
  mutation deleteInsightEngine($id: ID!) {
    deleteInsightEngine(id: $id)
  }
`
export const useGqlDeleteInsightEngine = () =>
  Urql.useMutation<
    DeleteInsightEngineMutation,
    DeleteInsightEngineMutationVariables
  >(DeleteInsightEngineDocument)
export interface GqlOperations {
  deleteInsightEngine: (
    variables: DeleteInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteInsightEngineMutation,
      DeleteInsightEngineMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteInsightEngine: (
    client: Urql.Client,
  ) => (
    variables: DeleteInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteInsightEngineMutation,
      DeleteInsightEngineMutationVariables
    >
  >
}
gqlOperationsFactory.deleteInsightEngine = client => (variables, context) =>
  client
    .mutation(DeleteInsightEngineDocument, variables, context)
    .toPromise() as any
export const SearchInsightEngineDocument = gql`
  query searchInsightEngine(
    $filter: InsightEngineFilter
    $order: [InsightEngineOrderBy!]
    $page: Pagination
  ) {
    searchInsightEngine(filter: $filter, order: $order, page: $page) {
      id
      name
      createdAt
      createdBy {
        id
        name
      }
    }
  }
`
export const useGqlSearchInsightEngine = (
  options?: Omit<Urql.UseQueryArgs<SearchInsightEngineQueryVariables>, 'query'>,
) =>
  Urql.useQuery<
    SearchInsightEngineQuery,
    SearchInsightEngineQueryVariables | void
  >({ query: SearchInsightEngineDocument, ...options })
export interface GqlOperations {
  searchInsightEngine: (
    variables?: SearchInsightEngineQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchInsightEngineQuery,
      SearchInsightEngineQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchInsightEngine: (
    client: Urql.Client,
  ) => (
    variables?: SearchInsightEngineQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchInsightEngineQuery,
      SearchInsightEngineQueryVariables
    >
  >
}
gqlOperationsFactory.searchInsightEngine = client => (variables, context) =>
  client
    .query(SearchInsightEngineDocument, variables, context)
    .toPromise() as any
export const SearchSessionDocument = gql`
  query searchSession(
    $filter: SessionFilter
    $order: [SessionOrderBy!]
    $page: Pagination
  ) {
    searchSession(filter: $filter, order: $order, page: $page) {
      ...sessionInfo
    }
  }
  ${SessionInfoFragmentDoc}
`
export const useGqlSearchSession = (
  options?: Omit<Urql.UseQueryArgs<SearchSessionQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchSessionQuery, SearchSessionQueryVariables | void>({
    query: SearchSessionDocument,
    ...options,
  })
export interface GqlOperations {
  searchSession: (
    variables?: SearchSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchSessionQuery, SearchSessionQueryVariables>
  >
}
interface GqlOperationsFactory {
  searchSession: (
    client: Urql.Client,
  ) => (
    variables?: SearchSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchSessionQuery, SearchSessionQueryVariables>
  >
}
gqlOperationsFactory.searchSession = client => (variables, context) =>
  client.query(SearchSessionDocument, variables, context).toPromise() as any
export const RegisterDocument = gql`
  mutation register($data: Register!) {
    register(data: $data)
  }
`
export const useGqlRegister = () =>
  Urql.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
  )
export interface GqlOperations {
  register: (
    variables: RegisterMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<RegisterMutation, RegisterMutationVariables>
  >
}
interface GqlOperationsFactory {
  register: (
    client: Urql.Client,
  ) => (
    variables: RegisterMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<RegisterMutation, RegisterMutationVariables>
  >
}
gqlOperationsFactory.register = client => (variables, context) =>
  client.mutation(RegisterDocument, variables, context).toPromise() as any
export const ResolveRegisterDocument = gql`
  mutation resolveRegister($data: CheckTicket!) {
    resolveRegister(data: $data) {
      ...authInfo
    }
  }
  ${AuthInfoFragmentDoc}
`
export const useGqlResolveRegister = () =>
  Urql.useMutation<ResolveRegisterMutation, ResolveRegisterMutationVariables>(
    ResolveRegisterDocument,
  )
export interface GqlOperations {
  resolveRegister: (
    variables: ResolveRegisterMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ResolveRegisterMutation,
      ResolveRegisterMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  resolveRegister: (
    client: Urql.Client,
  ) => (
    variables: ResolveRegisterMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ResolveRegisterMutation,
      ResolveRegisterMutationVariables
    >
  >
}
gqlOperationsFactory.resolveRegister = client => (variables, context) =>
  client
    .mutation(ResolveRegisterDocument, variables, context)
    .toPromise() as any
export const AnswerJoinRequestDocument = gql`
  mutation answerJoinRequest(
    $sessionId: ID!
    $waitingUserId: ID!
    $isAccepted: Boolean!
  ) {
    answerJoinRequest(
      sessionId: $sessionId
      waitingUserId: $waitingUserId
      isAccepted: $isAccepted
    )
  }
`
export const useGqlAnswerJoinRequest = () =>
  Urql.useMutation<
    AnswerJoinRequestMutation,
    AnswerJoinRequestMutationVariables
  >(AnswerJoinRequestDocument)
export interface GqlOperations {
  answerJoinRequest: (
    variables: AnswerJoinRequestMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      AnswerJoinRequestMutation,
      AnswerJoinRequestMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  answerJoinRequest: (
    client: Urql.Client,
  ) => (
    variables: AnswerJoinRequestMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      AnswerJoinRequestMutation,
      AnswerJoinRequestMutationVariables
    >
  >
}
gqlOperationsFactory.answerJoinRequest = client => (variables, context) =>
  client
    .mutation(AnswerJoinRequestDocument, variables, context)
    .toPromise() as any
export const ChangeMixerRatioDocument = gql`
  mutation changeMixerRatio(
    $sessionId: ID!
    $ratio: Float!
    $viewmode: NeString!
  ) {
    changeMixerRatio(sessionId: $sessionId, ratio: $ratio, viewmode: $viewmode)
  }
`
export const useGqlChangeMixerRatio = () =>
  Urql.useMutation<ChangeMixerRatioMutation, ChangeMixerRatioMutationVariables>(
    ChangeMixerRatioDocument,
  )
export interface GqlOperations {
  changeMixerRatio: (
    variables: ChangeMixerRatioMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ChangeMixerRatioMutation,
      ChangeMixerRatioMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  changeMixerRatio: (
    client: Urql.Client,
  ) => (
    variables: ChangeMixerRatioMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ChangeMixerRatioMutation,
      ChangeMixerRatioMutationVariables
    >
  >
}
gqlOperationsFactory.changeMixerRatio = client => (variables, context) =>
  client
    .mutation(ChangeMixerRatioDocument, variables, context)
    .toPromise() as any
export const CreateInsightEngineDocument = gql`
  mutation createInsightEngine($data: CreateInsightEngine!) {
    createInsightEngine(data: $data) {
      id
      name
      createdById
    }
  }
`
export const useGqlCreateInsightEngine = () =>
  Urql.useMutation<
    CreateInsightEngineMutation,
    CreateInsightEngineMutationVariables
  >(CreateInsightEngineDocument)
export interface GqlOperations {
  createInsightEngine: (
    variables: CreateInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateInsightEngineMutation,
      CreateInsightEngineMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  createInsightEngine: (
    client: Urql.Client,
  ) => (
    variables: CreateInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateInsightEngineMutation,
      CreateInsightEngineMutationVariables
    >
  >
}
gqlOperationsFactory.createInsightEngine = client => (variables, context) =>
  client
    .mutation(CreateInsightEngineDocument, variables, context)
    .toPromise() as any
export const CreateNoteDocument = gql`
  mutation createNote($data: CreateNote!) {
    createNote(data: $data) {
      id
      orgId
      sessionId
      createdById
      content
    }
  }
`
export const useGqlCreateNote = () =>
  Urql.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(
    CreateNoteDocument,
  )
export interface GqlOperations {
  createNote: (
    variables: CreateNoteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateNoteMutation, CreateNoteMutationVariables>
  >
}
interface GqlOperationsFactory {
  createNote: (
    client: Urql.Client,
  ) => (
    variables: CreateNoteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateNoteMutation, CreateNoteMutationVariables>
  >
}
gqlOperationsFactory.createNote = client => (variables, context) =>
  client.mutation(CreateNoteDocument, variables, context).toPromise() as any
export const CreateOutputDocument = gql`
  mutation createOutput($data: CreateOutput!) {
    createOutput(data: $data) {
      ...output
    }
  }
  ${OutputFragmentDoc}
`
export const useGqlCreateOutput = () =>
  Urql.useMutation<CreateOutputMutation, CreateOutputMutationVariables>(
    CreateOutputDocument,
  )
export interface GqlOperations {
  createOutput: (
    variables: CreateOutputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateOutputMutation, CreateOutputMutationVariables>
  >
}
interface GqlOperationsFactory {
  createOutput: (
    client: Urql.Client,
  ) => (
    variables: CreateOutputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<CreateOutputMutation, CreateOutputMutationVariables>
  >
}
gqlOperationsFactory.createOutput = client => (variables, context) =>
  client.mutation(CreateOutputDocument, variables, context).toPromise() as any
export const CreateOutputInSessionDocument = gql`
  mutation createOutputInSession($data: CreateOutputInSession!) {
    createOutputInSession(data: $data) {
      id
      title
      description
      thumbnail {
        url
      }
    }
  }
`
export const useGqlCreateOutputInSession = () =>
  Urql.useMutation<
    CreateOutputInSessionMutation,
    CreateOutputInSessionMutationVariables
  >(CreateOutputInSessionDocument)
export interface GqlOperations {
  createOutputInSession: (
    variables: CreateOutputInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateOutputInSessionMutation,
      CreateOutputInSessionMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  createOutputInSession: (
    client: Urql.Client,
  ) => (
    variables: CreateOutputInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateOutputInSessionMutation,
      CreateOutputInSessionMutationVariables
    >
  >
}
gqlOperationsFactory.createOutputInSession = client => (variables, context) =>
  client
    .mutation(CreateOutputInSessionDocument, variables, context)
    .toPromise() as any
export const CreateRecordingOutputInSessionDocument = gql`
  mutation createRecordingOutputInSession(
    $sessionId: ID!
    $mixerQuery: String
    $roomType: NeString!
  ) {
    createRecordingOutputInSession(
      sessionId: $sessionId
      mixerQuery: $mixerQuery
      roomType: $roomType
    )
  }
`
export const useGqlCreateRecordingOutputInSession = () =>
  Urql.useMutation<
    CreateRecordingOutputInSessionMutation,
    CreateRecordingOutputInSessionMutationVariables
  >(CreateRecordingOutputInSessionDocument)
export interface GqlOperations {
  createRecordingOutputInSession: (
    variables: CreateRecordingOutputInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateRecordingOutputInSessionMutation,
      CreateRecordingOutputInSessionMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  createRecordingOutputInSession: (
    client: Urql.Client,
  ) => (
    variables: CreateRecordingOutputInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateRecordingOutputInSessionMutation,
      CreateRecordingOutputInSessionMutationVariables
    >
  >
}
gqlOperationsFactory.createRecordingOutputInSession =
  client => (variables, context) =>
    client
      .mutation(CreateRecordingOutputInSessionDocument, variables, context)
      .toPromise() as any
export const CreateResourceInInsightEngineDocument = gql`
  mutation createResourceInInsightEngine(
    $data: CreateResourceInInsightEngine!
  ) {
    createResourceInInsightEngine(data: $data) {
      id
      insightEngineId
      resourceId
      note
      isFromBot
      isDownloading
      resource {
        id
        name
        duration
        url
        thumbnailResource {
          url
        }
      }
      status
    }
  }
`
export const useGqlCreateResourceInInsightEngine = () =>
  Urql.useMutation<
    CreateResourceInInsightEngineMutation,
    CreateResourceInInsightEngineMutationVariables
  >(CreateResourceInInsightEngineDocument)
export interface GqlOperations {
  createResourceInInsightEngine: (
    variables: CreateResourceInInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateResourceInInsightEngineMutation,
      CreateResourceInInsightEngineMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  createResourceInInsightEngine: (
    client: Urql.Client,
  ) => (
    variables: CreateResourceInInsightEngineMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CreateResourceInInsightEngineMutation,
      CreateResourceInInsightEngineMutationVariables
    >
  >
}
gqlOperationsFactory.createResourceInInsightEngine =
  client => (variables, context) =>
    client
      .mutation(CreateResourceInInsightEngineDocument, variables, context)
      .toPromise() as any
export const DeleteNoteDocument = gql`
  mutation deleteNote($id: ID!) {
    deleteNote(id: $id)
  }
`
export const useGqlDeleteNote = () =>
  Urql.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(
    DeleteNoteDocument,
  )
export interface GqlOperations {
  deleteNote: (
    variables: DeleteNoteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteNoteMutation, DeleteNoteMutationVariables>
  >
}
interface GqlOperationsFactory {
  deleteNote: (
    client: Urql.Client,
  ) => (
    variables: DeleteNoteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteNoteMutation, DeleteNoteMutationVariables>
  >
}
gqlOperationsFactory.deleteNote = client => (variables, context) =>
  client.mutation(DeleteNoteDocument, variables, context).toPromise() as any
export const DeleteOutputDocument = gql`
  mutation deleteOutput($id: ID!) {
    deleteOutput(id: $id)
  }
`
export const useGqlDeleteOutput = () =>
  Urql.useMutation<DeleteOutputMutation, DeleteOutputMutationVariables>(
    DeleteOutputDocument,
  )
export interface GqlOperations {
  deleteOutput: (
    variables: DeleteOutputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteOutputMutation, DeleteOutputMutationVariables>
  >
}
interface GqlOperationsFactory {
  deleteOutput: (
    client: Urql.Client,
  ) => (
    variables: DeleteOutputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<DeleteOutputMutation, DeleteOutputMutationVariables>
  >
}
gqlOperationsFactory.deleteOutput = client => (variables, context) =>
  client.mutation(DeleteOutputDocument, variables, context).toPromise() as any
export const DeleteOutputInSessionDocument = gql`
  mutation deleteOutputInSession($id: ID!) {
    deleteOutputInSession(id: $id)
  }
`
export const useGqlDeleteOutputInSession = () =>
  Urql.useMutation<
    DeleteOutputInSessionMutation,
    DeleteOutputInSessionMutationVariables
  >(DeleteOutputInSessionDocument)
export interface GqlOperations {
  deleteOutputInSession: (
    variables: DeleteOutputInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteOutputInSessionMutation,
      DeleteOutputInSessionMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteOutputInSession: (
    client: Urql.Client,
  ) => (
    variables: DeleteOutputInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteOutputInSessionMutation,
      DeleteOutputInSessionMutationVariables
    >
  >
}
gqlOperationsFactory.deleteOutputInSession = client => (variables, context) =>
  client
    .mutation(DeleteOutputInSessionDocument, variables, context)
    .toPromise() as any
export const DeleteUserInSessionDocument = gql`
  mutation deleteUserInSession($userId: ID!, $sessionId: ID!) {
    deleteUserInSession(userId: $userId, sessionId: $sessionId)
  }
`
export const useGqlDeleteUserInSession = () =>
  Urql.useMutation<
    DeleteUserInSessionMutation,
    DeleteUserInSessionMutationVariables
  >(DeleteUserInSessionDocument)
export interface GqlOperations {
  deleteUserInSession: (
    variables: DeleteUserInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteUserInSessionMutation,
      DeleteUserInSessionMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteUserInSession: (
    client: Urql.Client,
  ) => (
    variables: DeleteUserInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteUserInSessionMutation,
      DeleteUserInSessionMutationVariables
    >
  >
}
gqlOperationsFactory.deleteUserInSession = client => (variables, context) =>
  client
    .mutation(DeleteUserInSessionDocument, variables, context)
    .toPromise() as any
export const InviteUserInSessionDocument = gql`
  mutation inviteUserInSession(
    $sessionId: ID!
    $observers: [Email!]
    $participants: [Email!]
  ) {
    inviteUserInSession(
      sessionId: $sessionId
      observers: $observers
      participants: $participants
    )
  }
`
export const useGqlInviteUserInSession = () =>
  Urql.useMutation<
    InviteUserInSessionMutation,
    InviteUserInSessionMutationVariables
  >(InviteUserInSessionDocument)
export interface GqlOperations {
  inviteUserInSession: (
    variables: InviteUserInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      InviteUserInSessionMutation,
      InviteUserInSessionMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  inviteUserInSession: (
    client: Urql.Client,
  ) => (
    variables: InviteUserInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      InviteUserInSessionMutation,
      InviteUserInSessionMutationVariables
    >
  >
}
gqlOperationsFactory.inviteUserInSession = client => (variables, context) =>
  client
    .mutation(InviteUserInSessionDocument, variables, context)
    .toPromise() as any
export const JoinFromInviteDocument = gql`
  mutation joinFromInvite($id: ID!) {
    joinFromInvite(sessionId: $id)
  }
`
export const useGqlJoinFromInvite = () =>
  Urql.useMutation<JoinFromInviteMutation, JoinFromInviteMutationVariables>(
    JoinFromInviteDocument,
  )
export interface GqlOperations {
  joinFromInvite: (
    variables: JoinFromInviteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      JoinFromInviteMutation,
      JoinFromInviteMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  joinFromInvite: (
    client: Urql.Client,
  ) => (
    variables: JoinFromInviteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      JoinFromInviteMutation,
      JoinFromInviteMutationVariables
    >
  >
}
gqlOperationsFactory.joinFromInvite = client => (variables, context) =>
  client.mutation(JoinFromInviteDocument, variables, context).toPromise() as any
export const ReauthenticateOutputDocument = gql`
  mutation reauthenticateOutput($id: ID!, $data: ReauthenticateOutputData!) {
    reauthenticateOutput(id: $id, data: $data) {
      id
    }
  }
`
export const useGqlReauthenticateOutput = () =>
  Urql.useMutation<
    ReauthenticateOutputMutation,
    ReauthenticateOutputMutationVariables
  >(ReauthenticateOutputDocument)
export interface GqlOperations {
  reauthenticateOutput: (
    variables: ReauthenticateOutputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ReauthenticateOutputMutation,
      ReauthenticateOutputMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  reauthenticateOutput: (
    client: Urql.Client,
  ) => (
    variables: ReauthenticateOutputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      ReauthenticateOutputMutation,
      ReauthenticateOutputMutationVariables
    >
  >
}
gqlOperationsFactory.reauthenticateOutput = client => (variables, context) =>
  client
    .mutation(ReauthenticateOutputDocument, variables, context)
    .toPromise() as any
export const RequestJoinDocument = gql`
  mutation requestJoin(
    $sessionId: ID!
    $socketId: ID!
    $sessionRole: SessionRoleName!
  ) {
    requestJoin(
      sessionId: $sessionId
      socketId: $socketId
      sessionRole: $sessionRole
    )
  }
`
export const useGqlRequestJoin = () =>
  Urql.useMutation<RequestJoinMutation, RequestJoinMutationVariables>(
    RequestJoinDocument,
  )
export interface GqlOperations {
  requestJoin: (
    variables: RequestJoinMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<RequestJoinMutation, RequestJoinMutationVariables>
  >
}
interface GqlOperationsFactory {
  requestJoin: (
    client: Urql.Client,
  ) => (
    variables: RequestJoinMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<RequestJoinMutation, RequestJoinMutationVariables>
  >
}
gqlOperationsFactory.requestJoin = client => (variables, context) =>
  client.mutation(RequestJoinDocument, variables, context).toPromise() as any
export const SearchInsightEngineOfResourceDocument = gql`
  query searchInsightEngineOfResource(
    $resourceId: ID!
    $filter: ResourceInInsightEngineFilter
    $order: [ResourceInInsightEngineOrderBy!]
    $page: Pagination
  ) {
    searchInsightEngineOfResource(
      resourceId: $resourceId
      filter: $filter
      order: $order
      page: $page
    ) {
      id
      insightEngineId
    }
  }
`
export const useGqlSearchInsightEngineOfResource = (
  options: Omit<
    Urql.UseQueryArgs<SearchInsightEngineOfResourceQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchInsightEngineOfResourceQuery,
    SearchInsightEngineOfResourceQueryVariables
  >({ query: SearchInsightEngineOfResourceDocument, ...options })
export interface GqlOperations {
  searchInsightEngineOfResource: (
    variables: SearchInsightEngineOfResourceQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchInsightEngineOfResourceQuery,
      SearchInsightEngineOfResourceQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchInsightEngineOfResource: (
    client: Urql.Client,
  ) => (
    variables: SearchInsightEngineOfResourceQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchInsightEngineOfResourceQuery,
      SearchInsightEngineOfResourceQueryVariables
    >
  >
}
gqlOperationsFactory.searchInsightEngineOfResource =
  client => (variables, context) =>
    client
      .query(SearchInsightEngineOfResourceDocument, variables, context)
      .toPromise() as any
export const SearchNoteDocument = gql`
  query searchNote(
    $sessionId: ID!
    $filter: NoteFilter
    $order: [NoteOrderBy!]
    $page: Pagination
  ) {
    searchNote(
      sessionId: $sessionId
      filter: $filter
      order: $order
      page: $page
    ) {
      id
      content
      createdBy {
        name
        avatarUrl
      }
    }
  }
`
export const useGqlSearchNote = (
  options: Omit<Urql.UseQueryArgs<SearchNoteQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchNoteQuery, SearchNoteQueryVariables>({
    query: SearchNoteDocument,
    ...options,
  })
export interface GqlOperations {
  searchNote: (
    variables: SearchNoteQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SearchNoteQuery, SearchNoteQueryVariables>>
}
interface GqlOperationsFactory {
  searchNote: (
    client: Urql.Client,
  ) => (
    variables: SearchNoteQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<Urql.OperationResult<SearchNoteQuery, SearchNoteQueryVariables>>
}
gqlOperationsFactory.searchNote = client => (variables, context) =>
  client.query(SearchNoteDocument, variables, context).toPromise() as any
export const SearchOutputDocument = gql`
  query searchOutput(
    $sessionId: ID!
    $filter: OutputFilter
    $order: [OutputOrderBy!]
    $page: Pagination
  ) {
    searchOutput(
      sessionId: $sessionId
      filter: $filter
      order: $order
      page: $page
    ) {
      ...output
    }
  }
  ${OutputFragmentDoc}
`
export const useGqlSearchOutput = (
  options: Omit<Urql.UseQueryArgs<SearchOutputQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchOutputQuery, SearchOutputQueryVariables>({
    query: SearchOutputDocument,
    ...options,
  })
export interface GqlOperations {
  searchOutput: (
    variables: SearchOutputQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchOutputQuery, SearchOutputQueryVariables>
  >
}
interface GqlOperationsFactory {
  searchOutput: (
    client: Urql.Client,
  ) => (
    variables: SearchOutputQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchOutputQuery, SearchOutputQueryVariables>
  >
}
gqlOperationsFactory.searchOutput = client => (variables, context) =>
  client.query(SearchOutputDocument, variables, context).toPromise() as any
export const SearchOutputInSessionDocument = gql`
  query searchOutputInSession(
    $sessionId: ID!
    $filter: OutputInSessionFilter
    $order: [OutputInSessionOrderBy!]
    $page: Pagination
  ) {
    searchOutputInSession(
      sessionId: $sessionId
      filter: $filter
      order: $order
      page: $page
    ) {
      ...outputInSession
    }
  }
  ${OutputInSessionFragmentDoc}
`
export const useGqlSearchOutputInSession = (
  options: Omit<
    Urql.UseQueryArgs<SearchOutputInSessionQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    SearchOutputInSessionQuery,
    SearchOutputInSessionQueryVariables
  >({ query: SearchOutputInSessionDocument, ...options })
export interface GqlOperations {
  searchOutputInSession: (
    variables: SearchOutputInSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchOutputInSessionQuery,
      SearchOutputInSessionQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchOutputInSession: (
    client: Urql.Client,
  ) => (
    variables: SearchOutputInSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchOutputInSessionQuery,
      SearchOutputInSessionQueryVariables
    >
  >
}
gqlOperationsFactory.searchOutputInSession = client => (variables, context) =>
  client
    .query(SearchOutputInSessionDocument, variables, context)
    .toPromise() as any
export const SearchUserInSessionDocument = gql`
  query searchUserInSession(
    $sessionId: ID!
    $filter: UserInSessionFilter
    $order: [UserInSessionOrderBy!]
    $page: Pagination
  ) {
    searchUserInSession(
      sessionId: $sessionId
      filter: $filter
      order: $order
      page: $page
    ) {
      ...userInSession
    }
  }
  ${UserInSessionFragmentDoc}
`
export const useGqlSearchUserInSession = (
  options: Omit<Urql.UseQueryArgs<SearchUserInSessionQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchUserInSessionQuery, SearchUserInSessionQueryVariables>({
    query: SearchUserInSessionDocument,
    ...options,
  })
export interface GqlOperations {
  searchUserInSession: (
    variables: SearchUserInSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchUserInSessionQuery,
      SearchUserInSessionQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchUserInSession: (
    client: Urql.Client,
  ) => (
    variables: SearchUserInSessionQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchUserInSessionQuery,
      SearchUserInSessionQueryVariables
    >
  >
}
gqlOperationsFactory.searchUserInSession = client => (variables, context) =>
  client
    .query(SearchUserInSessionDocument, variables, context)
    .toPromise() as any
export const SearchWaitingUserDocument = gql`
  query searchWaitingUser(
    $sessionId: ID!
    $filter: WaitingUserFilter
    $order: [WaitingUserOrderBy!]
    $page: Pagination
  ) {
    searchWaitingUser(
      sessionId: $sessionId
      filter: $filter
      order: $order
      page: $page
    ) {
      id
      sessionRole
      user {
        avatarUrl
        name
      }
    }
  }
`
export const useGqlSearchWaitingUser = (
  options: Omit<Urql.UseQueryArgs<SearchWaitingUserQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchWaitingUserQuery, SearchWaitingUserQueryVariables>({
    query: SearchWaitingUserDocument,
    ...options,
  })
export interface GqlOperations {
  searchWaitingUser: (
    variables: SearchWaitingUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchWaitingUserQuery,
      SearchWaitingUserQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  searchWaitingUser: (
    client: Urql.Client,
  ) => (
    variables: SearchWaitingUserQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SearchWaitingUserQuery,
      SearchWaitingUserQueryVariables
    >
  >
}
gqlOperationsFactory.searchWaitingUser = client => (variables, context) =>
  client.query(SearchWaitingUserDocument, variables, context).toPromise() as any
export const SessionPlanDocument = gql`
  query sessionPlan($sessionId: ID!) {
    sessionPlan(sessionId: $sessionId) {
      ...currentPlan
    }
  }
  ${CurrentPlanFragmentDoc}
`
export const useGqlSessionPlan = (
  options: Omit<Urql.UseQueryArgs<SessionPlanQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SessionPlanQuery, SessionPlanQueryVariables>({
    query: SessionPlanDocument,
    ...options,
  })
export interface GqlOperations {
  sessionPlan: (
    variables: SessionPlanQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SessionPlanQuery, SessionPlanQueryVariables>
  >
}
interface GqlOperationsFactory {
  sessionPlan: (
    client: Urql.Client,
  ) => (
    variables: SessionPlanQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SessionPlanQuery, SessionPlanQueryVariables>
  >
}
gqlOperationsFactory.sessionPlan = client => (variables, context) =>
  client.query(SessionPlanDocument, variables, context).toPromise() as any
export const StartOutputsDocument = gql`
  mutation startOutputs($sessionId: ID!, $outputInSessionIds: [ID!]) {
    startOutputs(sessionId: $sessionId, outputInSessionIds: $outputInSessionIds)
  }
`
export const useGqlStartOutputs = () =>
  Urql.useMutation<StartOutputsMutation, StartOutputsMutationVariables>(
    StartOutputsDocument,
  )
export interface GqlOperations {
  startOutputs: (
    variables: StartOutputsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<StartOutputsMutation, StartOutputsMutationVariables>
  >
}
interface GqlOperationsFactory {
  startOutputs: (
    client: Urql.Client,
  ) => (
    variables: StartOutputsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<StartOutputsMutation, StartOutputsMutationVariables>
  >
}
gqlOperationsFactory.startOutputs = client => (variables, context) =>
  client.mutation(StartOutputsDocument, variables, context).toPromise() as any
export const StartRecordingDocument = gql`
  mutation startRecording(
    $sessionId: ID!
    $queries: [NeString!]
    $roomType: String
  ) {
    startRecording(
      sessionId: $sessionId
      queries: $queries
      roomType: $roomType
    )
  }
`
export const useGqlStartRecording = () =>
  Urql.useMutation<StartRecordingMutation, StartRecordingMutationVariables>(
    StartRecordingDocument,
  )
export interface GqlOperations {
  startRecording: (
    variables: StartRecordingMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      StartRecordingMutation,
      StartRecordingMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  startRecording: (
    client: Urql.Client,
  ) => (
    variables: StartRecordingMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      StartRecordingMutation,
      StartRecordingMutationVariables
    >
  >
}
gqlOperationsFactory.startRecording = client => (variables, context) =>
  client.mutation(StartRecordingDocument, variables, context).toPromise() as any
export const StopOutputsDocument = gql`
  mutation stopOutputs($sessionId: ID!, $outputInSessionIds: [ID!]) {
    stopOutputs(sessionId: $sessionId, outputInSessionIds: $outputInSessionIds)
  }
`
export const useGqlStopOutputs = () =>
  Urql.useMutation<StopOutputsMutation, StopOutputsMutationVariables>(
    StopOutputsDocument,
  )
export interface GqlOperations {
  stopOutputs: (
    variables: StopOutputsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<StopOutputsMutation, StopOutputsMutationVariables>
  >
}
interface GqlOperationsFactory {
  stopOutputs: (
    client: Urql.Client,
  ) => (
    variables: StopOutputsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<StopOutputsMutation, StopOutputsMutationVariables>
  >
}
gqlOperationsFactory.stopOutputs = client => (variables, context) =>
  client.mutation(StopOutputsDocument, variables, context).toPromise() as any
export const StopRecordingDocument = gql`
  mutation stopRecording(
    $sessionId: ID!
    $queries: [NeString!]
    $roomType: String
  ) {
    stopRecording(sessionId: $sessionId, queries: $queries, roomType: $roomType)
  }
`
export const useGqlStopRecording = () =>
  Urql.useMutation<StopRecordingMutation, StopRecordingMutationVariables>(
    StopRecordingDocument,
  )
export interface GqlOperations {
  stopRecording: (
    variables: StopRecordingMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<StopRecordingMutation, StopRecordingMutationVariables>
  >
}
interface GqlOperationsFactory {
  stopRecording: (
    client: Urql.Client,
  ) => (
    variables: StopRecordingMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<StopRecordingMutation, StopRecordingMutationVariables>
  >
}
gqlOperationsFactory.stopRecording = client => (variables, context) =>
  client.mutation(StopRecordingDocument, variables, context).toPromise() as any
export const SwitchRoleDocument = gql`
  mutation switchRole($sessionId: ID!, $role: String!) {
    switchRole(sessionId: $sessionId, role: $role)
  }
`
export const useGqlSwitchRole = () =>
  Urql.useMutation<SwitchRoleMutation, SwitchRoleMutationVariables>(
    SwitchRoleDocument,
  )
export interface GqlOperations {
  switchRole: (
    variables: SwitchRoleMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SwitchRoleMutation, SwitchRoleMutationVariables>
  >
}
interface GqlOperationsFactory {
  switchRole: (
    client: Urql.Client,
  ) => (
    variables: SwitchRoleMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SwitchRoleMutation, SwitchRoleMutationVariables>
  >
}
gqlOperationsFactory.switchRole = client => (variables, context) =>
  client.mutation(SwitchRoleDocument, variables, context).toPromise() as any
export const UpdateNoteDocument = gql`
  mutation updateNote($id: ID!, $data: UpdateNote!) {
    updateNote(id: $id, data: $data) {
      id
      orgId
      sessionId
      createdById
      content
    }
  }
`
export const useGqlUpdateNote = () =>
  Urql.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(
    UpdateNoteDocument,
  )
export interface GqlOperations {
  updateNote: (
    variables: UpdateNoteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateNoteMutation, UpdateNoteMutationVariables>
  >
}
interface GqlOperationsFactory {
  updateNote: (
    client: Urql.Client,
  ) => (
    variables: UpdateNoteMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateNoteMutation, UpdateNoteMutationVariables>
  >
}
gqlOperationsFactory.updateNote = client => (variables, context) =>
  client.mutation(UpdateNoteDocument, variables, context).toPromise() as any
export const UpdateOutputInSessionDocument = gql`
  mutation updateOutputInSession($id: ID!, $data: UpdateOutputInSession!) {
    updateOutputInSession(id: $id, data: $data) {
      id
      title
      description
      thumbnail {
        url
      }
    }
  }
`
export const useGqlUpdateOutputInSession = () =>
  Urql.useMutation<
    UpdateOutputInSessionMutation,
    UpdateOutputInSessionMutationVariables
  >(UpdateOutputInSessionDocument)
export interface GqlOperations {
  updateOutputInSession: (
    variables: UpdateOutputInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateOutputInSessionMutation,
      UpdateOutputInSessionMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateOutputInSession: (
    client: Urql.Client,
  ) => (
    variables: UpdateOutputInSessionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateOutputInSessionMutation,
      UpdateOutputInSessionMutationVariables
    >
  >
}
gqlOperationsFactory.updateOutputInSession = client => (variables, context) =>
  client
    .mutation(UpdateOutputInSessionDocument, variables, context)
    .toPromise() as any
export const UpdateOutputDocument = gql`
  mutation updateOutput(
    $sessionId: ID!
    $id: ID!
    $name: NeString
    $data: Json
  ) {
    updateOutput(sessionId: $sessionId, id: $id, name: $name, data: $data) {
      ...output
    }
  }
  ${OutputFragmentDoc}
`
export const useGqlUpdateOutput = () =>
  Urql.useMutation<UpdateOutputMutation, UpdateOutputMutationVariables>(
    UpdateOutputDocument,
  )
export interface GqlOperations {
  updateOutput: (
    variables: UpdateOutputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateOutputMutation, UpdateOutputMutationVariables>
  >
}
interface GqlOperationsFactory {
  updateOutput: (
    client: Urql.Client,
  ) => (
    variables: UpdateOutputMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpdateOutputMutation, UpdateOutputMutationVariables>
  >
}
gqlOperationsFactory.updateOutput = client => (variables, context) =>
  client.mutation(UpdateOutputDocument, variables, context).toPromise() as any
export const UpgradePlanDocument = gql`
  mutation upgradePlan($data: UpgradePlan!) {
    upgradePlan(data: $data)
  }
`
export const useGqlUpgradePlan = () =>
  Urql.useMutation<UpgradePlanMutation, UpgradePlanMutationVariables>(
    UpgradePlanDocument,
  )
export interface GqlOperations {
  upgradePlan: (
    variables: UpgradePlanMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpgradePlanMutation, UpgradePlanMutationVariables>
  >
}
interface GqlOperationsFactory {
  upgradePlan: (
    client: Urql.Client,
  ) => (
    variables: UpgradePlanMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<UpgradePlanMutation, UpgradePlanMutationVariables>
  >
}
gqlOperationsFactory.upgradePlan = client => (variables, context) =>
  client.mutation(UpgradePlanDocument, variables, context).toPromise() as any
export const CancelSubscriptionDocument = gql`
  mutation cancelSubscription($orgId: ID!) {
    cancelSubscription(orgId: $orgId)
  }
`
export const useGqlCancelSubscription = () =>
  Urql.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument)
export interface GqlOperations {
  cancelSubscription: (
    variables: CancelSubscriptionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CancelSubscriptionMutation,
      CancelSubscriptionMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  cancelSubscription: (
    client: Urql.Client,
  ) => (
    variables: CancelSubscriptionMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      CancelSubscriptionMutation,
      CancelSubscriptionMutationVariables
    >
  >
}
gqlOperationsFactory.cancelSubscription = client => (variables, context) =>
  client
    .mutation(CancelSubscriptionDocument, variables, context)
    .toPromise() as any
export const BuyMoreStorageDocument = gql`
  mutation buyMoreStorage($data: BuyMoreStorage!, $at: PInt!) {
    buyMoreStorage(data: $data, at: $at)
  }
`
export const useGqlBuyMoreStorage = () =>
  Urql.useMutation<BuyMoreStorageMutation, BuyMoreStorageMutationVariables>(
    BuyMoreStorageDocument,
  )
export interface GqlOperations {
  buyMoreStorage: (
    variables: BuyMoreStorageMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      BuyMoreStorageMutation,
      BuyMoreStorageMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  buyMoreStorage: (
    client: Urql.Client,
  ) => (
    variables: BuyMoreStorageMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      BuyMoreStorageMutation,
      BuyMoreStorageMutationVariables
    >
  >
}
gqlOperationsFactory.buyMoreStorage = client => (variables, context) =>
  client.mutation(BuyMoreStorageDocument, variables, context).toPromise() as any
export const BuyMoreMinutesDocument = gql`
  mutation buyMoreMinutes($data: BuyMoreMinutes!, $at: PInt!) {
    buyMoreMinutes(data: $data, at: $at)
  }
`
export const useGqlBuyMoreMinutes = () =>
  Urql.useMutation<BuyMoreMinutesMutation, BuyMoreMinutesMutationVariables>(
    BuyMoreMinutesDocument,
  )
export interface GqlOperations {
  buyMoreMinutes: (
    variables: BuyMoreMinutesMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      BuyMoreMinutesMutation,
      BuyMoreMinutesMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  buyMoreMinutes: (
    client: Urql.Client,
  ) => (
    variables: BuyMoreMinutesMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      BuyMoreMinutesMutation,
      BuyMoreMinutesMutationVariables
    >
  >
}
gqlOperationsFactory.buyMoreMinutes = client => (variables, context) =>
  client.mutation(BuyMoreMinutesDocument, variables, context).toPromise() as any
export const BuyMoreStorageComputeDocument = gql`
  query buyMoreStorageCompute($data: BuyMoreStorage!) {
    buyMoreStorageCompute(data: $data) {
      ...computedPrice
    }
  }
  ${ComputedPriceFragmentDoc}
`
export const useGqlBuyMoreStorageCompute = (
  options: Omit<
    Urql.UseQueryArgs<BuyMoreStorageComputeQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    BuyMoreStorageComputeQuery,
    BuyMoreStorageComputeQueryVariables
  >({ query: BuyMoreStorageComputeDocument, ...options })
export interface GqlOperations {
  buyMoreStorageCompute: (
    variables: BuyMoreStorageComputeQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      BuyMoreStorageComputeQuery,
      BuyMoreStorageComputeQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  buyMoreStorageCompute: (
    client: Urql.Client,
  ) => (
    variables: BuyMoreStorageComputeQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      BuyMoreStorageComputeQuery,
      BuyMoreStorageComputeQueryVariables
    >
  >
}
gqlOperationsFactory.buyMoreStorageCompute = client => (variables, context) =>
  client
    .query(BuyMoreStorageComputeDocument, variables, context)
    .toPromise() as any
export const BuyMoreMinutesComputeDocument = gql`
  query buyMoreMinutesCompute($data: BuyMoreMinutes!) {
    buyMoreMinutesCompute(data: $data) {
      ...computedPrice
    }
  }
  ${ComputedPriceFragmentDoc}
`
export const useGqlBuyMoreMinutesCompute = (
  options: Omit<
    Urql.UseQueryArgs<BuyMoreMinutesComputeQueryVariables>,
    'query'
  >,
) =>
  Urql.useQuery<
    BuyMoreMinutesComputeQuery,
    BuyMoreMinutesComputeQueryVariables
  >({ query: BuyMoreMinutesComputeDocument, ...options })
export interface GqlOperations {
  buyMoreMinutesCompute: (
    variables: BuyMoreMinutesComputeQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      BuyMoreMinutesComputeQuery,
      BuyMoreMinutesComputeQueryVariables
    >
  >
}
interface GqlOperationsFactory {
  buyMoreMinutesCompute: (
    client: Urql.Client,
  ) => (
    variables: BuyMoreMinutesComputeQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      BuyMoreMinutesComputeQuery,
      BuyMoreMinutesComputeQueryVariables
    >
  >
}
gqlOperationsFactory.buyMoreMinutesCompute = client => (variables, context) =>
  client
    .query(BuyMoreMinutesComputeDocument, variables, context)
    .toPromise() as any
export const DeleteSubscriptionsDocument = gql`
  mutation deleteSubscriptions($orgId: ID!) {
    deleteSubscriptions(orgId: $orgId)
  }
`
export const useGqlDeleteSubscriptions = () =>
  Urql.useMutation<
    DeleteSubscriptionsMutation,
    DeleteSubscriptionsMutationVariables
  >(DeleteSubscriptionsDocument)
export interface GqlOperations {
  deleteSubscriptions: (
    variables: DeleteSubscriptionsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteSubscriptionsMutation,
      DeleteSubscriptionsMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteSubscriptions: (
    client: Urql.Client,
  ) => (
    variables: DeleteSubscriptionsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteSubscriptionsMutation,
      DeleteSubscriptionsMutationVariables
    >
  >
}
gqlOperationsFactory.deleteSubscriptions = client => (variables, context) =>
  client
    .mutation(DeleteSubscriptionsDocument, variables, context)
    .toPromise() as any
export const SearchSystemFlagDocument = gql`
  query searchSystemFlag(
    $filter: SystemFlagFilter
    $order: [SystemFlagOrderBy!]
    $page: Pagination
  ) {
    searchSystemFlag(filter: $filter, order: $order, page: $page) {
      ...systemFlag
    }
    countSystemFlag(filter: $filter)
  }
  ${SystemFlagFragmentDoc}
`
export const useGqlSearchSystemFlag = (
  options?: Omit<Urql.UseQueryArgs<SearchSystemFlagQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchSystemFlagQuery, SearchSystemFlagQueryVariables | void>({
    query: SearchSystemFlagDocument,
    ...options,
  })
export interface GqlOperations {
  searchSystemFlag: (
    variables?: SearchSystemFlagQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchSystemFlagQuery, SearchSystemFlagQueryVariables>
  >
}
interface GqlOperationsFactory {
  searchSystemFlag: (
    client: Urql.Client,
  ) => (
    variables?: SearchSystemFlagQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchSystemFlagQuery, SearchSystemFlagQueryVariables>
  >
}
gqlOperationsFactory.searchSystemFlag = client => (variables, context) =>
  client.query(SearchSystemFlagDocument, variables, context).toPromise() as any
export const UpdateSystemFlagsDocument = gql`
  mutation updateSystemFlags($data: [UpdateSystemFlag!]!) {
    updateSystemFlags(data: $data)
  }
`
export const useGqlUpdateSystemFlags = () =>
  Urql.useMutation<
    UpdateSystemFlagsMutation,
    UpdateSystemFlagsMutationVariables
  >(UpdateSystemFlagsDocument)
export interface GqlOperations {
  updateSystemFlags: (
    variables: UpdateSystemFlagsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateSystemFlagsMutation,
      UpdateSystemFlagsMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  updateSystemFlags: (
    client: Urql.Client,
  ) => (
    variables: UpdateSystemFlagsMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      UpdateSystemFlagsMutation,
      UpdateSystemFlagsMutationVariables
    >
  >
}
gqlOperationsFactory.updateSystemFlags = client => (variables, context) =>
  client
    .mutation(UpdateSystemFlagsDocument, variables, context)
    .toPromise() as any
export const DeleteOrgInviteTicketDocument = gql`
  mutation deleteOrgInviteTicket($orgId: ID!, $id: ID!) {
    deleteOrgInviteTicket(orgId: $orgId, id: $id)
  }
`
export const useGqlDeleteOrgInviteTicket = () =>
  Urql.useMutation<
    DeleteOrgInviteTicketMutation,
    DeleteOrgInviteTicketMutationVariables
  >(DeleteOrgInviteTicketDocument)
export interface GqlOperations {
  deleteOrgInviteTicket: (
    variables: DeleteOrgInviteTicketMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteOrgInviteTicketMutation,
      DeleteOrgInviteTicketMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteOrgInviteTicket: (
    client: Urql.Client,
  ) => (
    variables: DeleteOrgInviteTicketMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteOrgInviteTicketMutation,
      DeleteOrgInviteTicketMutationVariables
    >
  >
}
gqlOperationsFactory.deleteOrgInviteTicket = client => (variables, context) =>
  client
    .mutation(DeleteOrgInviteTicketDocument, variables, context)
    .toPromise() as any
export const DeleteUserInOrgDocument = gql`
  mutation deleteUserInOrg($id: ID!) {
    deleteUserInOrg(id: $id)
  }
`
export const useGqlDeleteUserInOrg = () =>
  Urql.useMutation<DeleteUserInOrgMutation, DeleteUserInOrgMutationVariables>(
    DeleteUserInOrgDocument,
  )
export interface GqlOperations {
  deleteUserInOrg: (
    variables: DeleteUserInOrgMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteUserInOrgMutation,
      DeleteUserInOrgMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  deleteUserInOrg: (
    client: Urql.Client,
  ) => (
    variables: DeleteUserInOrgMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      DeleteUserInOrgMutation,
      DeleteUserInOrgMutationVariables
    >
  >
}
gqlOperationsFactory.deleteUserInOrg = client => (variables, context) =>
  client
    .mutation(DeleteUserInOrgDocument, variables, context)
    .toPromise() as any
export const SendOrgInvitationDocument = gql`
  mutation sendOrgInvitation($orgId: ID!, $emails: [Email!]!) {
    sendOrgInvitation(orgId: $orgId, emails: $emails)
  }
`
export const useGqlSendOrgInvitation = () =>
  Urql.useMutation<
    SendOrgInvitationMutation,
    SendOrgInvitationMutationVariables
  >(SendOrgInvitationDocument)
export interface GqlOperations {
  sendOrgInvitation: (
    variables: SendOrgInvitationMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SendOrgInvitationMutation,
      SendOrgInvitationMutationVariables
    >
  >
}
interface GqlOperationsFactory {
  sendOrgInvitation: (
    client: Urql.Client,
  ) => (
    variables: SendOrgInvitationMutationVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<
      SendOrgInvitationMutation,
      SendOrgInvitationMutationVariables
    >
  >
}
gqlOperationsFactory.sendOrgInvitation = client => (variables, context) =>
  client
    .mutation(SendOrgInvitationDocument, variables, context)
    .toPromise() as any
export const SearchUserInOrgDocument = gql`
  query searchUserInOrg(
    $orgId: ID!
    $filter: UserInOrgFilter
    $order: [UserInOrgOrderBy!]
    $page: Pagination
  ) {
    searchUserInOrg(
      orgId: $orgId
      filter: $filter
      page: $page
      order: $order
    ) {
      userId
      orgId
      role
      id
      createdAt
      user {
        id
        name
        email
        avatarUrl
      }
    }
    countUserInOrg(orgId: $orgId, filter: $filter)
  }
`
export const useGqlSearchUserInOrg = (
  options: Omit<Urql.UseQueryArgs<SearchUserInOrgQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchUserInOrgQuery, SearchUserInOrgQueryVariables>({
    query: SearchUserInOrgDocument,
    ...options,
  })
export interface GqlOperations {
  searchUserInOrg: (
    variables: SearchUserInOrgQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchUserInOrgQuery, SearchUserInOrgQueryVariables>
  >
}
interface GqlOperationsFactory {
  searchUserInOrg: (
    client: Urql.Client,
  ) => (
    variables: SearchUserInOrgQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchUserInOrgQuery, SearchUserInOrgQueryVariables>
  >
}
gqlOperationsFactory.searchUserInOrg = client => (variables, context) =>
  client.query(SearchUserInOrgDocument, variables, context).toPromise() as any
export const SystemSearchOrgDocument = gql`
  query systemSearchOrg(
    $filter: OrgFilter
    $order: [OrgOrderBy!]
    $page: Pagination
  ) {
    systemSearchOrg(filter: $filter, order: $order, page: $page) {
      ...orgInfoAdmin
    }
    systemCountOrg(filter: $filter)
  }
  ${OrgInfoAdminFragmentDoc}
`
export const useGqlSystemSearchOrg = (
  options?: Omit<Urql.UseQueryArgs<SystemSearchOrgQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SystemSearchOrgQuery, SystemSearchOrgQueryVariables | void>({
    query: SystemSearchOrgDocument,
    ...options,
  })
export interface GqlOperations {
  systemSearchOrg: (
    variables?: SystemSearchOrgQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SystemSearchOrgQuery, SystemSearchOrgQueryVariables>
  >
}
interface GqlOperationsFactory {
  systemSearchOrg: (
    client: Urql.Client,
  ) => (
    variables?: SystemSearchOrgQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SystemSearchOrgQuery, SystemSearchOrgQueryVariables>
  >
}
gqlOperationsFactory.systemSearchOrg = client => (variables, context) =>
  client.query(SystemSearchOrgDocument, variables, context).toPromise() as any
export const SearchReportsDocument = gql`
  query searchReports($filter: SearchReportFilter) {
    searchReports(filter: $filter) {
      totalMinutesOfVideo
      totalNumberOfLogin
      totalOfCompletedSession
    }
  }
`
export const useGqlSearchReports = (
  options?: Omit<Urql.UseQueryArgs<SearchReportsQueryVariables>, 'query'>,
) =>
  Urql.useQuery<SearchReportsQuery, SearchReportsQueryVariables | void>({
    query: SearchReportsDocument,
    ...options,
  })
export interface GqlOperations {
  searchReports: (
    variables?: SearchReportsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchReportsQuery, SearchReportsQueryVariables>
  >
}
interface GqlOperationsFactory {
  searchReports: (
    client: Urql.Client,
  ) => (
    variables?: SearchReportsQueryVariables,
    context?: Partial<Urql.OperationContext>,
  ) => Promise<
    Urql.OperationResult<SearchReportsQuery, SearchReportsQueryVariables>
  >
}
gqlOperationsFactory.searchReports = client => (variables, context) =>
  client.query(SearchReportsDocument, variables, context).toPromise() as any
