import { Progress } from 'antd'
import clsx from 'clsx'
import { toArray } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { ChangeEvent } from 'react'
import { useEffect, useRef, useState } from 'react'
import { ulid } from 'ulidx'

import css from './UploadButton.module.scss'

import { Icon } from '../../../components/base/Icon'
import { ModalService } from '../../../components/Modal/Modal'
import { NotificationService } from '../../../components/widget/Notification'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { ModalOutStorage } from '../../../context/shared/components/ModalOutStorage'
import { NotificationOutStorage } from '../../../context/shared/components/NotificationOutStorage'
import { S } from '../../../context/store'
import {
  covertGBtoBytes,
  getTotalSizeOfFiles,
  modifyFiles,
} from '../../../context/store/studio/utils'
import type { TMediaItem } from '../../../context/store/studio/WebrtcStore'
import { showError } from '../../../utils/showError'
import type { TModifyFiles, TUploadFile } from './RightContent'

export const UploadButton = observer(
  ({
    label,
    onUploadFile,
    typeFile = 'image',
    typeResource,
  }: {
    label?: string
    isUploading?: boolean
    onUploadFile?: Function
    typeFile?: string
    typeResource: string
  }) => {
    const currentSub = S.webrtc.plan
    const uploadFile = useRef<null | HTMLInputElement>(null)
    const [statusUpload, setStatus] = useState(false)
    const [filesUploaded, setFilesUploaded] = useState<TMediaItem[]>([])
    const [totalFiles, setTotalFiles] = useState(0)
    const [percentProgress, setProgress] = useState(0)
    const isEnterprise = currentSub?.plan === 'Enterprise'
    const selectFile = () => {
      if (statusUpload) {
        return
      }
      uploadFile.current?.click()
    }

    const handleCheckStorage = (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files
      if (!files) {
        return
      }
      const storageInUse = Number(currentSub?.totalStorageUsed || '0')
      const storageTotal = Number(currentSub?.totalStorage || '0')
      const storageAvailable = covertGBtoBytes(storageTotal - storageInUse)
      const totalSize = getTotalSizeOfFiles(files)

      const modifyFile: TModifyFiles = modifyFiles({}, files)
      const listArray = toArray(modifyFile)

      if (totalSize >= storageAvailable && !isEnterprise) {
        ModalService.show(ModalOutStorage)
        return
      }
      handleChange(listArray)
    }
    const handleChange = async (files: TUploadFile[]) => {
      if (!files || files.length === 0) {
        return
      }

      const storageInUse = Number(currentSub?.totalStorageUsed || '0')
      const storageTotal = Number(currentSub?.totalStorage || '0')
      let storageAvailable = covertGBtoBytes(storageTotal - storageInUse)
      const exceedFiles: string[] = []

      setTotalFiles(files.length)
      setStatus(true)
      for (let index = 0; index < files.length; index++) {
        const file = files[index]
        if (storageAvailable > file.file.size) {
          const result = await handleUploadFile(file)
          storageAvailable = storageAvailable - file.file.size
          if (result) {
            setFilesUploaded(prev => [...prev, result])
          }
        } else {
          exceedFiles.push(file.file.name)
        }
      }
      if (exceedFiles.length > 0) {
        handleResetUpload()
        NotificationService.error({
          message: 'Failed to upload file',
          description: <NotificationOutStorage files={exceedFiles} />,
        })
      }
    }
    const countProgress = (progressEvent: ProgressEvent, id: number) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      )
      setProgress(prev => prev + percentCompleted)
    }
    const handleUploadFile = async (file: TUploadFile) => {
      try {
        const formData = new FormData()
        formData.append('file', file.file)
        const result = await S.webrtc.callApiUpload(formData, typeResource, {
          onUploadProgress: (progress: ProgressEvent) =>
            countProgress(progress, file.id),
        })
        if (result) {
          return {
            id: result.id || ulid(),
            value:
              typeof result?.resource?.url === 'string'
                ? result?.resource?.url
                : '',
            mediaType: 'image',
            name: file.file.name,
            size: result?.resource?.fileSize || 0,
            position: Number(result?.position || 1000),
            resourceId: result.resourceId,
          }
        }
        return null
      } catch (err: any) {
        setStatus(false)
        showError('Failed to upload file', err as Error)
        return
      }
    }
    const handleResetUpload = () => {
      setProgress(0)
      setStatus(false)
      setFilesUploaded([])
      setTotalFiles(0)
    }
    useEffect(() => {
      if (filesUploaded.length === totalFiles && totalFiles !== 0) {
        onUploadFile && onUploadFile(filesUploaded)
        handleResetUpload()
      }
    }, [filesUploaded])

    return (
      <WarmnessGrayButton
        className={clsx(css.BtnUpload, {
          [css.Overlay]: true,
        })}
        onClick={selectFile}
      >
        <input
          accept={
            typeFile === 'image' ? 'image/*' : 'video/mp4,video/x-m4v,video/*'
          }
          type='file'
          className='display-none'
          value=''
          onChange={handleCheckStorage}
          ref={uploadFile}
          multiple
        />
        {!statusUpload && (
          <Icon icon='icon_plus' size={14} className={css.UploadIcon} />
        )}
        {statusUpload && (
          <div className={css.IconLoading}>
            <Progress
              type='circle'
              percent={Math.ceil(percentProgress / totalFiles)}
              // percent={percentProgress}
              width={30}
              format={() => ''}
            />
          </div>
        )}
      </WarmnessGrayButton>
    )
  },
)
