import { useCallback, useState } from 'react'

import { qs } from '../../../#/shared/qs'
import { setLoadingForLogin } from '../context/actions/auth/setLoadingForLogin'

interface IFacebookLoginHook {
  client_id: string
  redirect_uri: string
  state: string
  scope: string
  loginHandler: (code: string) => Promise<void>
}

// const dec2hex = (dec: number) => ('0' + dec.toString(16)).substring(-2)

// const generateCodeVerifier = () => {
//   var array = new Uint32Array(56 / 2)
//   window.crypto.getRandomValues(array)
//   return Array.from(array, dec2hex).join('')
// }

// const sha256 = (plain: string) => {
//   const encoder = new TextEncoder()
//   const data = encoder.encode(plain)
//   return window.crypto.subtle.digest('SHA-256', data)
// }

// const base64urlencode = (a: ArrayBuffer) => {
//   var str = ''
//   var bytes = new Uint8Array(a)
//   var len = bytes.byteLength
//   for (var i = 0; i < len; i++) {
//     str += String.fromCharCode(bytes[i])
//   }
//   return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
// }

// const generateCodeChallengeFromVerifier = async () => {
//   const v = generateCodeVerifier()
//   var hashed = await sha256(v)
//   var base64encoded = base64urlencode(hashed)
//   return base64encoded
// }

export const useFacebookLogin = ({
  loginHandler,
  client_id,
  redirect_uri,
  scope,
  state,
}: IFacebookLoginHook) => {
  const [isProcessing, setIsProcessing] = useState(false)

  const getUrl = () => {
    const query = qs.stringify({
      client_id,
      redirect_uri,
      state: state + '_facebook',
      response_type: 'code',
      scope,
      // code_challenge: c,
    })
    const facebookAuthLink =
      'https://www.facebook.com/v20.0/dialog/oauth?' + query
    return facebookAuthLink
  }

  const onChangeLocalStorage = useCallback(() => {
    window.removeEventListener('storage', onChangeLocalStorage, false)
    setLoadingForLogin(true)
    const code = localStorage.getItem('facebook')
    if (code) {
      handleLoginFacebook(code)
      localStorage.removeItem('facebook')
    } else {
      setIsProcessing(false)
      setLoadingForLogin(false)
    }
  }, [])

  const handleLoginFacebook = async (code: string) => {
    setIsProcessing(true)
    await loginHandler(code)
    setIsProcessing(false)
  }

  const startLogin =
    // async
    () => {
      // const codeVerrifier = await generateCodeChallengeFromVerifier()
      const oauthUrl = getUrl()
      const width = 1000
      const height = 500
      const left = window.screen.width / 2 - width / 2
      const top = window.screen.height / 2 - height / 2
      window.open(
        oauthUrl,
        'Facebook',
        'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
          width +
          ', height=' +
          height +
          ', top=' +
          top +
          ', left=' +
          left,
      )
    }

  const facebookLogin = useCallback(() => {
    if (!isProcessing) {
      window.addEventListener('storage', onChangeLocalStorage, false)
      startLogin()
    }
  }, [isProcessing])

  return facebookLogin
}
