export const routerPaths = {
  MAIN: '/',
  NOTFOUND: '*',
  SIGNUP: '/signup',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  HOME: '/home',
  SESSION_MANAGEMENT: '/session-management',
  INSIGHT_ENGINE_MANAGEMENT: '/insight-engine-management',
  PAYMENT: '/payment',
  STUDIO: '/studio',
  ADMIN: '/admin',
  CLIENT: '/client',
  ADMIN_MEDIA_SERVERS: '/admin/media-servers',
  ADMIN_LB_INSTANCES: '/admin/instances',
  ADMIN_SYSTEM_FLAGS: '/admin/system-flags',
  ADMIN_USER_MANAGER: '/admin/user-manager',
  ADMIN_OLD_USER_MANAGER: '/admin/old-user-manager',
  ADMIN_USER_TEAM_MANAGER: '/client/user-manager',
  TEAM_USER_PROFILE_ROOT: '/client/user-profile',
  TEAM_USER_PROFILE: '/client/user-profile/:id',
  ADMIN_TEAM_PLAN: '/client/plan',
  ADMIN_ACCESS_CONTROL: '/admin/admin-access-control',
  ADMIN_USER_PROFILE_ROOT: '/admin/user-profile',
  ADMIN_USER_PROFILE: '/admin/user-profile/:id',
  TEST_LAYOUT: '/test-custom-layout',
  ADMIN_PLAN_MANAGEMENT: '/admin/plan-management',
  ADMIN_CHATBOT_SETTINGS: '/admin/chatbot-settings',
  ADMIN_CHATBOT_FORM: '/admin/chatbot-form',
  ADMIN_DISCOUNTS_PROMOTIONS: '/admin/discounts-promotions',
  REFUND_MANAGEMENT: '/admin/refund-management',
  SCHEDULE_DETAIL: '/schedule-detail/:id',
  CUSTOMIZE_UI: '/client/customize-ui',
  INSIGHT_ENGINE: '/insight-engine/:id',
  INSIGHT_ENGINE_ORIGIN: '/insight-engine',
  ADMIN_MEETING_BOT_SETTINGS: '/admin/meeting-bot-settings',
  USER_METRICS_REPORT: '/admin/user-metrics-report',
  CUSTOM_DOMAIN: '/client/custom-domain',
  CALLBACK: '/callback',
  AUTHENTICATE: '/authenticate',
}
