import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import screenfull from 'screenfull'

import css from './RightSidebar.module.scss'

import { WarmnessGrayDiv } from '#HACK_FOR_RN_ONLY/src/components/composites/WarmnessGrayDiv'

import { Icon } from '../../../components/base/Icon'
import { WarmnessGrayButton } from '../../../components/widget/WarmnessGrayButton'
import { S } from '../../../context/store'
import { Chat } from './Chat'
import { Graphic } from './Graphic'
import { Live } from './Live'
import { Media } from './Media'
import { Note } from './Note'
import { RightTabBar } from './RightTabBar'
import { Titles } from './Titles'

export const RightSider = observer(() => {
  const { selectedRightTabBarKey } = S.webrtc
  const { isViewmodeParticipant, updateDataOfStore } = S.webrtc

  const { isLightTheme, warmnessColor } = S.local
  const listTabPanel = isViewmodeParticipant
    ? [<Chat />]
    : [<Note />, <Live />, <Graphic />, <Media />, <Titles />, <Chat />]
  return (
    <div className={css.SideContent}>
      {selectedRightTabBarKey !== '' && !screenfull.isFullscreen && (
        <WarmnessGrayButton
          className={css.IconClose}
          onClick={() =>
            updateDataOfStore({
              selectedRightTabBarKey: '',
              stateVideoPreviewId: '',
            })
          }
        >
          <Icon icon='icon_arrow_right' size={12} />
        </WarmnessGrayButton>
      )}
      {!isViewmodeParticipant && <RightTabBar />}
      <div
        style={{
          background: isLightTheme ? warmnessColor : undefined,
        }}
        className={clsx(css.RightTabPanel, {
          [css.RightTabPanelNotScroll]: selectedRightTabBarKey === '',
        })}
        key={selectedRightTabBarKey || -1}
      >
        <div className={css.RightTabPanelContainer}>
          {listTabPanel.map((item, index) => {
            const tabKey = (index + 1).toString()
            return (
              <WarmnessGrayDiv
                className={clsx({
                  [css.TabPanel]: true,
                  [css.tabPanelOpen]: isViewmodeParticipant
                    ? selectedRightTabBarKey === '6'
                    : selectedRightTabBarKey === tabKey,
                })}
                key={tabKey}
              >
                {item}
              </WarmnessGrayDiv>
            )
          })}
        </div>
      </div>
    </div>
  )
})
