import type { IconName } from '../../components/base/Icon/type'
import type { SearchUserInResourceQuery } from '../../context/gql/codegen'
import type {
  IBlurItem,
  IMuteItem,
  Resource,
} from '../../context/store/insight-engine/InsightEngineStore'

export type ParamsEmitEvent = {
  eventName: string
  data: any
}

export enum ListenerEvent {
  Message = 'message',
}

export interface IVideoCard {
  name?: string
  thumbnailSrc?: { url?: string } | null
  duration?: number
  selected?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  resource: Resource
  id?: string
}

export interface IconItem {
  icon: IconName
  iconClassName?: string
  size?: number
  disableFill?: boolean
  name: string
}

export enum ESharePopupList {
  DOWNLOAD_VIDEO,
  DOWNLOAD_NOTE,
  DOWNLOAD_TRANSCRIPT,
  ADD_USER,
  EXPORT_GGDOCS,
}

export interface IOverlayItem {
  title: string
  contents: {
    type: ESharePopupList
    iconName: IconName
    iconClassName?: string
    label: string
    onClick?: () => void
    disable?: boolean
  }[]
}

export type TBottomElLoadingState = {
  [k: number]: boolean
}

export type AddMenu = {
  title: string
  items: AddMenuItem[]
}

export type AddMenuItem = {
  iconName: IconName
  iconClassName?: string
  iconSize?: number
  disableFill?: boolean
  name: string
  onClick: () => void
}

export type Message = {
  contextId: string
  senderId: string | null
  content: string
  ownerId: string
  status: string
  isChatbot: boolean
  id: string
  createdAt: Date
}

export type MessageRes = {
  chatbot: Message
  user: Message
}

export enum InsightEngineListenerEvent {
  BOT_RECORDED = 'botRecorded',
  BOT_STATUS_CHANGE = 'botStatusChange',
  DOWNLOAD_RESOURCE_IN_INSIGHT_ENGINE = 'downloadResourceInInsightEngine',
}

export type BotRecordedRes = {
  insightEngineId: string
  resourceInInsightEngine: string
  meetInfo: {
    platform: string
    meetingId: string
  }
}

export type BotStatusChangeRes = Omit<
  BotRecordedRes,
  'resourceInInsightEngine'
> & { code: string }

export type DownloadResourceInInsightEngine = {
  insightEngineId: string
  url: string
  resourceInInsightEngineId: string
}

export type ModalReplaceSpeakerProps = {
  name?: string
  id: string
  onCancel: () => void
}

export enum EReplaceType {
  ReplaceOne = 'Replace one',
  ReplaceAll = 'Replace all',
}

export interface ITimeLineItemProps {
  block: IBlurItem | IMuteItem
}

export type IDownloadingResource =
  SearchUserInResourceQuery['searchUserInResource'][0]
