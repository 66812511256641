import { io } from 'socket.io-client'

import { ToastService } from '../../../components/widget/Toast'
import { reactServerOrigin } from '../../../config'
import { S } from '../../../context/store'
import type {
  BotRecordedRes,
  BotStatusChangeRes,
  DownloadResourceInInsightEngine,
} from '../type'
import { InsightEngineListenerEvent } from '../type'
import { getLocationData } from './getLocationData'
import { getResourcesInsightEngine } from './getResourcesInsightEngine'
import { listenInsightEngineEvent } from './listenInsightEngineEvent'

const newIo = (namespace: string, query?: object) =>
  io(`${reactServerOrigin}/${namespace}`, {
    path: '/api/socket-io',
    query,
    forceNew: true,
  })

export const initInsightEngineSocket = async (ieId?: string) => {
  if (S.insightEngine.socketInstance) {
    return
  }
  const profile = S.profile.profile

  const socket = newIo('user', {
    userId: profile.id,
  })

  S.insightEngine.socketInstance = socket
  socket.on('connect', () => {
    console.log('socket insight engine Chatbot connected')
  })

  socket.on('connect_error', err => {
    console.log('socket insight engine Chatbot connect error', err.message)
  })

  socket.on('disconnect', () => {
    console.log('socket insight engine Chatbot disconnected')
  })

  setupInsightEngineSocket(ieId)

  listenInsightEngineEvent()
}

const setupInsightEngineSocket = (ieId?: string) => {
  const { insightEngineId } = getLocationData()

  const InsightEngineSocket = newIo('insightEngine', {
    insightEngineId: ieId ?? insightEngineId,
  })

  S.insightEngine.recallBotSocketInstance = InsightEngineSocket
  InsightEngineSocket.on('connect', () => {
    console.log('socket recording bot connected')
  })

  InsightEngineSocket.on('connect_error', err => {
    console.log('socket recording bot connect error', err.message)
  })

  InsightEngineSocket.on('disconnect', () => {
    console.log('socket recording bot disconnected')
  })

  InsightEngineSocket.on(
    InsightEngineListenerEvent.BOT_RECORDED,
    (data: BotRecordedRes) => {
      ToastService.success({
        content: `The record file from ${
          platformName[data.meetInfo.platform] ?? ''
        } of the room id ${data.meetInfo.meetingId} is successful`,
      })
      getResourcesInsightEngine({
        insightEngineId: data.insightEngineId,
      })
    },
  )

  InsightEngineSocket.on(
    InsightEngineListenerEvent.BOT_STATUS_CHANGE,
    (data: BotStatusChangeRes) => {
      if (data.code === 'in_call_recording') {
        ToastService.success({
          content: `Bot has started recording room ${
            data.meetInfo.meetingId
          } of ${platformName[data.meetInfo.platform] ?? ''}`,
        })
      }
    },
  )

  InsightEngineSocket.on(
    InsightEngineListenerEvent.DOWNLOAD_RESOURCE_IN_INSIGHT_ENGINE,
    (data: DownloadResourceInInsightEngine) => {
      const { resources } = S.insightEngine
      S.insightEngine.resources = resources.map(r =>
        r.id === data.resourceInInsightEngineId
          ? { ...r, isDownloading: false }
          : r,
      )

      if (data?.url) {
        const { url } = data
        const a = document.createElement('a')
        a.href = url
        a.download = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
  )
}

const platformName = {
  google_meet: 'Google Meet',
  microsoft_teams_live: 'Microsoft Teams',
  zoom: 'Zoom',
}
