import { Typography } from 'antd'
import clsx from 'clsx'
import type { FC } from 'react'

import css from './style.module.scss'

import { CONSTANTS } from '../../../styles/Constants'
import { getFontSizeBySize } from './getFontSizeBySize'
import type { AppTextProps, TTextContainerProps } from './type'

const { Text } = Typography

export const BeamText: FC<AppTextProps> = props => {
  const { containerProps, ...textProps } = props
  const containerPropsDefault: TTextContainerProps = {
    successColor: containerProps?.successColor ?? CONSTANTS.statusColor.success,
    dangerColor: containerProps?.dangerColor ?? CONSTANTS.statusColor.danger,
    warningColor: containerProps?.warningColor ?? CONSTANTS.statusColor.warning,
    secondaryColor:
      containerProps?.secondaryColor ?? CONSTANTS.basicColor.gray3,
  }
  return (
    <div
      className={clsx(containerProps?.classNameContainer, css.TextContainer)}
      style={{
        ...containerProps?.style,
        // @ts-ignore
        '--clTypographySuccess': containerPropsDefault.successColor,
        '--clTypographyWarning': containerPropsDefault.warningColor,
        '--clTypographyDanger': containerPropsDefault.dangerColor,
        '--clTypographySecondary': containerPropsDefault.secondaryColor,
      }}
    >
      <Text
        {...textProps}
        className={clsx(css.TextStyled, textProps.className)}
        style={{
          ...textProps.style,
          // @ts-ignore
          '--fTextStyled': getFontSizeBySize(textProps.size) + '',
        }}
      ></Text>
    </div>
  )
}
