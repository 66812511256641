import { useState } from 'react'

import { separateLogic } from '##/reactjs/redux'

import { ModalService } from '../../../../components/Modal/Modal'
import { ToastService } from '../../../../components/widget/Toast'
import {
  FACEBOOK_AUTH_CLIENT_ID,
  FACEBOOK_AUTH_STATE_CODE,
  FACEBOOK_AUTH_STATE_SCOPE,
  GOOGLE_CLIENT_ID,
  LINKED_LIVE_STATE_SCOPE,
  LINKEDIN_LIVE_CLIENT_ID,
  LINKEDIN_LIVE_STATE_CODE,
} from '../../../../config'
import type { Output } from '../../../../context/gql/codegen'
import { useGqlReauthenticateOutput } from '../../../../context/gql/codegen'
import { reduxStore } from '../../../../context/redux'
import { S } from '../../../../context/store'
import { catchPromiseError } from '../../../../utils/catchPromiseError'
import { useLoadGsiScript } from '../../../../utils/googleLogin/useGoogleLoadScript'
import { useGoogleLogin } from '../../../../utils/googleLogin/useGoogleLogin'
import { useFacebookLogin } from '../../../../utils/useFacebookLogin'
import { useLinkedInLogin } from '../../../../utils/useLinkedInLogin'
import { EventModal } from '../CreateEvent'
import { OutputType } from '../DestinationEnum'
import { InstagramFormDestination } from '../InstagramFormDestination'
import { RTMPDestinationForm } from '../RTMPDestinationForm'
import type { IDestinationActionProps } from './type'
import { OutputInSessionStatus } from './type'

export const createComponent = separateLogic(
  (props: IDestinationActionProps) => {
    const { destination, refreshDestination, destinationInSession, isExpired } =
      props
    const [visible, setVisible] = useState<boolean>(false)
    const onEditItem = (type?: OutputType) => {
      if (!destination) {
        return
      }
      if (type === OutputType.RTMP || type === OutputType.SRT) {
        ModalService.show(RTMPDestinationForm, {
          ...props,
        })
        return
      }
      if (type === OutputType.Instagram) {
        ModalService.show(InstagramFormDestination, {
          ...props,
          isUpdate: true,
        })
        return
      }
      ModalService.show(EventModal, {
        output: destination,
        data: destinationInSession,
        onSave: refreshDestination,
      })
    }
    const onRemoveItem = async () => {
      if (!destination) {
        return
      }
      await reduxStore.context.gql.deleteOutput({ id: destination.id })
      refreshDestination()
    }
    const handleVisibleChange = (v: boolean) => {
      let visibility = v
      if (!destination) {
        visibility = v
      } else if (visibility) {
        visibility = false
      }
      setVisible(visibility)
    }
    const handleClickDestination = async (d: Output) => {
      if (new Date(d.expiredAt ?? '').getTime() < new Date().getTime()) {
        handleRelogin(d.type as OutputType)
        return
      }
      if (!destinationInSession) {
        if (!destination) {
          return
        }
        if (
          d.type === OutputType.RTMP ||
          d.type === OutputType.SRT ||
          d.type === OutputType.Instagram
        ) {
          await reduxStore.context.gql.createOutputInSession({
            data: {
              outputId: d.id,
              sessionId: S.webrtc.sessionId,
            },
          })
          refreshDestination()
        } else {
          ModalService.show(EventModal, {
            onSave: refreshDestination,
            output: d,
          })
        }
        return
      }
      if (destinationInSession.status === OutputInSessionStatus.Expired) {
        handleRelogin(d.type as OutputType)
        return
      }
      await reduxStore.context.gql.deleteOutputInSession({
        id: destinationInSession.id,
      })
      refreshDestination()
    }
    const [, executeReauthenticate] = useGqlReauthenticateOutput()
    const loadScriptSuccess = useLoadGsiScript({
      onScriptLoadError: () => {
        ToastService.error({ content: 'Cookie is required', duration: 2 })
      },
    })
    const loginHandler = (code: string, type: OutputType) => {
      const redirectUrl =
        location.origin +
        (type === OutputType.Linkedin || type === OutputType.Facebook
          ? '/studio'
          : '')
      const data = executeReauthenticate({
        id: destinationInSession?.outputId || destination?.id || '',
        data: {
          code,
          redirectUrl,
          sessionId: S.webrtc.sessionId,
        },
      })
      catchPromiseError(data, () => {
        refreshDestination()
      })
    }
    const loginGoogle: any = useGoogleLogin({
      onSuccess: (tokenResponse: any) => {
        loginHandler(tokenResponse.code, OutputType.Youtube)
      },
      onError: error => {
        ToastService.error({ content: 'Login failed', duration: 2 })
      },
      scope:
        'https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.channel-memberships.creator https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtubepartner-channel-audit',
      clientId: GOOGLE_CLIENT_ID,
      scriptLoadedSuccessfully: loadScriptSuccess,
      flow: 'auth-code',
      redirect_uri: `${location.origin}`,
    })

    const loginLinkedIn = useLinkedInLogin({
      redirectUri: `${location.origin}/studio`,
      loginHandler: async code => {
        loginHandler(code, OutputType.Linkedin)
      },
      scope: LINKED_LIVE_STATE_SCOPE,
      clientId: LINKEDIN_LIVE_CLIENT_ID,
      stateCode: LINKEDIN_LIVE_STATE_CODE,
    })
    const loginFacebook = useFacebookLogin({
      redirect_uri: `${location.origin}/studio`,
      loginHandler: async code => {
        loginHandler(code, OutputType.Linkedin)
      },
      scope: FACEBOOK_AUTH_STATE_SCOPE,
      client_id: FACEBOOK_AUTH_CLIENT_ID,
      state: FACEBOOK_AUTH_STATE_CODE,
    })
    const handleRelogin = (socialType: OutputType) => {
      switch (socialType) {
        case OutputType.Youtube:
          loginGoogle()
          break
        case OutputType.Linkedin:
          loginLinkedIn()
          break
        case OutputType.Facebook:
          loginFacebook()
          break
        case OutputType.Instagram:
          ModalService.show(InstagramFormDestination, {
            ...props,
            onRemoveItem,
          })
          break
        default:
          break
      }
    }
    return {
      visible,
      onEditItem,
      onRemoveItem,
      handleVisibleChange,
      handleClickDestination,
      setVisible,
      componentProps: props,
      warning: isExpired,
    }
  },
)
