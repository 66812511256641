import moment from 'moment'

import type { Resource } from '../../../context/gql/codegen'
import { serverTime } from '../shared/serverTime'

export class Helper {
  static getDaysInMonth = (date: Date) => moment(date).daysInMonth()
  static getMonthName = (month: number) => {
    const monthNames = [
      'Jan',
      'Feb',
      'March',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    return monthNames[month - 1]
  }
  static getTime = (date: Date | string) => {
    const dateValidate = typeof date === 'string' ? new Date(date) : date
    return (
      (dateValidate.getHours() >= 10
        ? `${dateValidate.getHours()}`
        : `0${dateValidate.getHours()}`) +
      ':' +
      ('00' + dateValidate.getMinutes()).slice(-2)
    )
  }
  static getAmPm = (hours: number) => (hours >= 12 ? 'pm' : 'am')
  static getDayName = (date: Date | string) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const dateValidate = typeof date === 'string' ? new Date(date) : date
    return days[dateValidate.getDay()]
  }
  static getFirstDayOfMonth = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth(), 1)
  static getLastDayOfMonth = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 1)
  static generateGuid = () => {
    const s: any = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] as any & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'
    const uuid = s.join('')
    return uuid
  }
  static validateEmail = (email: string) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  static validUrl = (str: string) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ) // fragment locator
    return !!pattern.test(str)
  }
  static formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) {
      return '0 Bytes'
    }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
  }
  static getColorRange = (warmnessColorValue: number) => {
    let colorRange: number[] = []
    if (warmnessColorValue <= 0) {
      colorRange = [0, 1]
      return colorRange
    }
    if (warmnessColorValue > 0) {
      colorRange = [1, 2]
      return colorRange
    }

    return colorRange
  }
  static getWarmnessColor = (
    colorValue: number,
    gradients: any[][],
    sliderHeight: number,
  ) => {
    const colorRange = this.getColorRange(colorValue)

    const firstColor = gradients[colorRange[0]][1]
    const secondColor = gradients[colorRange[1]][1]
    const firstColorY = sliderHeight * (gradients[colorRange[0]][0] / 100)
    const secondColorY =
      sliderHeight * (gradients[colorRange[1]][0] / 100) - firstColorY
    const sliderX = sliderHeight * ((colorValue + 30) / 100) - firstColorY
    const ratio = sliderX / secondColorY
    const result = Helper.pickHex(secondColor, firstColor, ratio)
    const color = `rgb(${result.join()},1)`
    return color
  }
  static pickHex = (color1: number[], color2: number[], weight: number) => {
    const p = weight
    const w = p * 2 - 1
    const w1 = (w / 1 + 1) / 2
    const w2 = 1 - w1
    const rgb = [
      Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2),
    ]
    return rgb
  }
  static getArrayWithFromTo = (from: number, to: number) => {
    const arr: number[] = []

    for (let i = from; i <= to; i++) {
      arr.push(i)
    }
    return arr
  }
  static getDiffTimeNotification = (timestamp: number) => {
    const now = serverTime.date()
    const diffSeconds = Math.floor((now.getTime() - timestamp) / 1000)

    if (diffSeconds < 60) {
      return 'Just now'
    } else if (diffSeconds < 3600) {
      const diffMinutes = Math.floor(diffSeconds / 60)
      return `${diffMinutes} ${diffMinutes === 1 ? 'minute' : 'minutes'} ago`
    } else if (diffSeconds < 86400) {
      const diffHours = Math.floor(diffSeconds / 3600)
      return `${diffHours} ${diffHours === 1 ? 'hour' : 'hours'} ago`
    } else if (diffSeconds < 86400) {
      const diffDays = Math.floor(diffSeconds / 86400)
      return `${diffDays} ${diffDays === 1 ? 'day' : 'days'} ago`
    } else if (diffSeconds < 604800) {
      const diffWeek = Math.floor(diffSeconds / 604800)
      return `${diffWeek} ${diffWeek === 1 ? 'week' : 'weeks'} ago`
    } else {
      const diffMonth = Math.floor(diffSeconds / 2592000)
      return `${diffMonth} ${diffMonth === 1 ? 'month' : 'months'} ago`
    }
  }
  static diffMinutes = (startDate: Date, endDate: Date) =>
    Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60)
}

export const buildRecordingFileName = (
  title: string,
  item: Pick<Resource, 'createdAt' | 'url'>,
) =>
  title.toLowerCase().replace(/\s+/g, '-') +
  '_' +
  moment(item.createdAt).format('YYYY-MM-DD_HH-mm-ss') +
  '.' +
  item.url?.split('.').pop()
export const getIdNumber = (id: number, ids: number[]) => {
  let newId = id
  const isIdExit = ids.find(i => i === newId)
  if (isIdExit || newId === 0) {
    newId = getIdNumber(Math.floor(Math.random() * (ids.length + 2)), ids)
  }
  return newId
}
