import type { AsyncStorageStatic } from '@react-native-async-storage/async-storage'
import { get, set } from 'lodash'
import { autorun, makeAutoObservable } from 'mobx'

import type { Undefined } from '##/shared/ts'

import { CustomizeUIStore } from './admin/CustomizeUIStore'
import { AmsStore } from './ams/AmsStore'
import { InsightEngineStore } from './insight-engine/InsightEngineStore'
import { AuthStore } from './shared/AuthStore'
import { InvitationStore } from './shared/InvitationStore'
import { LayoutStore } from './shared/LayoutStore'
import { LocalStore } from './shared/LocalStore'
import { ProfileStore } from './shared/ProfileStore'
import { SharedStore } from './shared/SharedStore'
import type { ExcludeNullish } from './studio/WebrtcStore'
import { WebrtcStore } from './studio/WebrtcStore'

export type RootStore = {
  webrtc: WebrtcStore
  profile: ProfileStore
  invitation: InvitationStore
  layout: LayoutStore
  ams: AmsStore
  shared: SharedStore
  auth: AuthStore
  local: LocalStore
  customizeUI: CustomizeUIStore
  insightEngine: InsightEngineStore
}

export const stores = {
  shared: SharedStore,
  auth: AuthStore,
  local: LocalStore,
  profile: ProfileStore,
  invitation: InvitationStore,
  layout: LayoutStore,
  ams: AmsStore,
  webrtc: WebrtcStore,
  customizeUI: CustomizeUIStore,
  insightEngine: InsightEngineStore,
}

export const S = {} as RootStore
WebrtcStore.getRootStore = () => S

Object.entries(stores).forEach(([name, ctor]) => {
  const inst = new ctor()
  makeAutoObservable(inst)
  set(S, name, inst)
})

export const resetStore = <T extends keyof RootStore>(name: T) => {
  const ctor = stores[name]
  const inst = new ctor()
  makeAutoObservable(inst)
  set(S, name, inst)
}

/**
 * Automatically synchronize store with local storage.
 * Now it only supports those types: boolean, number, string.
 * ```ts
 * class ExampleStore {
 *    static sync: Sync<ExampleStore> = {
 *      // ...
 *    }
 * }
 * ```
 */
export type Sync<T> = {
  [k in keyof T]?: ExcludeNullish<T[k]> extends boolean
    ? 'boolean'
    : ExcludeNullish<T[k]> extends number
      ? 'number'
      : ExcludeNullish<T[k]> extends string
        ? 'string'
        : never
}

/**
 * Automatically synchronize store with local storage.
 * For rn code, we must set the AsyncStorage instance manually.
 * We can not use `@react-native-async-storage/async-storage` here
 *    since it is outside of rn folder and wont be included in the rn build
 */
export const syncLocalStorage = async (asyncStorage: AsyncStorageStatic) => {
  const $ = S.shared.storage
  let resolveStorage = (...args: any[]) => {}
  $.loading = true
  $.promise = new Promise(r => {
    resolveStorage = r
  })

  const tobeSynced: string[] = []
  Object.entries(stores).forEach(([name, ctor]) => {
    if (!('sync' in ctor)) {
      return
    }
    const { sync } = ctor
    if (typeof sync !== 'object' || !sync) {
      return
    }
    Object.keys(sync).forEach(k => {
      tobeSynced.push(`${name}.${k}`)
    })
  })

  const ls = 'syncLocalStorage'
  let existingData: Undefined = undefined
  try {
    const json = await asyncStorage.getItem(ls)
    existingData = json && JSON.parse(json)
  } catch (err) {
    console.error(err)
    asyncStorage.removeItem(ls)
  }

  tobeSynced.forEach(k => {
    const v = get(existingData, k)
    const t = get(stores, k.replace('.', '.sync.'))
    if (v === null || v === undefined || typeof v !== t) {
      return
    }
    set(S, k, v)
  })

  autorun(() => {
    const data = tobeSynced.reduce((d, k) => set(d, k, get(S, k)), {})
    asyncStorage.setItem(ls, JSON.stringify(data))
  })

  resolveStorage()
  $.loading = false
}
